export class FileUtils {
  static downloadFile(data: Blob, fileName: string): void {
    const url = URL.createObjectURL(data);

    const link = this.createLink(url);
    link.setAttribute('download', fileName);

    this.clickLink(link);
    URL.revokeObjectURL(url);
  }

  static previewFile(data: Blob): void {
    const url = URL.createObjectURL(data);
    this.previewUrl(url);
  }

  static previewUrl(url: string): void {
    const link = this.createLink(url);
    this.clickLink(link);
  }

  private static createLink(url: string): HTMLAnchorElement {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_blank');

    return link;
  }

  private static clickLink(link: HTMLAnchorElement): void {
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

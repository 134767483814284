import { GetterTree } from 'vuex';
import { State } from '@/store/summary/state';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public current = (state: State) => {
    return state.current;
  }

  public valuationDates = (state: State) => {
    if (state.current == null) {
      return [];
    }

    return state.current.valuationDates;
  }

  public fromValuationDate = (state: State) => {
    if (state.current == null) {
      return null;
    }

    return this.valuationDates(state).find((d) => d.valuationDate === state.fromValuationDate) || null;
  }

  public toValuationDate = (state: State) => {
    if (state.current == null) {
      return null;
    }

    return this.valuationDates(state).find((d) => d.valuationDate === state.toValuationDate) || null;
  }

  public availableFromValuationDates = (state: State) => {
    const valuationDates = this.valuationDates(state);
    if (valuationDates.length <= 1) {
      return valuationDates;
    }

    return valuationDates.filter((date) => date.valuationDate !== state.toValuationDate);
  }

  public availableToValuationDates = (state: State) => {
    const valuationDates = this.valuationDates(state);
    if (valuationDates.length <= 1) {
      return valuationDates;
    }

    return valuationDates.filter((date) => date.valuationDate !== state.fromValuationDate);
  }
}

import { GetterTree } from 'vuex';
import { State } from '@/store/workflow/state';
import { camelcase } from '@/utilities/text.utils';

export class Getters implements GetterTree<State, any> {
    [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

    public valuationDates = (state: State) => {
      if (state.current === null) {
        return [];
      }
      return state.current!.dates;
    }

    public current = (state: State) => {
      return state.current;
    }

    public selectedReport = (state: State) => {
      if (state.selectedReportId === null) {
        return null;
      }

      return this.workflows(state).find((r) => r.id === state.selectedReportId) || null;
    }

    public selectedValuationDate = (state: State) => {
      return state.selectedValuationDate;
    }

    public workflows = (state: State) => {
      return state.pages.flatMap((p) => p.workflows);
    }

    public pages = (state: State) => {
      return state.pages;
    }

    public options = (state: State) => {
      if (state.current === null) {
        return [];
      }

      const date = state.selectedValuationDate;

      if (date === null || date === undefined) {
        return [];
      }

      const results = state.current.options.filter((item) => {
        const name = camelcase(item.name);

        const count = date.options[name] as number | undefined;

        if (count === undefined) {
          return false;
        }

        if (count < 1 && item.hideIfEmpty) {
          return false;
        }

        return true;
      });

      return results;
    }

    public currentOption = (state: State) => {
      if (state.current === null) {
        return null;
      }

      return state.currentOption;
    }

    public flagColours = (state: State) => {
      if (state.current === null) {
        return null;
      }

      return state.current.flags;
    }

    public isLoading = (state: State) => {
      return state.loadingWorkflowReports;
    }

    public isDownloading = (state: State) : boolean => {
      return state.downloadingReports;
    }
}

<template>
  <validation-observer v-slot="{ passes, invalid, pending }">
    <b-modal id="modal-center"
             v-model="visible"
             ok-variant="primary"
             ok-title="Submit"
             cancel-variant="link"
             centered
             title="Add new phrase"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             :ok-disabled="(invalid && !pending) || submitting"
             @ok.prevent="passes(onOk)"
             @cancel="onCancel"
             @close="onClose"
    >
      <b-container fluid>
        <validation-provider
          ref="phrase"
          rules="required|uniquephrase"
          v-slot="{ valid, errors }"
          tag="div"
        >
          <b-form-group label="Phrase:"
                        label-cols="3"
                        label-cols-lg="3"
                        label-for="phrase-input"
          >
            <b-form-input
              id="phrase-input"
              autofocus
              v-model="phrase"
              :state="errors[0] ? false : (valid ? true : null)"
              @keypress.enter="passes(onOk)"
            ></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-container>
    </b-modal>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { Tenant } from '../../store/tenant/state';
import { BvModalEvent } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

const tenantModule = namespace('tenant');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreatePhraseDialog extends Mixins(BvToastMixin) {
  @tenantModule.Getter('current') private current!: Tenant;

  @Prop({ type: Boolean, required: true }) public show!: boolean;

  private visible: boolean = false;
  private phrase: string | null = null;
  private submitting: boolean = false;

  private async onOk(event: BvModalEvent): Promise<void> {
    this.submitting = true;
    const phrase = this.phrase;

    try {
      await this.$store.dispatch('languageMaintenance/createPhraseAsync', { phrase, language: this.current.defaultLanguage, languageId: this.current.defaultLanguageId });
      this.showSuccessToast(`Successfully created phrase '${phrase}'.`);

      this.$emit('ok', { phrase });
    } catch (e) {
      this.showErrorToast(`Could not create phrase '${phrase}'. Please try again.`);
    } finally {
      this.$store.commit('languageMaintenance/setSearchPhrase', phrase);
      this.submitting = false;
    }
  }

  private onCancel(): void {
    this.$emit('cancel');
  }

  private onClose(): void {
    this.$emit('close');
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.phrase = null;
    this.visible = value;
  }
}
</script>

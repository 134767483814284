<template>
  <div class="workflow-report-rendering-status is-background-distribution-waiting text-center">
    <div class="text">{{ report.remainingDistributionBatches }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { WorkflowReport } from '../../../store/workflow/state';

@Component
export default class AwaitingBatchDistribution extends Vue {
  @Prop({ required: true, type: Object }) public report!: WorkflowReport;
}
</script>

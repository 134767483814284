import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State, Notification } from '@/store/notification/state';
import uuid from 'uuid';
import { kebabcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public append = (state: State, notification: Notification) => {
    if (notification.metadata !== undefined) {
      // Find the existing notification with the same correlation id
      const matchedNotification = state.notifications.find((n) => n.metadata && n.metadata.correlationId === notification.metadata?.correlationId) || null;
      if (matchedNotification !== null) {
        if (matchedNotification.link!.params!.reportId !== undefined) {
          // Delete the report id from the query so the link only takes the user to
          // the main tab (Waiting, Rejected etc).
          delete matchedNotification.link!.params!.reportId;
        }

        matchedNotification.metadata!.count += 1;

        notification = {
          message: buildBatchNotificationMessage(matchedNotification.metadata!.count, notification.metadata!.action, notification.metadata!.reportGroup, notification.metadata!.dateFormat, notification.metadata!.displayDate),
          read: false,
          timestamp: notification.timestamp,
          variant: matchedNotification.variant,
          link: matchedNotification.link,
          metadata: matchedNotification.metadata!,
        };

        this.dismiss(state, matchedNotification);
      }
    }

    const count = state.notifications.length + 1;
    const index = count % 50;

    Vue.set(notification, 'id', notification.id || uuid());
    state.notifications.splice(index, 0, notification);
  }

  public dismiss = (state: State, notification: Notification) => {
    const index = state.notifications.findIndex((n) => n === notification);

    if (index > -1) {
      state.notifications.splice(index, 1);
    }
  }

  public read = (state: State, notification: Notification) => {
    const match = state.notifications.find((n) => n === notification) || null;

    if (match === null) {
      return;
    }

    notification.read = true;
  }

  public updateWorkflowReportLinks = (state: State, data: { reportId: number, toState: string }) => {
    const matches = state.notifications.filter((n) => n.link !== undefined && n.link?.params?.reportId === data.reportId.toString());

    for (const notification of matches) {
      notification.link!.params!.option = kebabcase(data.toState);
    }
  }

  private clearAll = (state: State) => {
    state.notifications = [];
  }
}

function buildBatchNotificationMessage(count: number, action: string | null, reportGroup: string | null, dateFormat: string | null, displayDate: string | null): string {
  let message = `A new batch of ${count} requests have been ${action} `;

  if (reportGroup !== null) {
    message += `for report group '${reportGroup}' `;
  }

  if (dateFormat !== null) {
    message += `and valuation date '${date(displayDate, dateFormat)}' `;
  }

  return message.trim() + '.';
}

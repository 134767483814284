<template>
  <div class="summary-period-list" :style="{ height: height }">
    <summary-period-table v-for="period in periods" :key="period.name" :period="period" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SummaryPeriodTable from './summary-period-table.vue';
import { Summary, SummaryPeriod } from '../../store/summary/state';
import { namespace } from 'vuex-class';

const summaryModule = namespace('summary');
const environmentModule = namespace('environment');

@Component({
  components: {
    SummaryPeriodTable,
  },
})
export default class SummaryPeriodList extends Vue {
  @summaryModule.Getter private current!: Summary | null;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;

  private get periods(): Array<SummaryPeriod> {
    if (this.current === null) {
      return [];
    }

    return this.current.periods;
  }

  public get height(): string {
    if (this.environment === null) {
      return 'calc(100vh - 196px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 196px)';
    }

    return 'calc(100vh - 220px)';
  }
}
</script>

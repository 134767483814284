import { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import { DirectiveOptions } from 'vue/types/umd';
import Sortable from 'sortablejs';

const createSortable = (el: HTMLElement, options: any, vnode: VNode) => {
  return Sortable.create(el, {
    ...options,
  });
};

export const sortable: DirectiveOptions = {
  bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    (el as any)._sortable = createSortable(el.querySelector('tbody')!, binding.value, vnode);
  },
};

<template>
  <div>
    <b-table class="workflow-report-commentary-table mb-0"
             striped
             hover
             thead-class="text-capitalize"
             :fields="fields"
             :items="filteredResults"
             :busy="loading"
             sort-icon-left
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
    >
      <template v-slot:head(description)="data">
        <span>{{ data.label }}<table-filter-header :ref="`${data.label}-filter`" v-model="descriptionFilter" @input="onDescriptionFiltered" :show="showFilter" @cleared="onDescriptionFilterClear" @hidden="onShowFilter(data.label)" @shown="onShowFilter(data.label)" /></span>
      </template>

      <template v-slot:cell(receivedDate)="{ item }">
        {{ item.receivedDate | datetime }}
      </template>

      <template v-slot:cell(complete)="{ item }">
        <workflow-report-content-status :item="item" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown :disabled="item.links === null || (item.links.document.length < 1 && item.links.report.length < 1)" :no-caret="true" variant="outline-primary" size="sm" right :popper-opts="{ modifiers: { computeStyle: { gpuAcceleration: false } } }">
          <template v-slot:button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-item v-if="item.links !== null && item.links.document.length > 0" title="View document" @click="onViewDocumentClicked(item)">View document</b-dropdown-item>
          <b-dropdown-item v-if="item.links !== null && item.links.report.length > 0" title="View report" @click="onViewReportClicked(item)">View report</b-dropdown-item>
          <b-dropdown-item v-if="item.links !== null && item.links.document.length > 0 && item.actions.canCheckOut" @click="onDocumentCheckoutClicked(item)">Check out</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <document-checkout-confirmation-dialog :title="`Check out document ${(actionItem !== null ? actionItem.links.document[0].id : null)}`" :show="showDocumentCheckoutConfirmation" @ok="onDocumentCheckoutConfirmationOkClicked" @cancel="onDocumentConfirmationCancelled" @close="onDocumentConfirmationCancelled" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { DateTime } from 'luxon';
import { WorkflowReportContentItemResponse } from '@/api/responses/workflow/workflow-report-content-item-response';
import debounce from 'lodash.debounce';
import WorkflowReportContentStatus from './workflow-report-content-status.vue';
import TableFilterHeader from '@/components/table/table-header-filter.vue';
import DocumentCheckoutConfirmationDialog from '@/components/workflow/document-checkout-confirmation-dialog.vue';
import { FindDocumentResponse } from '@/api/responses/document-library/find-document-response';
import { kebabcase } from '@/utilities/text.utils';
import { Tenant } from '../../store/tenant/state';
import { BvTableFieldArray } from 'bootstrap-vue';
import { FindWorkflowResponse } from '../../api/responses/workflow/find-workflow-response';
import { date } from '../../filters/date';
import { WorkflowDate, WorkflowOption, WorkflowReport } from '../../store/workflow/state';
import * as Sentry from '@sentry/browser';

const tenantModule = namespace('tenant');
const workflowModule = namespace('workflow');

@Component({
  components: {
    WorkflowReportContentStatus,
    TableFilterHeader,
    DocumentCheckoutConfirmationDialog,
  },
})
export default class WorkflowReportContentTable extends Mixins(BvToastMixin) {
  private sortBy: string | null = null;
  private sortDesc: string | null = null;
  private descriptionFilter: string | null = null;
  private showFilter: boolean = false;
  private showDocumentCheckoutConfirmation: boolean = false;
  private actionItem: WorkflowReportContentItemResponse | null = null;
  @Prop({ type: String, required: true }) public name!: string;
  @Prop({ type: Array, required: true }) public content!: Array<WorkflowReportContentItemResponse>;
  @Prop({ type: Boolean, required: true }) public loading!: boolean;
  @tenantModule.Getter private selectedReportGroup!: { id: number, name: string } | null;
  @tenantModule.Getter('current') private currentTenant!: Tenant | null;
  @tenantModule.Getter private tenants!: Array<Tenant>;
  @workflowModule.Getter private selectedValuationDate!: WorkflowDate | null;
  @workflowModule.State private currentOption!: WorkflowOption | null;
  @workflowModule.Getter private selectedReport!: WorkflowReport | null;

  public get fields(): BvTableFieldArray {
    return [
      { key: 'complete', label: '', class: ['col-auto'] },
      { key: 'description', label: this.name, class: ['w-100'] },
      { key: 'receivedDate', label: 'Date', class: ['col-auto'], tdClass: ['text-nowrap'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); } },
      { key: 'actions', label: '', class: ['col-auto'], sortable: false }
    ];
  }

  public get filteredResults(): Array<WorkflowReportContentItemResponse> {
    if (this.content == null || this.content.length < 1) {
      return [];
    }

    if (!this.descriptionFilter) {
      return this.content;
    }

    return this.content
      .filter((a) => a.description !== null && a.description!.toLowerCase().includes(this.descriptionFilter!.trim().toLowerCase()));
  }

  private onShowFilter(id: string): void {
    this.showFilter = !this.showFilter;

    if (this.showFilter) {
      this.$nextTick(() => (this.$refs[`${id}-filter`] as TableFilterHeader).focus());
    }
  }

  private onDescriptionFiltered = debounce(this.onDescriptionFilteredInternal, 250);
  private onDescriptionFilteredInternal(value: string | null) {
    this.descriptionFilter = value;
  }

  private onDescriptionFilterClear(): void {
    this.descriptionFilter = null;
  }

  private async onViewDocumentClicked(item: WorkflowReportContentItemResponse, index: number): Promise<void> {
    if (item.links !== null && item.links.document.length > 0) {
      const documentId = item.links.document[0].id;
      const result = await this.$store.dispatch('documentLibrary/findDocumentAsync', documentId) as FindDocumentResponse;

      if (result === null) {
        return;
      }

      const { ...params } = this.$route.params;

      if (this.currentTenant!.id !== result.tenantId) {
        const match = this.tenants.find((t) => t.id === result.tenantId) || null;

        if (match !== null) {
          params.tenant = kebabcase(match.name);
        }
      }

      if (this.selectedReportGroup!.id !== result.reportGroupId) {
        const match = this.currentTenant!.reportGroups.find((t) => t.id === result.reportGroupId) || null;

        if (match !== null) {
          params.reportGroup = kebabcase(match.name);
        }
      }

      params.documentId = documentId.toString();

      await this.$router.push({ name: 'document-library', params: params });
    }
  }

  private async onViewReportClicked(item: WorkflowReportContentItemResponse, index: number): Promise<void> {
    if (item.links === null || item.links.report.length < 1) {
      return;
    }

    const reportId = item.links.report[0];

    try {
      const result = await this.$store.dispatch('workflow/findWorkflowReportAsync', reportId) as FindWorkflowResponse;

      if (result === null) {
        return;
      }

      const currentTenant = this.currentTenant!;
      const params = { ...this.$route.params };
      const currentReportGroup = this.selectedReportGroup!;
      const currentState = this.currentOption!;

      let paramsChanged: boolean = false;

      if (currentTenant.id !== result.tenantId) {
        const matchingTenant = this.tenants.find((t) => t.id === result.tenantId) || null;

        if (matchingTenant === null) {
          throw new Error('No matching tenant found.');
        }

        params.tenant = kebabcase(matchingTenant.name);
        paramsChanged = true;
      }

      if (currentReportGroup.id !== result.reportGroupId) {
        params.reportGroup = kebabcase(result.reportGroup);
        paramsChanged = true;
      }

      const currentValuationDate = this.selectedValuationDate;

      if (currentValuationDate!.valuationDate !== result.valuationDate) {
        params.valuationDate = kebabcase(date(result.displayDate, result.dateFormat));
        paramsChanged = true;
      }

      if (currentState.id !== result.stateId) {
        params.option = kebabcase(result.state);
        paramsChanged = true;
      }

      if (params.reportId !== reportId.toString()) {
        params.reportId = reportId.toString();
        paramsChanged = true;
      }

      if (paramsChanged) {
        try {
          await this.$store.commit('workflow/reset');
          await this.$router.push({ name: 'workflow', params: params });
        } catch (e) {
        }
      }
    } catch (e) {
      this.showErrorToast(`Could not find report '${reportId}'. Please try again.`);
    }
  }

  private onDocumentCheckoutClicked(item: WorkflowReportContentItemResponse): void {
    this.actionItem = item;
    this.showDocumentCheckoutConfirmation = true;
  }

  private async onDocumentCheckoutConfirmationOkClicked(): Promise<void> {
    try {
      await this.$store.dispatch('documentLibrary/checkoutDocumentAsync', { documentId: this.actionItem!.links.document[0].id, reportGroupId: this.actionItem!.links.document[0].reportGroupId });

      const report = (this.selectedReport! as WorkflowReport & { __version: number | undefined });

      if (report.__version === undefined) {
        Vue.set(report!, '__version', 1);
      } else {
        report!.__version++;
      }

      this.showSuccessToast(`Successfully checked out document '${this.actionItem!.links.document[0].id}'.`);
    } catch (e) {
      this.showErrorToast(`Could not checkout document '${this.actionItem!.links.document[0].id}'. Please try again.`);
      Sentry.captureException(e);
    }

    this.onDocumentConfirmationCancelled();
  }

  private onDocumentConfirmationCancelled(): void {
    this.actionItem = null;
    this.showDocumentCheckoutConfirmation = false;
  }
}
</script>

const th = ['', 'thousand', 'million', 'billion', 'trillion'];
const dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
const tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export function numberToWords(value: number): string | null {
  const s = value.toString();
  let length = s.indexOf('.');

  if (length === -1) {
    length = s.length;
  }

  if (length > 15) {
    return null;
  }

  const parts = s.split('');
  let result = '';
  let sk = 0;

  for (let i = 0; i < length; i++) {
    if ((length - i) % 3 === 2) {
      if (parts[i] === '1') {
        result += tn[Number(parts[i + 1])] + ' ';
        i++;
        sk = 1;
      } else if (parts[i] !== '0') {
        result += tw[Number(parts[i]) - 2] + ' ';
        sk = 1;
      }
    } else if (parts[i] !== '0') {
      result += dg[Number(parts[i])] + ' ';

      if ((length - i) % 3 === 0) {
        result += 'hundred ';
        sk = 1;
      }
    }

    if ((length - i) % 3 === 1) {
      if (sk > 0) {
        result += th[Number(length - i - 1) / 3] + ' ';
        sk = 0;
      }
    }
  }

  if (length !== s.length) {
    result += 'point ';

    for (let i = length + 1; i < s.length; i++) {
      result += dg[Number(parts[i])] + ' ';
    }
  }

  return result.replace(/\s+/g, ' ').trim();
}

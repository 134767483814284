export class State {
  public current: Summary | null = null;
  public fromValuationDate: string | null = null;
  public toValuationDate: string | null = null;
}

export interface Summary {
  valuationDate: string;
  displayDate: string;
  previousValuationDate: string | null;
  previousDisplayDate: string | null;
  dateFormat: string;
  periods: Array<SummaryPeriod>;
  valuationDates: Array<SummaryValuationDate>;
}

export interface SummaryPeriod {
  name: string;
  valuationDate: string;
  displayDate: string;
  dateFormat: string;
  entries: Array<SummaryPeriodEntry>;
}

export interface SummaryPeriodEntry {
  status: 'Unknown' | 'Error' | 'Warning' | 'Information' | 'Success' | 'AwaitingDistribution';
  description: string;
  count: number | null;
  batchId: number | null;
  links: SummaryPeriodEntryLink;
  schedule: SummaryPeriodEntrySchedule | null;
  actions: SummaryPeriodEntryActions;
}

export interface SummaryPeriodEntryActions {
  canDeleteReport: boolean,
  canDistributeBatch: boolean,
  canReRun: boolean,
  canReRunSystemReports: boolean,
  canRunNewEntities: boolean,
  canRunNewEntity: boolean,
  canRunNow: boolean,
  canRunReplacement: boolean,
  canRunSystemReports: boolean,
}

export interface SummaryPeriodEntrySchedule {
  entityId: number;
  regionId: number;
  subEntityId: number;
}

export interface SummaryPeriodEntryLink {
  reports: SummaryLinkReport | null;
  issues: SummaryLinkIssue | null;
}

export interface SummaryLinkReport {
  reportId: number | null;
  step: string | null;
  valuationDate: string | null;
  displayDate: string | null;
  dateFormat: string | null;
}

export interface SummaryLinkIssue {
  type: string;
  valuationDate: string;
  displayDate: string;
  dateFormat: string;
}

export interface SummaryStatistics {
  description: string;
  series: Array<SummaryStatisticsSeries>;
  labels: Array<string>;
}

export interface SummaryStatisticsSeries {
  name: string;
  colour: { r: number, b: number, g: number } | null;
  values: Array<SummaryStatisticsSeriesValue>;
}

export interface SummaryStatisticsSeriesValue {
  label: string;
  value: number;
}

export interface SummaryValuationDate {
  valuationDate: string;
  displayDate: string | null;
}

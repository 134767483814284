<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { ChartOptions } from 'chart.js';
import { SummaryStatistics } from '@/store/summary/state';

@Component({
  extends: Bar,
})
export default class SummaryPeriodChart extends Vue {
  @Prop({ type: Object, required: true }) private data!: SummaryStatistics;
  @Prop({ type: Object, default: null }) private options!: ChartOptions | null;

  private get colours() {
    const colours: Array<string> = this.data.labels.map((l) => {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);

      return `rgb(${r}, ${g}, ${b})`;
    });

    return colours;
  }

  private get formattedData() {
    const data = {
      labels: this.data.labels,
      datasets: this.data.series.map((s, i) => ({
        label: s.name,
        data: s.values.map((v) => v.value),
        backgroundColor: s.colour !== null ? `rgb(${s.colour.r}, ${s.colour.g}, ${s.colour.b})` : this.colours[i % 4],
      })),
    };

    return data;
  }

  private get formattedOptions(): ChartOptions {
    const max = Math.round((Math.max(...this.formattedData.datasets.flatMap((set) => Math.max(...set.data))) * 1.1)) || undefined;
    return this.options || {
      showLines: false,
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            suggestedMax: max,
          },
        }],
      },
    };
  }

  public mounted(): void {
    // @ts-ignore
    this.renderChart(this.formattedData, this.formattedOptions);
  }

  @Watch('data')
  private onDataChanged(): void {
    // @ts-ignore
    this.renderChart(this.formattedData, this.formattedOptions);
  }

  @Watch('options', { deep: true })
  private onOptionsChanged(): void {
    // @ts-ignore
    this.renderChart(this.formattedData, this.formattedOptions);
  }
}
</script>

import { Api } from '@/api/api';
import { DashboardResponse } from './responses/dashboard/dashboard-response';
import { DashboardForValuationDateResponse } from './responses/dashboard/dashboard-for-valuation-date-response';
import { DashboardStatisticsReportGroupResponse } from './responses/dashboard/dashboard-statistics-reportgroup-response';
import axios, { CancelTokenSource } from 'axios';

export class DashboardApi extends Api {
  private dashboardForValuationDateCancellationToken: CancelTokenSource | null = axios.CancelToken.source();

  public constructor() {
    super();
  }

  public getDashboardConfigurationAsync(): Promise<DashboardResponse> {
    return this.getAsync<DashboardResponse>('v1/statistics');
  }

  public getDashboardForValuationDateAsync(valuationDate: string): Promise<DashboardForValuationDateResponse> {
    if (this.dashboardForValuationDateCancellationToken !== null) {
      this.dashboardForValuationDateCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.dashboardForValuationDateCancellationToken = axios.CancelToken.source();
    this.dashboardForValuationDateCancellationToken.token.throwIfRequested();

    return this.getAsync<DashboardForValuationDateResponse>('v1/statistics/dashboard', { valuationDate }, this.dashboardForValuationDateCancellationToken.token);
  }

  public getDashboardReportGroupForValuationDateAsync(reportGroupId: number, valuationDate: string): Promise<DashboardStatisticsReportGroupResponse> {
    return this.getAsync<DashboardStatisticsReportGroupResponse>(`v1/statistics/dashboard/${reportGroupId}`, { valuationDate });
  }
}

<template>
  <span>
    <b-button
      v-if="filterValue"
      title="Clear filter"
      size="sm"
      variant="link"
      @click.stop="onFilterClear"
    >
      (clear filter)
    </b-button>
    <span @click.stop="toggleFilter" style="cursor: pointer;">
      <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
        <g data-v-11c9e491="">
          <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
        </g>
      </svg>
    </span>
    <b-form-input :ref="'filter'" class="mt-3" v-if="show" v-model="filterValue" @keyup.esc="onEsc" />
  </span>
</template>

<script lang="ts">
import { BFormInput } from 'bootstrap-vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TableFilterHeader extends Vue {
  @Prop({ type: Boolean, required: true }) show!: boolean;
  @Prop({ required: true }) value!: string | null;

  get filterValue(): string | null {
    return this.value;
  }

  private set filterValue(value: string | null) {
    this.$emit('input', value);
  }

  onFilterClear(): void {
    this.$emit('cleared');
    if (this.show) {
      this.toggleFilter();
    }
  }

  toggleFilter(): void {
    this.$emit(this.show ? 'hidden' : 'shown');
  }

  onEsc(): void {
    this.onFilterClear();
  }

  focus(): void {
    (this.$refs.filter as BFormInput)?.focus();
  }
}
</script>

<template>
  <data-load-running-icon v-if="item.status === 'Running'" />
  <div v-else-if="classes !== null" :class="classes" class="data-load-status text-center" :title="title">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataLoad } from '../../store/data/state';
import DataLoadRunningIcon from './data-load-status-icons/data-load-running.vue';

@Component({
  components: {
    DataLoadRunningIcon,
  },
})
export default class DataLoadStatusIcon extends Vue {
  @Prop({ type: Object, required: true }) public item!: DataLoad;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.item.status) {
      case 'Succeeded':
        return { 'is-background-success': true };
      case 'Failed':
        return { 'is-background-danger': true };
      case 'Running':
        return { 'is-background-info': true };
      case 'WaitingForOtherFiles':
        return { 'is-background-orange': true };
      default:
        return null;
    }
  }

  public get title(): string | null {
    if (this.item.tooltips.status !== null) {
      return this.item.tooltips.status;
    }

    switch (this.item.status) {
      case 'Succeeded':
        return 'Succeeded';
      case 'Failed':
        return 'Failed';
      case 'Running':
        return 'Running';
      case 'WaitingForOtherFiles':
        return 'Waiting for other files';
      default:
        return null;
    }
  }

  public get text(): string | null {
    switch (this.item.status) {
      case 'Failed':
        return '!';
      case 'Succeeded':
        return '✓';
      case 'WaitingForOtherFiles':
        return 'i';
      default:
        return null;
    }
  }
}
</script>

/* eslint-disable no-console */
import { ILogger } from './ILogger';

class ConsoleLogger implements ILogger {
  trackException(exception: any, customProperties?: any): void {
    console.error('Exception:', exception);

    if (customProperties) {
      console.log('Custom Properties:', customProperties);
    }
  }
}
let consoleLoggerInstance: ConsoleLogger | null = null;

export function getConsoleLogger(): ILogger {
  if (!consoleLoggerInstance) {
    consoleLoggerInstance = new ConsoleLogger();
    console.log('Instance of console logger created');
  }

  return consoleLoggerInstance;
}

<template>
  <b-modal id="modal-center"
           visible
           ok-variant="primary"
           ok-title="Submit"
           cancel-variant="link"
           centered
           :title="title"
           :no-close-on-backdrop="true"
           @ok="onOk"
           @cancel="onCancel"
           @close="onClose"
  >
    <span>Are you sure?</span>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AttachmentRemoveDialog extends Vue {
  @Prop({ required: true }) title!: string | null;

  onOk(): void {
    this.$emit('ok');
  }

  onCancel(): void {
    this.$emit('cancel');
  }

  onClose(): void {
    this.$emit('close');
  }
}
</script>

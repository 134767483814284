import { WorkflowReportRenderCompleted } from '../events/workflow-report-render-completed';
import store from '@/store';

async function handleWorkflowReportRenderCompleted(data: WorkflowReportRenderCompleted): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportRenderCompleted event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  if (data.payload.fromStateId !== data.payload.toStateId) {
    store.commit('workflow/tryRemoveReportFromPage', reportId);
    await store.dispatch('workflow/tryMoveReportToPage', { ...data.payload, reportId });
    await store.dispatch('workflow/tryUpdateOptionCounts', data.payload);
    await store.dispatch('notification/tryUpdateWorkflowReportLinks', { ...data.payload, reportId });
    return;
  }

  await store.dispatch('workflow/tryUpdateReportProperties', {
    numberOfPages: data.payload.numberOfPages,
    commentary: data.payload.commentary,
    pageStatus: data.payload.pageStatus,
    renderingStatus: 'Blank',
    lastRenderedDate: data.timestamp,
    reportId: reportId,
  });
}

export default handleWorkflowReportRenderCompleted;

import { HelpResponse } from '@/api/responses/help/help-response';

export class State {
  public current: HelpResponse | null = null;
}

export interface HelpVideo {
  name: string;
  fileSize: number;
  contentType: string;
}

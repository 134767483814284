import { ActionTree, ActionContext } from 'vuex';
import { State } from '@/store/user/state';
import { UserApi } from '@/api/user-api';
import userManager from '@/services/user-manager/user-manager';

const userApi = new UserApi();

export class Actions implements ActionTree<State, any> {
    [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

    public loadMeAsync = async (context: ActionContext<State, any>) => {
      const user = await userApi.getMeAsync();
      context.commit('setUser', user);
    }

    public logoutAsync = async (context: ActionContext<State, any>) => {
      await userManager.signoutRedirect();
    }
}

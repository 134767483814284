<template>
  <div :class="classes" class="workflow-report-status" :title="title">
    <div class="text">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkflowReport } from '@/store/workflow/state';
import { DateTime } from 'luxon';

@Component
export default class WorkflowReportStatus extends Vue {
  @Prop({ type: Object, required: true }) public item!: WorkflowReport;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.item.status) {
      case 'Blank':
        return null;
      case 'OK':
        return { 'is-background-success': true };
      case 'Warning':
        return { 'is-background-warning': true };
      case 'Error':
        return { 'is-background-danger': true };
      default:
        return null;
    }
  }

  public get title(): string | null {
    const deadlineDate = DateTime.fromISO(this.item.deadlineDate!);
    const createdDate = DateTime.fromISO(this.item.createdDate);
    let completedText = '';
    const completedDate = this.item.completedDate !== null ? DateTime.fromISO(this.item.completedDate) : null;

    if (this.item.completedDate !== null && this.item.deadlineDate !== null) {
      const completedDate = DateTime.fromISO(this.item.completedDate);

      if (completedDate < deadlineDate) {
        return `Created at ${createdDate.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}
Completed at ${completedDate.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}
Deadline at ${deadlineDate.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}`;
      }
    }

    if (completedDate !== null) {
      completedText = `
Completed at ${completedDate!.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}`;
    }

    return `Created at ${createdDate.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}
Deadline at ${deadlineDate.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}${completedText}`;
  }
}
</script>

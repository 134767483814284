import { ActionTree, ActionContext } from 'vuex';
import { State } from '@/store/schedule/state';
import { ScheduleApi } from '@/api/schedule-api';

type ConfigurationState = { tenant: string, reportGroup: string, entity: string, settings: string };

const api = new ScheduleApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public getScheduleForReportGroupAsync = async (context: ActionContext<State, any>, reportGroup: { id: number; name: string }) => {
    try {
      context.commit('setLoading', true);
      const result = await api.getScheduleForReportGroupAsync(reportGroup.id);

      context.commit('setCurrent', result);
      context.commit('clearEntityScheduleRequestPages');
      context.commit('setLoading', false);
    } catch (e) {
    }
  }

  public getScheduleForEntityAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number }) => {
    try {
      context.commit('setLoading', true);
      const result = await api.getScheduleForEntityAsync(payload.entityId, payload.reportGroupId);

      context.commit('setEntitySchedule', result);
      context.commit('setLoading', false);
    } catch (e) {
    }
  }

  public getScheduleActivityForEntityAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number }) => {
    try {
      const result = await api.getScheduleActivityForEntityAsync(payload.entityId, payload.reportGroupId);
      context.commit('setEntityScheduleActivity', result);
    } catch (e) {
    }
  }

  public getCompletedScheduleEntityRequestsAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number, page: number, pageSize: number }) => {
    try {
      const result = await api.getCompletedScheduleEntityRequestsAsync(payload.entityId, payload.reportGroupId, payload.page, payload.pageSize);
      context.commit('setEntityScheduleRequestPage', result);
    } catch (e) {
    }
  }

  public enableEntityScheduleAsync = async (context: ActionContext<State, any>, payload: { subEntityId: number, entityId: number, reportGroupId: number }) => {
    api.refreshCorrelationId();
    await api.enableEntityScheduleAsync(payload.subEntityId, payload.entityId, payload.reportGroupId);
  }

  public disableEntityScheduleAsync = async (context: ActionContext<State, any>, payload: { subEntityId: number, entityId: number, reportGroupId: number }) => {
    api.refreshCorrelationId();
    await api.disableEntityScheduleAsync(payload.subEntityId, payload.entityId, payload.reportGroupId);
  }

  public enableEntityScheduleForRegionAsync = async (context: ActionContext<State, any>, payload: { regionId: number, subEntityId: number, entityId: number, reportGroupId: number }) => {
    api.refreshCorrelationId();
    await api.enableEntityScheduleForRegionAsync(payload.regionId, payload.subEntityId, payload.entityId, payload.reportGroupId);
  }

  public disableEntityScheduleForRegionAsync = async (context: ActionContext<State, any>, payload: { regionId: number, subEntityId: number, entityId: number, reportGroupId: number }) => {
    api.refreshCorrelationId();
    await api.disableEntityScheduleForRegionAsync(payload.regionId, payload.subEntityId, payload.entityId, payload.reportGroupId);
  }

  public enableEntityScheduleForLanguageAsync = async (context: ActionContext<State, any>, payload: { languageId: number, subEntityId: number, entityId: number, reportGroupId: number }) => {
    api.refreshCorrelationId();
    await api.enableEntityScheduleForLanguageAsync(payload.languageId, payload.subEntityId, payload.entityId, payload.reportGroupId);
  }

  public disableEntityScheduleForLanguageAsync = async (context: ActionContext<State, any>, payload: { languageId: number, subEntityId: number, entityId: number, reportGroupId: number }) => {
    api.refreshCorrelationId();
    await api.disableEntityScheduleForLanguageAsync(payload.languageId, payload.subEntityId, payload.entityId, payload.reportGroupId);
  }

  public runEntityScheduleAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean }) => {
    api.refreshCorrelationId();
    return await api.runEntityScheduleAsync(payload.entityId, payload.reportGroupId, payload.valuationDate, payload.useApprovalWorkflow, payload.useStandardDistribution);
  }

  public runEntityScheduleForRegionAsync = async (context: ActionContext<State, any>, payload: { regionId: number, subEntityId: number, entityId: number, reportGroupId: number, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean }) => {
    api.refreshCorrelationId();
    return api.runEntityScheduleForRegionAsync(payload.regionId, payload.subEntityId, payload.entityId, payload.reportGroupId, payload.valuationDate, payload.useApprovalWorkflow, payload.useStandardDistribution);
  }

  public runEntityScheduleForLanguageAsync = async (context: ActionContext<State, any>, payload: { languageId: number, subEntityId: number, entityId: number, reportGroupId: number, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean }) => {
    api.refreshCorrelationId();
    return api.runEntityScheduleForLanguageAsync(payload.languageId, payload.subEntityId, payload.entityId, payload.reportGroupId, payload.valuationDate, payload.useApprovalWorkflow, payload.useStandardDistribution);
  }

  public runScheduleForReportGroupAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean, systemReportsOnly?: boolean, newEntitiesOnly?: boolean }) => {
    api.refreshCorrelationId();
    return api.runScheduleForReportGroupAsync(payload.reportGroupId, payload.valuationDate, payload.useApprovalWorkflow, payload.useStandardDistribution, payload.systemReportsOnly, payload.newEntitiesOnly);
  }

  public makeEntityPrimaryAsync = async (context: ActionContext<State, any>, payload: { subEntityId: number, entityId: number, reportGroupId: number }) => {
    api.refreshCorrelationId();
    return api.makeEntityPrimaryAsync(payload.subEntityId, payload.entityId, payload.reportGroupId);
  }

  public reorderScheduledEntity = async (context: ActionContext<State, any>, payload: { from: number, to: number, subEntityId: number, entityId: number, reportGroupId: number }) => {
    api.refreshCorrelationId();
    return api.reorderScheduledEntity(payload.from, payload.to, payload.subEntityId, payload.entityId, payload.reportGroupId);
  }
}

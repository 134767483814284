<template>
  <div class="notification-tray-message" :class="{ 'is-unread': isUnread }">
    <div class="notification-tray-message-content" :class="classes">
      <div class="row">
        <div class="col">
          {{ notification.message }}
        </div>

        <div class="col-auto ml-auto pl-0">
          <b-button-close @click="dismiss(notification)" />
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-auto pr-0" v-if="notification.link">
          <router-link :to="notification.link">View</router-link>
        </div>

        <div class="col-auto" v-if="isUnread">
          <a href="#" @click="read(notification)">Mark as read</a>
        </div>
      </div>

      <div class="row pt-2" :title="timestamp">
        <div class="col-auto">
          <small>{{ timeago }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Notification } from '@/store/notification/state';
import { DateTime, ToRelativeUnit, Duration } from 'luxon';
import { datetime } from '@/filters/datetime';

const MILLISECOND = 1;
const SECOND = MILLISECOND * 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

@Component
export default class NotificationMessage extends Vue {
  @Prop({ type: Object, required: true }) private notification!: Notification;

  private timer: number | null = null;
  private now: DateTime | null = DateTime.utc();

  public get variant(): string | null {
    if (this.notification.variant === undefined) {
      return null;
    }

    return `is-${this.notification.variant}`;
  }

  public get isUnread(): boolean {
    return !this.notification.read;
  }

  public get classes(): Object {
    const result: { [key: string]: boolean } = { 'is-unread': this.isUnread };

    if (this.variant !== null) {
      result[this.variant] = true;
    }

    return result;
  }

  public get timeago(): string | null {
    const now = this.now;
    const { unit, duration } = this.getTimestampUnitType();

    return now!.plus(duration).toRelativeCalendar({ unit: unit });
  }

  public get timestamp(): string {
    return datetime(this.notification.timestamp);
  }

  private dismiss(notification: Notification): void {
    this.$store.commit('notification/dismiss', notification);
  }

  private read(notification: Notification): void {
    this.$store.commit('notification/read', notification);
  }

  public mounted(): void {
    const handler: TimerHandler = () => {
      this.now = DateTime.utc();
      this.timer = setTimeout(handler, this.calculateNextTimeagoTimeout());
    };
    this.timer = setTimeout(handler, this.calculateNextTimeagoTimeout());
  }

  public beforeDestroy(): void {
    clearInterval(this.timer!);
  }

  private getTimestampUnitType(): { unit: ToRelativeUnit, duration: Duration } {
    const duration = DateTime.fromISO(this.notification.timestamp).diffNow(['weeks', 'days', 'hours', 'minutes', 'seconds']);

    let unit: ToRelativeUnit = 'seconds';

    if (duration.years !== 0 || Math.abs(Math.trunc(duration.weeks)) === 52) {
      unit = 'years';
    } else if (duration.weeks !== 0 || Math.abs(Math.trunc(duration.days)) === 7) {
      unit = 'weeks';
    } else if (duration.days !== 0 || Math.abs(Math.trunc(duration.hours)) === 24) {
      unit = 'days';
    } else if (duration.hours !== 0 || Math.abs(Math.trunc(duration.minutes)) === 60) {
      unit = 'hours';
    } else if (duration.minutes !== 0 || Math.abs(Math.trunc(duration.seconds)) === 60) {
      unit = 'minutes';
    }

    return { unit, duration };
  }

  private calculateNextTimeagoTimeout(): number {
    const { unit } = this.getTimestampUnitType();

    switch (unit) {
      case 'years':
      case 'weeks':
      case 'days':
      case 'hours':
        return HOUR;
      case 'minutes':
        return MINUTE;
      case 'seconds':
        return SECOND;

      default:
        return MINUTE;
    }
  }
}
</script>

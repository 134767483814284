import Pluralize from 'pluralize';

export function uppercase(value: string | null): string {
  if (value == null) {
    return '';
  }

  return value.toUpperCase();
}

export function lowercase(value: string | null): string {
  if (value == null) {
    return '';
  }

  return value.toLowerCase();
}

export function pascalcase(value: string | null): string {
  if (value == null) {
    return '';
  }

  return camelcase(value).replace(/^[a-z]/, uppercase);
}

export function camelcase(value: string): string {
  value = replaceAccents(value);
  value = removeNonWord(value)
    .replace(/-/g, ' ') // convert all hyphens to spaces
    .replace(/\s[a-z]/g, uppercase) // convert first char of each word to UPPERCASE
    .replace(/\s+/g, '') // remove spaces
    .replace(/^[A-Z]/g, lowercase); // convert first char to lowercase

  return value;
}

export function kebabcase(value: string | null): string {
  if (value === null) {
    return '';
  }

  return removeNonWord(lowercase(value))
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)!
    .map(x => x.toLowerCase())
    .join('-');
}

export function pluralize(value: string | null): string {
  if (value == null) {
    return '';
  }

  return Pluralize(value, 0);
}

export function removeNonAlphanumeric(value: string): string {
  // Allow only alphanumerics & spaces
  return value.replace(/[^0-9a-zA-Z ]/g, '');
}

export function splitPascalCase(value: string): string {
  const regex = /($[a-z])|[A-Z][^A-Z]+/g;
  const parts = value.match(regex);

  if (parts === null) {
    return '';
  }

  return parts.join(' ');
}

function replaceAccents(value: string): string {
  // verifies if the String has accents and replace them
  if (value.search(/[\xC0-\xFF]/g) > -1) {
    value = value
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xDE]/g, 'P')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFE]/g, 'p')
      .replace(/[\xFD\xFF]/g, 'y');
  }

  return value;
}

function removeNonWord(value: string): string {
  return value.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, '');
}

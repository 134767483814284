<template>
  <div>
    <filter-bar />

    <div class="container-fluid dashboard-content" :style="{ height: headerStyle }">
      <div class="row pt-4">
        <div class="col">
          <report-group-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import FilterBar from '@/components/dashboard/filter-bar.vue';
import ReportGroupList from '@/components/dashboard/report-group-list.vue';
import { namespace } from 'vuex-class';

const environmentModule = namespace('environment');

@Component({
  components: {
    FilterBar,
    ReportGroupList,
  },
})
export default class Home extends Vue {
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 196px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 172px)';
    }

    return 'calc(100vh - 196px)';
  }
}
</script>

import { Location } from 'vue-router';

export class State {
  public notifications: Array<Notification> = [];
}

export interface Notification {
  id?: string;
  variant?: string;
  message: string;
  link?: Location | null;
  read: boolean;
  timestamp: string;
  metadata?: {
    correlationId: string;
    count: number;
    reportGroup: string | null;
    dateFormat: string | null;
    displayDate: string | null;
    action: string | null;
  };
}

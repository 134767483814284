<template>
  <div class="data-issues" :aria-busy="loading">
    <b-table class="data-loads-table"
             ref="table"
             striped
             hover
             :sticky-header="headerStyle"
             show-empty
             sort-icon-left
             selectable
             select-mode="single"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :busy="loading"
             :fields="fields"
             :items="filteredResults"
             @row-clicked="onRowClicked"
             @row-selected="onRowSelected"
             @sort-changed="onSortChanged"
    >
      <template v-slot:head(id)="data">
        <span>{{ data.label }}<table-filter-header :ref="`${data.label}-filter`" v-model="idFilter" :show="showFilter" @cleared="onFiltersCleared" @hidden="onShowFilter(data.label)" @shown="onShowFilter(data.label)" /></span>
      </template>

      <template v-slot:head(fileName)="data">
        <span>{{ data.label }}<table-filter-header :ref="`${data.label}-filter`" v-model="fileNameFilter" :show="showFilter" @cleared="onFiltersCleared" @hidden="onShowFilter(data.label)" @shown="onShowFilter(data.label)" /></span>
      </template>

      <template v-slot:head(submittedBy)="data">
        <span>{{ data.label }}<table-filter-header :ref="`${data.label}-filter`" v-model="submittedByFilter" :show="showFilter" @cleared="onFiltersCleared" @hidden="onShowFilter(data.label)" @shown="onShowFilter(data.label)" /></span>
      </template>

      <template v-slot:cell(timestamp)="{ item }">
        {{ item.timestamp | datetime }}
      </template>

      <template v-slot:cell(fileSize)="{ item }">
        <file-size v-if="item.fileSize > 0" :bytes="item.fileSize" />
      </template>

      <template v-slot:cell(status)="{ item }">
        <data-load-status-icon :item="item" />
      </template>

      <template v-slot:[`cell(${field.key})`]="{ item }" v-for="field in fields.filter((f) => f.key.startsWith('content.'))">
        <!-- Some complicated rendering logic here -->
        <b-checkbox :key="`${field.key}`" :disabled="true" v-model="item.content[field.key.replace('content.', '')]" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-button-group>
          <b-button size="sm" variant="outline-primary" v-if="item.downloadFileName" :title="getDownloadFileTooltip(item)" @click="onDownloadDataLoadClicked(item)">
            <b-icon icon="download" aria-hidden="true"></b-icon>
          </b-button>
          <b-button size="sm" :disabled="item.status === 'Running'" v-if="item.downloadFileName" variant="outline-primary" title="Reload data file" @click="onReloadDataLoadClicked(item)">
            <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon>
          </b-button>
        </b-button-group>
      </template>
    </b-table>

    <reload-dataload-confirmation-dialog :title="`Reload data file ${(actionItem !== null ? actionItem.id : null)}?`" :item="actionItem" :show="showReloadDataLoadConfirmation" @ok="onReloadDataLoadConfirmed" @cancel="onReloadDataLoadCancelled" @close="onReloadDataLoadCancelled" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { Tenant, ReportGroup } from '../../store/tenant/state';
import throttle from 'lodash.throttle';
import { DataDate, DataLoad } from '../../store/data/state';
import { pascalcase, splitPascalCase } from '@/utilities/text.utils';
import DataLoadStatusIcon from './data-load-status-icon.vue';
import ReloadDataloadConfirmationDialog from './reload-dataload-confirmation-dialog.vue';
import { DateTime } from 'luxon';
import { BTable } from 'bootstrap-vue';
import { parseFilters, applyFilters } from '@/components/table/input-filter';
import TableFilterHeader from '../table/table-header-filter.vue';
import FileSize from '@/components/utils/file-size.vue';
import { FileUtils } from '@/utilities/file.utils';

const tenantModule = namespace('tenant');
const dataModule = namespace('data');
const environmentModule = namespace('environment');

@Component({
  components: {
    DataLoadStatusIcon,
    ReloadDataloadConfirmationDialog,
    TableFilterHeader,
    FileSize,
  },
})
export default class DataLoadsTable extends Mixins(BvToastMixin) {
  @Prop({ type: Boolean, required: true }) public loading!: boolean;
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @environmentModule.Getter('current') private environment!: { environment: string };
  @dataModule.Getter private dataLoads!: Array<DataLoad>;
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;
  @dataModule.Getter private selectedDataLoad!: DataLoad | null;
  @dataModule.Getter private currentValuationDate!: DataDate | null;

  private sortBy: string | null = null;
  private sortDesc: boolean = false;
  private selectedRowIndex: number = -1;
  private preventScrollLoading: boolean = false;
  private showFilter: boolean = false;
  private showReloadDataLoadConfirmation: boolean = false;
  private actionItem: DataLoad | null = null;
  private idFilter: string | null = null;
  private fileNameFilter: string | null = null;
  private submittedByFilter: string | null = null;

  public get filteredResults(): Array<DataLoad> {
    let dataLoads = this.dataLoads;

    if (dataLoads == null || dataLoads.length < 1) {
      return [];
    }

    if (!this.idFilter && !this.fileNameFilter && !this.submittedByFilter) {
      return dataLoads;
    }

    if (this.idFilter) {
      const idFilters = parseFilters(this.idFilter);
      dataLoads = dataLoads.filter((dl) => applyFilters(dl, x => x.id, idFilters));
    }

    if (this.fileNameFilter) {
      const fileNameFilters = parseFilters(this.fileNameFilter);
      dataLoads = dataLoads.filter((dl) => applyFilters(dl, x => x.fileName, fileNameFilters, { mode: 'include' }));
    }

    if (this.submittedByFilter) {
      const submittedByFilters = parseFilters(this.submittedByFilter);
      dataLoads = dataLoads.filter((dl) => applyFilters(dl, x => x.submittedBy, submittedByFilters, { mode: 'include' }));
    }

    return dataLoads;
  }

  private get fields(): Array<{}> {
    const fields = [
      { key: 'id', label: 'ID', class: ['col-auto', 'text-nowrap'], tdClass: ['text-nowrap'], sortable: true },
      { key: 'timestamp', label: 'Date', class: ['col-auto', 'text-nowrap'], tdClass: ['text-nowrap'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); }, sortByFormatted: true },
      { key: 'submittedBy', label: 'Submitted By', class: ['col-auto', 'text-nowrap'], tdClass: ['text-nowrap', 'word-break-all'], sortable: true },
      { key: 'fileName', label: 'File Name', class: ['col'], sortable: true },
      { key: 'fileSize', label: 'Size', class: ['col-auto', 'text-nowrap'], tdClass: ['text-nowrap'], sortable: true },
      { key: 'status', label: 'Status', class: ['col-auto', 'text-nowrap'], tdClass: ['text-nowrap', 'text-center'], sortable: true },
      { key: 'reportsUpdated', label: 'Records Updated', class: ['col-auto'], tdClass: ['text-center'], sortable: true },
      { key: 'reportsAffected', label: 'Reports Affected', class: ['col-auto'], tdClass: ['text-center'], sortable: true }
    ];

    if (this.dataLoads.length > 0) {
      const item = this.dataLoads[0];
      const keys = Object.keys(item.content);

      for (const key of keys) {
        // @ts-ignore
        fields.push({ key: `content.${key}`, label: splitPascalCase(pascalcase(key)), class: ['col-auto', 'text'], tdClass: ['text-center'], sortable: false });
      }
    }

    // @ts-ignore
    fields.push({ key: 'actions', label: '', tdClass: ['text-right'], class: ['col-auto'] });

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  public async mounted(): Promise<void> {
    let lastScrollPos: number = 0;
    ((this.$refs.table as Vue).$el as HTMLDivElement).addEventListener('scroll', throttle((e) => {
      const element = ((this.$refs.table as Vue).$el as HTMLDivElement);
      const offsetHeight = element.offsetHeight;
      const scrolled = element.scrollTop;
      const height = element.scrollHeight;

      // NOTE(Dan): We're scrolling back up the list from the bottom, so we don't want to trigger here...
      if (lastScrollPos >= scrolled) {
        lastScrollPos = scrolled;
        return;
      }

      lastScrollPos = scrolled;

      if (offsetHeight + scrolled >= (height * 0.9)) {
        this.$emit('scroll-completed', { height, offsetHeight, scrolled });
      }
    }, 1000));

    this.$nextTick(() => {
      if (this.selectedDataLoad !== null) {
        const table = (this.$refs.table as BTable);

        if (table === undefined) {
          return;
        }

        const index = (table.computedItems as Array<DataLoad>).findIndex((r) => r.id === this.selectedDataLoad!.id);

        if (index !== this.selectedRowIndex) {
          this.selectedRowIndex = index;
          table.selectRow(this.selectedRowIndex);
          this.scrollToSelectedDataLoad();
        }
      }
    });
  }

  public updated(): void {
    this.$nextTick(() => {
      if (this.selectedDataLoad !== null) {
        const index = ((this.$refs.table as BTable).computedItems as Array<DataLoad>).findIndex((r) => r.id === this.selectedDataLoad!.id);

        if (index !== this.selectedRowIndex) {
          this.selectedRowIndex = index;
        }

        (this.$refs.table as BTable).selectRow(this.selectedRowIndex);
      }
    });
  }

  private onShowFilter(id: string): void {
    this.showFilter = !this.showFilter;

    if (this.showFilter) {
      this.$nextTick(() => (this.$refs[`${id}-filter`] as TableFilterHeader).focus());
    }
  }

  @Watch('sortDesc')
  private onSortDescChanged(to: boolean, from: boolean) {
    if (!to && from) {
      this.sortBy = null;
    }
  }

  @Watch('selectedDataLoad')
  private async onSelectedDataLoadChanged(to: DataLoad) {
    this.$nextTick(() => {
      this.scrollToSelectedDataLoad();
    });
  }

  @Watch('currentValuationDate')
  private async onCurrentValuationDateChanged(to: DataLoad) {
    this.$nextTick(() => {
      if (this.selectedDataLoad !== null) {
        return;
      }
      const div = this.$el.querySelector('.data-loads-table') as HTMLDivElement;
      div.scrollTop = 0;
    });
  }

  private onFiltersCleared(): void {
    this.idFilter = null;
    this.fileNameFilter = null;
    this.submittedByFilter = null;
  }

  private onReloadDataLoadClicked(item: DataLoad): void {
    this.actionItem = item;
    this.showReloadDataLoadConfirmation = true;
  }

  private onReloadDataLoadCancelled(): void {
    this.showReloadDataLoadConfirmation = false;
    this.actionItem = null;
  }

  private async onReloadDataLoadConfirmed(): Promise<void> {
    try {
      await this.$store.dispatch('data/reloadDataLoadAsync', { dataLoadId: this.actionItem!.id, reportGroupId: this.currentReportGroup!.id });
    } catch (e) {
      this.showErrorToast(`Could not reload data file '${this.actionItem!.id}'. Please try again.`);
    }

    this.onReloadDataLoadCancelled();
  }

  private getDownloadFileTooltip(item: DataLoad) {
    if (item.downloadFileName?.toLowerCase().endsWith('.zip')) {
      return `Download ZIP
${this.getFileNameWithoutExtension(item.downloadFileName)}`;
    }

    if (item.downloadFileName?.toLowerCase().endsWith('.xlsx')) {
      return `Download XLSX
${this.getFileNameWithoutExtension(item.downloadFileName)}`;
    }

    if (item.downloadFileName?.toLowerCase().endsWith('.pdf')) {
      return `Download PDF
${this.getFileNameWithoutExtension(item.downloadFileName)}`;
    }

    return 'Download';
  }

  private getFileNameWithoutExtension(fileName: string) {
    const index = fileName.lastIndexOf('.');
    if (index > -1) {
      fileName = fileName.substring(0, index);
    }

    return fileName;
  }

  private async onDownloadDataLoadClicked(item: DataLoad): Promise<void> {
    try {
      const data = await this.$store.dispatch('data/downloadDataLoadAsync', { dataLoadId: item.id, reportGroupId: this.currentReportGroup.id }) as Blob;
      FileUtils.downloadFile(data, item.downloadFileName!);
    } catch (e) {
      this.showErrorToast('Could not download file. Please try again.');
    }
  }

  private async scrollToSelectedDataLoad(): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, 100));

    this.$nextTick(() => {
      const div = this.$el.querySelector('.data-loads-table') as HTMLDivElement;
      const table = this.$refs.table as BTable;
      const header = table.$el.querySelector('thead') as HTMLTableSectionElement;
      const row = table.$el.querySelector('.b-table-row-selected') as HTMLTableRowElement | null;

      if (row === null) {
        return;
      }

      const containerHeight = table.$el.clientHeight;
      const rowTop = row.getBoundingClientRect().top - div.getBoundingClientRect().top;
      const rowBottom = rowTop + row.offsetHeight;
      const total = rowTop >= 0 && rowBottom <= containerHeight;
      const partial = (rowTop < 0 && rowBottom > 0) || (rowTop > 0 && rowTop <= containerHeight);

      if (total || partial) {
        return;
      }

      div.scrollTop = div.scrollTop + rowTop - header.offsetHeight;
    });
  }

  private async onRowClicked(item: DataLoad, index: number): Promise<void> {
    if (index === this.selectedRowIndex) {
      this.selectedRowIndex = -1;
      this.$emit('data-load-row-deselected');
      const { dataLoadId, ...params } = this.$route.params;
      try {
        await this.$router.push({ name: 'data', params: { ...params } });
      } catch (e) {
        // TODO(Dan): Navigation errors.
      }
    } else {
      this.selectedRowIndex = index;
      this.$emit('data-load-row-selected', item);
      try {
        await this.$router.push({ name: 'data', params: { ...this.$route.params, dataLoadId: item.id.toString() } });
      } catch (e) {
        // TODO(Dan): Navigation errors.
      }
    }
  }

  private onRowSelected(items: Array<DataLoad>): void {
    if (this.selectedRowIndex > -1 && this.selectedDataLoad !== null) {
      return;
    }

    if (items.length < 1 || items[0] === undefined) {
      return;
    }

    const item = items[0];
    const index = this.filteredResults.findIndex((r) => r.id === item.id);

    this.selectedRowIndex = index;
  }

  private onSortChanged(context: any): void {}
}
</script>

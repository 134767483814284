import store from '@/store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsightsInstance: ApplicationInsights | null = null;

export function getApplicationInsights(): ApplicationInsights {
  if (!appInsightsInstance) {
    appInsightsInstance = new ApplicationInsights({
      config: {
        connectionString: store.getters['environment/loggerConnectionString'],
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAjaxPerfTracking: true,
        enableAjaxErrorStatusText: true,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });

    appInsightsInstance.loadAppInsights();
  }

  return appInsightsInstance;
}

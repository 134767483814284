<template>
  <support-ticket-entry-list
    :entries="messages"
    :get-entry-status-label="getMessageStatusLabel"
    @saved="onMessageSaved"
  />
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { SupportTicketMessage, SupportTicketDetails } from '@/store/support/state';
import { namespace } from 'vuex-class';
import SupportTicketEntryList from '@/components/support/support-ticket-entry-list.vue';

const supportModule = namespace('support');

@Component({
  components: {
    SupportTicketEntryList,
  },
})
export default class SupportTicketMessageList extends Mixins(BvToastMixin) {
  @supportModule.Getter messages!: Array<SupportTicketMessage>;
  @supportModule.Getter ticket!: SupportTicketDetails | null;

  async onMessageSaved(result: { supportIssueDetailID: number, detail: string }): Promise<void> {
    try {
      await this.$store.dispatch('support/amendSupportTicketMessageAsync', { ticketId: this.ticket!.id, supportIssueDetailID: result.supportIssueDetailID, detail: result.detail });

      this.showSuccessToast('Message successfully updated.');
      await this.$store.dispatch('support/getSupportTicketMessagesAsync', this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update Message. Please try again.');
    }
  }

  getMessageStatusLabel(message: SupportTicketMessage) {
    return message.userType === 'Agent' ? 'replied' : 'asked';
  }
}
</script>

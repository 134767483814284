<template>
  <div class="report-group-list">
    <div class="row report-group-list-item" v-for="group in reportGroups" :key="group.name">
      <div class="col-4 report-group-chart">
        <div class="title">
          <div>{{ group.name }} &ndash; {{ group.displayDate | date(group.dateFormat) }}</div>
        </div>
        <report-group-chart :data="group" />
      </div>
      <div class="col-4">
        <report-group-overview-table :report-group="group" />
      </div>
      <div class="col-4">
        <report-group-warning-table :report-group="group" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Dashboard, DashboardStatisticsReportGroup } from '../../store/dashboard/state';
import ReportGroupOverviewTable from './report-group-overview-table.vue';
import ReportGroupWarningTable from './report-group-warning-table.vue';
import ReportGroupChart from './report-group-chart.vue';

const dashboardModule = namespace('dashboard');
const environmentModule = namespace('environment');

@Component({
  components: {
    ReportGroupOverviewTable,
    ReportGroupWarningTable,
    ReportGroupChart,
  },
})
export default class ReportGroupList extends Vue {
  @dashboardModule.Getter private current!: Dashboard | null;
  @dashboardModule.Getter private reportGroups!: Array<DashboardStatisticsReportGroup>;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;

  public get height(): string {
    if (this.environment === null) {
      return 'calc(100vh - 196px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 196px)';
    }

    return 'calc(100vh - 220px)';
  }
}
</script>

<template>
  <div class="environment-bar" :class="classes" v-if="current && current.environment !== 'Production'">
    {{ description }}
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const environmentModule = namespace('environment');

@Component
export default class EnvironmentBar extends Vue {
  @environmentModule.Getter public current!: { environment: string, version: string | null, runtime: string } | null;

  public get description() {
    if (this.current === null) {
      return '';
    }

    if (location.host.includes('localhost')) {
      if (this.current!.version !== null) {
        return `Environment – Local – ${this.current!.version}`;
      } else {
        return 'Environment – Local';
      }
    }

    if (this.current!.version !== null) {
      return `Environment – ${this.current!.environment} – ${this.current!.version}`;
    }

    return `Environment – ${this.current!.environment}`;
  }

  public get classes(): {} {
    if (this.current === null) {
      return {};
    }

    const name = this.current!.environment.toLowerCase();

    return { uat: name === 'uat', dev: name === 'developmentserver', local: name === 'development' && location.host.includes('localhost') };
  }
}
</script>

<template>
  <div class="translation-table">
    <b-table striped
             selectable
             ref="table"
             select-mode="single"
             :sticky-header="headerStyle"
             :fields="fields"
             :items="translations"
             :no-sort-reset="true"
             :no-local-sorting="true"
             :no-local-filtering="true"
             :no-local-paging="true"
             @row-clicked="onLanguageClicked"
    >
      <template v-slot:cell(value)="{ item }">
        <translation-table-value :key="`${item.phrase}-${item.value}`" :translation="item" @saved="onTranslationSaved" @removed="onTranslationRemoved" />
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Translation, Phrase } from '../../store/language-maintenance/state';
import { BvTableFieldArray, BTable } from 'bootstrap-vue';
import TranslationTableValue from './translation-table-value.vue';

const environmentModule = namespace('environment');
const languageMaintenanceModule = namespace('languageMaintenance');

@Component({
  components: {
    TranslationTableValue,
  },
})
export default class LanguageMaintenanceTranslationTable extends Vue {
  @languageMaintenanceModule.Getter private selectedPhrase!: Phrase;
  @languageMaintenanceModule.Getter private translations!: Array<Translation>;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;

  public mounted(): void {
    this.onTranslationsChanged(this.translations);
  }

  public get fields(): BvTableFieldArray {
    const fields: BvTableFieldArray = [
      { key: 'language', label: 'Language', class: ['col-auto'], tdClass: ['text-nowrap'] },
      { key: 'value', label: `Translation of '${this.selectedPhrase?.value}'`, class: ['col']/*, thAttr: { style: 'min-width: 200px;' } */ }
    ];

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 196px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 172px)';
    }

    return 'calc(100vh - 196px)';
  }

  private onTranslationSaved(translation: Translation): void {
    this.$emit('translation-saved', translation);
  }

  private onTranslationRemoved(translation: Translation): void {
    this.$emit('translation-removed', translation);
  }

  private async onLanguageClicked(item: Translation, index: number): Promise<void> {
    if (this.$route.params.language !== item.language) {
      await this.$router.replace({ name: this.$route.name!, params: { ...this.$route.params, language: item.language } });
    } else {
      const { language, ...params } = this.$route.params;
      await this.$router.replace({ name: this.$route.name!, params: params });
    }
  }

  @Watch('translations')
  private async onTranslationsChanged(translations: Array<Translation>): Promise<void> {
    if (translations.length < 1) {
      return;
    }

    const index = translations.findIndex((t) => t.language === this.$route.params.language);

    if (index < 0) {
      (this.$refs.table as BTable).clearSelected();
      const { language, ...params } = this.$route.params;
      await this.$router.replace({ name: this.$route.name!, params: params });
      return;
    }

    (this.$refs.table as BTable).selectRow(index);
  }
}
</script>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import userManager from '@/services/user-manager/user-manager';
import websocketManager from './services/notifications/websocket-manager';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { date } from '@/filters/date';
import { valuationdate } from '@/filters/valuationdate';
import { datetime } from '@/filters/datetime';
import { toString } from './filters/to-string';
import { storeRedirectPath } from './redirect-path';
import '@/scss/app.scss';
import './validation';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { getLogger, setUpLogger } from '@/logging';
await setUpLogger();
Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  getLogger().trackException({
    exception: err,
    properties: {
      component: vm.$options.name || 'unknown',
      info: info,
    },
  });
};
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.filter('date', date);
Vue.filter('valuationdate', valuationdate);
Vue.filter('datetime', datetime);
Vue.filter('string', toString);

async function main() {
  try {
    await store.dispatch('environment/loadAsync');
    const env = store.getters['environment/current'] as { environment: string, version: string, runtime: string };
    Sentry.init({
      dsn: 'https://4469eb46933840269cb25c1316e4e106@o225354.ingest.sentry.io/5281642',
      integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
      environment: `${env.environment} - Web App`,
    });

    try {
      await store.dispatch('user/loadMeAsync');
    } catch (e) {
      const user = await userManager.getUser();

      if (user === null) {
        // NOTE(Dan): If you navigate directly to a URL, but are not logged in you will get sent to login and lose the URL
        //            you were trying to visit. Here, we're going to try and store then restore this on returning to the page.
        if (window.location.pathname !== null && window.location.pathname.length > 0) {
          // NOTE(Dan): If there are no matched components then the url in definitely invalid.
          //            We could also use router.resolve, but this returns a Route regardless of a proper match with a null name
          //            (all of our routes are named), either would work in theory, but using getMatchedComponents for the first
          //            version of this.
          if (router.getMatchedComponents(window.location.pathname).length > 0) {
            storeRedirectPath(location.pathname);
          }
        }

        await userManager.signinRedirect();
        return;
      }
    }

    await store.dispatch('tenant/loadTenantsAsync');
    await websocketManager.startAsync();

    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  } catch (e) {
    if (e instanceof Error) {
      getLogger().trackException({ exception: e });
    }
    // TODO(Dan): Error page, retry etc?!
    setTimeout(main, 5000);
  }
}

main();

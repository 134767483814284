<template>
  <b-modal id="modal-center"
           v-model="visible"
           ok-variant="primary"
           ok-title="Submit"
           cancel-variant="link"
           centered
           :title="title"
           :no-close-on-backdrop="true"
           @ok="onOk"
           @cancel="onCancel"
           @close="onClose"
  >
    <b-container fluid>
      <div class="row">
        <div class="col">
          <b-form-group
            id="input-group-1"
            label="Comments"
            label-for="input-comments"
          >
            <b-form-textarea
              id="input-comments"
              v-model="comments"
              rows="3"
            >
            </b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div v-if="hasApprovalStages">
        <div class="row align-items-center mb-3" v-if="approvalStagesCount === 1">
          <label class="col-5 mb-0 pr-0">Use Approval Workflow</label>
          <label class="col-auto pr-0 pl-0">:</label>
          <div class="col">
            <b-checkbox v-model="useApprovalWorkflow" value="1" unchecked-value="0" />
          </div>
        </div>
        <div class="row align-items-center mb-3" v-else>
          <label class="col-5 mb-0 pr-0">Workflow Start At Stage</label>
          <label class="col-auto pr-0 pl-0">:</label>
          <div class="col">
            <b-form-select v-model="useApprovalWorkflow">
              <b-form-select-option :value="approval.stage" v-for="approval in approvalStages" :key="approval.stage">{{ approval.name }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <label class="col-5 mb-0 pr-0">Use Standard Distribution</label>
        <label class="col-auto pr-0 pl-0">:</label>
        <div class="col">
          <b-checkbox v-model="useStandardDistribution" />
        </div>
      </div>
      <div class="row align-items-center mb-3" v-if="workflow.supportNoCommentaryReplacements">
        <label class="col-5 mb-0 pr-0">Reacquire Commentary</label>
        <label class="col-auto pr-0 pl-0">:</label>
        <div class="col">
          <b-checkbox v-model="includeCommentary" />
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import { namespace } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Workflow } from '../../store/workflow/state';

const workflowModule = namespace('workflow');

@Component
export default class BulkRunReplacementWorkflowReportDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  //  @Prop({ type: Boolean, required: true }) public hasCommentary!: boolean;
  @Prop({ required: true }) public title!: string | null;
  @workflowModule.Getter('current') public workflow!: Workflow;

  private visible: boolean = false;
  private useApprovalWorkflow: number = 1;
  private useStandardDistribution: boolean = true;
  private includeCommentary: boolean = false;
  private approvalStagesCount: number = 0;
  private comments: string | null = null;

  private onOk(): void {
    this.$emit('ok', { comments: this.comments, useApprovalWorkflow: this.useApprovalWorkflow, useStandardDistribution: this.useStandardDistribution, includeCommentary: this.includeCommentary });
    this.useApprovalWorkflow = 1;
    this.useStandardDistribution = true;
    this.includeCommentary = true;
    this.comments = null;
  }

  private onCancel(): void {
    this.$emit('cancel');
    this.useApprovalWorkflow = 1;
    this.useStandardDistribution = true;
    this.includeCommentary = true;
    this.comments = null;
  }

  private onClose(): void {
    this.$emit('close');
    this.useApprovalWorkflow = 1;
    this.useStandardDistribution = true;
    this.includeCommentary = true;
    this.comments = null;
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
  }

  @Watch('hasCommentary')
  private onCommentaryChanged(value: boolean): void {
    this.includeCommentary = value;
  }

  private get hasApprovalStages(): boolean {
    if (this.workflow.approvalStages > 0) {
      this.useApprovalWorkflow = 1;
      this.approvalStagesCount = this.workflow.approvalStages;
      return true;
    }

    this.useApprovalWorkflow = 0;
    return false;
  }

  private get approvalStages(): Array<{ stage: number, name: string }> {
    this.useApprovalWorkflow = 1;

    const stages: Array<{ stage: number, name: string }> = [];
    const approvalStageIDs = [3, 4, 5, 6];
    const approvals = this.workflow.options.filter(option => approvalStageIDs.includes(option.id));

    for (var approval of approvals) {
      let stage = 0;
      if (approval.id === 3) {
        stage = 1;
      } else if (approval.id === 4) {
        stage = 2;
      } else if (approval.id === 5) {
        stage = 3;
      } else if (approval.id === 6) {
        stage = 4;
      }

      stages.push({ stage, name: approval.name });
    }

    return stages;
  }
}
</script>

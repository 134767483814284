<template>
  <div class="phrase-table">
    <b-table striped
             ref="table"
             hover
             selectable
             show-empty
             :sticky-header="headerStyle"
             :fields="fields"
             :items="phrases"
             select-mode="single"
             :no-sort-reset="true"
             :no-local-sorting="true"
             :no-local-filtering="true"
             :no-local-paging="true"
             @row-clicked="onPhraseClicked"
    >
      <template v-slot:cell(value)="{ item }">
        <phrase-table-value :key="item.value" :phrase="item" @saved="onPhraseSaved" @removed="onPhraseRemoved" />
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Phrase } from '../../store/language-maintenance/state';
import { BvTableFieldArray, BTable } from 'bootstrap-vue';
import PhraseTableValue from './phrase-table-value.vue';

const environmentModule = namespace('environment');
const languageMaintenanceModule = namespace('languageMaintenance');

@Component({
  components: {
    PhraseTableValue,
  },
})
export default class LanguageMaintenancePhraseTable extends Vue {
  @languageMaintenanceModule.Getter private selectedPhrase!: Phrase | null;
  @languageMaintenanceModule.Getter private phrases!: Array<Phrase>;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;

  public get fields(): BvTableFieldArray {
    const fields: BvTableFieldArray = [
      { key: 'value', label: 'Phrase', class: ['col']/*, thAttr: { style: 'min-width: 200px;' } */ }
    ];

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 196px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 172px)';
    }

    return 'calc(100vh - 196px)';
  }

  @Watch('selectedPhrase')
  private onSelectedPhraseChanged(value: Phrase | null): void {
    if (value === null) {
      (this.$refs.table as BTable).clearSelected();
      return;
    }

    const index = this.phrases.findIndex((p) => p === value);

    (this.$refs.table as BTable).selectRow(index);
  }

  private async onPhraseClicked(item: Phrase, index: number): Promise<void> {
    if (this.selectedPhrase === item) {
      this.$store.commit('languageMaintenance/setSelectedPhrase', null);
      this.$store.commit('languageMaintenance/setTranslations', []);
    } else {
      await this.$store.dispatch('languageMaintenance/getTranslationsForPhraseAsync', { phrase: item.value });
      this.$store.commit('languageMaintenance/setSelectedPhrase', item.value || null);
    }
  }

  private onPhraseSaved(phrase: { phrase: string, to: string }): void {
    this.$emit('phrase-saved', { ...phrase, language: '', languageId: 0 });
  }

  private onPhraseRemoved(phrase: Phrase): void {
    this.$emit('phrase-removed', phrase);
  }
}
</script>

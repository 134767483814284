import axios, { AxiosResponse, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import userManager from '@/services/user-manager/user-manager';

export async function defaultResponseInterceptor(response: AxiosResponse<any>): Promise<AxiosResponse<any>> {
  return response;
}

export async function authResponseFailureInterceptor(error: any): Promise<any> {
  if (error.response && error.response.status === 401) {
    if (error.config && !error.config.__isRetryRequest) {
      await userManager.signinSilent();
      error.config.__isRetryRequest = true;

      return axios(error.config);
    } else {
      // TODO(Dan): We failed after retrying and still had a 401. We probably need to log the user out.
    }
  }

  return error;
}

export async function applyAuthenticationHeader(config: InternalAxiosRequestConfig ): Promise<InternalAxiosRequestConfig > {
  const user = await userManager.getUser();

  if (user != null) {
    config.headers!.Authorization = `Bearer ${user.access_token}`;
  }
  return config;
}

<template>
  <b-table class="document-distribution-table"
           striped
           hover
           :sticky-header="headerStyle"
           :fields="fields"
           :items="filteredResults"
           :busy="loading"
           sort-icon-left
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
  >
    <template v-slot:head(description)="data">
      <span>
        {{ data.label }}

        <b-button
          v-if="descriptionFilter"
          title="Clear filter"
          size="sm"
          variant="link"
          @click.stop="onDescriptionFilterClear"
        >
          (clear filter)
        </b-button></span>
      <span @click.stop="onShowFilter">
        <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
          <g data-v-11c9e491="">
            <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
          </g>
        </svg>
      </span>
      <b-form-input class="mt-3" v-if="showFilter" v-model="descriptionFilter" @input="onDescriptionFiltered" />
    </template>

    <template v-slot:cell(timestamp)="{ item }">
      {{ item.timestamp | datetime }}
    </template>

    <template v-slot:cell(status)="{ item }">
      <document-distribution-status :item="item" />
    </template>
  </b-table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import debounce from 'lodash.debounce';
import { DocumentDistributionResponse } from '@/api/responses/document-library/document-distribution-response';
import DocumentDistributionStatus from './document-distribution-status.vue';

const environmentModule = namespace('environment');

@Component({
  components: {
    DocumentDistributionStatus,
  },
})
export default class DocumentDistributionTable extends Vue {
  @Prop({ type: Array, required: true }) distribution!: Array<DocumentDistributionResponse>;
  @Prop({ type: Boolean, required: true }) loading!: boolean;
  @environmentModule.Getter('current') environment!: { environment: string, version: string | null, runtime: string } | null;

  sortBy: string | null = null;
  sortDesc: string | null = null;
  descriptionFilter: string | null = null;
  showFilter: boolean = false;

  get fields(): Array<{ key: string, label: string, class?: Array<string> | string, sortable?: boolean, formatter?: Function }> {
    return [
      { key: 'status', label: 'Status', class: ['col-auto'] },
      { key: 'description', label: 'Description', class: ['col'] }
    ];
  }

  get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  public get filteredResults(): Array<DocumentDistributionResponse> {
    if (this.distribution == null || this.distribution.length < 1) {
      return [];
    }

    if (!this.descriptionFilter) {
      return this.distribution;
    }

    return this.distribution
      .filter((a) => a.description!.toLowerCase().includes(this.descriptionFilter!.trim().toLowerCase()));
  }

  onShowFilter(): void {
    this.showFilter = !this.showFilter;
  }

  onDescriptionFiltered = debounce(this.onDescriptionFilteredInternal, 250);
  private onDescriptionFilteredInternal(value: string | null) {
    this.descriptionFilter = value;
  }

  onDescriptionFilterClear(): void {
    this.descriptionFilter = null;
    this.onShowFilter();
  }
}
</script>

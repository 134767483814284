export class State {
  public current: Dashboard | null = null;
  public valuationDate: string | null = null;
  public reportGroups: Array<DashboardStatisticsReportGroup> = [];
}

export interface Dashboard {
  valuationDate: string;
  displayDate: string;
  dateFormat: string;
  valuationDates: Array<DashboardValuationDate>;
}

export interface DashboardValuationDate {
  valuationDate: string;
  displayDate: string | null;
}

export interface DashboardForValuationDate {
  reportGroups: Array<DashboardStatisticsReportGroup>;
}

export interface DashboardReportGroupOverviewValue {
  description: string;
  value: string | null;
}

export interface DashboardReportGroupWarningValue {
  description: string;
  value: string | null;
}

export interface DashboardStatisticsForValuationDateSeries {
  name: string;
  colour: {
    r: number;
    b: number;
    g: number;
  } | null;
  values: Array<DashboardStatisticsSeriesValue>;
}

export interface DashboardStatisticsReportGroup {
  name: string;
  valuationDate: string;
  displayDate: string;
  dateFormat: string;
  overview: Array<DashboardReportGroupOverviewValue>;
  series: Array<DashboardStatisticsForValuationDateSeries>;
  warnings: Array<DashboardReportGroupWarningValue>;
}

export interface DashboardStatisticsSeriesValue {
  label: string;
  category: string;
  categoryColour: {
    r: number;
    b: number;
    g: number;
  } | null;
  value: number;
}

import { ActionTree, ActionContext } from 'vuex';
import { State } from '@/store/environment/state';
import { EnvironmentApi } from '@/api/environment-api';

const environmentApi = new EnvironmentApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public loadConfigAsync = async (context: ActionContext<State, any>) => {
    try {
      const config = await environmentApi.getEnvironmentConfigAsync();
      context.commit('setEnvironmentConfig', config);
    } catch (e) {
    }
  }

  public loadAsync = async (context: ActionContext<State, any>) => {
    try {
      const env = await environmentApi.getEnvironmentAsync();
      context.commit('setEnvironment', env);
    } catch (e) {
    }
  }
}

<template>
  <div>
    <filter-bar />
    <div class="container-fluid dashboard-content" :style="{ height: headerStyle }">
      <div class="row pt-4">
        <div class="col">
          <help-video-table :loading="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import FilterBar from '@/components/help/filter-bar.vue';
import HelpVideoTable from '@/components/help/help-video-table.vue';
import { namespace } from 'vuex-class';

const environmentModule = namespace('environment');

@Component({
  components: {
    FilterBar,
    HelpVideoTable,
  },
})
export default class Help extends Vue {
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;

  private loading: boolean = false;

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 196px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 172px)';
    }

    return 'calc(100vh - 196px)';
  }

  public async mounted(): Promise<void> {
    await this.$store.dispatch('help/loadAsync');
  }
}
</script>

import { MutationTree } from 'vuex';
import { State } from '@/store/help/state';
import { HelpResponse } from '@/api/responses/help/help-response';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setHelp = (state: State, help: HelpResponse) => {
    state.current = help;
  }
}

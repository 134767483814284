<template>
  <b-tabs class="workflow-report-details" v-model="selectedTabIndex" :aria-busy="loading" @changed="onTabsChanged">
    <b-tab :title="`Data issues (${issueCount})`" v-if="issues !== null && issues.length > 0">
      <workflow-report-issues-table :issues="issues" :loading="loading" @workflow-report-data-issue-ignore-clicked="onIssueIgnoreClicked" @workflow-report-data-issue-unignore-clicked="onIssueUnignoreClicked" />
      <workflow-report-issue-ignore-dialog :show="showIgnoreDialog" submit-text="Ignore" title="Ignore data issue" @ok="onIgnoreDialogOk" @cancel="onIgnoreDialogCancel" @close="onIgnoreDialogClose" />
      <workflow-report-issue-ignore-dialog :show="showUnignoreDialog" submit-text="Unignore" title="Unignore data issue" @ok="onUnignoreDialogOk" @cancel="onIgnoreDialogCancel" @close="onIgnoreDialogClose" />
    </b-tab>
    <b-tab :title="`Contents (${contentCount})`" v-if="content !== null && contentCount > 0">
      <div class="workflow-report-content-list" :style="headerStyle">
        <workflow-report-content-table v-for="group in contentGroups" :key="group.name" :name="group.name" :content="group.content" :loading="loading" />
      </div>
    </b-tab>
    <b-tab :title="`Distribution (${distributions.length})`" v-if="distributions !== null && distributions.length > 0">
      <workflow-report-distribution-table :distribution="distributions" :loading="loading" />
    </b-tab>
    <b-tab :title="`Activity log (${activity.length})`">
      <workflow-report-activity-table :activity="activity" :loading="loading" />
    </b-tab>
  </b-tabs>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { WorkflowReport } from '@/store/workflow/state';
import WorkflowReportActivityTable from './workflow-report-activity-table.vue';
import WorkflowReportContentTable from './workflow-report-content-table.vue';
import WorkflowReportDistributionTable from './workflow-report-distribution-table.vue';
import WorkflowReportIssuesTable from './workflow-report-issues-table.vue';
import { WorkflowReportIssueResponse } from '@/api/responses/workflow/workflow-report-issue-response';
import WorkflowReportIssueIgnoreDialog from './workflow-report-issue-ignore-dialog.vue';
import debounce from 'lodash.debounce';
import { BTab } from 'bootstrap-vue';

const environmentModule = namespace('environment');
const workflowModule = namespace('workflow');
const tenantModule = namespace('tenant');

@Component({
  components: {
    WorkflowReportActivityTable,
    WorkflowReportContentTable,
    WorkflowReportDistributionTable,
    WorkflowReportIssuesTable,
    WorkflowReportIssueIgnoreDialog,
  },
})
export default class WorkflowReportDetails extends Mixins(BvToastMixin) {
  @tenantModule.Getter selectedReportGroup!: { id: number, name: string } | null;
  @workflowModule.Getter selectedReport!: WorkflowReport | null;
  @environmentModule.Getter('current') environment!: { environment: string, version: string | null, runtime: string } | null;
  loading = false;
  showIgnoreDialog = false;
  showUnignoreDialog = false;
  selectedIssue: WorkflowReportIssueResponse | null = null;
  activity: Array<{ timestamp: string, description: string | null }> = [];
  issues: Array<{ canBeIgnored: boolean; canBeUnignored: boolean; commentaryReceivedDate: string | null; description: string; id: number; ignored: boolean; ignoredBy: string | null; ignoredDate: string | null; reportId: number; type: string; }> = [];
  content: { [key: string]: Array<{ description: string, complete: boolean, receivedDate: string }> } | null = null;
  distributions: Array<{ description: string, status: string }> = [];
  selectedTabIndex = 0;

  get headerStyle(): { 'max-height': string, 'overflow-y': string } {
    if (this.environment === null) {
      return { 'max-height': 'calc(100vh - 230px)', 'overflow-y': 'auto' };
    }

    if (this.environment.environment === 'Production') {
      return { 'max-height': 'calc(100vh - 230px)', 'overflow-y': 'auto' };
    }

    return { 'max-height': 'calc(100vh - 254px)', 'overflow-y': 'auto' };
  }

  get issueCount(): number {
    if (this.issues === null) {
      return 0;
    }

    return this.issues.filter((i) => !i.ignored).length;
  }

  get contentGroups() {
    if (this.content === null) {
      return [];
    }

    return Object.keys(this.content).map((c) => ({ name: c, content: this.content![c] }));
  }

  get contentCount(): number {
    if (this.content === null) {
      return 0;
    }

    return Object.keys(this.content).flatMap((c) => this.content![c].length).reduce((prev, next) => prev + next, 0);
  }

  onTabsChanged(current: Array<BTab>, old: Array<BTab>): void {
    if (this.content === null) {
      return;
    }

    if (Object.keys(this.content).length < 1) {
      return;
    }

    this.selectedTabIndex = this.selectedReport!.hasDataIssues ? 1 : 0;
  }

  @Watch('selectedReport', { deep: true })
  async onReportChanged(report: WorkflowReport | null, old: WorkflowReport | null | undefined = undefined): Promise<void> {
    if (report === null || report === undefined) {
      this.activity = [];
      this.issues = [];
      this.content = null;
      this.distributions = [];
      return;
    }

    // NOTE(Dan): We conditionally clear the lists here, previously we were always clearing.
    //            This is done so that we can remain on the same tab when switching reports
    //            without having to compute previous vs new tab and reset the index causing weird
    //            UX around the tab switching appearing "glitchy".
    //            Doing the below means that the tab will only disappear when it actually should
    //            moving your tab to activity log (typically).
    if (report.id !== old?.id) {
      this.loading = true;

      if (!report.hasDataIssues) {
        this.issues = [];
      }

      if (!report.hasCommentary) {
        this.content = null;
      }

      if (!report.hasDistribution) {
        this.distributions = [];
      }
    }

    // NOTE(Dan): If we change selected report don't use the debounced `loadReportDetailsAsync` call.
    if (report.id !== old?.id) {
      await this.loadReportDetailsInternalAsync(report);
    } else {
      await this.loadReportDetailsAsync(report);
    }

    if (report.id !== old?.id) {
      this.loading = false;
    }
  }

  async mounted(): Promise<void> {
    this.loading = true;
    await this.onReportChanged(this.selectedReport!);
    this.loading = false;
  }

  async onIssueIgnoreClicked(item: WorkflowReportIssueResponse): Promise<void> {
    this.selectedIssue = item;
    this.showIgnoreDialog = true;
  }

  async onIssueUnignoreClicked(item: WorkflowReportIssueResponse): Promise<void> {
    // await this.$store.dispatch('workflow/unignoreWorkflowReportIssueAsync', { id: item.id, workflowReportId: this.report!.id, comments: '' });
    this.selectedIssue = item;
    this.showUnignoreDialog = true;
  }

  async onIgnoreDialogOk(value: { comments: string | null }) {
    this.loading = true;
    this.showIgnoreDialog = false;
    try {
      await this.$store.dispatch('workflow/ignoreWorkflowReportIssueAsync', { id: this.selectedIssue!.id, workflowReportId: this.selectedReport!.id, reportGroupId: this.selectedReportGroup!.id, comments: value.comments });
    } catch (e) {
      this.showErrorToast('Could not ignore data issue. Please try again.');
    }

    this.selectedIssue = null;

    this.onReportChanged(this.selectedReport!, this.selectedReport!);
    this.loading = false;
  }

  async onUnignoreDialogOk(value: { comments: string | null }) {
    this.loading = true;
    this.showUnignoreDialog = false;
    try {
      await this.$store.dispatch('workflow/unignoreWorkflowReportIssueAsync', { id: this.selectedIssue!.id, workflowReportId: this.selectedReport!.id, reportGroupId: this.selectedReportGroup!.id, comments: value.comments });
    } catch (e) {
      this.showErrorToast('Could not unignore data issue. Please try again.');
    }

    this.selectedIssue = null;

    this.onReportChanged(this.selectedReport!, this.selectedReport!);
    this.loading = false;
  }

  async onIgnoreDialogCancel() {
    this.showIgnoreDialog = false;
    this.showUnignoreDialog = false;
    this.selectedIssue = null;
  }

  async onIgnoreDialogClose() {
    this.showIgnoreDialog = false;
    this.showUnignoreDialog = false;
    this.selectedIssue = null;
  }

  loadReportDetailsAsync = debounce(this.loadReportDetailsInternalAsync, 500, { leading: true });

  async loadReportDetailsInternalAsync(report: WorkflowReport): Promise<void> {
    const result = await this.$store.dispatch('workflow/getWorkflowReportActivityAsync', { workflowReportId: report!.id, reportGroupId: this.selectedReportGroup!.id });
    this.activity = result.activity;

    if (report.hasDataIssues) {
      const issues = await this.$store.dispatch('workflow/getWorkflowReportIssuesAsync', { workflowReportId: report!.id, reportGroupId: this.selectedReportGroup!.id });
      this.issues = issues.issues;
    } else {
      this.issues = [];
    }

    if (report.hasCommentary) {
      const content = await this.$store.dispatch('workflow/getWorkflowReportContentAsync', { workflowReportId: report!.id, reportGroupId: this.selectedReportGroup!.id });
      this.content = content.content;
    }

    if (report.hasDistribution) {
      const distributions = await this.$store.dispatch('workflow/getWorkflowReportDistributionAsync', { workflowReportId: report!.id, reportGroupId: this.selectedReportGroup!.id });
      this.distributions = distributions.distribution;
    } else {
      this.distributions = [];
    }
  }
}
</script>

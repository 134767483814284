import { WorkflowReportDataCheckStarted } from '../events/workflow-report-data-check-started';
import store from '@/store';

async function handleWorkflowReportDataCheckStarted(data: WorkflowReportDataCheckStarted): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportDataCheckStarted event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryUpdateReportProperties', {
    renderingStatus: 'DataCheckRunning',
    reportId: reportId,
    tooltips: {
      renderingStatus: data.payload.tooltip,
    },
  });
}

export default handleWorkflowReportDataCheckStarted;

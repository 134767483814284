import { WorkflowReportReplaced } from '../events/workflow-report-replaced';
import store from '@/store';

async function handleWorkflowReportReplaced(data: WorkflowReportReplaced): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportReplaced event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryUpdateReportProperties', {
    replaced: true,
    reportId: reportId,
  });
}

export default handleWorkflowReportReplaced;

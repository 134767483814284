import { WorkflowReportAvailableActionsReplaced } from '../events/workflow-report-available-actions-replaced';
import store from '@/store';

async function handleWorkflowReportAvailableActionsReplaced(data: WorkflowReportAvailableActionsReplaced): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportAvailableActionsReplaced event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryReplaceReportActionsFromPage', { reportId, ...data });
}

export default handleWorkflowReportAvailableActionsReplaced;

<template>
  <validation-observer v-slot="{ passes, invalid, pending }">
    <div class="row align-items-center">
      <div class="col">
        <validation-provider
          ref="phrase"
          :rules="`required|uniquephrase:${phrase.value}`"
          v-slot="{ valid, errors }"
          tag="div"
          :mode="validationMode"
        >
          <b-input-group v-if="isEditing">
            <b-form-input ref="input" v-model="value" @keypress.enter="passes(save)" @keyup.esc="cancel" :state="errors[0] ? false : (valid ? true : null)" />
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
          <span v-else>
            {{ value }}
          </span>
        </validation-provider>
      </div>

      <div class="col-auto ml-auto" v-if="canManageLanguages">
        <b-button-group>
          <b-button v-if="!isEditing" variant="outline-primary" @click="toggleEditing" size="sm" title="Edit">
            <b-icon icon="pencil" aria-hidden="true"></b-icon>
          </b-button>
          <b-button v-if="!isEditing" variant="outline-primary" @click="toggleRemoving" size="sm" title="Delete">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>
          <b-button v-if="isEditing" variant="outline-primary" size="sm" :disabled="(invalid && !pending) || phrase.value === value" @click="passes(save)" title="Save">
            <b-icon icon="check2" aria-hidden="true"></b-icon>
          </b-button>
          <b-button v-if="isEditing" variant="outline-primary" size="sm" @click="cancel" title="Cancel">
            <b-icon icon="X" aria-hidden="true"></b-icon>
          </b-button>
        </b-button-group>
      </div>

      <delete-phrase-confirmation-dialog v-if="showRemoveConfirmation" :show="showRemoveConfirmation" :phrase="phrase" :title="`Remove phrase '${phrase.value}'?`" @ok="onRemoveConfirmed" @cancel="onRemoveCancelled" @close="onRemoveCancelled" />
    </div>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';
import { namespace } from 'vuex-class';
import { Phrase } from '../../store/language-maintenance/state';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import DeletePhraseConfirmationDialog from './delete-phrase-confirmation-dialog.vue';
import { ValidationFlags } from 'vee-validate/dist/types/types';

const tenantModule = namespace('tenant');

type ValidationModeContext = { errors: Array<string>, value: any, flags: ValidationFlags };

@Component({
  components: {
    DeletePhraseConfirmationDialog,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class PhraseTableValue extends Vue {
  @tenantModule.Getter private canManageLanguages!: boolean;

  @Prop({ required: true }) public phrase!: Phrase;

  private isEditing: boolean = false;
  private value: string | null = this.phrase?.value;
  private showRemoveConfirmation: boolean = false;

  @Watch('phrase.value', { immediate: true, deep: true })
  onPhraseValueChange(newValue: string, oldValue: string) {
    this.value = newValue;
  }

  @Watch('isEditing')
  onIsEditingChange(newVal: boolean, oldVal: boolean) {
    this.isEditing = newVal;
  }

  @Watch('showRemoveConfirmation')
  onShowRemoveConfirmationChange(newVal: boolean, oldVal: boolean) {
    this.isEditing = newVal;
  }

  private toggleEditing(): void {
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      this.$nextTick(() => {
        (this.$refs.input as BFormInput).focus();
      });
    }

    this.$emit('editing', this.isEditing);
  }

  private toggleRemoving(): void {
    this.showRemoveConfirmation = true;
  }

  private save(): void {
    if (this.phrase.value === null || this.phrase.value === this.value) {
      return;
    }

    this.toggleEditing();

    this.$emit('saved', { phrase: this.phrase.value, to: this.value! });
  }

  private cancel(): void {
    this.value = this.phrase.value;
    this.toggleEditing();

    this.$emit('cancelled', this.phrase);
  }

  private onRemoveConfirmed(): void {
    this.$emit('removed', this.phrase);
    this.onRemoveCancelled();
  }

  private onRemoveCancelled(): void {
    this.showRemoveConfirmation = false;
  }

  private validationMode({ errors }: ValidationModeContext) {
    if (errors.length) {
      return {
        on: ['input', 'change'],
      };
    }

    return {
      on: ['input', 'change'],
    };
  }
}
</script>

<template>
  <div>
    <filter-bar />
    <div class="container-fluid">
      <div class="row mt-4">
        <div class="col-8">
          <summary-period-list />
        </div>
        <div class="col-4" style="height: calc(100vh - 254px);">
          <div class="row summary-period-chart" v-if="statistics !== null">
            <div class="col">
              <div class="title">
                <!-- <div>{{ statistics.description }}</div> -->
                <div class="row">
                  <div class="col-auto">
                    <valuation-date-from-selector />
                  </div>
                </div>
              </div>
              <summary-period-chart ref="statistics-chart" :data="statistics" :options="statisticsOptions" v-if="statistics !== null" />
            </div>
          </div>
          <div class="row summary-period-chart" v-if="previousStatistics !== null">
            <div class="col">
              <div class="title">
                <!-- <div>{{ previousStatistics.description }}</div> -->
                <div class="row">
                  <div class="col-auto">
                    <valuation-date-to-selector />
                  </div>
                </div>
              </div>
              <summary-period-chart ref="previous-statistics-chart" :data="previousStatistics" :options="previousStatisticsOptions" v-if="previousStatistics !== null" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import FilterBar from '@/components/summary/filter-bar.vue';
import SummaryPeriodList from '@/components/summary/summary-period-list.vue';
import SummaryPeriodChart from '@/components/summary/summary-period-chart.vue';
import { namespace } from 'vuex-class';
import { Summary, SummaryStatistics, SummaryValuationDate } from '../store/summary/state';
import { ReportGroup } from '@/store/tenant/state';
import { ChartOptions } from 'chart.js';
import { rotate } from '@/matrix';
import '@/chart-positioner';
import ValuationDateFromSelector from '@/components/summary/filter-bar-selectors/valuation-date-from-selector.vue';
import ValuationDateToSelector from '@/components/summary/filter-bar-selectors/valuation-date-to-selector.vue';
import { kebabcase } from '@/utilities/text.utils';
import { date } from '../filters/date';
import { Location } from 'vue-router';

const summaryModule = namespace('summary');
const tenantModule = namespace('tenant');

function log10(value: number): number {
  const exponent = Math.log(value) * Math.LOG10E;
  const powerOf10 = Math.round(exponent);
  const isPowerOf10 = Math.pow(10, powerOf10) === value;

  return isPowerOf10 ? powerOf10 : exponent;
}

function niceNum(range: number): number {
  const exponent = Math.floor(log10(range));
  const fraction = range / Math.pow(10, exponent);

  let niceFraction = 1;

  if (fraction <= 1.0) {
    niceFraction = 1;
  } else if (fraction <= 2) {
    niceFraction = 2;
  } else if (fraction <= 5) {
    niceFraction = 5;
  } else {
    niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
}

const isNumberFinite = (value: any) => (typeof value === 'number' || value instanceof Number) && isFinite(+value);

function decimalPlaces(value: number): number {
  if (!isNumberFinite(value)) {
    return 0;
  }

  let e: number = 1;
  let p: number = 0;

  while (Math.round(value * e) / e !== value) {
    e *= 10;
    p++;
  }

  return p;
}

type BarModel = { backgroundColor: string, base: number, borderColor: string, borderSkipped: 'bottom', borderWidth: number, datasetLabel: string, height?: number, horizontal: boolean, label: string, width: number, x: number, y: number };
type ChartElement = { hidden: boolean, _chart: Chart, _datasetIndex: number, _index: number, _model: BarModel, _options: {} };

@Component({
  components: {
    FilterBar,
    SummaryPeriodList,
    SummaryPeriodChart,
    ValuationDateFromSelector,
    ValuationDateToSelector,
  },
})
export default class SummaryView extends Vue {
  @summaryModule.Getter private current!: Summary | null;
  @summaryModule.Getter private fromValuationDate!: SummaryValuationDate | null;
  @summaryModule.Getter private toValuationDate!: SummaryValuationDate | null;
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;

  private statistics: SummaryStatistics | null = null;
  private previousStatistics: SummaryStatistics | null = null;

  private get chartMax(): number {
    if (this.statistics === null && this.previousStatistics === null) {
      return 0;
    }

    const currentHighs = this.statistics?.series.map((series) => series.values.map((v) => v.value)) || this.previousStatistics?.series.map((series) => series.values.map((v) => v.value)) || [];
    const previousHighs = this.previousStatistics?.series.map((series) => series.values.map((v) => v.value)) || currentHighs;
    const seriesLength = Math.max(this.statistics?.series.length || 0, this.previousStatistics?.series.length || 0);
    const cumulativeHighs: Array<number> = new Array<number>(seriesLength);

    for (let i = 0; i < cumulativeHighs.length; i++) {
      cumulativeHighs[i] = 0;
    }

    const rotatedCurrentHighs = rotate(currentHighs);
    const rotatedPreviousHighs = rotate(previousHighs);

    for (let j = 0; j <= seriesLength; j++) {
      let currentHigh = 0;
      let previousHigh = 0;

      for (let k = 0; k <= seriesLength; k++) {
        currentHigh += rotatedCurrentHighs[j][k] || 0;
        previousHigh += rotatedPreviousHighs[j][k] || 0;
      }

      cumulativeHighs[j] = Math.max(currentHigh, previousHigh);
    }

    if (cumulativeHighs.length < 1) {
      return 0;
    }

    return Math.max(...cumulativeHighs);
  }

  private get statisticsOptions(): ChartOptions | null {
    if (this.statistics === null) {
      return null;
    }

    const scales = this.chartScaleSizes;
    const { gap, min, max } = scales;

    return {
      showLines: false,
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: false,
      tooltips: {
        position: 'center',
      },
      legend: {
        display: false,
      },
      hover: {
        onHover: (e: MouseEvent, activeElements: Array<ChartElement>) => {
          const target = (this.$refs['statistics-chart'] as Vue).$el.getElementsByTagName('canvas')[0];
          target.style.cursor = activeElements.length > 0 ? 'pointer' : 'default';
        },
      },
      onClick: async (e?: MouseEvent, activeElements?: Array<ChartElement>) => {
        if (activeElements === undefined || activeElements.length < 1) {
          return;
        }

        const chart = activeElements[0]._chart;
        const activeElement = chart.getElementAtEvent(e)[0] as ChartElement;
        const fromValuationDate = this.fromValuationDate;
        const reportGroup = this.currentReportGroup.name;
        const label: string | undefined = kebabcase(chart.data.labels![activeElement._index] as string);
        let route: Location = { name: 'workflow', params: { ...this.$route.params, reportGroup: kebabcase(reportGroup), valuationDate: kebabcase(date(fromValuationDate!.displayDate, this.current!.dateFormat)), option: label } };

        if (label === 'data-loads') {
          route = { name: 'data', params: { ...this.$route.params, reportGroup: kebabcase(reportGroup), valuationDate: kebabcase(date(fromValuationDate!.displayDate, this.current!.dateFormat)), tab: label } };
        }

        this.$store.commit('data/reset');

        await this.$router.push(route);
      },
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            max: max,
            min: min,
            stepSize: gap,
          },
        }],
      },
    };
  }

  private get previousStatisticsOptions(): ChartOptions | null {
    if (this.previousStatistics === null) {
      return null;
    }

    const scales = this.chartScaleSizes;
    const { gap, min, max } = scales;

    return {
      showLines: false,
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: false,
      tooltips: {
        position: 'center',
      },
      legend: {
        display: false,
      },
      hover: {
        onHover: (e: MouseEvent, activeElements: Array<ChartElement>) => {
          const target = (this.$refs['previous-statistics-chart'] as Vue).$el.getElementsByTagName('canvas')[0];
          target.style.cursor = activeElements.length > 0 ? 'pointer' : 'default';
        },
      },
      onClick: async (e?: MouseEvent, activeElements?: Array<ChartElement>) => {
        if (activeElements === undefined || activeElements.length < 1) {
          return;
        }

        const chart = activeElements[0]._chart;
        const activeElement = chart.getElementAtEvent(e)[0] as ChartElement;
        const toValuationDate = this.toValuationDate;
        const reportGroup = this.currentReportGroup.name;
        const label: string | undefined = kebabcase(chart.data.labels![activeElement._index] as string);
        let route: Location = { name: 'workflow', params: { ...this.$route.params, reportGroup: kebabcase(reportGroup), valuationDate: kebabcase(date(toValuationDate!.displayDate, this.current!.dateFormat)), option: label } };

        if (label === 'data-loads') {
          route = { name: 'data', params: { ...this.$route.params, reportGroup: kebabcase(reportGroup), valuationDate: kebabcase(date(toValuationDate!.displayDate, this.current!.dateFormat)), tab: label } };
        }

        this.$store.commit('data/reset');

        await this.$router.push(route);
      },
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            max: max,
            min: min,
            stepSize: gap,
          },
        }],
      },
    };
  }

  private get chartScaleSizes(): { spaces: number, gap: number, min: number, max: number } {
    const chartMax = this.chartMax;

    const min = 0;
    const max = chartMax || 0;

    if (max === 0) {
      return { spaces: 0, gap: 0, min: 0, max: 0 };
    }

    const MAX_TICK_LIMIT = 11;
    const UNIT: number = 1;
    const MAX_SPACES = MAX_TICK_LIMIT - 1;

    let spacing = niceNum((max - min) / MAX_SPACES / UNIT) * UNIT;

    if (spacing < 1) {
      spacing = UNIT;
    }

    let spaces = Math.ceil(max / spacing) - Math.floor(min / spacing);

    if (spaces > MAX_SPACES) {
      spacing = niceNum(spaces * spacing / MAX_SPACES / UNIT) * UNIT;
    }

    const factor = Math.pow(10, decimalPlaces(spacing));
    let niceMin = Math.floor(min / spacing) * spacing;
    let niceMax = Math.ceil(max / spacing) * spacing;

    spaces = (niceMax - niceMin) / spacing;

    if (Math.abs(spaces - Math.round(spaces)) < (spacing / 1000)) {
      spaces = Math.round(spaces);
    } else {
      spaces = Math.ceil(spaces);
    }

    niceMin = Math.round(niceMin * factor) / factor;
    niceMax = Math.round(niceMax * factor) / factor;

    return { spaces: spaces, gap: spacing, min: niceMin, max: niceMax };
  }

  @Watch('current')
  @Watch('currentReportGroup')
  private async onCurrentChanged(): Promise<void> {
    this.statistics = null;
    this.previousStatistics = null;
  }

  @Watch('fromValuationDate')
  private async onFromValuationDate(): Promise<void> {
    if (this.fromValuationDate === null) {
      this.statistics = null;
      return;
    }

    this.statistics = await this.$store.dispatch('summary/getStatisticsForValuationDateAsync', { valuationDate: this.fromValuationDate!.valuationDate, reportGroupId: this.currentReportGroup.id });
  }

  @Watch('toValuationDate')
  private async onToValuationDate(): Promise<void> {
    if (this.toValuationDate === null) {
      this.previousStatistics = null;
      return;
    }

    this.previousStatistics = await this.$store.dispatch('summary/getStatisticsForValuationDateAsync', { valuationDate: this.toValuationDate!.valuationDate, reportGroupId: this.currentReportGroup.id });
  }
}
</script>

import { getLogger } from '@/logging';

export async function errorResponseFailureInterceptor(error: any): Promise<any> {
  getLogger().trackException({ exception: error });
  return Promise.reject(error);
}

export async function errorRequestFailureInterceptor(error: any): Promise<any> {
  getLogger().trackException({ exception: error });
  return Promise.reject(error);
}

<template>
  <div class="data-issues" :aria-busy="loading">
    <b-table ref="table"
             striped
             hover
             :sticky-header="headerStyle"
             show-empty
             sort-icon-left
             :no-sort-reset="true"
             :no-local-filtering="true"
             :no-local-paging="true"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :busy="loading"
             :fields="fields"
             :items="filteredResults"
             :tbody-tr-class="applyTableRowClasses"
    >
      <template v-slot:head(name)="data">
        <span>{{ data.label }}<b-button title="Clear filter" size="sm" variant="link" v-if="reportFilter" @click.stop="onReportFilterClear">(clear filter)</b-button></span>
        <span @click.stop="onShowFilter">
          <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
            <g data-v-11c9e491="">
              <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
            </g>
          </svg>
        </span>
        <b-form-input class="mt-3" v-if="showFilter" v-model="reportFilter" />
      </template>

      <template v-slot:head(description)="data">
        <span>{{ data.label }}<b-button title="Clear filter" size="sm" variant="link" v-if="issueFilter" @click.stop="onIssueFilterClear">(clear filter)</b-button></span>
        <span @click.stop="onShowFilter">
          <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
            <g data-v-11c9e491="">
              <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
            </g>
          </svg>
        </span>
        <b-form-input class="mt-3" v-if="showFilter" v-model="issueFilter" />
      </template>

      <template v-slot:head(reportsAffected)="data">
        {{ data.label }} ({{ dataIssueReportsAffected }})
      </template>

      <template v-slot:cell(reportsAffected)="{ item }">
        {{ item.reportsAffected }} <span v-if="item.reportsAffected === 1 && item.links.reports.length === 1">(ID: <b-link v-if="item.reportsAffected === 1 && item.links.reports.length === 1" @click="onViewReportClicked(item)" class="text-decoration-underline">{{ item.links.reports[0].reportId }}</b-link>)</span>
      </template>

      <template v-slot:cell(type)="{ item }">
        <data-issue-type-icon :item="item" />
      </template>

      <template v-slot:cell(description)="{ item }">
        {{ item.description }} <b-link v-if="item.links.document !== null" @click="onViewLinkedDocumentClicked(item)">(view document)</b-link>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-button-group>
          <b-button v-if="item.canBeIgnored" size="sm" variant="outline-primary" title="Ignore data issue" @click="onIssueIgnoreClicked(item)">Ignore</b-button>
          <b-button v-if="item.canBeUnignored" size="sm" variant="outline-primary" title="Unignore data issue" @click="onIssueUnignoreClicked(item)">Unignore</b-button>
        </b-button-group>
      </template>

      <!-- <template v-slot:cell(actions)>
        <b-button-group>
          <b-button size="sm" variant="outline-primary" title="Ignore">
            <b-icon icon="eye-slash" aria-hidden="true"></b-icon>
          </b-button>
          <b-button size="sm" variant="outline-primary" title="Unignore">
            <b-icon icon="eye" aria-hidden="true"></b-icon>
          </b-button>
        </b-button-group>
      </template> -->
    </b-table>

    <data-issue-ignore-dialog :show="showIgnoreDialog" submit-text="Ignore" title="Ignore data issue" @ok="onIgnoreDialogOk" @cancel="onIgnoreDialogCancel" @close="onIgnoreDialogClose" />
    <data-issue-ignore-dialog :show="showUnignoreDialog" submit-text="Unignore" title="Unignore data issue" @ok="onUnignoreDialogOk" @cancel="onIgnoreDialogCancel" @close="onIgnoreDialogClose" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { Tenant, ReportGroup } from '../../store/tenant/state';
import { DataIssue, DataDate } from '../../store/data/state';
import DataIssueTypeIcon from './data-issue-type-icon.vue';
import DataIssueIgnoreDialog from './data-issue-ignore-dialog.vue';
import throttle from 'lodash.throttle';
import * as Sentry from '@sentry/browser';
import { kebabcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';
import { FindWorkflowResponse } from '@/api/responses/workflow/find-workflow-response';
import { FindDocumentResponse } from '@/api/responses/document-library/find-document-response';
import { parseFilters, applyFilters } from '@/components/table/input-filter';

const tenantModule = namespace('tenant');
const dataModule = namespace('data');
const environmentModule = namespace('environment');

@Component({
  components: {
    DataIssueTypeIcon,
    DataIssueIgnoreDialog,
  },
})
export default class DataIssuesTable extends Mixins(BvToastMixin) {
  @Prop({ type: Boolean, required: true }) public loading!: boolean;
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @environmentModule.Getter('current') private environment!: { environment: string };
  @dataModule.Getter private dataIssues!: Array<DataIssue>;
  @dataModule.Getter private dataIssueReportsAffected!: number;
  @dataModule.Getter private currentValuationDate!: DataDate | null;
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;
  @tenantModule.Getter private tenants!: Array<Tenant>;

  private sortBy: string | null = null;
  private sortDesc: boolean = false;
  private selectedRowIndex: number = -1;
  private showFilter: boolean = false;
  private reportFilter: string | null = null;
  private issueFilter: string | null = null;
  private showIgnoreDialog: boolean = false;
  private showUnignoreDialog: boolean = false;
  private selectedIssue: DataIssue | null = null;

  public get filteredResults(): Array<DataIssue> {
    const dataIssues = this.dataIssues;

    if (dataIssues == null || dataIssues.length < 1) {
      return [];
    }

    if (!this.reportFilter && !this.issueFilter) {
      return dataIssues;
    }

    if (this.reportFilter && !this.issueFilter) {
      const reportFilters = parseFilters(this.reportFilter);
      return dataIssues.filter((r) => applyFilters(r, x => x.name, reportFilters, { mode: 'include' }));
    }

    if (!this.reportFilter && this.issueFilter) {
      const issueFilters = parseFilters(this.issueFilter);
      return dataIssues.filter((r) => applyFilters(r, x => x.description, issueFilters, { mode: 'include' }));
    }

    const reportFilters = parseFilters(this.reportFilter!);
    const issueFilters = parseFilters(this.issueFilter!);

    return dataIssues
      .filter((r) => applyFilters(r, x => x.name, reportFilters, { mode: 'include' }))
      .filter((r) => applyFilters(r, x => x.description, issueFilters, { mode: 'include' }));
  }

  private get fields(): Array<{}> {
    const fields = [
      // { key: 'id', label: 'ID', class: ['col-1'], sortable: true },
      { key: 'type', label: '', class: ['col-auto'], thClass: ['text-nowrap'], sortable: true },
      { key: 'name', label: 'Subject', class: ['col-auto'], tdClass: ['text-nowrap'], thClass: ['text-nowrap'], sortable: true },
      { key: 'reportsAffected', label: 'Reports Affected', class: ['col-2'], tdClass: ['text-center'], thClass: ['text-center'], sortable: true },
      { key: 'description', label: 'Issue', class: ['w-100'], sortable: true },
      { key: 'actions', label: '', class: ['col-auto'], sortable: false }
    ];

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  public mounted(): void {
    let lastScrollPos: number = 0;
    ((this.$refs.table as Vue).$el as HTMLDivElement).addEventListener('scroll', throttle((e) => {
      const element = ((this.$refs.table as Vue).$el as HTMLDivElement);
      const offsetHeight = element.offsetHeight;
      const scrolled = element.scrollTop;
      const height = element.scrollHeight;

      // NOTE(Dan): We're scrolling back up the list from the bottom, so we don't want to trigger here...
      if (lastScrollPos >= scrolled) {
        lastScrollPos = scrolled;
        return;
      }

      lastScrollPos = scrolled;

      if (offsetHeight + scrolled >= (height * 0.9)) {
        this.$emit('scroll-completed', { height, offsetHeight, scrolled });
      }
    }, 1000));
  }

  private onShowFilter(): void {
    this.showFilter = !this.showFilter;
  }

  @Watch('sortDesc')
  private onSortDescChanged(to: boolean, from: boolean) {
    if (!to && from) {
      this.sortBy = null;
    }
  }

  private onReportFilterClear(): void {
    this.reportFilter = null;
    this.showFilter = false;
  }

  private onIssueFilterClear(): void {
    this.issueFilter = null;
    this.showFilter = false;
  }

  private async onIssueIgnoreClicked(item: DataIssue): Promise<void> {
    this.selectedIssue = item;
    this.showIgnoreDialog = true;
  }

  private async onIssueUnignoreClicked(item: DataIssue): Promise<void> {
    this.selectedIssue = item;
    this.showUnignoreDialog = true;
  }

  private async onIgnoreDialogOk(value: { comments: string | null }) {
    this.showIgnoreDialog = false;
    try {
      await this.$store.dispatch('data/ignoreDataIssueAsync', { issueId: this.selectedIssue!.id, reportGroupId: this.currentReportGroup!.id, comments: value.comments });
      this.selectedIssue!.canBeIgnored = false;
      this.selectedIssue!.canBeUnignored = true;
      this.selectedIssue!.status = 'Ignored';
    } catch (e) {
      this.showErrorToast('Could not ignore data issue. Please try again.');
      Sentry.captureException(e);
    }

    this.selectedIssue = null;
  }

  private async onUnignoreDialogOk(value: { comments: string | null }) {
    this.showUnignoreDialog = false;
    try {
      await this.$store.dispatch('data/unignoreDataIssueAsync', { issueId: this.selectedIssue!.id, reportGroupId: this.currentReportGroup!.id, comments: value.comments });
      this.selectedIssue!.canBeIgnored = true;
      this.selectedIssue!.canBeUnignored = false;
      this.selectedIssue!.status = 'Pending';
    } catch (e) {
      this.showErrorToast('Could not unignore data issue. Please try again.');
      Sentry.captureException(e);
    }

    this.selectedIssue = null;
  }

  private async onIgnoreDialogCancel() {
    this.showIgnoreDialog = false;
    this.showUnignoreDialog = false;
    this.selectedIssue = null;
  }

  private async onIgnoreDialogClose() {
    this.showIgnoreDialog = false;
    this.showUnignoreDialog = false;
    this.selectedIssue = null;
  }

  private applyTableRowClasses(item: DataIssue, type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy') {
    if (item !== null && type !== 'table-busy') {
      return { replaced: item.status === 'Ignored' };
    }
  }

  private async onViewReportClicked(item: DataIssue): Promise<void> {
    if (item.links.reports.length !== 1) {
      return;
    }

    const reportId = item.links.reports[0].reportId;
    const result = await this.$store.dispatch('workflow/findWorkflowReportAsync', reportId) as FindWorkflowResponse;

    if (result === null) {
      // TODO(Dan): Maybe we should show an error toast here?
      return;
    }

    const tenant = this.tenants.find((t) => t.id === result.tenantId) || null;

    if (tenant === null) {
      // TODO(Dan): Maybe we should show an error toast here?
      return;
    }

    await this.$router.push({ name: 'workflow', params: { tenant: kebabcase(tenant.name), reportGroup: kebabcase(result.reportGroup), valuationDate: kebabcase(date(result.displayDate, result.dateFormat)), option: kebabcase(result.state), reportId: reportId.toString() } });
  }

  private async onViewLinkedDocumentClicked(item: DataIssue): Promise<void> {
    if (item.links.document === null) {
      return;
    }

    const documentId = item.links.document.documentId!;
    const result = await this.$store.dispatch('documentLibrary/findDocumentAsync', documentId) as FindDocumentResponse;

    if (result === null) {
      // TODO(Dan): Maybe we should show an error toast here?
      return;
    }

    await this.$router.push({ name: 'document-library', params: { ...this.$route.params, tenant: this.$route.params.tenant, reportGroup: kebabcase(result.reportGroup), documentId: documentId.toString() } });
  }
}
</script>

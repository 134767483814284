import { getApplicationInsights } from './appInsights';
import { getConsoleLogger } from './consoleLog';
import { ILogger } from './ILogger';
import store from '@/store';

export function getLogger(): ILogger {
  if (store.getters['environment/loggerConnectionString']) return getApplicationInsights();
  return getConsoleLogger();
}

export async function setUpLogger():Promise<void> {
  await store.dispatch('environment/loadConfigAsync');
  getLogger();
}

import { MutationTree } from 'vuex';
import { State } from '@/store/environment/state';
import { EnvironmentResponse } from '@/api/responses/environment/environment-response';
import { EnvironmentConfigResponse } from '@/api/responses/environment/environment-config-response';

export class Mutations implements MutationTree<State> {
    [key: string]: (state: State, payload: any) => any;

    public setEnvironment = (state: State, environment: EnvironmentResponse) => {
      state.current = environment;
    }

    public setEnvironmentConfig = (state: State, config: EnvironmentConfigResponse) => {
      state.loggerConnectionString = config.loggerConnectionString;
    }

    public setWebsocketConnected = (state: State, connected: boolean) => {
      state.websocketConnected = connected;
    }
}

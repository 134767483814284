<template>
  <div class="report-group-warnings">
    <b-table class="report-group-table"
             ref="table"
             striped
             hover
             show-empty
             sort-icon-left
             :no-sort-reset="true"
             :no-local-filtering="true"
             :no-local-paging="true"
             :fields="fields"
             :items="entries"
    >
      <!-- <template v-slot:cell(status)="{ item }">
        <summary-period-entry-status-icon :entry="item" />
      </template>

      <template v-slot:cell(links)="{ item }">
        <b-btn variant="link" size="sm" :to="buildRoute(item)" v-if="item.links.reports !== null || item.links.issues !== null">View</b-btn>
      </template> -->
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { BvTableFieldArray } from 'bootstrap-vue';
import { DashboardStatisticsReportGroup, DashboardReportGroupWarningValue } from '../../store/dashboard/state';

@Component
export default class ReportGroupWarningTable extends Vue {
  @Prop({ type: Object, required: true }) public reportGroup!: DashboardStatisticsReportGroup;

  public get fields(): BvTableFieldArray {
    return [{ key: 'description', label: 'Warnings', tdClass: [] },
      { key: 'value', label: '', tdClass: ['col-1'] }];
  }

  public get entries(): Array<DashboardReportGroupWarningValue> {
    return this.reportGroup.warnings;
  }
}
</script>

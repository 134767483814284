<template>
  <div>
    <websocket-connection-status-bar />
    <environment-bar />
    <navbar />
    <main role="main" class="col-12 position-relative">
      <slot />
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Navbar from '@/components/navbar/navbar.vue';
import EnvironmentBar from '@/components/environment-bar/environment-bar.vue';
import WebsocketConnectionStatusBar from '@/components/websocket-connection-status-bar/websocket-connection-status-bar.vue';

@Component({
  components: {
    Navbar,
    EnvironmentBar,
    WebsocketConnectionStatusBar,
  },
})
export default class AppLayout extends Vue {}
</script>

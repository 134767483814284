<template>
  <b-table class="workflow-report-distribution-table"
           striped
           hover
           :sticky-header="headerStyle"
           :fields="fields"
           :items="filteredResults"
           :busy="loading"
           sort-icon-left
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
  >
    <template v-slot:head(description)="data">
      <span>{{ data.label }}<table-filter-header :ref="`${data.label}-filter`" v-model="descriptionFilter" @input="onDescriptionFiltered" :show="showFilter" @cleared="onDescriptionFilterClear" @hidden="onShowFilter(data.label)" @shown="onShowFilter(data.label)" /></span>
    </template>

    <template v-slot:cell(timestamp)="{ item }">
      {{ item.timestamp | datetime }}
    </template>

    <template v-slot:cell(status)="{ item }">
      <workflow-report-distribution-status :item="item" />
    </template>
  </b-table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import debounce from 'lodash.debounce';
import { WorkflowReportDistributionResponse } from '@/api/responses/workflow/workflow-report-distribution-response';
import WorkflowReportDistributionStatus from './workflow-report-distribution-status.vue';
import TableFilterHeader from '@/components/table/table-header-filter.vue';

const environmentModule = namespace('environment');

@Component({
  components: {
    WorkflowReportDistributionStatus,
    TableFilterHeader,
  },
})
export default class WorkflowReportDistributionTable extends Vue {
  private sortBy: string | null = null;
  private sortDesc: string | null = null;
  private descriptionFilter: string | null = null;
  private showFilter: boolean = false;
  @Prop({ type: Array, required: true }) public distribution!: Array<WorkflowReportDistributionResponse>;
  @Prop({ type: Boolean, required: true }) public loading!: boolean;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;

  public get fields(): Array<{ key: string, label: string, class?: Array<string> | string, sortable?: boolean, formatter?: Function }> {
    return [
      { key: 'status', label: 'Status', class: ['col-auto'] },
      { key: 'description', label: 'Description', class: ['col'] }
    ];
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  public get filteredResults(): Array<WorkflowReportDistributionResponse> {
    if (this.distribution == null || this.distribution.length < 1) {
      return [];
    }

    if (!this.descriptionFilter) {
      return this.distribution;
    }

    return this.distribution
      .filter((a) => a.description!.toLowerCase().includes(this.descriptionFilter!.trim().toLowerCase()));
  }

  private onShowFilter(id: string): void {
    this.showFilter = !this.showFilter;

    if (this.showFilter) {
      this.$nextTick(() => (this.$refs[`${id}-filter`] as TableFilterHeader).focus());
    }
  }

  private onDescriptionFiltered = debounce(this.onDescriptionFilteredInternal, 250);
  private onDescriptionFilteredInternal(value: string | null) {
    this.descriptionFilter = value;
  }

  private onDescriptionFilterClear(): void {
    this.descriptionFilter = null;
  }
}
</script>

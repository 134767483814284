<template>
  <div class="not-found d-flex align-items-center justify-content-center mt-5">
    <div class="col-1 text-center">
      <p>
        <!-- <svg style="width: 70px; height: 70px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12" y2="17"></line></svg> -->
        <svg style="width: 70px; height: 70px;" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
        <br /><small>Status Code: 500</small>
      </p>
    </div>
    <div class="col-4">
      <h3>Error</h3>
      <p>Something went wrong.</p>
      <p><router-link class="btn btn-primary" :to="{ name: 'home' }">Back to home</router-link></p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Error extends Vue {
}
</script>

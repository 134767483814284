import { DataCheckBatchDequeued } from '../events/data-check-batch-dequeued';
import store from '@/store';
import { ReportGroup } from '@/store/tenant/state';
import { DataDate } from '@/store/data/state';
import router from '@/router';

async function handleDataCheckBatchDequeued(data: DataCheckBatchDequeued): Promise<void> {
  /* eslint-disable */
  console.log(`DataCheckBatchDequeued event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  if (store.getters['data/current'] === null) {
    return;
  }

  const reportGroup = store.getters['tenant/selectedReportGroup'] as ReportGroup;
  const valuationDate = store.getters['data/currentValuationDate'] as DataDate | null;

  if (valuationDate === null || valuationDate.valuationDate !== data.payload.valuationDate) {
    return;
  }

  if (reportGroup === null || reportGroup.id !== data.payload.reportGroupId) {
    return;
  }

  await store.dispatch('data/getAndMergeDataForReportGroupNoLoaderAsync', reportGroup);
  await store.dispatch('data/trySetDataCheckQueueCount', data.payload.remaining);

  if (router.currentRoute.name === 'data') {
    if (router.currentRoute.params.tab === 'data-issues') {
      await store.dispatch('data/getDataIssuesAsync', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate, page: 1, pageSize: 250 });
    } else if (router.currentRoute.params.tab === 'translation-issues') {
      await store.dispatch('data/getTranslationIssuesAsync', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate, page: 1, pageSize: 250 });
    }
  }
}

export default handleDataCheckBatchDequeued;

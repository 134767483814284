<template>
  <div :class="classes" class="workflow-report-page-count" :title="title">
    <div class="text">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkflowReport } from '@/store/workflow/state';

@Component
export default class WorkflowReportPageCount extends Vue {
  @Prop({ type: Object, required: true }) public item!: WorkflowReport;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.item.pageStatus) {
      case 'Blank':
        return null;
      case 'Ok':
        return { 'is-background-success': true };
      case 'Warning':
        return { 'is-background-warning': true };
      case 'Error':
        return { 'is-background-danger': true };
      default:
        return null;
    }
  }

  public get title(): string | null {
    switch (this.item.pageStatus) {
      case 'Blank':
        return null;
      case 'Ok':
        return 'Page number correct';
      case 'Warning':
        return 'Warning: Page number incorrect';
      case 'Error':
        return 'Error: Page number incorrect';
      default:
        return null;
    }
  }
}
</script>

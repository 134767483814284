<template>
  <div class="data-filter-bar row">
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">Report group:</label>
        <div class="col-auto">
          <b-form-select v-model="reportGroup" @change="changeReportGroup(reportGroup)">
            <b-form-select-option :value="group" v-for="group in reportGroups" :key="group.id">{{ group.name }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <valuation-date-selector />
    </div>
    <div class="col-auto" v-if="showFindDataLoad">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">Find data load ID:</label>
        <div class="col-auto">
          <b-input-group>
            <template v-slot:append>
              <b-button variant="primary" @click="findDataLoadAsync(findValue)">Find</b-button>
            </template>
            <b-form-input v-model="findValue" :number="true" placeholder="Data load ID" @keyup.enter="findDataLoadAsync(findValue)"></b-form-input>
          </b-input-group>
        </div>
      </div>
    </div>
    <div class="col-auto align-self-center" v-if="current !== null">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">
          {{ queuedDataChecks }} item{{ queuedDataChecks === 1 ? '' : 's' }} in data check queue
        </label>
      </div>
    </div>
    <div class="col-auto ml-auto">
      <b-btn variant="primary" v-if="canRecheckData" :disabled="!enableRecheckDataIssuesButton" @click="onRecheckDataIssuesClicked">Recheck data issues</b-btn>
      <b-btn variant="primary" v-if="showUploadDataLoadButton" @click="onUploadDataLoadClicked">Upload data file</b-btn>
    </div>

    <import-data-load-dialog title="Upload data file" :show="showImportDataLoad" @ok="onImportDataLoadConfirmed" @cancel="onImportDataLoadCancelled" @close="onImportDataLoadCancelled" />
    <recheck-data-issues-dialog title="Recheck data issues" :show="showRecheckDataIssuesDialog" @ok="onRecheckDataIssuesOk" @cancel="onRecheckDataIssuesCancel" @close="onRecheckDataIssuesClose" />
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { TenantGroupResponse } from '@/api/responses/tenant/tenant-group-response';
import VueRouter from 'vue-router';
import { Tenant, ReportGroup } from '@/store/tenant/state';
import { kebabcase } from '@/utilities/text.utils';
import { Data, DataDate } from '../../store/data/state';
import ValuationDateSelector from './valuation-date-selector.vue';
import ImportDataLoadDialog from './import-dataload-dialog.vue';
import RecheckDataIssuesDialog from './recheck-data-issues-dialog.vue';
import { date } from '@/filters/date';
import * as Sentry from '@sentry/browser';
import { FoundDataLoadResponse } from '../../api/responses/data/found-data-load-response';
import { UserResponse } from '../../api/responses/user/user-response';

const tenantModule = namespace('tenant');
const dataModule = namespace('data');
const userModule = namespace('user');

@Component({
  components: {
    ValuationDateSelector,
    ImportDataLoadDialog,
    RecheckDataIssuesDialog,
  },
})
export default class DataFilterBar extends Mixins(BvToastMixin) {
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @tenantModule.Getter private tenants!: Array<Tenant>;
  @tenantModule.Getter private selectedReportGroup!: ReportGroup;
  @dataModule.Getter private currentValuationDate!: DataDate | null;
  @dataModule.Getter private current!: Data | null;
  @userModule.Getter('current') private currentUser!: UserResponse;

  private reportGroup: ReportGroup | null = null;
  private enableRecheckDataIssuesButton: boolean = true;
  private showImportDataLoad: boolean = false;
  private findValue: string | number | null = null;
  private showRecheckDataIssuesDialog: boolean = false;

  private get showUploadDataLoadButton(): boolean {
    if (this.current === null) {
      return false;
    }

    return this.current.actions.canUploadData && this.$route.params.tab !== undefined && (this.$route.params.tab === 'data-loads' || this.$route.params.tab === 'data-files');
  }

  private get showFindDataLoad(): boolean {
    if (this.current === null) {
      return false;
    }

    if (this.currentUser === null) {
      return false;
    }

    return this.$route.params.tab !== undefined && this.$route.params.tab === 'data-loads';
  }

  private get canRecheckData(): boolean {
    if (this.current === null) {
      return false;
    }

    const paramMatch = this.$route.params.tab !== undefined && (this.$route.params.tab === 'data-issues' || this.$route.params.tab === 'translation-issues');

    return this.current.actions.canRecheckData && paramMatch;
  }

  private get queuedDataChecks(): number {
    if (this.current === null) {
      return 0;
    }

    return this.current.queuedDataChecks;
  }

  public get reportGroups(): Array<TenantGroupResponse> {
    return this.currentTenant.reportGroups.filter((rg) => rg.visible.all);
  }

  public async mounted(): Promise<void> {
    this.reportGroup = this.selectedReportGroup;
    this.$root.$on('datafile-dataload-clicked', this.onDataFileDataLoadClicked);
  }

  @Watch('selectedReportGroup')
  private onSelectedReportGroupChanged(reportGroup: TenantGroupResponse): void {
    this.reportGroup = this.selectedReportGroup;
  }

  private async changeReportGroup(group: TenantGroupResponse): Promise<void> {
    if (this.$route.params.reportGroup === undefined || this.$route.params.reportGroup !== kebabcase(group.name)) {
      try {
        await this.$router.push({ name: 'data', params: { ...this.$route.params, reportGroup: kebabcase(group.name) } });
      } catch (e) {
        // NOTE(Dan): In the router beforeEach we may get redirected to a different URL, e.g. we may calculate a new route based on configuration for the users initial page
        if (VueRouter.isNavigationFailure(e, VueRouter.NavigationFailureType.redirected)) {
          return;
        }

        throw e;
      }
    }
  }

  private setCurrentValuationDate(valuationDate: DataDate) {
    this.$root.$emit('valuation-date-selected', { page: this.$route.matched[0].name, valuationDate: { ...valuationDate } });
  }

  private async onRecheckDataIssuesClose(): Promise<void> {
    this.enableRecheckDataIssuesButton = true;
    this.showRecheckDataIssuesDialog = false;
  }

  private async onRecheckDataIssuesCancel(): Promise<void> {
    this.enableRecheckDataIssuesButton = true;
    this.showRecheckDataIssuesDialog = false;
  }

  private async onRecheckDataIssuesClicked(): Promise<void> {
    this.enableRecheckDataIssuesButton = false;
    this.showRecheckDataIssuesDialog = true;
  }

  private async onRecheckDataIssuesOk(value: { comments: string, reset: boolean }): Promise<void> {
    this.showRecheckDataIssuesDialog = false;

    try {
      await this.$store.dispatch('data/recheckDataIssuesAsync', { reportGroupId: this.selectedReportGroup.id, valuationDate: this.currentValuationDate!.valuationDate, comments: value.comments, resetIgnoredIssues: value.reset });

      this.showSuccessToast('Data issue re-check started.');
    } catch (e) {
      this.showErrorToast('Could not recheck all data issue. Please try again.');
      Sentry.captureException(e);
    }

    this.enableRecheckDataIssuesButton = true;
  }

  private onUploadDataLoadClicked(): void {
    this.showImportDataLoad = true;
  }

  private async onImportDataLoadConfirmed(): Promise<void> {
    this.onImportDataLoadCancelled();
  }

  private onImportDataLoadCancelled(): void {
    this.showImportDataLoad = false;
  }

  private async findDataLoadAsync(id: string | number): Promise<void> {
    const tryFindAsync = async (reportGroupId: number | null = null, valuationDate: string | null = null) => {
      try {
        const result = await this.$store.dispatch('data/findDataLoadAsync', { dataLoadId: id, reportGroupId: reportGroupId, valuationDate: valuationDate }) as FoundDataLoadResponse;

        return result;
      } catch (e) {
        return null;
      }
    };

    try {
      let result = await tryFindAsync(this.selectedReportGroup.id, this.currentValuationDate?.displayDate);

      if (result === null) {
        result = await tryFindAsync(null);
      }

      if (result === null) {
        throw new Error(`Cannot find data load '${id}'`);
      }

      const currentTenant = this.currentTenant;
      const params = { ...this.$route.params };
      const currentReportGroup = this.selectedReportGroup;
      let paramsChanged: boolean = false;
      let shouldReset: boolean = false;

      if (currentTenant.id !== result.tenantId) {
        const matchingTenant = this.tenants.find((t) => t.id === result!.tenantId) || null;

        if (matchingTenant === null) {
          throw new Error('No matching tenant found.');
        }

        params.tenant = kebabcase(matchingTenant.name);
        paramsChanged = true;
        shouldReset = true;
      }

      if (currentReportGroup.id !== result.reportGroupId) {
        params.reportGroup = kebabcase(result.reportGroup);
        paramsChanged = true;
      }

      const currentValuationDate = this.currentValuationDate;

      if (currentValuationDate!.valuationDate !== result.valuationDate) {
        params.valuationDate = kebabcase(date(result.displayDate, result.dateFormat));
        paramsChanged = true;
      }

      if (params.dataLoadId !== id) {
        params.dataLoadId = id.toString();
        paramsChanged = true;
      }

      if (paramsChanged) {
        try {
          // NOTE(Dan): We need to do this to clear the current state to prevent the cascading watchers from interupting
          //            or overwritting the information we are trying to load by going straight to the route.
          //            This is less than ideal and really, we should fixup how these watchers etc are used on the workflow page
          //            as it's rather broken currently due to multiple changes and "races".
          if (shouldReset) {
            await this.$store.commit('data/reset');
          }
          await this.$router.push({ name: 'data', params: { ...params, dataLoadId: id.toString(), tab: 'data-loads' } });
        } catch (e) {
        }
      }
    } catch (e) {
      this.showErrorToast(`Could not find data load '${id}'. Please try again.`);
    }

    this.findValue = null;
  }

  private async onDataFileDataLoadClicked(id: string | number): Promise<void> {
    await this.findDataLoadAsync(id);
  }
}
</script>

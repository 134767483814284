<template>
  <div class="support-filter-bar row align-items-center">
    <div class="col-auto pl-0">
      <b-button type="submit" variant="primary" @click="onNewTicketClicked">Open ticket</b-button>
    </div>
    <div class="col-auto ml-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0 pt-1 pb-1">Find ticket:</label>
        <div class="col-auto pt-1 pb-1">
          <b-input-group>
            <template v-slot:append>
              <b-button variant="primary" @click="findTicket">Find</b-button>
            </template>
            <b-form-input v-model="findValue" placeholder="Ticket ID" @keyup.enter="findTicket"></b-form-input>
          </b-input-group>
        </div>
      </div>
    </div>
    <open-support-ticket-dialog v-if="showOpenTicketDialog" :show="showOpenTicketDialog" @ok="onOpenTicketOk" @close="onOpenTicketCancel" @cancel="onOpenTicketCancel" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { Tenant } from '@/store/tenant/state';
import { Status } from '@/store/support/state';
import { namespace } from 'vuex-class';
import OpenSupportTicketDialog from '@/components/support/open-support-ticket-dialog.vue';
import { FindTicketResponse } from '../../api/responses/support/find-ticket-response';
import * as Sentry from '@sentry/browser';
import { kebabcase } from '@/utilities/text.utils';

const supportModule = namespace('support');
const tenantModule = namespace('tenant');

@Component({
  components: {
    OpenSupportTicketDialog,
  },
})
export default class SupportFilterBar extends Mixins(BvToastMixin) {
  @supportModule.Getter currentStatus!: Status | null;
  @supportModule.Getter status!: Status[] | null;
  @tenantModule.Getter('current') currentTenant!: Tenant;
  @tenantModule.Getter tenants!: Array<Tenant>;

  showOpenTicketDialog = false;
  findValue: string | number | null = null;

  get currentOption(): Status | null {
    return this.currentStatus;
  }

  async onOpenTicketOk(): Promise<void> {
    this.showSuccessToast('Ticket successfully opened.');

    await new Promise((resolve) => setTimeout(resolve, 1000));
    await this.$store.dispatch('support/getSupportAsync');

    this.onOpenTicketCancel();
  }

  onOpenTicketCancel(): void {
    this.showOpenTicketDialog = false;
  }

  onNewTicketClicked(): void {
    this.showOpenTicketDialog = true;
  }

  async findTicket(): Promise<void> {
    try {
      if (this.findValue === null || this.findValue.toString().trim().length < 1) {
        return;
      }

      const result = await this.$store.dispatch('support/findTicketAsync', this.findValue) as FindTicketResponse;

      const { tenant, status, ...params } = this.$route.params;

      if (this.currentTenant.id !== result.tenantId) {
        const match = this.tenants.find((t) => t.id === result.tenantId);

        if (match !== undefined) {
          params.tenant = kebabcase(match.name);
        }
      }

      if (this.currentOption?.id !== result.stateId) {
        const match = this.status?.find((t) => t.id === result.stateId);

        if (match !== undefined) {
          params.status = kebabcase(match.name);
        }
      }

      if (params.tenant !== undefined || params.status !== undefined) {
        const newParams: { tenant?: string, status?: string } = { tenant, status, ...params };
        if (newParams.tenant !== tenant || newParams.status !== status) {
          await this.$router.push({ name: 'support', params: { tenant, status, ...params } });
        }
      }

      const matchingStatus = this.status?.findIndex((s) => s.id === result.stateId)!;
      const matchingTenant = this.tenants.find((t) => t.id === result.tenantId);

      this.$root.$emit('support-ticket-found', { id: this.findValue, tabIndex: matchingStatus });

      if (this.currentTenant.id !== matchingTenant?.id) {
        await this.$store.dispatch('support/getSupportAsync');
      }
    } catch (e) {
      this.showErrorToast(`Could not find ticket '${this.findValue}'. Please try again.`);
      Sentry.captureException(e);
    }
    this.findValue = null;
  }
}
</script>

import { WorkflowReportFlagged } from '../events/workflow-report-flagged';
import store from '@/store';

async function handleWorkflowReportFlagged(data: WorkflowReportFlagged): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportFlagged event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryUpdateReportProperties', {
    reportId: reportId,
    flagged: true,
    flagColour: data.payload.flagColour,
    flagReason: data.payload.comments,
    actions: {
      canFlag: false,
      canEditFlag: true,
      canUnflag: true,
    },
  });
}

export default handleWorkflowReportFlagged;

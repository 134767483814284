<template>
  <div>
    <b-table class="workflow-report-issues-table"
             striped
             hover
             sticky-header="calc(100vh - 230px)"
             :tbody-tr-class="applyTableRowClasses"
             :fields="fields"
             :items="issues"
             :busy="loading"
    >
      <template v-slot:cell(type)="{ item }">
        <workflow-report-issue-type :item="item" />
      </template>

      <template v-slot:cell(description)="{ item }">
        {{ item.description }} <b-link v-if="item.warehouseDocumentId !== null" @click="onViewLinkedDocumentClicked(item)">(view document)</b-link>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-btn variant="outline-primary" v-if="hasLanguageLink(item)" size="sm" @click="showFixTranslationIssueDialog(item)" class="text-nowrap">Fix issue</b-btn>
        <b-btn variant="outline-primary" v-if="item.canBeIgnored" size="sm" @click="onIgnoredClicked(item)">Ignore</b-btn>
        <b-btn variant="outline-primary" v-if="item.canBeUnignored" size="sm" @click="onUnignoreClicked(item)">Unignore</b-btn>
      </template>
    </b-table>

    <fix-translation-issue-dialog v-if="showingFixTranslationIssueDialog" :show="showingFixTranslationIssueDialog" :issue="selectedTranslationIssue" @ok="onFixTranslationIssueDialogClosed" @close="onFixTranslationIssueDialogClosed" @cancel="onFixTranslationIssueDialogClosed" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WorkflowReportIssueResponse } from '@/api/responses/workflow/workflow-report-issue-response';
import WorkflowReportIssueType from './workflow-report-issue-type.vue';
import { WorkflowReportIssue } from '../../store/workflow/state';
import { ReportGroup } from '../../store/tenant/state';
import FixTranslationIssueDialog from './fix-translation-issue-dialog.vue';
import { FindDocumentResponse } from '@/api/responses/document-library/find-document-response';
import { kebabcase } from '@/utilities/text.utils';

const tenantModule = namespace('tenant');

@Component({
  components: {
    WorkflowReportIssueType,
    FixTranslationIssueDialog,
  },
})
export default class WorkflowReportIssuesTable extends Vue {
  @tenantModule.Getter private selectedReportGroup!: ReportGroup;
  @Prop({ type: Array, required: true }) public issues!: Array<WorkflowReportIssueResponse>;
  @Prop({ type: Boolean, required: true }) public loading!: boolean;

  private showingFixTranslationIssueDialog: boolean = false;
  private selectedTranslationIssue: WorkflowReportIssue | null = null;

  public get fields(): Array<{ key: string, label: string, class?: Array<string> | string }> {
    return [
      { key: 'type', label: '', class: ['col-auto'] },
      { key: 'description', label: '', class: ['col', 'data-issue-description'] },
      { key: 'actions', label: '', class: ['col-auto'] }
    ];
  }

  private applyTableRowClasses(item: WorkflowReportIssueResponse, type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy') {
    if (item !== null && type !== 'table-busy') {
      return { ignored: item.ignored };
    }
  }

  private hasLanguageLink(item: WorkflowReportIssueResponse): boolean {
    return item.links.languages.length > 0;
  }

  private onIgnoredClicked(item: WorkflowReportIssueResponse): void {
    this.$emit('workflow-report-data-issue-ignore-clicked', item);
  }

  private onUnignoreClicked(item: WorkflowReportIssueResponse): void {
    this.$emit('workflow-report-data-issue-unignore-clicked', item);
  }

  private showFixTranslationIssueDialog(item: WorkflowReportIssue): void {
    if (item.links.languages.length < 0) {
      return;
    }

    this.selectedTranslationIssue = item;
    this.showingFixTranslationIssueDialog = true;
  }

  private onFixTranslationIssueDialogClosed(): void {
    this.selectedTranslationIssue = null;
    this.showingFixTranslationIssueDialog = false;
  }

  private async onFixTranslationIssueDialogOk(): Promise<void> {
    this.onFixTranslationIssueDialogClosed();
  }

  private async onViewLinkedDocumentClicked(item: WorkflowReportIssueResponse): Promise<void> {
    if (item.warehouseDocumentId === null) {
      return;
    }

    const documentId = item.warehouseDocumentId;
    const result = await this.$store.dispatch('documentLibrary/findDocumentAsync', documentId) as FindDocumentResponse;

    if (result === null) {
      // TODO(Dan): Maybe we should show an error toast here?
      return;
    }

    await this.$router.push({ name: 'document-library', params: { tenant: this.$route.params.tenant, reportGroup: kebabcase(result.reportGroup), documentId: documentId.toString() } });
  }
}
</script>

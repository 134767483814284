<template>
  <div class="support-ticket-priority text-center" :title="title">
    <b-badge :variant="variant">{{ title }}</b-badge>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SupportTicket } from '../../store/support/state';

@Component
export default class SupportTicketPriorityIcon extends Vue {
  @Prop({ type: Object, required: true }) public item!: SupportTicket;

  public get title(): string | null {
    const parts = this.item.priority.split(/\s+/);

    if (parts.length < 1) {
      return null;
    }

    const last = parts[parts.length - 1];

    return last;
  }

  public get variant(): string {
    const parts = this.item.priority.split(/\s+/);

    if (parts.length < 1) {
      return 'info';
    }

    switch (parts[0]) {
      case '1':
        return 'critical';
      case '2':
        return 'danger';
      case '3':
        return 'warning';
      case '4':
        return 'success';
      default:
        return 'info';
    }
  }
}
</script>

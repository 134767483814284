<template>
  <b-navbar-nav class="ml-auto">
    <b-nav-item class="navbar-notification-tray">
      <div v-b-toggle.notification-tray-sidebar>
        <!-- <b-icon icon="bell" /> -->
        <b-avatar :badge="showBadge" badge-variant="danger" badge-top variant="white">
          <template v-slot:badge v-if="showBadge">{{ unreadNotifications }}</template>
          <b-icon icon="bell" font-scale="1.6" />
        </b-avatar>
      </div>
    </b-nav-item>

    <b-sidebar id="notification-tray-sidebar" class="notification-tray-sidebar" title="Notifications" bg-variant="white" right>
      <template v-slot:default>
        <!-- <header class="b-sidebar-header">
          <div class="d-flex">
            <b-btn-close @click="hide"><b-icon icon="x" /></b-btn-close>
            <strong id="notification-tray-sidebar___title__">Notifications</strong>
          </div>
        </header> -->
        <div class="row no-gutters">
          <div class="col-auto">
            <b-btn variant="link" size="md" @click="filterByUnread = !filterByUnread" v-if="notifications.length > 0">{{ filterDescription }}</b-btn>
          </div>
          <div class="col-auto ml-auto">
            <b-btn variant="link" size="md" @click="clearAll" v-if="notifications.length > 0">Clear all</b-btn>
          </div>
        </div>
        <div class="notification-tray-sidebar-content flex-column-reverse justify-content-end d-flex">
          <notification-message v-for="notification in filteredNotifications" :key="notification.id" :notification="notification" />
        </div>
      </template>
    </b-sidebar>
  </b-navbar-nav>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Notification } from '@/store/notification/state';
import NotificationMessage from './notification-message.vue';

const notificationModule = namespace('notification');

@Component({
  components: {
    NotificationMessage,
  },
})
export default class NotificationTray extends Vue {
  @notificationModule.Getter private notifications!: Array<Notification>;

  private filterByUnread: boolean = false;

  private get showBadge(): boolean {
    return this.notifications.some((n) => !n.read);
  }

  private get filterDescription(): string {
    if (this.filterByUnread) {
      return 'View all';
    }

    return 'Filter by unread';
  }

  private get filteredNotifications(): Array<Notification> {
    return this.notifications.filter((n) => (this.filterByUnread && !n.read) || !this.filterByUnread);
  }

  private get unreadNotifications(): string | number {
    const count = this.notifications.filter((n) => !n.read).length;

    if (count > 9) {
      return '9+';
    }

    return count;
  }

  private clearAll(): void {
    this.$store.commit('notification/clearAll');
  }
}
</script>

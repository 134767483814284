import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State, WorkflowPage, Workflow, WorkflowDate, WorkflowOption, WorkflowReport } from '@/store/workflow/state';
import { camelcase } from '@/utilities/text.utils';
import merge from 'lodash.merge';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setCurrent = (state: State, page: Workflow) => {
    state.current = page;
  }

  public setSelectedReportId = (state: State, id: number | null) => {
    state.selectedReportId = id;
  }

  public addPage = (state: State, page: WorkflowPage) => {
    Vue.set(state.pages, page.page - 1, page);
  }

  public addPages = (state: State, pages: Array<WorkflowPage>) => {
    state.pages = pages;
  }

  public clearPages = (state: State) => {
    state.pages = [];
  }

  public setSelectedValuationDate = (state: State, date: WorkflowDate) => {
    if (state.selectedValuationDate !== null && state.selectedValuationDate.valuationDate !== date.valuationDate) {
      this.clearPages(state);
    }
    state.selectedValuationDate = date;
  }

  public setCurrentOption = (state: State, option: WorkflowOption | null) => {
    state.currentOption = option;
  }

  public tryRemoveReportFromPage = (state: State, reportId: number) => {
    const pages = state.pages;
    const page = pages.find((p) => p.workflows.some((w) => w.id === reportId));

    if (page === undefined) {
      return false;
    }

    const index = page.workflows.findIndex((w) => w.id === reportId);

    if (index < 0) {
      return false;
    }

    page.workflows.splice(index, 1);
    page.pageSize -= 1;
  }

  public pushReportToPage = (state: State, payload: { report: WorkflowReport, pageNumber: number }) => {
    const page = state.pages.find((p) => p.page === payload.pageNumber);

    if (page === undefined) {
      return;
    }

    page.workflows.unshift(payload.report);
    page.pageSize += 1;
  }

  public replaceReportActionsFromPage = (state: State, data: { report: WorkflowReport, actions: { [key: string]: boolean }}) => {
    const report = data.report;

    for (const key in data.actions) {
      report.actions[key] = data.actions[key];
    }

    const version = (report as WorkflowReport & { __version: number | undefined }).__version;

    if (version === undefined) {
      Vue.set(report, '__version', 1);
    } else {
      (report as any).__version++;
    }
  }

  public updateOptionCounts = (state: State, data: { date: WorkflowDate, to: WorkflowOption | undefined, from: WorkflowOption | undefined}) => {
    const date = data.date;
    const from = data.from;
    const to = data.to;

    if (from !== undefined) {
      const count = date.options[camelcase(from.name)] || 0;
      if (count > 0) {
        date.options[camelcase(from.name)]! -= 1;
      }
    }

    if (to !== undefined) {
      date.options[camelcase(to.name)]! += 1;
    }
  }

  public updateReportProperties = (state: State, data: { source: Partial<WorkflowReport>, destination: WorkflowReport & { __version?: number } }) => {
    // NOTE(Dan): Destructure the data to exclude the report id and take the source. This is to prevent us merging the "reportId" into
    //            the actual workflow report.
    const destination = data.destination;

    if (destination.__version === undefined) {
      Vue.set(destination, '__version', 1);
    } else {
      destination.__version++;
    }

    const { ...source } = data.source;
    merge(destination, source);
  }

  public setLoading = (state: State, value: boolean) => {
    state.loadingWorkflowReports = value;
  }

  public setDownloading = (state: State, value: boolean) => {
    state.downloadingReports = value;
  }

  public reset = (state: State) => {
    state.current = null;
    state.currentOption = null;
    state.loadingWorkflowReports = false;
    state.downloadingReports = false;
    state.pages = [];
    state.selectedReportId = null;
    state.selectedValuationDate = null;
  }

  public setStateFromRoute = (state: State, data: { configuration: Workflow, valuationDate: WorkflowDate, option: WorkflowOption, reportId?: string, pages: Array<WorkflowPage> }) => {
    state.current = data.configuration;
    state.currentOption = data.option;
    state.loadingWorkflowReports = false;
    state.downloadingReports = false;
    state.pages = data.pages;
    state.selectedReportId = Number(data.reportId) || null;
    state.selectedValuationDate = data.valuationDate;
  }

  public addValuationDate = (state: State, data: { workflowDate: WorkflowDate }) => {
    if (data.workflowDate !== undefined || data.workflowDate !== null) {
      state.current!.dates.unshift(data.workflowDate);
    }
  }
}

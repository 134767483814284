import { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Summary from '@/views/Summary.vue';
import Configuration from '@/views/Configuration.vue';
import Data from '@/views/Data.vue';
import Workflow from '@/views/Workflow.vue';
import DocumentLibrary from '@/views/DocumentLibrary.vue';
import LanguageMaintenance from '@/views/LanguageMaintenance.vue';
import Forbidden from '@/views/Forbidden.vue';
import Support from '@/views/Support.vue';
import SupportTicket from '@/views/SupportTicket.vue';
import NotFound from '@/views/NotFound.vue';
import Help from '@/views/Help.vue';
import Error from '@/views/Error.vue';
import SetupRequired from '@/views/SetupRequired.vue';
import SignoutRedirect from '@/views/SignoutRedirect.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '/setup-required',
    name: 'setup-required',
    component: SetupRequired,
  },
  {
    path: '/password-change-completed',
    name: 'password-change-completed',
    component: SignoutRedirect,
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
  },
  {
    path: '/:tenant?/language-maintenance/:phrase?/:language?',
    name: 'language-maintenance',
    // component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue'),
    component: LanguageMaintenance,
    meta: {
      requiresAuthentication: true,
      requiresAnyPermission: ['canViewLanguages', 'canManageLanguages'],
    },
  },
  {
    path: '/:tenant?/summary/:reportGroup?',
    name: 'summary',
    // component: () => import(/* webpackChunkName: "summary" */ '../views/Summary.vue'),
    component: Summary,
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/:tenant?/configuration/:reportGroup?/:settings?',
    name: 'configuration',
    // component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue'),
    component: Configuration,
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/:tenant?/data/:reportGroup?/:valuationDate?/:tab?/:dataLoadId?',
    name: 'data',
    // component: () => import(/* webpackChunkName: "data" */ '../views/Data.vue'),
    component: Data,
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/:tenant?/workflow/:reportGroup?/:valuationDate?/:option?/:reportId?',
    name: 'workflow',
    // component: () => import(/* webpackChunkName: "workflow" */ '../views/Workflow.vue'),
    component: Workflow,
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/:tenant?/document-library/:reportGroup?/:documentId?',
    name: 'document-library',
    // component: () => import(/* webpackChunkName: "document-library" */ '../views/DocumentLibrary.vue'),
    component: DocumentLibrary,
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/:tenant?/support/:status?',
    name: 'support',
    // component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue'),
    component: Support,
    meta: {
      requiresAuthentication: true,
      requiresAnyPermission: [],
    },
  },
  {
    path: '/:tenant?/support/ticket/:id',
    name: 'support-ticket',
    // component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue'),
    component: SupportTicket,
    meta: {
      requiresAuthentication: true,
      requiresAnyPermission: [],
    },
  },
  {
    path: '/:tenant?/:valuationDate?',
    name: 'home',
    // redirect: 'workflow',
    // component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    component: Home,
    meta: {
      requiresAuthentication: true,
    },
  }
];

export default routes;

<template>
  <div>
    <svg
      width="2em"
      height="2em"
      viewBox="0 0 200 200"
    >
      <g transform="translate(0.0383083,-10.724966)">
        <g transform="matrix(5.8001012,0,0,5.8001012,4.9616917,15.724966)">
          <path style="fill:#a9a9a9;fill-opacity:1;stroke-width:1.08912" d="M 5.4176089,2.2341684 A 1.4907672,1.4138231 0 0 0 3.9268032,3.648045 v 6.0106294 7.4244126 7.424411 6.010629 a 1.4907672,1.4138231 0 0 0 1.4908057,1.413782 H 29.276557 a 1.4907672,1.4138231 0 0 0 1.490806,-1.413782 V 24.507498 17.083087 9.6586744 3.648045 A 1.4907672,1.4138231 0 0 0 29.276557,2.2341684 Z" />
          <path d="m 3.4463134,0.65967124 a 1.4952716,1.415241 0 0 0 -1.49531,1.41529456 v 6.0166574 7.4318578 7.431857 6.016657 a 1.4952716,1.415241 0 0 0 1.49531,1.415201 H 27.377349 a 1.4952716,1.415241 0 0 0 1.49531,-1.415201 V 22.955338 15.523481 8.0916232 2.0749658 a 1.4952716,1.415241 0 0 0 -1.49531,-1.41529456 z" style="fill:#e8e8e8;fill-opacity:1;stroke-width:1.09131" />
          <path fill="none" d="M 0,0 H 32 V 32 H 0 Z" />
          <path style="stroke-width:0.797792" d="M 3.3861815,1.4234418 H 27.261294 V 28.721743 H 3.3861815 Z" fill="none" />
          <path style="stroke-width:1.45986" fill="#c43e1c" d="M 5.8460454,4.138327 H 25.311809 a 1.9459925,1.9459925 0 0 1 1.945992,1.945992 v 19.465766 a 1.9459925,1.9459925 0 0 1 -1.945992,1.945992 H 5.8460454 A 1.9459925,1.9459925 0 0 1 3.9000514,25.550085 V 6.084319 a 1.9459925,1.9459925 0 0 1 1.945994,-1.945992 z" />
          <path style="stroke-width:1.45986" fill="#f9f7f7" d="m 15.574546,8.517905 a 6.0846939,6.0846939 0 0 1 4.021913,1.175187 4.2014753,4.2014753 0 0 1 1.395626,3.402932 3.9795767,3.9795767 0 0 1 -0.690513,2.318258 4.6189951,4.6189951 0 0 1 -1.962051,1.731392 6.6715571,6.6715571 0 0 1 -2.948916,0.618981 h -2.788332 v 5.351843 H 9.7394884 V 8.517905 Z m -2.973733,6.836522 h 2.462783 a 3.2029315,3.2029315 0 0 0 2.178111,-0.648177 1.6160644,1.6160644 0 0 0 0.73577,-1.497817 q 0,-2.421906 -2.821909,-2.421906 h -2.554755 z" />
          <g transform="matrix(1.1217054,0,0,1.1211122,-2.4307141,-2.8637004)">
            <g transform="matrix(2.190629,0,0,3.0733002,-24.726698,-50.392988)" style="fill:#ffffff;fill-opacity:1" />
            <g transform="translate(-0.00296584,-0.02239232)">
              <path d="m 17.659158,22.185449 0.163486,-0.226192 2.019642,0.0086 0.02424,-1.927708 3.987832,0.01714 v 1.910403 l 2.059206,-0.0087 0.01885,0.336162 0.0076,0.387608 -3.601086,4.276548 -0.5084,0.0068 -0.494112,-0.01001 -3.83433,-4.552281 z" style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.0255418;stroke-opacity:1" transform="matrix(2.190629,0,0,3.0733002,-24.726698,-50.392987)" />
              <g style="fill:#233264;fill-opacity:1" transform="matrix(1.7980234,0,0,2.3926161,-10.485526,-38.451952)">
                <path d="m 180.20709,87.443958 24.94671,-1.262975 -1.56126,-30.875168 31.64023,-1.322643 1.28472,30.609965 24.39561,-1.235075 -36.81451,71.936408 z" transform="matrix(0.11224705,0.00402688,-0.00567597,0.07963492,-5.5901957,16.017455)" style="fill:#233264;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DownloadPowerpointIcon extends Vue {
}
</script>

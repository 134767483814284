import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import UserManager from '@/services/user-manager/user-manager';
import routes from '@/router/routes';
import store from '@/store';
import { kebabcase } from '@/utilities/text.utils';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';
import { UserResponse } from '@/api/responses/user/user-response';
import { storeRedirectPath, loadRedirectPath, clearRedirectPath } from '@/redirect-path';
import { Dictionary } from 'vue-router/types/router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await UserManager.clearStaleState();
  const user = await UserManager.getUser();

  const isLoggedIn = user != null;

  if ((!isLoggedIn || user!.expired) && to.meta!.requiresAuthentication) {
    // NOTE(Dan): If you navigate directly to a URL, but are not logged in you will get sent to login and lose the URL
    //            you were trying to visit. Here, we're going to try and store then restore this on returning to the page.
    storeRedirectPath(to.fullPath);
    await UserManager.signinRedirect();
    return;
  }

  const redirectUri = loadRedirectPath();

  if (redirectUri !== null) {
    clearRedirectPath();
    next(redirectUri);
    return;
  }

  const env = store.getters['environment/current'] as { environment: string, version: string, runtime: string };

  if (env.environment !== 'Production') {
    document.title = `Opus Nebula - ${env.environment}`;
  }

  let currentTenant = store.getters['tenant/current'] as TenantResponse | null;

  if (currentTenant !== null && to.params.tenant === undefined) {
    to.params.tenant = kebabcase(currentTenant.name);
    await router.replace({ name: to.name || undefined, params: { ...to.params }, query: { ...to.query }, hash: to.hash });
    return;
  }

  if (currentTenant !== null && to.params.tenant !== undefined) {
    if (kebabcase(currentTenant.name) !== to.params.tenant) {
      const currentUser = store.getters['user/current'] as UserResponse;
      const tenants = (store.getters['tenant/tenants'] as Array<TenantResponse>);
      const match = tenants.find((t) => kebabcase(t.name) === to.params.tenant) || tenants.find((t) => t.id === currentUser.currentTenantId) || tenants[0];

      currentTenant = match;
      if (match !== undefined) {
        store.commit('tenant/setCurrentTenant', match);
        store.dispatch('tenant/setCurrentTenantAsync', match);
      }
    }
  }

  // TODO(Dan): Handle when we have no report groups
  if (currentTenant !== null && currentTenant.reportGroups.length < 1 && !['forbidden', 'error', 'setup-required'].some(n => n === to.name)) {
    next({ name: 'setup-required' });
    return;
  }

  // TODO(Dan): Roll out this change to other pages/routes/modules
  if (to.name === 'workflow' || to.name === 'data') {
    const newParams = await store.dispatch('applyStateFromRoute', { route: to.name, ...to.params }) as Dictionary<string>;

    if (JSON.stringify(to.params) !== JSON.stringify(newParams)) {
      await router.replace({ name: to.name || undefined, params: { ...newParams }, query: { ...to.query }, hash: to.hash });
      return;
    }
  }

  if (to.meta!.requiresAuthentication || (to.meta!.requiresAnyRole != null && to.meta!.requiresAnyRole.length > 0)) {
    const routeCheck = store.getters['tenant/hasPermissionForRoute'] as (route: Route) => boolean;

    if (!routeCheck(to)) {
      next({ name: 'forbidden' });
      return;
    }
  }

  next();
});

export default router;

<template>
  <validation-provider
    :vid="setting.name"
    :name="setting.displayName || setting.name"
    :ref="setting.name"
    :rules="validationRules"
    v-slot="{ errors }"
    mode="lazy"
    tag="div"
  >
    <b-checkbox v-if="setting.type === 'Checkbox'" :state="errors[0] ? false : null" v-model="value" @change="onChange(value, originalValue)" :switch="true" inline :disabled="!current.canEditReportGroupSettings || !setting.editable" />

    <b-form-select v-else-if="setting.type === 'Select'" :options="options" :state="errors[0] ? false : null" v-model="value" @change="onChange(value, originalValue)" :disabled="!current.canEditReportGroupSettings || !setting.editable">
    </b-form-select>

    <b-form-input v-else-if="setting.type === 'Text'" :state="errors[0] ? false : null" v-model="value" @blur="onChange(value, originalValue)" :disabled="!current.canEditReportGroupSettings || !setting.editable" type="text" />

    <b-form-textarea v-else-if="setting.type === 'Textarea'" :state="errors[0] ? false : null" v-model="value" @blur="onChange(value, originalValue)" size="sm" rows="3" :disabled="!current.canEditReportGroupSettings || !setting.editable" />

    <div v-else-if="setting.type === 'Password'" class="row">
      <div class="col pr-0">
        <b-form-input :state="errors[0] ? false : null" v-model="value" @blur="onChange(value, originalValue)" :type="passwordInputType" :disabled="!current.canEditReportGroupSettings || !setting.editable" />
      </div>
      <div class="col-auto pl-2">
        <b-btn variant="link" @click="togglePasswordVisibility">{{ isPasswordVisible ? 'hide' : 'show' }}</b-btn>
      </div>
    </div>

    <b-form-radio-group v-else-if="setting.type === 'Radio'" :options="options" :state="errors[0] ? false : null" v-model="value" @change="onChange(value, originalValue)" :disabled="!current.canEditReportGroupSettings || !setting.editable">
    </b-form-radio-group>

    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EntitySetting, Configuration, ConfigurationEntity } from '@/store/configuration/state';
import { ValidationProvider } from 'vee-validate';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { ReportGroup } from '../../store/tenant/state';

const configurationModule = namespace('configuration');

@Component({
  components: {
    ValidationProvider,
  },
})
export default class ReportGroupSettingControl extends Vue {
  @configurationModule.Getter public currentEntity!: ConfigurationEntity | null;
  @configurationModule.Getter public current!: Configuration | null;
  @configurationModule.Getter private currentReportGroup!: ReportGroup;
  @Prop({ type: Object, required: true }) public setting!: EntitySetting;
  @Prop({ type: Array, required: false }) public group!: Array<EntitySetting> | null;

  private value: string | number | null = this.setting?.value;
  private originalValue: string | number | null = this.setting?.value;
  private passwordInputType: 'password' | 'text' = 'password';

  public get validationRules() {
    const hasGroup = this.group !== null && this.group.length > 1;
    const hasRules = this.setting.metadata?.validation?.rules !== undefined && this.setting.metadata?.validation?.rules !== null;

    if (!hasGroup && !hasRules) {
      return null;
    }

    if (hasGroup && !hasRules) {
      const otherSettingNames = this.group!.filter(g => g.name !== this.setting.name).map(g => `@${g.name}`).join(',');
      return `notOneOf:${otherSettingNames}`;
    }

    if (!hasGroup && hasRules) {
      return this.setting.metadata.validation!.rules;
    }

    const otherSettingNames = this.group!.filter(g => g.name !== this.setting.name).map(g => `@${g.name}`).join(',');

    return `notOneOf:${otherSettingNames}|${this.setting.metadata.validation!.rules}`;
  }

  public get options() {
    return this.setting.options.map(o => ({ value: o.value, text: o.label }));
  }

  public get isPassword(): boolean {
    return this.setting.type === 'Password';
  }

  public get isPasswordVisible(): boolean {
    if (!this.isPassword) {
      return false;
    }

    return this.passwordInputType === 'text';
  }

  private async onChange(newValue: string | number | null, currentValue: string | number | null): Promise<void> {
    const provider = this.$refs[this.setting.name];

    // @ts-ignore
    const result: ValidationResult = await provider.validate();

    if (!result.valid) {
      return;
    }

    if (newValue === currentValue) {
      return;
    }

    this.$emit('change', { newValue: newValue, currentValue: this.originalValue, setting: this.setting });
  }

  private togglePasswordVisibility(): void {
    if (this.isPasswordVisible) {
      this.passwordInputType = 'password';
    } else {
      this.passwordInputType = 'text';
    }
  }

  @Watch('setting', { immediate: true })
  private onSettingChanged(setting: EntitySetting): void {
    if (setting === null || setting === undefined) {
      this.originalValue = null;
      return;
    }

    this.originalValue = setting.value;
    this.value = setting?.value;
  }

  @Watch('setting.value')
  private onSettingValueChanged(value: string | number | null): void {
    this.setting.value = value;
    this.originalValue = value;
  }
}
</script>

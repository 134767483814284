<template>
  <div class="workflow-report-rendering-status data-check text-center">
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DataCheckWaiting extends Vue {
}
</script>

import { ActionTree, ActionContext } from 'vuex';
import { State } from '@/store/help/state';
import { HelpApi } from '@/api/help-api';

const api = new HelpApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public loadAsync = async (context: ActionContext<State, any>) => {
    try {
      const result = await api.getHelpAsync();

      context.commit('setHelp', result);
    } catch (e) {
    }
  }

  public downloadHelpVideoAsync = async (context: ActionContext<State, any>, file: string) => {
    return api.downloadHelpVideoAsync(file);
  }
}

<template>
  <div class="row align-items-center">
    <div class="col">
      <b-input-group v-if="isEditing">
        <b-form-input ref="input" v-model="value" @keypress.enter="save" @keyup.esc="cancel" />
      </b-input-group>
      <span v-else>
        {{ value }}
      </span>
    </div>
    <div class="col-auto ml-auto" v-if="canManageLanguages">
      <b-button-group>
        <b-button v-if="!isEditing" variant="outline-primary" @click="toggleEditing" size="sm" title="Edit">
          <b-icon icon="pencil" aria-hidden="true"></b-icon>
        </b-button>
        <b-button v-if="!isEditing" :disabled="!translation.value" variant="outline-primary" @click="toggleRemoving" size="sm" title="Delete">
          <b-icon icon="trash" aria-hidden="true"></b-icon>
        </b-button>
        <b-button v-if="isEditing" variant="outline-primary" size="sm" @click="save" title="Save">
          <b-icon icon="check2" aria-hidden="true"></b-icon>
        </b-button>
        <b-button v-if="isEditing" variant="outline-primary" size="sm" @click="cancel" title="Cancel">
          <b-icon icon="X" aria-hidden="true"></b-icon>
        </b-button>
      </b-button-group>
    </div>

    <delete-translation-confirmation-dialog v-if="showRemoveConfirmation" :show="showRemoveConfirmation" :translation="translation" :title="`Remove ${translation.language} phrase '${translation.value}'?`" @ok="onRemoveConfirmed" @cancel="onRemoveCancelled" @close="onRemoveCancelled" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Translation } from '../../store/language-maintenance/state';
import { BFormInput } from 'bootstrap-vue';
import { namespace } from 'vuex-class';
import DeleteTranslationConfirmationDialog from './delete-translation-confirmation-dialog.vue';

const tenantModule = namespace('tenant');

@Component({
  components: {
    DeleteTranslationConfirmationDialog,
  },
})
export default class TranslationTableValue extends Vue {
  @tenantModule.Getter private canManageLanguages!: boolean;

  @Prop({ required: true }) public translation!: Translation;

  private isEditing: boolean = false;
  private value: string | null = this.translation?.value;
  private showRemoveConfirmation: boolean = false;

  @Watch('translation.value', { immediate: true, deep: true })
  onTranslationValueChange(newValue: string, oldValue: string) {
    this.value = newValue;
  }

  @Watch('isEditing')
  onIsEditingChange(newVal: boolean, oldVal: boolean) {
    this.isEditing = newVal;
  }

  @Watch('showRemoveConfirmation')
  onshowRemoveConfirmationChange(newVal: boolean, oldVal: boolean) {
    this.showRemoveConfirmation = newVal;
  }

  private toggleEditing(): void {
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      this.$nextTick(() => {
        (this.$refs.input as BFormInput).focus();
      });
    }

    this.$emit('editing', this.isEditing);
  }

  private toggleRemoving(): void {
    this.showRemoveConfirmation = true;
  }

  private save(): void {
    if (this.translation.value === this.value) {
      return;
    }

    this.translation.value = this.value;
    this.toggleEditing();

    this.$emit('saved', this.translation);
  }

  private cancel(): void {
    this.value = this.translation.value;
    this.toggleEditing();

    this.$emit('cancelled', this.translation);
  }

  private onRemoveConfirmed(): void {
    this.$emit('removed', this.translation);
    this.onRemoveCancelled();
  }

  private onRemoveCancelled(): void {
    this.showRemoveConfirmation = false;
  }
}
</script>

import store from '@/store';
import { DataLoadForReportGroupValuationDateStarted } from '../events/data-load-for-report-group-valuation-date-started';

async function handleDataLoadForReportGroupValuationDateStarted(data: DataLoadForReportGroupValuationDateStarted): Promise<void> {
  /* eslint-disable */
  console.log(`DataLoadForReportGroupValuationDateStarted event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const dataLoadId = Number(data.payload.aggregateId.split('-')[1]);

  const pushed = await store.dispatch('data/tryPushDataLoadToPage', { ...data, dataLoadId });
  await store.dispatch('data/tryUpdateDataLoadStatus', { status: 'Running', dataLoadId: dataLoadId });

  if (pushed) {
    await store.dispatch('data/tryIncrementDataLoadCount', { ...data, dataLoadId });
  }
}

export default handleDataLoadForReportGroupValuationDateStarted;

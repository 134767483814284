import { GetterTree } from 'vuex';
import { State } from '@/store/environment/state';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public loggerConnectionString = (state: State) => {
    return state.loggerConnectionString;
  }

  public current = (state: State) => {
    return state.current;
  }

  public isWebsocketConnected = (state: State) => {
    return state.websocketConnected;
  }
}

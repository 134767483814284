import { WorkflowReportDataCheckQueued } from '../events/workflow-report-data-check-queued';
import store from '@/store';

async function handleWorkflowReportDataCheckQueued(data: WorkflowReportDataCheckQueued): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportDataCheckQueued event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryUpdateReportProperties', {
    renderingStatus: 'DataCheckWaiting',
    reportId: reportId,
    tooltips: {
      renderingStatus: data.payload.tooltip,
    },
  });

  await store.dispatch('data/tryIncrementDataCheckQueueCount', data);
}

export default handleWorkflowReportDataCheckQueued;

export class State {
  public loadingSchedule: boolean = false;
  public entitySchedule: EntitySchedule | null = null;
  public entityScheduleActivity: EntityScheduleActivityLog | null = null;
  public requestPages: Array<EntityScheduleCompletedRequestsPage> = [];
  public selectedEntryId: number | null = null;
}

export interface Schedule {
  entityType: string | null;
  entitySubType: string;
  regionOrLanguage: string | null;
  canRunReports: boolean;
  canScheduleReports: boolean;
  canReorderReports: boolean;
  hasSubEntities: boolean;
  entities: Array<ScheduleEntity>;
  dates: Array<ScheduleDate>;
}

export interface ScheduleEntity {
  id: number;
  name: string;
}

export interface ScheduleDate {
  valuationDate: string;
  displayDate: string;
}

export interface EntityScheduleEntry {
  id: number;
  name: string;
  active: boolean | null;
  subActive: boolean | null;
  order: number;
  regions: { [key: string]: boolean | null | undefined };
}

export interface EntityScheduleRegion {
  id: number;
  name: string;
}

export interface EntitySchedule {
  entities: Array<EntityScheduleEntry>;
  regions: Array<EntityScheduleRegion>;
}

export interface EntityScheduleActivityLog {
  reportGroupId: number;
  entityId: string;
  activity: Array<EntityScheduleActivity>;
}

export interface EntityScheduleActivity {
  entityId: number;
  timestamp: string;
  description: string | null;
}

export interface EntityScheduleCompletedRequestsPage {
  reportGroupId: number;
  entityId: number;
  page: number;
  pageSize: number;
  requests: Array<EntityScheduleCompletedRequest>;
}

export interface EntityScheduleCompletedRequest {
  fileName: string | null;
  entityId: number;
  fileType: string | null;
  name: string;
  replaced: boolean;
  reportId: number;
}

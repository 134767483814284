import { ActionTree, ActionContext } from 'vuex';
import { State, WorkflowReport, WorkflowDate, WorkflowOption, WorkflowPage, Workflow } from '@/store/workflow/state';
import { WorkflowApi } from '@/api/workflow-api';
import debounce from 'lodash.debounce';
import { WorkflowReportAvailableActionsReplaced } from '@/services/notifications/events/workflow-report-available-actions-replaced';
import { WorkflowPageResponse } from '@/api/responses/workflow/workflow-page-response';
import { Dictionary } from 'vue-router/types/router';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';
import { UserResponse } from '@/api/responses/user/user-response';
import { date } from '@/filters/date';
import { WorkflowResponse } from '@/api/responses/workflow/workflow-response';
import { camelcase, kebabcase } from '@/utilities/text.utils';
import { ReportGroup } from '../tenant/state';
import moment from 'moment';

type WorkflowState = { tenant: string, reportGroup?: string, valuationDate?: string, option?: string, reportId?: string };

const workflowApi = new WorkflowApi();
const DEFAULT_PAGE_SIZE = 250;

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  private getWorkflowPageInternalAsync = async (context: ActionContext<State, any>, options: { reportGroupId: number, valuationDate: string | Date, status: string | number, page: number, pageSize: number, filter?: string | null }) => {
    context.commit('setLoading', true);
    const result = await workflowApi.getWorkflowPageAsync(options.reportGroupId, options.valuationDate, options.status, options.page, options.pageSize, options.filter);
    const reportGroupMatches = context.state.pages[context.state.pages.length - 1] != null && context.state.current!.reportGroupId === options.reportGroupId;
    const valuationDateMatches = context.state.selectedValuationDate !== null && context.state.selectedValuationDate.valuationDate === options.valuationDate;
    const statusMatches = context.state.pages[context.state.pages.length - 1] != null && context.state.pages[context.state.pages.length - 1].statusId === options.status;

    if (!reportGroupMatches || !valuationDateMatches || !statusMatches) {
      context.commit('clearPages');
    }

    context.commit('addPage', result);
    context.commit('setLoading', false);

    workflowApi.refreshCorrelationId();

    return result;
  }

  private getWorkflowReportActivityInternalAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number }) => {
    try {
      const result = await workflowApi.getWorkflowReportActivityAsync(options.workflowReportId, options.reportGroupId);

      return result;
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  private getWorkflowReportIssuesInternalAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number }) => {
    try {
      const result = await workflowApi.getWorkflowReportIssuesAsync(options.workflowReportId, options.reportGroupId);

      return result;
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  private getWorkflowReportContentInternalAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number }) => {
    try {
      const result = await workflowApi.getWorkflowReportContentAsync(options.workflowReportId, options.reportGroupId);

      return result;
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  private getWorkflowReportDistributionInternalAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number }) => {
    try {
      const result = await workflowApi.getWorkflowReportDistributionAsync(options.workflowReportId, options.reportGroupId);

      return result;
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public getWorkflowPageAsync = debounce(this.getWorkflowPageInternalAsync, 0, { leading: true });

  public getWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number }) => {
    try {
      const result = await workflowApi.getWorkflowReportAsync(options.reportGroupId, options.workflowReportId);

      return result;
    } catch (e) {
      return null;
    }

    workflowApi.refreshCorrelationId();
  }

  public getWorkflowReportActivityAsync = debounce(this.getWorkflowReportActivityInternalAsync, 0, { leading: true });

  public getWorkflowReportIssuesAsync = debounce(this.getWorkflowReportIssuesInternalAsync, 0, { leading: true });

  public getWorkflowReportContentAsync = debounce(this.getWorkflowReportContentInternalAsync, 0, { leading: true });

  public getWorkflowReportDistributionAsync = debounce(this.getWorkflowReportDistributionInternalAsync, 0, { leading: true });

  public ignoreWorkflowReportIssueAsync = async (context: ActionContext<State, any>, options: { id: number, workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.ignoreWorkflowReportIssueAsync(options.id, options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public unignoreWorkflowReportIssueAsync = async (context: ActionContext<State, any>, options: { id: number, workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.unignoreWorkflowReportIssueAsync(options.id, options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public approveWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.approveWorkflowReportAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public rejectWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.rejectWorkflowReportAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }
  }

  public flagWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null, flagColour: number }) => {
    try {
      return await workflowApi.flagWorkflowReportAsync(options.workflowReportId, options.reportGroupId, options.comments, options.flagColour);
    } catch (e) {
    }
  }

  public editFlagWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null, flagColour: number }) => {
    try {
      return await workflowApi.editFlagWorkflowReportAsync(options.workflowReportId, options.reportGroupId, options.comments, options.flagColour);
    } catch (e) {
    }
  }

  public unflagWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.unflagWorkflowReportAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }
  }

  public sendWorkflowReportDelayedCommentaryAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.sendWorkflowReportDelayedCommentaryAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }
  }

  public cancelWorkflowReportCommentaryReaquisitionAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.cancelWorkflowReportCommentaryReaquisitionAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public deleteWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.deleteWorkflowReportAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public reAquireWorkflowReportCommentaryAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.reAquireWorkflowReportCommentaryAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public reRenderWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.reRenderWorkflowReportAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public recheckWorkflowReportDataAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null, reset: boolean }) => {
    try {
      return await workflowApi.recheckWorkflowReportDataAsync(options.workflowReportId, options.reportGroupId, options.comments, options.reset);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public releaseWorkflowReportDraftDataAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.releaseWorkflowReportDraftDataAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public resendWorkflowReportApprovalEmailAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.resendWorkflowReportApprovalEmailAsync(options.workflowReportId, options.reportGroupId, options.type, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public resendWorkflowReportCommentaryEmailAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.resendWorkflowReportCommentaryEmailAsync(options.workflowReportId, options.reportGroupId, options.type, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public returnWorkflowReportForApprovalOneAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.returnWorkflowReportForApprovalOneAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public returnWorkflowReportForApprovalTwoAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.returnWorkflowReportForApprovalTwoAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public returnWorkflowReportForApprovalThreeAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.returnWorkflowReportForApprovalThreeAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  returnWorkflowReportForApprovalFourAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.returnWorkflowReportForApprovalFourAsync(options.workflowReportId, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public runReplacementForWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, useApprovalWorkflow: number, useStandardDistribution: boolean, includeCommentary: boolean, reportGroupId: number, comments: string | null }) => {
    try {
      await workflowApi.runReplacementForWorkflowReportAsync(options.workflowReportId, options.useApprovalWorkflow, options.useStandardDistribution, options.includeCommentary, options.reportGroupId, options.comments);
    } finally {
      workflowApi.refreshCorrelationId();
    }
  }

  public downloadWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, isPreview:boolean }): Promise<Blob> => {
    const result = await workflowApi.downloadWorkflowReportAsync(options.workflowReportId, options.reportGroupId, options.isPreview);

    workflowApi.refreshCorrelationId();

    return result;
  }

  public findWorkflowReportAsync = async (context: ActionContext<State, any>, id: string | number) => {
    const result = await workflowApi.findWorkflowReportAsync(id);

    workflowApi.refreshCorrelationId();

    return result;
  }

  public releasePendingWorkflowReportAsync = async (context: ActionContext<State, any>, data: { reportGroupId: number, workflowReportId: number }) => {
    const result = await workflowApi.releasePendingWorkflowReportAsync(data.workflowReportId, data.reportGroupId);

    workflowApi.refreshCorrelationId();

    return result;
  }

  public releaseAllPendingWorkflowReportsAsync = async (context: ActionContext<State, any>, data: { reportGroupId: number, stateId: number, valuationDate: string | Date }) => {
    const result = await workflowApi.releaseAllPendingWorkflowReportsAsync(data.reportGroupId, data.stateId, data.valuationDate);

    workflowApi.refreshCorrelationId();

    return result;
  }

  public scheduleWorkflowReportBatchDistribution = async (context: ActionContext<State, any>, data: { batchId: number, runAt: string | Date, reportGroupId: number, valuationDate: string | Date }) => {
    await workflowApi.scheduleWorkflowReportBatchDistribution(data.batchId, data.reportGroupId, data.valuationDate, data.runAt);

    workflowApi.refreshCorrelationId();
  }

  public ignoreCompletedWorkflowReportNewDataIssuesAsync = async (context: ActionContext<State, any>, options: { workflowReportId: number, reportGroupId: number, comments: string | null }) => {
    await workflowApi.ignoreCompletedWorkflowReportNewDataIssuesAsync(options.workflowReportId, options.reportGroupId, options.comments);

    workflowApi.refreshCorrelationId();
  }

  public bulkApproveWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkApproveWorkflowReportAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkRejectWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkRejectWorkflowReportAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }
  }

  public bulkFlagWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null, flagColour: number }) => {
    try {
      return await workflowApi.bulkFlagWorkflowReportAsync(options.workflowReportIds, options.reportGroupId, options.comments, options.flagColour);
    } catch (e) {
    }
  }

  public bulkUnflagWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkUnflagWorkflowReportAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }
  }

  public bulkSendWorkflowReportDelayedCommentaryAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkSendWorkflowReportDelayedCommentaryAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }
  }

  public bulkCancelWorkflowReportCommentaryReaquisitionAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkCancelWorkflowReportCommentaryReaquisitionAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkDeleteWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkDeleteWorkflowReportAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkReAquireWorkflowReportCommentaryAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkReAquireWorkflowReportCommentaryAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkReRenderWorkflowReportAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkReRenderWorkflowReportAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkRecheckWorkflowReportDataAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null, reset: boolean }) => {
    try {
      return await workflowApi.bulkRecheckWorkflowReportDataAsync(options.workflowReportIds, options.reportGroupId, options.comments, options.reset);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkReleaseWorkflowReportDraftDataAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    try {
      return await workflowApi.bulkReleaseWorkflowReportDraftDataAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkResendWorkflowReportApprovalEmailAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.bulkResendWorkflowReportApprovalEmailAsync(options.workflowReportIds, options.reportGroupId, options.type, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkResendWorkflowReportCommentaryEmailAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.bulkResendWorkflowReportCommentaryEmailAsync(options.workflowReportIds, options.reportGroupId, options.type, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkReturnWorkflowReportForApprovalOneAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.bulkReturnWorkflowReportForApprovalOneAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkReturnWorkflowReportForApprovalTwoAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.bulkReturnWorkflowReportForApprovalTwoAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkReturnWorkflowReportForApprovalThreeAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.bulkReturnWorkflowReportForApprovalThreeAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkReturnWorkflowReportForApprovalFourAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null }) => {
    try {
      return await workflowApi.bulkReturnWorkflowReportForApprovalFourAsync(options.workflowReportIds, options.reportGroupId, options.comments);
    } catch (e) {
    }

    workflowApi.refreshCorrelationId();
  }

  public bulkIgnoreCompletedWorkflowReportNewDataIssuesAsync = async (context: ActionContext<State, any>, options: { workflowReportIds: string, reportGroupId: number, comments: string | null }) => {
    await workflowApi.bulkIgnoreCompletedWorkflowReportNewDataIssuesAsync(options.workflowReportIds, options.reportGroupId, options.comments);

    workflowApi.refreshCorrelationId();
  }

  public bulkDownloadWorkflowReportsAsync = async (context: ActionContext<State, any>, options: { reportGroupId: number, zipFileName: string, files: { fileName: string, filePath: string }[] }): Promise<Blob> => {
    const result = await workflowApi.bulkDownloadWorkflowReportsAsync(options.reportGroupId, options.zipFileName, options.files);

    workflowApi.refreshCorrelationId();

    return result;
  }

  public tryReplaceReportActionsFromPage = async (context: ActionContext<State, any>, data: WorkflowReportAvailableActionsReplaced & { reportId: number }) => {
    const reportGroup = context.rootState.tenant.selectedReportGroup as { id: number; name: string } | null;

    if (reportGroup === null) {
      return;
    }

    if (reportGroup.id !== data.payload.reportGroupId) {
      return;
    }

    const valuationDate = context.state.selectedValuationDate as WorkflowDate | null;

    if (valuationDate === null) {
      return;
    }

    if (data.payload.valuationDate !== valuationDate.valuationDate) {
      return;
    }

    const report = await this.getWorkflowReportAsync(context, { workflowReportId: data.reportId, reportGroupId: data.payload.reportGroupId }) as WorkflowReport | null;

    if (report === null) {
      return;
    }

    const pages = context.state.pages;
    const page = pages.find((p) => p.workflows.some((w) => w.id === data.reportId));

    if (page === undefined) {
      return;
    }

    const match = page.workflows.find((w) => w.id === data.reportId);

    if (match === undefined) {
      return;
    }

    context.commit('updateReportProperties', { source: report, destination: match });
  }

  public tryMoveReportToPage = async (context: ActionContext<State, any>, data: { reportId: number, reportGroupId: number, valuationDate?: string, fromState: string, fromStateId: number, toState: string, toStateId: number }) => {
    if (this.shouldIgnoreEvent(context, data)) {
      return;
    }

    const step = context.state.currentOption;

    if (step === null) {
      return;
    }

    if (step.id !== data.toStateId) {
      return;
    }

    const pages = context.state.pages;
    const page = pages.find((p) => p.workflows.some((w) => w.id === data.reportId));

    if (page !== undefined) {
      return;
    }

    const report = await this.getWorkflowReportAsync(context, { workflowReportId: data.reportId, reportGroupId: data.reportGroupId }) as WorkflowReport | null;

    if (report === null) {
      return;
    }

    context.commit('pushReportToPage', { report, pageNumber: 1 });
  }

  public tryUpdateOptionCounts = (context: ActionContext<State, any>, data: { reportGroupId: number, valuationDate?: string, fromState: string, fromStateId: number, toState: string, toStateId: number }) => {
    if (this.shouldIgnoreEvent(context, data)) {
      return;
    }

    const workflow = context.state.current!;
    const currentDate = context.state.selectedValuationDate;
    const match = context.state.current!.dates.find((d) => d.valuationDate === currentDate!.valuationDate);
    const from = workflow.options.find((o) => o.id === data.fromStateId)!;
    const to = workflow.options.find((o) => o.id === data.toStateId)!;

    context.commit('updateOptionCounts', { date: match, to, from });
  }

  public tryUpdateReportProperties = (context: ActionContext<State, any>, data: Partial<WorkflowReport> & { reportId: number }) => {
    const pages = context.state.pages;
    const page = pages.find((p) => p.workflows.some((w) => w.id === data.reportId));

    if (page === undefined) {
      return;
    }

    const match = page.workflows.find((w) => w.id === data.reportId);

    if (match === undefined) {
      return;
    }

    const { reportId, ...source } = data;

    context.commit('updateReportProperties', { source: source, destination: match });
  }

  public tryUpdateValuationDatesAsync = async (context: ActionContext<State, any>, data: { reportGroupId: number, valuationDate: string }) => {
    if (context.state.current === null) {
      return;
    }

    if (data.valuationDate === null) {
      return;
    }

    const reportGroup = context.rootState.tenant.selectedReportGroup;

    if (reportGroup == null) {
      return true;
    }

    if (reportGroup.id !== data.reportGroupId) {
      return true;
    }

    const match = context.state.current.dates.find((d) => new Date(d.valuationDate).toDateString() === new Date(data.valuationDate).toDateString());
    if (match !== undefined) {
      return;
    }

    const result = await workflowApi.getWorkflowForReportGroupAsync(reportGroup.id) as Workflow;
    Promise.resolve(result);

    const newMatch = result.dates.find((d) => new Date(d.valuationDate).toDateString() === new Date(data.valuationDate).toDateString());

    if (newMatch === undefined) {
      return;
    }

    context.commit('addValuationDate', { workflowDate: newMatch });
  }

  public tryUpdateValuationDates = debounce(this.tryUpdateValuationDatesAsync, 5000, { leading: true });

  public applyStateFromRoute = async (context: ActionContext<State, any>, data: WorkflowState): Promise<Dictionary<string>> => {
    /* eslint-disable */
    console.log('workflow/applyStateFromRoute started', data);
    /* eslint-enable */

    // TODO(Dan): Tenant
    const currentUser = context.rootGetters['user/current'] as UserResponse;
    const tenants = context.rootGetters['tenant/tenants'] as Array<TenantResponse>;
    const currentTenant = context.rootGetters['tenant/current'] as TenantResponse | null;
    const matchingTenant = tenants.find((t) => kebabcase(t.name) === kebabcase(data.tenant)) || tenants.find((t) => t.id === currentUser.currentTenantId) || tenants[0] || null;

    if (matchingTenant === null) {
      throw Error(`No tenants found for user '${currentUser.email}'.`);
    }

    if (data.reportGroup !== undefined && data.reportId === undefined && data.valuationDate === undefined && data.option === undefined) {
      // NOTE(Dan): We only have a report group, however we should check for the following pattern:
      //           /<tenant>/workflow/123456

      const reportId = Number(data.reportGroup);

      if (!isNaN(reportId)) {
        data.reportId = reportId.toString();
        data.reportGroup = undefined;

        try {
          const found = await workflowApi.findWorkflowReportAsync(data.reportId!);
          data.reportGroup = kebabcase(found.reportGroup);
          data.valuationDate = kebabcase(date(found.displayDate, found.dateFormat!));
          data.option = kebabcase(found.state);
        } catch (e) {
          data.reportId = undefined;
        }
      }
    }

    // TODO(Dan): Report Group
    const matchingReportGroup = (data.reportGroup !== undefined && matchingTenant.reportGroups.find((group) => kebabcase(group.name) === kebabcase(data.reportGroup!))) || matchingTenant.reportGroups.find((group) => group.active.all) || matchingTenant.reportGroups[0] || null;

    if (matchingReportGroup === null) {
      throw Error(`No report groups found for tenant '${matchingTenant.name}'.`);
    }

    // TODO(Dan): Configuration
    let configuration = context.getters.current as WorkflowResponse | null;

    if (configuration === null || configuration.reportGroupId !== matchingReportGroup.id || (currentTenant !== null && currentTenant.id !== matchingTenant.id)) {
      configuration = await workflowApi.getWorkflowForReportGroupAsync(matchingReportGroup.id);
    }

    if (configuration === null) {
      throw Error(`Could not load workflow configuration for report group '${matchingReportGroup.name}'.`);
    }

    // TODO(Dan): Valuation Date
    const matchingValuationDate = (data.valuationDate !== undefined && configuration.dates.find((d) => kebabcase(date(d.displayDate, configuration!.dateFormat!)) === kebabcase(data.valuationDate!))) || configuration?.dates.find((d) => d.active) || configuration.dates[0] || null;

    if (matchingValuationDate === null) {
      throw Error(`No valuation dates found for workflow belonging to report group '${matchingReportGroup.name}'.`);
    }

    // TODO(Dan): Option (Tab)
    // TODO(Dan): If the current option exists, use it, else revert to the first option with > 0 count else waiting
    // TODO(Dan): Don't reload unless we need to.
    let matchingOption = (data.option !== undefined && configuration.options.find(o => kebabcase(o.name) === kebabcase(data.option!))) || null;

    if (matchingOption === null) {
      for (let index = 0; index < configuration.options.length; index++) {
        const option = configuration.options[index];
        const name = camelcase(option.name);
        const count = matchingValuationDate.options[name] as number | undefined;

        if (count !== undefined && count > 0) {
          matchingOption = option;
          break;
        }
      }

      if (matchingOption === null) {
        matchingOption = configuration.options.find(o => o.name === 'Waiting') || configuration.options[0] || null;
      }
    }

    if (matchingOption === null) {
      throw Error(`No status found for workflows belonging to report group '${matchingReportGroup.name}'.`);
    }

    context.commit('setLoading', true);

    let reportId = data.reportId;
    const status = matchingOption.id;
    const pages: Array<WorkflowPageResponse> = [];
    const tenantChanged = currentTenant?.id !== matchingTenant.id;
    const reportGroupChanged = tenantChanged || matchingReportGroup.id !== (context.getters.current as WorkflowResponse | null)?.reportGroupId || matchingReportGroup.id !== (context.rootGetters['tenant/selectedReportGroup'] as ReportGroup).id;
    const valuationDateChanged = reportGroupChanged || matchingValuationDate.valuationDate !== (context.getters.selectedValuationDate as WorkflowDate | null)?.valuationDate;
    const optionChanged = valuationDateChanged || matchingOption.id !== (context.getters.currentOption as WorkflowOption | null)?.id;
    const reportIdChanged = reportId !== context.state.selectedReportId?.toString();

    const loadPagesUntilReportFoundOrAllPages = async () => {
      let page = 1;
      let reports = await workflowApi.getWorkflowPageAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, status, 1, configuration?.itemsPerPage ?? DEFAULT_PAGE_SIZE);

      pages.push(reports);

      while (reports.workflows.length === reports.pageSize && !reports.workflows.some(w => w.id === Number(data.reportId))) {
        reports = await workflowApi.getWorkflowPageAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, status, ++page, configuration?.itemsPerPage ?? DEFAULT_PAGE_SIZE);
        pages.push(reports);
      }
    };

    if (tenantChanged || reportGroupChanged || valuationDateChanged || optionChanged) {
      if (reportId !== undefined) {
        await loadPagesUntilReportFoundOrAllPages();
      } else {
        const reports = await workflowApi.getWorkflowPageAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, status, 1, configuration?.itemsPerPage ?? DEFAULT_PAGE_SIZE);

        pages.push(reports);
      }
    } else if (reportIdChanged && reportId !== undefined) {
      // TODO(Dan): Check if we have the report in the already loaded list, if not load until we hopefully find it
      const reports = context.getters.workflows as Array<WorkflowReport>;
      const match = reports.find(w => w.id === Number(data.reportId)) || null;

      if (match === null) {
        await loadPagesUntilReportFoundOrAllPages();
      } else {
        pages.push(...(context.getters.pages as Array<WorkflowPage>));
      }
    } else {
      pages.push(...(context.getters.pages as Array<WorkflowPage>));
    }

    if (pages.length === 1 && pages[0].workflows.length === 1) {
      reportId = pages[0].workflows[0].id.toString();
    } else if (reportId !== undefined) {
      const hasMatch = pages.some(p => p.workflows.some(w => w.id === Number(reportId)));

      if (!hasMatch) {
        reportId = undefined;
      }
    }

    const result: Dictionary<string> = { tenant: kebabcase(matchingTenant.name), reportGroup: kebabcase(matchingReportGroup.name), valuationDate: kebabcase(date(matchingValuationDate.displayDate, configuration!.dateFormat!)), option: kebabcase(matchingOption.name) };

    if (reportId !== undefined) {
      result.reportId = reportId;
    }

    if (reportGroupChanged) {
      await context.dispatch('tryApplyReportGroup', matchingReportGroup, { root: true });
    }

    if (valuationDateChanged) {
      await context.dispatch('tenant/setCurrentReportGroupValuationDateAsync', { id: matchingReportGroup.id, name: matchingReportGroup.name, tenantId: matchingTenant.id, tenantName: matchingTenant.name, valuationDate: matchingValuationDate.valuationDate }, { root: true });
    }

    context.commit('setStateFromRoute', { configuration: configuration, valuationDate: matchingValuationDate, option: matchingOption, reportId: result.reportId, pages: pages });

    /* eslint-disable */
    console.log('workflow/applyStateFromRoute completed', result);
    /* eslint-enable */

    return result;
  }

  private shouldIgnoreEvent = (context: ActionContext<State, any>, data: { reportGroupId: number, valuationDate?: string, fromState: string, fromStateId: number, toState: string, toStateId: number }): boolean => {
    if (data.fromStateId === data.toStateId) {
      return true;
    }

    const workflow = context.state.current;

    if (workflow === null) {
      return true;
    }

    const currentDate = context.state.selectedValuationDate;

    if (currentDate === null) {
      return true;
    }

    const match = context.state.current!.dates.find((d) => d.valuationDate === currentDate.valuationDate);

    if (match === undefined) {
      return true;
    }

    const reportGroup = context.rootState.tenant.selectedReportGroup;

    if (reportGroup == null) {
      return true;
    }

    if (reportGroup.id !== data.reportGroupId) {
      return true;
    }
    const dateTime = moment(data.valuationDate).format('YYYY-MM-DD');
    const contextTime = moment(currentDate?.valuationDate).format('YYYY-MM-DD');

    if (dateTime === undefined || dateTime !== contextTime) {
      return true;
    }
    return false;
  }
}

import { MutationTree } from 'vuex';
import { State, Summary, SummaryValuationDate } from '@/store/summary/state';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setCurrent = (state: State, data: Summary) => {
    state.current = data;

    if (data === null) {
      return;
    }

    state.fromValuationDate = data.valuationDate;
    state.toValuationDate = data.previousValuationDate;
  }

  public setValuationDateFrom = (state: State, valuationDate: SummaryValuationDate | null) => {
    state.fromValuationDate = valuationDate?.valuationDate || null;
  }

  public setValuationDateTo = (state: State, valuationDate: SummaryValuationDate | null) => {
    state.toValuationDate = valuationDate?.valuationDate || null;
  }
}

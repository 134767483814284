import { DataLoadForReportGroupValuationDateFailed } from '../events/data-load-for-report-group-valuation-date-failed';
import store from '@/store';

async function handleDataLoadForReportGroupValuationDateFailed(data: DataLoadForReportGroupValuationDateFailed): Promise<void> {
  /* eslint-disable */
  console.log(`DataLoadForReportGroupValuationDateFailed event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const dataLoadId = Number(data.payload.aggregateId.split('-')[1]);

  const pushed = await store.dispatch('data/tryPushDataLoadToPage', { ...data, dataLoadId });
  await store.dispatch('data/tryUpdateDataLoadStatus', { status: 'Failed', dataLoadId: dataLoadId });

  if (pushed) {
    await store.dispatch('data/tryIncrementDataLoadCount', { ...data, dataLoadId });
  }
}

export default handleDataLoadForReportGroupValuationDateFailed;

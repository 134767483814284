import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { applyAuthenticationHeader, defaultResponseInterceptor, authResponseFailureInterceptor } from '@/api/interceptors/auth-interceptors';
import { Forbidden } from '@/api/responses/forbidden';
import { NotFound } from '@/api/responses/not-found';
import { BadRequest } from '@/api/responses/bad-request';
import { TenantResponse } from './responses/tenant/tenant-response';
import store from '@/store';
import uuid from 'uuid';
import { baseUrl } from '@/base-url';
import { errorRequestFailureInterceptor, errorResponseFailureInterceptor } from '@/api/interceptors/error-interceptors';

let correlationId: string = uuid.v4();

export abstract class Api {
  public constructor() {
    axios.interceptors.request.use(applyAuthenticationHeader, errorRequestFailureInterceptor);
    axios.interceptors.response.use(defaultResponseInterceptor, error => {
      errorResponseFailureInterceptor(error);
      return authResponseFailureInterceptor(error);
    });
  }

  protected async getAsync<T>(url: string, data?: any, cancellationToken: CancelToken | null = null) {
    url = this.buildUrl(url);
    const response = await axios.get(url, this.buildConfig(correlationId, cancellationToken, data));

    this.throwIfRequestWasUnsuccessful(url, response);
    return response.data as T;
  }

  protected async getFileAsync(url: string, data?: any, cancellationToken: CancelToken | null = null): Promise<Blob> {
    url = this.buildUrl(url);
    const config = this.buildConfig(correlationId, cancellationToken, data);
    config.responseType = 'blob';

    const response = await axios.get<Blob>(url, config);

    this.throwIfRequestWasUnsuccessful(url, response);

    return response.data;
  }

  protected async getFileAsPostAsync(url: string, data?: any, cancellationToken: CancelToken | null = null): Promise<Blob> {
    url = this.buildUrl(url);
    const config = this.buildConfig(correlationId, cancellationToken);
    config.responseType = 'blob';

    const response = await axios.post<Blob>(url, data, config);

    this.throwIfRequestWasUnsuccessful(url, response);

    return response.data;
  }

  protected async postAsync<T>(url: string, data: any, correlationId: string | null = null, cancellationToken: CancelToken | null = null): Promise<T> {
    url = this.buildUrl(url);

    const response = await axios.post(url, data, this.buildConfig(correlationId, cancellationToken));

    this.throwIfRequestWasUnsuccessful(url, response);

    if (response.headers.location) {
      let location = response.headers.location as string;

      if (location.startsWith('http://')) {
        location = location.replace('http://', 'https://');
      }

      return (location as any) as T;
    }

    return response.data as T;
  }

  protected async postFileAsync<T>(url: string, data: File, correlationId: string | null = null, cancellationToken: CancelToken | null = null): Promise<T> {
    url = this.buildUrl(url);

    const formData: FormData = new FormData();
    formData.append('file', data);

    const config = this.buildConfig(correlationId, cancellationToken);
    config.headers['Content-type'] = 'multipart/form-data';

    const response = await axios.post(url, formData, config);

    this.throwIfRequestWasUnsuccessful(url, response);

    if (response.headers.location) {
      return response.headers.location as T;
    }

    return response.data as T;
  }

  protected async postFilesAsync<T>(url: string, files: Array<File>, cancellationToken: CancelToken | null = null): Promise<T> {
    url = this.buildUrl(url);

    const formData: FormData = new FormData();

    for (const file of files) {
      formData.append('files', file);
    }

    const config = this.buildConfig(correlationId, cancellationToken);
    config.headers['Content-type'] = 'multipart/form-data';

    const response = await axios.post(url, formData, config);

    this.throwIfRequestWasUnsuccessful(url, response);

    if (response.headers.location) {
      return response.headers.location as T;
    }

    return response.data as T;
  }

  protected async putAsync<T>(url: string, data: any, cancellationToken: CancelToken | null = null) {
    url = this.buildUrl(url);

    const response = await axios.put(url, data, this.buildConfig(correlationId, cancellationToken));

    this.throwIfRequestWasUnsuccessful(url, response);

    return response.data as T;
  }

  protected async patchAsync<T>(url: string, data: any, cancellationToken: CancelToken | null = null) {
    url = this.buildUrl(url);

    const response = await axios.patch(url, data, this.buildConfig(correlationId, cancellationToken));

    this.throwIfRequestWasUnsuccessful(url, response);

    return response.data as T;
  }

  protected async deleteAsync<T>(url: string, data?: any, cancellationToken: CancelToken | null = null) {
    url = this.buildUrl(url);

    const response = await axios.request({ ...this.buildConfig(correlationId, cancellationToken), url, method: 'DELETE', data });

    this.throwIfRequestWasUnsuccessful(url, response);

    return response.data as T;
  }

  protected buildUrl(url: string): string {
    const base = baseUrl;
    return `${base}${url}`;
  }

  protected buildConfig(correlation: string | null, cancellationToken: CancelToken | null, data?: any): AxiosRequestConfig& { headers: Record<string, string> } {
    const config: AxiosRequestConfig = {
      cancelToken: cancellationToken || undefined,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'X-Correlation-Id': correlation || correlationId,
      },
      params: data || undefined,
      validateStatus: (status: number) => {
        return status >= 200 && status < 300;
      },
      withCredentials: false,
    };

    if (store.getters['tenant/current'] !== null) {
      const tenant = store.getters['tenant/current'] as TenantResponse;
      config.headers!['X-Tenant-Id'] = tenant.id;
    }

    return config as AxiosRequestConfig& { headers: Record<string, string> };
  }

  public refreshCorrelationId(): void {
    correlationId = uuid.v4();
  }

  private throwIfRequestWasUnsuccessful(url: string, response: any) {
    if (axios.isCancel(response)) {
      throw new Error(`Request to '${url}' has been cancelled. Another request has been started`);
    }

    const hasError = (response && response.request && response.request.readyState === 4 && response.request.status === 0) ||
                     (response && response.response && (response.response.status < 200 || response.response.status >= 300)) ||
                     (response instanceof Error);

    if (!hasError) {
      return;
    }

    if (response.response.status === 403) {
      throw new Forbidden(`Request to ${url} failed with a response status of: ${response.response.status}.`);
    }
    if (response.response.status === 404) {
      throw new NotFound(`Request to ${url} failed with a response status of: ${response.response.status}.`);
    }
    if (response.response.status === 400) {
      throw new BadRequest(`Request to ${url} failed with a response status of ${response.response.status}.`, [response.response.data.detail]);
    }
    if (response.response.data.error) {
      throw new Error(response.response.data.error);
    }
    if (response.response.data.errors) {
      throw new BadRequest(`Request to ${url} failed with a response status of ${response.response.status}.`, response.response.data.errors);
    }

    throw new Error(`Request to ${url} failed with a response status of: ${response.response.status}.`);
  }
}

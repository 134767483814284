<template>
  <div class="row align-items-center">
    <label class="col-auto mb-0 pr-0">Valuation date:</label>
    <div class="col-auto">
      <b-form-select v-model="selectedValuationDate" @change="changeValuationDate">
        <b-form-select-option v-for="date in valuationDates" :key="date.valuationDate" :value="date">{{ date.displayDate | valuationdate(current.dateFormat) }}{{ valuationDateDescription(date) }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Data, DataDate } from '../../store/data/state';
import { kebabcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';

const dataModule = namespace('data');

@Component
export default class DataFilterBarValuationDateSelector extends Vue {
  @dataModule.Getter private current!: Data;
  @dataModule.Getter private valuationDates!: Array<DataDate>;
  @dataModule.Getter private currentValuationDate!: DataDate | null;

  private selectedValuationDate: DataDate | null = null;

  public mounted(): void {
    this.selectedValuationDate = this.currentValuationDate;
  }

  private async changeValuationDate(valuationDate: DataDate): Promise<void> {
    if (valuationDate === undefined) {
      return;
    }

    const dateParam = kebabcase(date(valuationDate.displayDate, this.current.dateFormat));
    if (this.$route.params.valuationDate === undefined || this.$route.params.valuationDate !== dateParam) {
      try {
        await this.$router.push({ name: 'data', params: { ...this.$route.params, valuationDate: dateParam } });
      } catch (e) {
        // We can get a navugation cancelled here if the route changes multuple times so we don't need to log that error to Sentry
      }
    }
  }

  private valuationDateDescription(date: DataDate): string {
    if (!date.showCounts) {
      return '';
    }

    const dataIssues = date.options.dataIssues;
    const translationIssues = date.options.translationIssues;
    const dataLoads = date.options.dataLoads;

    const parts = new Array<string>();

    if (dataIssues !== undefined && dataIssues > 0) {
      parts.push(`${dataIssues} data issue${(dataIssues === 1 ? '' : 's')}`);
    }

    if (translationIssues !== undefined && translationIssues > 0) {
      parts.push(`${translationIssues} translation issue${(translationIssues === 1 ? '' : 's')}`);
    }

    if (dataLoads !== undefined && dataLoads > 0) {
      parts.push(`${dataLoads} data load${(dataLoads === 1 ? '' : 's')}`);
    } else {
      parts.push('0 data loads');
    }

    return ' - ' + parts.join(', ');
  }

  @Watch('currentValuationDate')
  private onCurrentValuationDateChanged(date: DataDate): void {
    this.selectedValuationDate = date;
  }
}
</script>

<template>
  <div :class="classes" class="data-issue-type text-center" :title="title" v-if="classes !== null">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataIssue } from '../../store/data/state';

@Component
export default class DataIssueTypeIcon extends Vue {
  @Prop({ type: Object, required: true }) public item!: DataIssue;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.item.type) {
      case 'Critical':
        return { 'is-background-critical': true };
      case 'Error':
        return { 'is-background-danger': true };
      case 'Warning':
        return { 'is-background-warning': true };
      case 'Information':
        return { 'is-background-info': true };
      default:
        return null;
    }
  }

  public get title(): string | null {
    switch (this.item.type) {
      case 'Critical':
        return 'Mandatory issue(s)';
      case 'Error':
        return 'Mandatory issue(s) - Render allowed';
      case 'Warning':
        return 'Optional issue(s)';
      case 'Information':
        return 'Information';
      default:
        return null;
    }
  }

  public get text(): string | null {
    switch (this.item.type) {
      case 'Critical':
        return this.item.blockingApproval?.toString() || '!';
      case 'Error':
      case 'Warning':
      case 'Information':
        return this.item.blockingApproval?.toString() || 'i';
      default:
        return null;
    }
  }
}
</script>

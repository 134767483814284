import { DataCheckBatchEnqueued } from '../events/data-check-batch-enqueued';
import store from '@/store';
import { ReportGroup } from '@/store/tenant/state';
import { DataDate } from '@/store/data/state';

async function handleDataCheckBatchEnqueued(data: DataCheckBatchEnqueued): Promise<void> {
  /* eslint-disable */
  console.log(`DataCheckBatchEnqueued event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  if (store.getters['data/current'] === null) {
    return;
  }

  const reportGroup = store.getters['tenant/selectedReportGroup'] as ReportGroup;
  const valuationDate = store.getters['data/currentValuationDate'] as DataDate | null;

  if (valuationDate === null || valuationDate.valuationDate !== data.payload.valuationDate) {
    return;
  }

  if (reportGroup === null || reportGroup.id !== data.payload.reportGroupId) {
    return;
  }

  await store.dispatch('data/getAndMergeDataForReportGroupNoLoaderAsync', reportGroup);
  await store.dispatch('data/trySetDataCheckQueueCount', data.payload.remaining);
}

export default handleDataCheckBatchEnqueued;

<template>
  <b-table class="schedule-activity-table"
           striped
           hover
           :sticky-header="headerStyle"
           :fields="fields"
           :items="filteredResults"
           :busy="loading"
           sort-icon-left
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
  >
    <template v-slot:cell(description)="{ item }">
      {{ item.description }}
    </template>

    <template v-slot:cell(timestamp)="{ item }">
      {{ item.timestamp | datetime }}
    </template>
  </b-table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DateTime } from 'luxon';
import { Tenant } from '@/store/tenant/state';
import { EntityContentControlActivity, TeamActivity } from '../../store/configuration/state';
import { EntityScheduleActivity } from '../../store/schedule/state';

const tenantModule = namespace('tenant');
const environmentModule = namespace('environment');

@Component({
  components: {
  },
})
export default class ActivityTable extends Vue {
  private sortBy: string | null = null;
  private sortDesc: string | null = null;
  @Prop({ type: Array, required: true }) public activity!: Array<EntityContentControlActivity | EntityScheduleActivity | TeamActivity>;
  @Prop({ type: Boolean, required: true }) public loading!: boolean;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;
  @tenantModule.Getter private tenants!: Array<Tenant>;

  public get fields(): Array<{ key: string, label: string, class?: Array<string> | string, sortable?: boolean, formatter?: Function }> {
    return [
      { key: 'description', label: 'Description', class: ['col-auto'] },
      { key: 'timestamp', label: 'Date', class: ['col-3'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); } }
    ];
  }

  public get headerStyle(): string {
    const isReportGroupSettings = this.$route.params.settings === 'report-group';

    if (this.environment === null) {
      if (isReportGroupSettings) {
        return 'calc(100vh - 230px - 54px)';
      }

      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      if (isReportGroupSettings) {
        return 'calc(100vh - 230px - 54px)';
      }

      return 'calc(100vh - 230px)';
    }

    if (isReportGroupSettings) {
      return 'calc(100vh - 254px - 54px)';
    }

    return 'calc(100vh - 254px)';
  }

  public get filteredResults(): Array<EntityContentControlActivity | EntityScheduleActivity | TeamActivity> {
    if (this.activity == null || this.activity.length < 1) {
      return [];
    }

    return this.activity;
  }
}
</script>

<template>
  <b-modal id="modal-center"
           v-model="visible"
           ok-variant="primary"
           ok-title="Submit"
           cancel-variant="link"
           centered
           :title="`Edit '${item !== null ? item.name : ''}' reminder settings`"
           :no-close-on-backdrop="true"
           @ok="onOk"
           @cancel="onCancel"
           @close="onClose"
  >
    <b-form-group label="Business day">
      <b-form-select v-model="businessDay" :options="businessDays" />
    </b-form-group>
    <b-form-group
      id="input-group-7"
      label="Email Address(es)"
      label-for="email"
    >
      <validation-provider name="email" :rules="{ regex: emailRegex }" v-slot="{ errors }" tag="div">
        <b-form-input
          id="email"
          name="mail"
          v-model="email"
          :state="errors[0] ? false : null"
        ></b-form-input>
        <b-form-invalid-feedback>{{ emailValidationErrorMsg }}</b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-modal>
</template>
<script lang="ts">
import { DataFile } from '@/store/data/state';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { validEmailRegex, invalidEmailErrorMsg } from '../../utilities/email';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class ReplaceDataFileReminderDetailsDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ required: true }) public item!: DataFile | null;
  private businessDay: number | null = this.item?.reminder?.businessDay || null;
  private email: string | null = this.item?.reminder?.email || null;
  private visible: boolean = false;
  private emailRegex: RegExp = validEmailRegex;
  private emailValidationErrorMsg: string = invalidEmailErrorMsg;

  private get businessDays(): Array<{ value: number | null, text: string}> {
    return [{ value: null, text: '--- Please select ---' },
      { value: 1, text: '1' },
      { value: 2, text: '2' },
      { value: 3, text: '3' },
      { value: 4, text: '4' },
      { value: 5, text: '5' },
      { value: 6, text: '6' },
      { value: 7, text: '7' },
      { value: 8, text: '8' },
      { value: 9, text: '9' },
      { value: 10, text: '10' },
      { value: 11, text: '11' },
      { value: 12, text: '12' },
      { value: 13, text: '13' },
      { value: 14, text: '14' },
      { value: 15, text: '15' },
      { value: 16, text: '16' },
      { value: 17, text: '17' },
      { value: 18, text: '18' },
      { value: 19, text: '19' },
      { value: 20, text: '20' }];
  }

  private onOk(): void {
    this.$emit('ok', { businessDay: this.businessDay, email: this.email });
    this.businessDay = null;
    this.email = null;
  }

  private onCancel(): void {
    this.$emit('cancel');
    this.businessDay = null;
    this.email = null;
  }

  private onClose(): void {
    this.$emit('close');
    this.businessDay = null;
    this.email = null;
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
  }
}
</script>

import { GetterTree } from 'vuex';
import { State } from '@/store/help/state';

export class Getters implements GetterTree<State, any> {
    [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

    public current = (state: State) => {
      return state.current;
    }

    public files = (state: State) => {
      if (state.current === null) {
        return [];
      }

      return state.current.files;
    }
}

import { Api } from '@/api/api';
import { ScheduleResponse } from '@/api/responses/schedule/schedule-response';
import { EntityScheduleResponse } from './responses/schedule/entity-schedule-response';
import { EntityScheduleActivityLogResponse } from './responses/schedule/entity-schedule-activity-log-response';
import { EntityScheduleCompletedRequestsResponse } from './responses/schedule/entity-schedule-completed-requests-page-response';
import { EntityScheduleRunResponse } from './responses/schedule/entity-schedule-run-response';

export class ScheduleApi extends Api {
  public constructor() {
    super();
  }

  public getScheduleForReportGroupAsync(reportGroupId: number): Promise<ScheduleResponse> {
    return this.getAsync<ScheduleResponse>(`v1/schedule/${reportGroupId}`);
  }

  public getScheduleForEntityAsync(entityId: number, reportGroupId: number): Promise<EntityScheduleResponse> {
    return this.getAsync<EntityScheduleResponse>(`v1/schedule/${reportGroupId}/entity/${entityId}`);
  }

  public getScheduleActivityForEntityAsync(entityId: number, reportGroupId: number): Promise<EntityScheduleActivityLogResponse> {
    return this.getAsync<EntityScheduleActivityLogResponse>(`v1/schedule/${reportGroupId}/entity/${entityId}/activity`);
  }

  public getCompletedScheduleEntityRequestsAsync(entityId: number, reportGroupId: number, page: number, pageSize: number): Promise<EntityScheduleCompletedRequestsResponse> {
    return this.getAsync<EntityScheduleCompletedRequestsResponse>(`v1/schedule/${reportGroupId}/entity/${entityId}/requests`, { page, pageSize });
  }

  public enableEntityScheduleAsync(subEntityId: number, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/schedule`, { enable: true });
  }

  public disableEntityScheduleAsync(subEntityId: number, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/schedule`, { enable: false });
  }

  public enableEntityScheduleForRegionAsync(regionId: number, subEntityId: number, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/region/${regionId}/schedule`, { enable: true });
  }

  public disableEntityScheduleForRegionAsync(regionId: number, subEntityId: number, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/region/${regionId}/schedule`, { enable: false });
  }

  public enableEntityScheduleForLanguageAsync(languageId: number, subEntityId: number, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/language/${languageId}/schedule`, { enable: true });
  }

  public disableEntityScheduleForLanguageAsync(languageId: number, subEntityId: number, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/language/${languageId}/schedule`, { enable: false });
  }

  public async runEntityScheduleForRegionAsync(regionId: number, subEntityId: number, entityId: number, reportGroupId: number, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean): Promise<EntityScheduleRunResponse> {
    return await this.postAsync<EntityScheduleRunResponse>(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/region/${regionId}/schedule/run`, { valuationDate, useApprovalWorkflow, useStandardDistribution });
  }

  public async runEntityScheduleForLanguageAsync(languageId: number, subEntityId: number, entityId: number, reportGroupId: number, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean): Promise<EntityScheduleRunResponse> {
    return await this.postAsync<EntityScheduleRunResponse>(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/language/${languageId}/schedule/run`, { valuationDate, useApprovalWorkflow, useStandardDistribution });
  }

  public async runScheduleForReportGroupAsync(reportGroupId: number, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean, systemReportsOnly?: boolean, newEntitiesOnly?: boolean): Promise<EntityScheduleRunResponse> {
    return this.postAsync<EntityScheduleRunResponse>(`v1/schedule/${reportGroupId}/schedule/run`, { valuationDate, useApprovalWorkflow, useStandardDistribution, systemReportsOnly, newEntitiesOnly });
  }

  public runEntityScheduleAsync(entityId: number, reportGroupId: number, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean): Promise<EntityScheduleRunResponse> {
    return this.postAsync<EntityScheduleRunResponse>(`v1/schedule/${reportGroupId}/entity/${entityId}/schedule/run`, { valuationDate, useApprovalWorkflow, useStandardDistribution });
  }

  public makeEntityPrimaryAsync(subEntityId: number, entityId: number, reportGroupId: number): Promise<void> {
    return this.postAsync(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/primary`, null);
  }

  public reorderScheduledEntity(from: number, to: number, subEntityId: number, entityId: number, reportGroupId: number): Promise<void> {
    return this.postAsync(`v1/schedule/${reportGroupId}/entity/${entityId}/subentity/${subEntityId}/order`, { to, from });
  }
}

import { Module } from 'vuex';
import { State } from '@/store/data/state';
import { Actions } from '@/store/data/actions';
import { Getters } from '@/store/data/getters';
import { Mutations } from '@/store/data/mutations';

export class DataStore implements Module<State, any> {
    public namespaced: boolean = true;
    public state: State = new State();
    public actions = new Actions();
    public getters: Getters = new Getters();
    public mutations = new Mutations();
}

<template>
  <div class="container-fluid support-ticket-activity-list border border-top-0">
    <div class="row p-4">
      <div class="col">
        <div class="support-ticket-activity row" v-for="activity in activities" :key="activity.id">
          <div class="col">
            <div class="row py-4 support-ticket-activity-date">
              <div class="col">
                <strong>{{ activity.timestamp | date('DDD') }}</strong>
              </div>
            </div>

            <div class="support-ticket-activity-entry py-2 row" v-for="entry in activity.entries" :key="entry.id">
              <div class="col">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <b-avatar />
                  </div>
                  <div class="col pl-2">
                    <div class="row">
                      <div class="col">
                        {{ entry.name }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span>{{ entry.description }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-auto ml-auto timestamp">
                    {{ entry.timestamp | datetime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { SupportTicketActivityEntryGroup } from '@/store/support/state';
import { namespace } from 'vuex-class';

const supportModule = namespace('support');

@Component
export default class SupportTicketActivityList extends Vue {
  @supportModule.Getter('activity') public activities!: Array<SupportTicketActivityEntryGroup>;
}
</script>

import { ActionTree, ActionContext } from 'vuex';
import { State, SupportTicket, Priority, IssueType, Category, User, ValuationDate } from '@/store/support/state';
import { SupportApi } from '@/api/support-api';
import { ReportGroup, Tenant } from '../tenant/state';
import { Dictionary } from 'vue-router/types/router';
import uuid from 'uuid';

type SupportState = { route: 'support', tenant: string, tab: string };
type SupportTicketState = { route: 'support-ticket', tenant: string };

const api = new SupportApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public getSupportAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number }) => {
    try {
      context.commit('setLoading', true);
      const result = await api.getSupportAsync();

      context.commit('setCurrent', result);
      context.commit('setLoading', false);
    } catch (e) {
    }
  }

  public getSupportTicketsAsync = async (context: ActionContext<State, any>, payload: { status: number, page: number, pageSize: number }) => {
    try {
      const result = await api.getSupportTicketsAsync(payload.status, payload.page, payload.pageSize);
      const tickets = context.getters.tickets as Array<SupportTicket>;

      if (tickets.length > 0) {
        if (payload.page < 2) {
          // TODO(Dan): Clear, the actual status on the ticket is a sub status of the status (tab) they belong to.
        }
      }

      context.commit('addTicketPage', result);
    } catch (e) {
    }
  }

  public findTicketAsync = async (context: ActionContext<State, any>, id: number) => {
    const result = await api.findTicketAsync(id);

    return result;
  }

  public getSupportTicketAsync = async (context: ActionContext<State, any>, id: number) => {
    const result = await api.getSupportTicketAsync(id);

    context.commit('setCurrentTicket', result);

    return result;
  }

  public getSupportTicketMessagesAsync = async (context: ActionContext<State, any>, id: number) => {
    const result = await api.getSupportTicketMessagesAsync(id);

    context.commit('setCurrentTicketMessages', result);

    return result;
  }

  public getSupportTicketNotesAsync = async (context: ActionContext<State, any>, id: number) => {
    const result = await api.getSupportTicketNotesAsync(id);

    context.commit('setCurrentTicketNotes', result);

    return result;
  }

  public getSupportTicketActivityAsync = async (context: ActionContext<State, any>, id: number) => {
    const result = await api.getSupportTicketActivityAsync(id);

    context.commit('setCurrentTicketActivity', result);

    return result;
  }

  public getSupportTicketAttachmentsAsync = async (context: ActionContext<State, any>, id: number) => {
    const result = await api.getSupportTicketAttachmentsAsync(id);

    context.commit('setCurrentTicketAttachments', result);

    return result;
  }

  public downloadSupportTicketAttachmentAsync = async (context: ActionContext<State, any>, payload: { attachmentId: number, ticketId: number }) => {
    return api.downloadSupportTicketAttachmentAsync(payload.attachmentId, payload.ticketId);
  }

  public openTicketAsync = async (context: ActionContext<State, any>, ticket: { subject: string, detail: string, priority: Priority, issueType: IssueType, category: Category, reportGroup: ReportGroup | null, tenant: Tenant | null, raisedBy: User | null, valuationDate: ValuationDate | null, reportId: number | null, ccmail: string | null}) => {
    const correlationId: string = uuid.v4();
    context.commit('setOpeningTicketCorrelationId', correlationId);
    await api.openTicketAsync(ticket.subject, ticket.detail, ticket.priority, ticket.issueType, ticket.category, ticket.reportGroup, ticket.tenant, ticket.raisedBy, ticket.valuationDate, ticket.reportId, correlationId, ticket.ccmail);
  }

  public submitTicketAttachmentAsync = async (context: ActionContext<State, any>, payload: { ticketId: number, file: File, correlationId: string | null }) => {
    await api.submitTicketAttachmentAsync(payload.ticketId, payload.file, payload.correlationId);
  }

  public assignTicketAsync = async (context: ActionContext<State, any>, payload: { id: number, assignToUserId: number, assignToUser: string }) => {
    await api.assignTicketAsync(payload.id, payload.assignToUserId, payload.assignToUser);
  }

  public reassignTicketAsync = async (context: ActionContext<State, any>, payload: { id: number, assignFromUserId: number, assignFromUser: string, assignToUserId: number, assignToUser: string }) => {
    await api.reassignTicketAsync(payload.id, payload.assignFromUserId, payload.assignFromUser, payload.assignToUserId, payload.assignToUser);
  }

  public amendTicketPriorityAsync = async (context: ActionContext<State, any>, payload: { id: number, fromPriorityId: number, fromPriority: string, toPriorityId: number, toPriority: string }) => {
    await api.amendTicketPriorityAsync(payload.id, payload.fromPriorityId, payload.fromPriority, payload.toPriorityId, payload.toPriority);
  }

  public amendTicketIssueTypeAsync = async (context: ActionContext<State, any>, payload: { id: number, fromIssueTypeId: number, fromIssueType: string, toIssueTypeId: number, toIssueType: string }) => {
    await api.amendTicketIssueTypeAsync(payload.id, payload.fromIssueTypeId, payload.fromIssueType, payload.toIssueTypeId, payload.toIssueType);
  }

  public amendTicketRaisedByAsync = async (context: ActionContext<State, any>, payload: { id: number, fromRaisedById: number, fromRaisedBy: string, toRaisedById: number, toRaisedBy: string }) => {
    await api.amendTicketRaisedByAsync(payload.id, payload.fromRaisedById, payload.fromRaisedBy, payload.toRaisedById, payload.toRaisedBy);
  }

  public amendTicketCategoryAsync = async (context: ActionContext<State, any>, payload: { id: number, fromCategoryId: number, fromCategory: string, toCategoryId: number, toCategory: string }) => {
    await api.amendTicketCategoryAsync(payload.id, payload.fromCategoryId, payload.fromCategory, payload.toCategoryId, payload.toCategory);
  }

  public amendTicketReportGroupAsync = async (context: ActionContext<State, any>, payload: { id: number, fromReportGroupId: number, fromReportGroup: string, toReportGroupId: number, toReportGroup: string }) => {
    await api.amendTicketReportGroupAsync(payload.id, payload.fromReportGroupId, payload.fromReportGroup, payload.toReportGroupId, payload.toReportGroup);
  }

  public amendTicketSubjectAsync = async (context: ActionContext<State, any>, payload: { id: number, from: string, to: string }) => {
    await api.amendTicketSubjectAsync(payload.id, payload.from, payload.to);
  }

  public amendTicketDescriptionAsync = async (context: ActionContext<State, any>, payload: { id: number, from: string, to: string }) => {
    await api.amendTicketDescriptionAsync(payload.id, payload.from, payload.to);
  }

  public amendTicketCcmailAsync = async (context: ActionContext<State, any>, payload: { id: number, from: string, to: string }) => {
    await api.amendTicketCcmailAsync(payload.id, payload.from, payload.to);
  }

  public amendTicketValuationDateAsync = async (context: ActionContext<State, any>, payload: { id: number, from: ValuationDate | null, to: ValuationDate | null }) => {
    await api.amendTicketValuationDateAsync(payload.id, payload.from, payload.to);
  }

  public amendTicketReportIdAsync = async (context: ActionContext<State, any>, payload: { id: number, from: number | null, to: number | null }) => {
    await api.amendTicketReportIdAsync(payload.id, payload.from, payload.to);
  }

  public submitTicketReplyAsync = async (context: ActionContext<State, any>, payload: { id: number, message: string }) => {
    await api.submitTicketReplyAsync(payload.id, payload.message);
  }

  public submitTicketNoteAsync = async (context: ActionContext<State, any>, payload: { id: number, message: string }) => {
    await api.submitTicketNoteAsync(payload.id, payload.message);
  }

  public placeTicketUnderInvestigationAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.placeTicketUnderInvestigationAsync(ticketId);
  }

  public ticketWaitingOnCustomerAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.ticketWaitingOnCustomerAsync(ticketId);
  }

  public reopenTicketAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.reopenTicketAsync(ticketId);
  }

  public resolveTicketAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.resolveTicketAsync(ticketId);
  }

  public unresolveTicketAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.unresolveTicketAsync(ticketId);
  }

  public closeTicketAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.closeTicketAsync(ticketId);
  }

  public placeTicketOnHoldAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.placeTicketOnHoldAsync(ticketId);
  }

  public placeTicketInDevelopmentAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.placeTicketInDevelopmentAsync(ticketId);
  }

  public ticketReadyForTestingAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.ticketReadyForTestingAsync(ticketId);
  }

  public ticketReadyForReleaseAsync = async (context: ActionContext<State, any>, ticketId: number) => {
    await api.ticketReadyForReleaseAsync(ticketId);
  }

  public tryMoveTicketToPage = async (context: ActionContext<State, any>, payload: { ticketId: number }) => {
    if (context.state.current === null) {
      return;
    }

    if (context.getters.currentStatus === null) {
      return;
    }

    const result = await api.getSupportAsync();

    context.commit('setCurrent', result);
  }

  public removeSupportTicketAttachmentAsync = async (context: ActionContext<State, any>, payload: { attachmentId: number, ticketId: number }) => {
    return api.removeSupportTicketAttachmentAsync(payload.attachmentId, payload.ticketId);
  }

  public applyStateFromRoute = async (context: ActionContext<State, any>, data: SupportState): Promise<Dictionary<string>> => {
    /* eslint-disable */
    console.log('support/applyStateFromRoute', data);
    /* eslint-enable */
    return {};
  }

  public amendSupportTicketMessageAsync = async (context: ActionContext<State, any>, payload: {ticketId: number, supportIssueDetailID: number, detail: string}) => {
    return api.amendSupportTicketMessageAsync(payload.ticketId, payload.supportIssueDetailID, payload.detail);
  }
}

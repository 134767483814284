<template>
  <validation-observer v-slot="{ passes, invalid, pending }">
    <b-modal id="modal-center"
             v-model="visible"
             ok-variant="primary"
             ok-title="Assign"
             cancel-variant="link"
             centered
             :title="`Assign members to ${team}`"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             size="md"
             :ok-disabled="(invalid && !pending) || submitting"
             @ok.prevent="passes(onOk)"
             @cancel="onCancel"
             @close="onClose"
    >
      <b-form-group
        id="input-group-1"
        class="mb-0"
      >
        <b-table-simple class="border-bottom-0 mb-0">
          <b-tbody>
            <b-tr v-for="member, index in members" :key="index">
              <b-td class="w-100">
                <validation-provider rules="required" v-slot="{ valid, errors }" tag="div">
                  <b-form-group
                    id="input-group-member"
                    label-for="input-member"
                    class="my-2"
                  >
                    <b-form-select v-model="member.email" :state="errors[0] ? false : (valid ? true : null)">
                      <b-form-select-option :value="null">Please select...</b-form-select-option>
                      <b-form-select-option v-for="user, i in getAvailableUsers(member)" :key="i" :value="user.email">{{ user.name }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback v-if="errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-td>
              <b-td class="col-auto">
                <b-button variant="outline-primary" @click="remove(index)" size="sm" title="Remove">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="2" class="text-center pt-3" :class="{ 'border-top-0': members.length < 1 }">
                <b-btn variant="link" @click="addMember">Add member</b-btn>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form-group>
    </b-modal>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Tenant, TenantUser } from '@/store/tenant/state';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { UserResponse } from '../../api/responses/user/user-response';
import { ConfigurationTeamMember } from '@/store/configuration/state';

const tenantModule = namespace('tenant');
const userModule = namespace('user');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class AssignTeamMemberDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ type: String, required: true }) public team!: string;
  @Prop({ type: Array, required: true }) public existingMembers!: Array<ConfigurationTeamMember>;
  @tenantModule.Getter private current!: Tenant;
  @userModule.Getter('current') private currentUser!: UserResponse;

  private members: Array<{ email: string | null }> = [{ email: null }];
  private visible: boolean = false;
  private submitting: boolean = false;

  private get users(): Array<TenantUser & { index: number }> {
    if (this.current === null) {
      return [];
    }

    return this.current.users.map((s, i) => ({ index: i, ...s }));
  }

  public get availableUsers(): Array<TenantUser & { index: number }> {
    return this.users.filter(u => !this.existingMembers.some(em => em.email === u.email))
      .filter(u => !this.members.some(m => m.email === u.email))
      .filter(u => u.isActive);
  }

  private getAvailableUsers(member: { email: string | null }): Array<TenantUser> {
    if (member.email === null) {
      return this.availableUsers;
    }

    const availableUsers = [...this.availableUsers];
    const currentUser = this.users.find(u => u.email === member.email)!;

    availableUsers.push(currentUser);

    return availableUsers.sort((a, b) => a.index - b.index);
  }

  private onOk(): void {
    this.submitting = true;
    this.$emit('ok', {
      members: [...this.members.map(m => {
        const name = this.users.find(u => u.email === m.email)?.name || '';

        return { name: name, email: m.email };
      })],
    });
  }

  private onCancel(): void {
    this.$emit('cancel');
    this.clear();
  }

  private onClose(): void {
    this.$emit('close');
    this.clear();
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
    this.clear();
  }

  private clear(): void {
    this.members = [];
  }

  private addMember(): void {
    this.members.push({ email: null });
  }

  private remove(index: number): void {
    const members = [...this.members];
    members.splice(index, 1);
    this.members = members;
  }
}
</script>

import { WorkflowReportArchivalQueued } from '../events/workflow-report-archival-queued';
import store from '@/store';

async function handleWorkflowReportArchivalQueued(data: WorkflowReportArchivalQueued): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportArchivalQueued event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryUpdateReportProperties', {
    renderingStatus: 'DistributionWaiting',
    reportId: reportId,
    tooltips: {
      renderingStatus: data.payload.tooltip,
    },
  });
}

export default handleWorkflowReportArchivalQueued;

<template>
  <div class="row align-items-center">
    <label class="col-auto mb-0 pr-0">To:</label>
    <div class="col-auto">
      <b-form-select v-model="selectedValuationDateTo">
        <template v-slot:first>
          <b-form-select-option :value="null">All</b-form-select-option>
        </template>
        <b-form-select-option v-for="date in toValuationDates" :key="date.valuationDate" :value="date">{{ date.displayDate | valuationdate(current.dateFormat) }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DocumentLibraryDate, DocumentLibrary } from '@/store/document-library/state';

const documentLibraryModule = namespace('documentLibrary');

@Component
export default class DocumentLibraryFilterBarValuationDateToSelector extends Vue {
  @documentLibraryModule.Getter private current!: DocumentLibrary;
  @documentLibraryModule.Getter private toValuationDates!: Array<DocumentLibraryDate>;
  @documentLibraryModule.Getter private currentValuationDateFrom!: DocumentLibraryDate | null;
  @documentLibraryModule.Getter private currentValuationDateTo!: DocumentLibraryDate | null;

  private get selectedValuationDateTo(): DocumentLibraryDate | null {
    return this.currentValuationDateTo;
  }

  private set selectedValuationDateTo(value: DocumentLibraryDate | null) {
    this.$store.commit('documentLibrary/setCurrentValuationDateTo', value);
  }
}
</script>

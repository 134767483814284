<template>
  <validation-observer v-slot="{ passes, invalid, pending }" class="col pl-3">
    <div class="row align-items-top justify-content-between">
      <div class="col">
        <validation-provider
          ref="phrase"
          :rules="`required`"
          v-slot="{ valid, errors }"
          tag="div"
          :mode="validationMode"
        >
          <b-input-group v-if="isEditing">
            <b-form-textarea ref="input" rows="5" v-model="value" @keyup.esc="cancel" :state="errors[0] ? false : (valid ? true : null)" />
            <b-form-invalid-feedback v-if="errors.length > 0">{{ errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
          <p class="mt-2 description" v-else>{{ message.comment }}</p>
        </validation-provider>
      </div>

      <div class="col-auto ml-auto pl-0" v-if="message.editable && !isEditing">
        <b-dropdown variant="link" no-caret v-if="!isEditing">
          <template v-slot:button-content>
            <b-icon icon="three-dots-vertical" />
          </template>
          <b-dropdown-item title="Edit" @click="toggleEditing">Edit</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="row">
      <div class="col-auto ml-auto pt-3" v-if="message.editable && isEditing">
        <b-button-group>
          <b-button v-if="isEditing" variant="primary" size="md" :disabled="(invalid && !pending) || message.comment === value" @click="passes(save)" title="Save">
            <b-icon icon="check2" aria-hidden="true"></b-icon> Save
          </b-button>
          <b-button v-if="isEditing" variant="outline-primary" size="md" @click="cancel" title="Cancel">
            <b-icon icon="X" aria-hidden="true"></b-icon> Cancel
          </b-button>
        </b-button-group>
      </div>
    </div>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ValidationFlags } from 'vee-validate/dist/types/types';
import { SupportTicketMessage } from '../../store/support/state';

type ValidationModeContext = { errors: Array<string>, value: any, flags: ValidationFlags };

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EditableSupportTicketMessage extends Vue {
  @Prop({ required: true }) public message!: SupportTicketMessage;

  isEditing = false;
  value: string | null = null;

  toggleEditing(): void {
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      this.value = this.message?.comment;
      this.$nextTick(() => {
        (this.$refs.input as BFormInput).focus();
      });
    }
  }

  save(): void {
    if (this.message.comment === null || this.message.comment === this.value) {
      return;
    }

    this.toggleEditing();

    this.$emit('saved', { supportIssueDetailID: this.message.supportIssueDetailID, detail: this.value! });
  }

  cancel(): void {
    this.toggleEditing();
  }

  validationMode({ errors }: ValidationModeContext) {
    if (errors.length) {
      return {
        on: ['input', 'change'],
      };
    }

    return {
      on: ['input', 'change'],
    };
  }

  @Watch('message')
  onMessageChanged(message: SupportTicketMessage): void {
    if (message === null || message === undefined) {
      this.value = null;
      return;
    }

    this.value = message?.comment ?? '';
  }
}
</script>

<template>
  <div>
    <svg
      viewBox="0 0 200 200"
      height="2em"
      width="2em"
    >
      <g transform="translate(0.0383083,-10.724966)">
        <g transform="matrix(5.8001012,0,0,5.8001012,4.9616917,15.724966)">
          <path d="M 5.4176089,2.2341684 A 1.4907672,1.4138231 0 0 0 3.9268032,3.648045 v 6.0106294 7.4244126 7.424411 6.010629 a 1.4907672,1.4138231 0 0 0 1.4908057,1.413782 H 29.276557 a 1.4907672,1.4138231 0 0 0 1.490806,-1.413782 V 24.507498 17.083087 9.6586744 3.648045 A 1.4907672,1.4138231 0 0 0 29.276557,2.2341684 Z" style="fill:#a9a9a9;fill-opacity:1;stroke-width:1.08912" />
          <path style="fill:#e8e8e8;fill-opacity:1;stroke-width:1.09131" d="m 3.4463134,0.65967124 a 1.4952716,1.415241 0 0 0 -1.49531,1.41529456 v 6.0166574 7.4318578 7.431857 6.016657 a 1.4952716,1.415241 0 0 0 1.49531,1.415201 H 27.377349 a 1.4952716,1.415241 0 0 0 1.49531,-1.415201 V 22.955338 15.523481 8.0916232 2.0749658 a 1.4952716,1.415241 0 0 0 -1.49531,-1.41529456 z" />
          <path d="M 0,0 H 32 V 32 H 0 Z" fill="none" />
          <path fill="none" d="M 3.3861815,1.4234418 H 27.261294 V 28.721743 H 3.3861815 Z" style="stroke-width:0.797792" />
          <path data-name="Back Plate" d="M 5.7189968,4.0633109 H 25.260152 A 1.9535293,1.9436037 0 0 1 27.21368,6.0069146 V 25.448784 a 1.9535293,1.9436037 0 0 1 -1.953528,1.943603 H 5.7189968 a 1.9535293,1.9436037 0 0 1 -1.95353,-1.943603 V 6.0069146 a 1.9535293,1.9436037 0 0 1 1.95353,-1.9436037 z" fill="#107c41" style="stroke-width:1.46178" />
          <path d="M 8.9431258,23.018185 13.685527,15.707435 9.3417458,8.4375125 h 3.4952502 l 2.3712,4.6468595 q 0.326809,0.660505 0.449912,0.985654 h 0.03077 q 0.234482,-0.52782 0.490946,-1.02648 l 2.533873,-4.6060335 h 3.208008 l -4.45516,7.2290965 4.568004,7.351576 H 18.619905 L 15.880861,17.91495 a 4.2822301,4.2604726 0 0 1 -0.32681,-0.682376 h -0.04103 a 3.2343879,3.2179544 0 0 1 -0.316552,0.660505 l -2.818183,5.125106 z" fill="#ffffff" style="stroke-width:1.46178" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ViewExcelIcon extends Vue {
}
</script>

import { GetterTree } from 'vuex';
import { State } from '@/store/language-maintenance/state';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public searchPhrase = (state: State) => {
    return state.searchPhrase;
  }

  public phrases = (state: State) => {
    return state.phrases;
  }

  public selectedPhrase = (state: State) => {
    if (state.selectedPhrase === null) {
      return null;
    }

    if (state.phrases === null || state.phrases.length < 1) {
      return null;
    }

    return state.phrases.find((p) => p.value === state.selectedPhrase) || state.phrases.find((p) => p.value.toLowerCase() === state.selectedPhrase?.toLowerCase()) || null;
  }

  public translations = (state: State) => {
    return state.translations;
  }
}

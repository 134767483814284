<template>
  <validation-observer v-slot="{ invalid, pending }">
    <b-modal id="modal-center"
             v-model="visible"
             ok-variant="primary"
             ok-title="Import"
             cancel-variant="link"
             centered
             :title="title"
             :no-close-on-backdrop="true"
             :ok-disabled="(invalid && !pending) || submitting"
             @ok="onOk"
             @cancel="onCancel"
             @close="onClose"
    >
      <validation-provider
        ref="file"
        :rules="`required|ext:${validationFileTypes}`"
        v-slot="{ valid, errors, validate }"
        tag="div"
        name="File"
      >
        <b-form-file
          v-model="files"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          :accept="accept"
          class="mt-3"
          multiple
          @input="validate"
          :state="errors[0] ? false : (valid ? true : null)"
        ></b-form-file>
        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        <small class="text-secondary">Supported file types: {{ supportedFileTypes }}.</small>
      </validation-provider>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="link" @click="cancel">
          Cancel
        </b-button>
        <b-button variant="primary" @click="ok" :disabled="(invalid && !pending) || submitting">
          <b-spinner small v-if="submitting"></b-spinner>
          {{ submitting ? 'Uploading...' : 'Upload' }}
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { namespace } from 'vuex-class';
import { ReportGroup } from '../../store/tenant/state';
import { BvModalEvent } from 'bootstrap-vue';
import { Data } from '@/store/data/state';
import { DataUploadFileResponse } from '@/api/responses/data/data-upload-file-response';

const tenantModule = namespace('tenant');
const dataModule = namespace('data');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ImportDataLoadDialog extends Mixins(BvToastMixin) {
  @dataModule.Getter private current!: Data;
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ required: true }) public title!: string | null;

  private visible: boolean = false;
  private files: Array<File> | null = null;
  private submitting: boolean = false;

  private get accept(): string {
    return this.current.allowedFileTypes.concat(this.current.allowedFileTypes.map(t => `${t.toUpperCase()}`)).map(t => `.${t}`).join(', ');
  }

  private get supportedFileTypes(): string {
    return this.current.allowedFileTypes.map(t => `'${t}'`).join(', ');
  }

  private get validationFileTypes(): string {
    return this.current.allowedFileTypes.join(',');
  }

  private async onOk(e: BvModalEvent): Promise<void> {
    e.preventDefault();

    try {
      // TODO(Dan): Upload the file...
      this.submitting = true;
      const response = await this.$store.dispatch('data/uploadDataLoadAsync', { files: this.files, reportGroupId: this.currentReportGroup.id }) as DataUploadFileResponse;

      const h = this.$createElement;
      const message = h(
        'p',
        [
          response.message
        ]
      );

      if (response.success) {
        this.showSuccessToast([message]);
      } else {
        this.showErrorToast([message]);
      }
      this.$emit('ok');
    } catch (e) {
      this.showErrorToast('Could not upload data file. Please try again.');
    } finally {
      this.submitting = false;
    }
  }

  private onCancel(): void {
    this.$emit('cancel');
  }

  private onClose(): void {
    this.$emit('close');
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
    if (value) {
      this.files = null;
    }
  }
}
</script>

<template>
  <div class="content-control-table" :aria-busy="loading">
    <b-table striped
             hover
             :sticky-header="headerStyle"
             :fields="fields"
             :items="entityContentControls"
             :busy="loading"
             :no-sort-reset="true"
             :no-local-sorting="true"
             :no-local-filtering="true"
             :no-local-paging="true"
    >
      <template v-slot:cell(name)="{ item }">
        {{ item.description || item.name }}
      </template>

      <template v-slot:cell(enabled)="{ item }">
        <b-checkbox v-model="item.enabled" :switch="true" inline :disabled="!current.canEditContentControl || !item.editable" @change="onActiveChanged(item)" style="margin-left: 1.5rem; margin-right: 0;" />
      </template>

      <template v-slot:cell(lastUpdated)="{ item }">
        {{ item.lastUpdated | datetime }}
      </template>

      <template v-slot:cell(lastUpdatedBy)="{ item }">
        {{ item.lastUpdatedBy }}
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { EntityScheduleEntry } from '@/store/schedule/state';
import { BvTableFieldArray } from 'bootstrap-vue';
import { ReportGroup, Tenant } from '@/store/tenant/state';
import { sortable } from '@/directives/sortable';
import { Configuration, ConfigurationEntity, EntityContentControl } from '../../store/configuration/state';
import { DateTime } from 'luxon';
import { UserResponse } from '../../api/responses/user/user-response';

const configurationModule = namespace('configuration');
const scheduleModule = namespace('schedule');
const environmentModule = namespace('environment');
const tenantModule = namespace('tenant');
const userModule = namespace('user');

@Component({
  directives: {
    sortable,
  },
})
export default class ContentControlTable extends Mixins(BvToastMixin) {
  @configurationModule.Getter public current!: Configuration | null;
  @configurationModule.Getter public currentEntity!: ConfigurationEntity | null;
  @scheduleModule.Getter('isLoading') public loading!: boolean;
  @scheduleModule.Getter('currentEntry') public selectedEntry!: EntityScheduleEntry | null;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;
  @configurationModule.Getter private currentReportGroup!: ReportGroup;
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @configurationModule.Getter public entityContentControls!: Array<EntityContentControl>;
  @userModule.Getter('current') public currentUser!: UserResponse;

  public get fields(): BvTableFieldArray {
    const fields: BvTableFieldArray = [
      { key: 'name', label: 'Name', class: ['col-auto'], tdClass: ['text-nowrap'] },
      { key: 'enabled', label: '', class: ['col-auto']/*, thAttr: { style: 'min-width: 200px;' } */ },
      { key: 'lastUpdatedBy', label: 'Last modified by', class: ['col-auto'], tdClass: ['text-nowrap'], thClass: ['text-nowrap'] },
      { key: 'lastUpdated', label: 'Last modified', class: ['w-100'], tdClass: ['text-nowrap'], thClass: ['text-nowrap'] }
    ];

    if (this.current !== null && this.current.canViewContentControlCodes) {
      fields.unshift({ key: 'code', label: 'Code', class: ['col-auto'], tdClass: ['text-nowrap'], thClass: ['text-nowrap'] });
    }

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  private async onActiveChanged(item: EntityContentControl): Promise<void> {
    const action = item.enabled
      ? 'configuration/enableContentControlForEntityAsync'
      : 'configuration/disableContentControlForEntityAsync';

    try {
      await this.$store.dispatch(action, { code: item.code, entityId: this.currentEntity!.id, reportGroupId: this.currentReportGroup!.id });

      this.showSuccessToast(`Content control for ${(item.description || name)} has been ${item.enabled ? 'enabled' : 'disabled'}`);

      await this.$store.dispatch('configuration/getContentControlActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });

      item.lastUpdated = DateTime.local().toString();
      item.lastUpdatedBy = this.currentUser.name;
    } catch (e) {
      item.enabled = !item.enabled;
      this.showErrorToast(`Could not ${item.enabled ? 'disable' : 'enable'} content control for ${(item.description || name)}`);
    }
  }
}
</script>

<template>
  <div>
    <filter-bar />
    <div class="mt-4">
      <support-ticket-list />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SupportTicketList from '@/components/support/support-ticket-list.vue';
import FilterBar from '@/components/support/filter-bar.vue';
import { Tenant } from '@/store/tenant/state';

const tenantModule = namespace('tenant');

@Component({
  components: {
    SupportTicketList,
    FilterBar,
  },
})
export default class Support extends Vue {
  @tenantModule.Getter('current') private currentTenant!: Tenant;

  async mounted(): Promise<void> {
    await this.$store.dispatch('support/getSupportAsync');
  }

  @Watch('currentTenant')
  private async onCurrentTenantChanged(): Promise<void> {
    await this.$store.dispatch('support/getSupportAsync');
  }
}
</script>

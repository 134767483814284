<template>
  <div>
    You will shortly be redirected to sign out
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import UserManager from '@/services/user-manager/user-manager';

@Component
export default class SignoutRedirect extends Vue {
  public async mounted(): Promise<void> {
    await UserManager.signoutRedirect();
  }
}
</script>

<template>
  <b-modal id="modal-center"
           v-model="visible"
           ok-variant="primary"
           ok-title="Submit"
           cancel-variant="link"
           centered
           :title="title"
           :no-close-on-backdrop="true"
           @ok="onOk"
           @cancel="onCancel"
           @close="onClose"
  >
    <b-container fluid>
      <div class="row align-items-center mb-3">
        <label class="col-5 mb-0 pr-0">Use Approval Workflow:</label>
        <div class="col">
          <b-checkbox v-model="useApprovalWorkflow" />
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <label class="col-5 mb-0 pr-0">Use Standard Distribution:</label>
        <div class="col">
          <b-checkbox v-model="useStandardDistribution" />
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ReportGroup } from '../../store/tenant/state';
import { SummaryPeriod, SummaryPeriodEntry } from '../../store/summary/state';

@Component
export default class RunScheduleForReportGroupDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ required: true }) public reportGroup!: ReportGroup | null;
  @Prop({ type: Object, required: true }) public period!: SummaryPeriod;
  @Prop({ type: Object, required: true }) public entry!: SummaryPeriodEntry;

  private visible: boolean = false;
  private useApprovalWorkflow: boolean = true;
  private useStandardDistribution: boolean = true;

  private get title(): string {
    return `Run schedule for ${this.entry.count} ${(this.entry.count! > 1 ? this.reportGroup?.displayName.plural : this.reportGroup?.displayName.singular)} - ${this.period.name.split('-')[1].trim()}`;
  }

  private onOk(): void {
    const reportGroup = this.reportGroup;
    const entry = this.entry;
    this.$emit('ok', { reportGroup, entry, useApprovalWorkflow: this.useApprovalWorkflow, useStandardDistribution: this.useStandardDistribution });
  }

  private onCancel(): void {
    this.$emit('cancel');
  }

  private onClose(): void {
    this.$emit('close');
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
  }
}
</script>

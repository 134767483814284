<template>
  <div>
    <svg
      width="2em"
      height="2em"
      viewBox="0 0 200 200"
    >
      <g transform="translate(0.0383083,-10.724966)">
        <g transform="matrix(5.8001012,0,0,5.8001012,4.9616917,15.724966)">
          <path style="fill:#a9a9a9;fill-opacity:1;stroke-width:1.08912" d="M 5.4176089,2.2341684 A 1.4907672,1.4138231 0 0 0 3.9268032,3.648045 v 6.0106294 7.4244126 7.424411 6.010629 a 1.4907672,1.4138231 0 0 0 1.4908057,1.413782 H 29.276557 a 1.4907672,1.4138231 0 0 0 1.490806,-1.413782 V 24.507498 17.083087 9.6586744 3.648045 A 1.4907672,1.4138231 0 0 0 29.276557,2.2341684 Z" />
          <path d="m 3.4463134,0.65967124 a 1.4952716,1.415241 0 0 0 -1.49531,1.41529456 v 6.0166574 7.4318578 7.431857 6.016657 a 1.4952716,1.415241 0 0 0 1.49531,1.415201 H 27.377349 a 1.4952716,1.415241 0 0 0 1.49531,-1.415201 V 22.955338 15.523481 8.0916232 2.0749658 a 1.4952716,1.415241 0 0 0 -1.49531,-1.41529456 z" style="fill:#e8e8e8;fill-opacity:1;stroke-width:1.09131" />
          <path fill="none" d="M 0,0 H 32 V 32 H 0 Z" />
          <path style="stroke-width:0.797792" d="M 3.3861815,1.4234418 H 27.261294 V 28.721743 H 3.3861815 Z" fill="none" />
          <path style="stroke-width:1.46166" fill="#185abd" d="M 5.6553466,4.0643691 H 25.145095 a 1.9483901,1.9483901 0 0 1 1.94839,1.94839 V 25.502507 a 1.9483901,1.9483901 0 0 1 -1.94839,1.94839 H 5.6553466 a 1.9483901,1.9483901 0 0 1 -1.94839,-1.94839 V 6.0127591 a 1.9483901,1.9483901 0 0 1 1.94839,-1.94839 z" />
          <path style="stroke-width:1.46166" fill="#ffffff" d="m 21.17377,23.065923 h -2.630984 l -3.069482,-10.08544 -3.215646,10.08544 H 9.6266716 L 6.7033556,8.4493441 h 2.630984 L 11.380661,18.680949 14.450143,8.7416751 h 2.192487 l 2.923316,9.9392739 2.046321,-10.2316049 h 2.484819 z" />
          <g transform="matrix(1.1217054,0,0,1.1211122,-2.4936097,-2.9483735)">
            <path d="m 17.659158,22.185449 0.163486,-0.226192 2.019642,0.0086 0.02424,-1.927708 3.987832,0.01714 v 1.910403 l 2.059206,-0.0087 0.01885,0.336162 0.0076,0.387608 -3.601086,4.276548 -0.5084,0.0068 -0.494112,-0.01001 -3.83433,-4.552281 z" style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.0255418;stroke-opacity:1" transform="matrix(2.190629,0,0,3.0733002,-24.726698,-50.392987)" />
            <g style="fill:#233264;fill-opacity:1" transform="matrix(1.7980234,0,0,2.3926161,-10.485526,-38.451952)">
              <path d="m 180.20709,87.443958 24.94671,-1.262975 -1.56126,-30.875168 31.64023,-1.322643 1.28472,30.609965 24.39561,-1.235075 -36.81451,71.936408 z" transform="matrix(0.11224705,0.00402688,-0.00567597,0.07963492,-5.5901957,16.017455)" style="fill:#233264;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DownloadWordIcon extends Vue {
}
</script>

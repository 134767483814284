<template>
  <div class="team-members-table"
       :aria-busy="loading"
  >
    <b-table striped
             hover
             :sticky-header="headerStyle"
             :fields="fields"
             :items="items"
             :busy="loading"
             :no-sort-reset="true"
             :no-local-sorting="true"
             :no-local-filtering="true"
             :no-local-paging="true"
    >
      <template v-slot:cell(actions)="{ item }">
        <b-button-group>
          <b-dropdown :no-caret="true" variant="outline-primary" size="sm" right :popper-opts="{ modifiers: { computeStyle: { gpuAcceleration: false } } }">
            <template v-slot:button-content>
              <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
            </template>
            <b-dropdown-item variant="danger" @click="onRemoveClicked(item)">Remove</b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </template>
    </b-table>

    <remove-team-member-confirmation-dialog v-if="showRemoveTeamMemberConfirmation" :show="showRemoveTeamMemberConfirmation" :title="`Remove ${selectedMember && selectedMember.firstName} ${selectedMember && selectedMember.lastName} from ${currentTeam.name}`" @ok="onRemoveTeamMemberConfirmed" @cancel="onRemoveTeamMemberCancelled" @close="onRemoveTeamMemberCancelled" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { BvTableFieldArray } from 'bootstrap-vue';
import { ReportGroup } from '@/store/tenant/state';
import { sortable } from '@/directives/sortable';
import { Configuration, ConfigurationTeam, ConfigurationTeamMember } from '../../store/configuration/state';
import RemoveTeamMemberConfirmationDialog from './remove-team-member-confirmation-dialog.vue';

const configurationModule = namespace('configuration');
const scheduleModule = namespace('schedule');
const environmentModule = namespace('environment');

@Component({
  directives: {
    sortable,
  },
  components: {
    RemoveTeamMemberConfirmationDialog,
  },
})
export default class TeamMembersTable extends Mixins(BvToastMixin) {
  @configurationModule.Getter public current!: Configuration | null;
  @scheduleModule.Getter('isLoading') public loading!: boolean;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;
  @configurationModule.Getter private currentReportGroup!: ReportGroup;
  @configurationModule.Getter public teamMembers!: Array<ConfigurationTeamMember>;
  @configurationModule.Getter private currentTeam!: ConfigurationTeam | null;

  private showRemoveTeamMemberConfirmation: boolean = false;
  private selectedMember: ConfigurationTeamMember | null = null;

  public get fields(): BvTableFieldArray {
    const fields: BvTableFieldArray = [
      { key: 'firstName', label: 'First name', class: ['col-auto', 'text-nowrap'], tdClass: ['text-nowrap'] },
      { key: 'lastName', label: 'Last name', class: ['col-auto', 'text-nowrap']/*, thAttr: { style: 'min-width: 200px;' } */ },
      { key: 'email', label: 'Email', class: ['w-100']/*, thAttr: { style: 'min-width: 200px;' } */ },
      { key: 'actions', label: '', class: ['col-auto']/*, thAttr: { style: 'min-width: 200px;' } */ }
    ];

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 270px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 270px)';
    }

    return 'calc(100vh - 294px)';
  }

  public get items() {
    return this.teamMembers;
  }

  private onRemoveClicked(member: ConfigurationTeamMember): void {
    this.selectedMember = member;
    this.showRemoveTeamMemberConfirmation = true;
  }

  private onRemoveTeamMemberCancelled(): void {
    this.selectedMember = null;
    this.showRemoveTeamMemberConfirmation = false;
  }

  private async onRemoveTeamMemberConfirmed(result: { comments: string | null }): Promise<void> {
    try {
      await this.$store.dispatch('configuration/removeTeamMemberAsync', { email: this.selectedMember?.email, comments: result.comments, teamId: this.currentTeam!.id, reportGroupId: this.currentReportGroup.id });

      this.showSuccessToast(`${this.selectedMember?.firstName} ${this.selectedMember?.lastName} has been successfully removed from ${this.currentTeam!.name}.`);
    } catch (e) {
      this.showErrorToast(`${this.selectedMember?.firstName} ${this.selectedMember?.lastName} could not be removed from ${this.currentTeam!.name}. Please try again.`);
    } finally {
      this.onRemoveTeamMemberCancelled();
    }

    const members = this.$store.dispatch('configuration/getMembersForTeamAsync', { teamId: this.currentTeam!.id, reportGroupId: this.currentReportGroup.id });
    const activity = this.$store.dispatch('configuration/getTeamActivityForReportGroupAsync', { teamId: this.currentTeam!.id, reportGroupId: this.currentReportGroup.id });

    await Promise.all([members, activity]);
  }
}
</script>

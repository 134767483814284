<template>
  <div :class="classes" class="document-activity-status text-center" :title="title" v-if="classes !== null">
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkflowReportActivityResponse } from '@/api/responses/workflow/workflow-report-activity-response';

@Component
export default class DocumentActivityStatus extends Vue {
  @Prop({ type: Object, required: true }) public item!: WorkflowReportActivityResponse;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.item.status) {
      case 'Blank':
        return null;
      case 'Error':
        return { 'is-background-danger': true };
      case 'Warning':
        return { 'is-background-warning': true };
      case 'OK':
        return { 'is-background-success': true };
      default:
        return null;
    }
  }

  public get title(): string | null {
    switch (this.item.status) {
      case 'Error':
        return 'Activity after deadline';
      case 'Warning':
        return 'Activity approaching deadline';
      case 'OK':
        return 'Activity before deadline';
      default:
        return null;
    }
  }
}
</script>

<template>
  <validation-observer v-slot="{ passes, invalid, pending }" class="pl-3" :class="{ 'col': isEditing, 'col-auto': !isEditing }">
    <div class="row align-items-center">
      <div :class="{ 'col': isEditing, 'col-auto': !isEditing }">
        <validation-provider
          ref="phrase"
          :rules="`required`"
          v-slot="{ valid, errors }"
          tag="div"
          :mode="validationMode"
        >
          <b-input-group v-if="isEditing">
            <b-form-input ref="input" name="subject" v-model="value" @keypress.enter="passes(save)" @keyup.esc="cancel" :state="errors[0] ? false : (valid ? true : null)" />
            <b-form-invalid-feedback v-if="errors.length > 0">{{ errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
          <h4 class="col-auto mb-0 pl-0 pr-0" style="line-height: 1.5;" v-else>#{{ ticket.id }} - {{ ticket.subject }}</h4>
        </validation-provider>
      </div>

      <div class="col-auto pl-0" v-if="canEdit">
        <b-dropdown variant="link" no-caret v-if="!isEditing">
          <template v-slot:button-content>
            <b-icon icon="three-dots-vertical" />
          </template>
          <b-dropdown-item title="Edit" @click="toggleEditing">Edit</b-dropdown-item>
        </b-dropdown>
        <b-button-group>
          <b-button v-if="isEditing" variant="outline-primary" size="sm" :disabled="(invalid && !pending) || ticket.subject === value" @click="passes(save)" title="Save">
            <b-icon icon="check2" aria-hidden="true"></b-icon> Save
          </b-button>
          <b-button v-if="isEditing" variant="outline-primary" size="sm" @click="cancel" title="Cancel">
            <b-icon icon="X" aria-hidden="true"></b-icon> Cancel
          </b-button>
        </b-button-group>
      </div>
    </div>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ValidationFlags } from 'vee-validate/dist/types/types';
import { namespace } from 'vuex-class';
import { UserResponse } from '@/api/responses/user/user-response';
import { SupportTicketDetails } from '../../store/support/state';

const userModule = namespace('user');

type ValidationModeContext = { errors: Array<string>, value: any, flags: ValidationFlags };

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EditableSupportTicketTitle extends Vue {
  @userModule.Getter('current') private currentUser!: UserResponse;
  @Prop({ required: true }) ticket!: SupportTicketDetails;

  get canEdit(): boolean {
    return this.currentUser.roles.administrator || this.ticket.createdBy === this.currentUser.name;
  }

  isEditing = false;
  value: string | null = null;

  toggleEditing(): void {
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      this.value = this.ticket?.subject;
      this.$nextTick(() => {
        (this.$refs.input as BFormInput).focus();
      });
    }
  }

  save(): void {
    if (this.ticket.subject === null || this.ticket.subject === this.value) {
      return;
    }

    this.toggleEditing();

    this.$emit('saved', { from: this.ticket.subject, to: this.value! });
  }

  cancel(): void {
    this.toggleEditing();
  }

  @Watch('ticket')
  onTicketChanged(ticket: SupportTicketDetails): void {
    if (ticket === null || ticket === undefined) {
      this.value = null;
      return;
    }

    this.value = ticket?.subject ?? '';
  }

  validationMode({ errors }: ValidationModeContext) {
    if (errors.length) {
      return {
        on: ['input', 'change'],
      };
    }

    return {
      on: ['input', 'change'],
    };
  }
}
</script>

<template>
  <div class="schedule-filter-bar row">
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">Report group:</label>
        <div class="col-auto">
          <b-form-select v-model="selectedReportGroup" @change="changeReportGroup(reportGroup)">
            <b-form-select-option :value="group" v-for="group in reportGroups" :key="group.id">{{ group.name }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div cass="col-auto">
      <div class="row align-items-center">
        <div class="col-auto pr-0">
          <entity-selector v-if="current !== null && !isReportGroupSettings && !isTeams" />
          <team-selector v-if="current !== null && isTeams" />
        </div>
        <div class="col-auto pr-0" v-if="current !== null && isTeams">
          <b-dropdown text="Actions" variant="primary" right :popper-opts="{ modifiers: { computeStyle: { gpuAcceleration: false } } }">
            <b-dropdown-item @click="onRegisterTeamClicked">Create new team</b-dropdown-item>
            <b-dropdown-item @click="onAssignTeamMemberClicked">Assign team members</b-dropdown-item>
            <b-dropdown-item @click="onRenameTeamClicked">Rename team</b-dropdown-item>
            <b-dropdown-item @click="onDeleteTeamClicked" v-if="teamMembers.length===0">Delete Team</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <create-team-dialog v-if="showRegisterTeamDialog" :show="showRegisterTeamDialog" @ok="onRegisterTeamOk" @cancel="onRegisterTeamCancel" @close="onRegisterTeamCancel" />
    <assign-team-member-dialog v-if="showAssignTeamMemberDialog" :team="currentTeam.name" :existing-members="teamMembers" :show="showAssignTeamMemberDialog" @ok="onAssignTeamMemberOk" @cancel="onAssignTeamMemberCancel" @close="onAssignTeamMemberCancel" />
    <rename-team-dialog v-if="showRenameTeamDialog" :show="showRenameTeamDialog" :team="currentTeam.name" @ok="onRenameTeamOk" @cancel="onRenameTeamCancel" @close="onRenameTeamCancel" />
    <delete-team-dialog v-if="showDeleteTeamDialog" :show="showDeleteTeamDialog" :team="currentTeam.name" @cancel="onDeleteTeamCancel" @close="onDeleteTeamCancel" @ok="onDeleteTeamOk" />
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { kebabcase } from '@/utilities/text.utils';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';
import { TenantGroupResponse } from '@/api/responses/tenant/tenant-group-response';
import { Route } from 'vue-router';
import { Tenant, ReportGroup } from '@/store/tenant/state';
import { Configuration, ConfigurationEntity, ConfigurationTeam, ConfigurationTeamMember } from '../../store/configuration/state';
import EntitySelector from './filter-bar-selectors/entity-selector.vue';
import TeamSelector from './filter-bar-selectors/team-selector.vue';
import CreateTeamDialog from './create-team-dialog.vue';
import AssignTeamMemberDialog from './assign-team-member-dialog.vue';
import RenameTeamDialog from './rename-team-dialog.vue';
import DeleteTeamDialog from './delete-team-dialog.vue';
import { DeleteTeamResponse } from '@/api/responses/configuration/delete-team-response';
import { WorkflowReport } from '../../store/workflow/state';

const tenantModule = namespace('tenant');
const configurationModule = namespace('configuration');
const workflowModule = namespace('workflow');

@Component({
  components: {
    EntitySelector,
    TeamSelector,
    CreateTeamDialog,
    AssignTeamMemberDialog,
    RenameTeamDialog,
    DeleteTeamDialog,
  },
})
export default class ConfigurationFilterBar extends Mixins(BvToastMixin) {
  @tenantModule.Getter('current') currentTenant!: Tenant;
  @configurationModule.Getter currentReportGroup!: ReportGroup;
  @configurationModule.Getter current!: Configuration;
  @configurationModule.Getter currentEntity!: ConfigurationEntity | null;
  @configurationModule.Getter currentTeam!: ConfigurationTeam | null;
  @configurationModule.Getter teamMembers!: Array<ConfigurationTeamMember>;
  @workflowModule.Getter('selectedReport') workflowSelectedReport!: WorkflowReport | null;

  reportGroup: TenantGroupResponse | null = null;
  showRegisterTeamDialog = false;
  showAssignTeamMemberDialog = false;
  showRenameTeamDialog = false;
  showDeleteTeamDialog = false;

  get selectedReportGroup(): ReportGroup {
    return this.currentReportGroup;
  }

  set selectedReportGroup(value: ReportGroup) {
    this.changeReportGroup(value);
  }

  get workflowSelectedReportId(): number {
    return this.workflowSelectedReport === null ? 0 : this.workflowSelectedReport.id;
  }

  get reportGroups(): Array<TenantGroupResponse> {
    return this.currentTenant.reportGroups.filter((rg) => rg.visible.configuration);
  }

  get isReportGroupSettings(): boolean {
    const isReportGroupSettings = this.$route.params.settings === 'report-group';
    return isReportGroupSettings;
  }

  get isTeams(): boolean {
    return this.$route.params.settings === 'teams';
  }

  async mounted(): Promise<void> {
    const { reportGroup } = this.$route.params;
    const setInitialState = async () => {
      const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.all) || this.reportGroups[0] || null;
      if (matchingReportGroup !== null) {
        const groupName = kebabcase(matchingReportGroup.name);
        if (groupName !== reportGroup) {
          try {
            await this.$router.replace({ name: 'configuration', params: { reportGroup: groupName } });
          } catch (e) {
          }
        }
        await this.setCurrentReportGroup(matchingReportGroup);
      }
    };

    if ((this.current === null && this.currentReportGroup !== null) || (this.current !== null && this.currentReportGroup !== null)) {
      await this.$store.dispatch('configuration/getConfigurationForReportGroupAsync', { reportGroup: this.currentReportGroup, reportId: this.workflowSelectedReportId });

      if (this.currentEntity === null) {
        this.$store.commit('configuration/setCurrentEntity', this.current.entities[0]?.id || null);
      }

      if (this.currentTeam === null) {
        this.$store.commit('configuration/setCurrentTeam', this.current.teams[0]?.id || null);
      }
    }

    await setInitialState();
    this.reportGroup = this.currentReportGroup;
  }

  @Watch('$route')
  async onRouteChanged(to: Route, from: Route) : Promise<void> {
    // NOTE(Dan): Don't destructure this below as we still need to keep this in the "params" object.
    const reportGroup = to.params.reportGroup;
    const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.all) || this.reportGroups[0] || null;
    const { ...params } = to.params;
    let updateParams: boolean = false;

    // NOTE(Dan): We should probably compare the previous params to the new ones before trying to find matches.
    //            This will us to prevent setting off watchers on the report group and valuation date uneccessarily.
    //            For example, currently changing a tab on the workflow-steps changes the URL, but only the option.
    //            So we will end up needlessly triggering watchers in this case.
    //            We should probably also bail if there is no params diff either in this case.
    if (from?.params.reportGroup === reportGroup) {
      if (matchingReportGroup?.id === this.currentReportGroup.id) {
        return;
      }
    }

    if (matchingReportGroup !== null) {
      if (kebabcase(matchingReportGroup.name) !== reportGroup) {
        updateParams = true;
        params.reportGroup = kebabcase(matchingReportGroup.name);
      }
      await this.setCurrentReportGroup(matchingReportGroup);
    }

    if (updateParams) {
      try {
        await this.$router.replace({ name: 'configuration', params: { ...to.params, ...params } });
      } catch (e) {
        // We catch the navigation cancelled here, we don't need to log to Sentry as the request has been replaced.
      }
    }
  }

  @Watch('currentTenant')
  async onTenantChanged(tenant: TenantResponse): Promise<void> {
    const { reportGroup } = this.$route.params;
    const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.all) || this.reportGroups[0] || null;

    if (matchingReportGroup !== null) {
      if (matchingReportGroup.name !== this.currentReportGroup?.name) {
        await this.changeReportGroup(matchingReportGroup);
      } else if (matchingReportGroup.id !== this.currentReportGroup?.id) {
        await this.setCurrentReportGroup(matchingReportGroup);
      } else {
        this.reportGroup = matchingReportGroup;
        await this.$store.dispatch('configuration/getConfigurationForReportGroupAsync', { reportGroup: this.currentReportGroup });
        this.$store.commit('configuration/setCurrentEntity', this.current.entities[0]?.id || null);
        this.$store.commit('configuration/setCurrentDate', this.current.dates[0]?.valuationDate || null);
        this.$store.commit('configuration/setCurrentTeam', this.current.teams[0]?.id || null);
      }
    }
  }

  async changeReportGroup(group: TenantGroupResponse): Promise<void> {
    if (this.$route.params.reportGroup === undefined || this.$route.params.reportGroup !== kebabcase(group.name)) {
      const { reportId, reportGroup, valuationDate, option, documentId, ...params } = this.$route.params;
      await this.$router.push({ name: 'configuration', params: { ...params, reportGroup: kebabcase(group.name) } });
    }
  }

  async setCurrentReportGroup(reportGroup: TenantGroupResponse): Promise<void> {
    const previous = { ...this.currentReportGroup! };
    this.reportGroup = reportGroup;
    this.$store.commit('configuration/setReportGroup', reportGroup);
    await this.$store.dispatch('tryApplyReportGroup', reportGroup, { root: true });

    if (previous?.id !== reportGroup.id) {
      await this.$store.dispatch('configuration/getConfigurationForReportGroupAsync', { reportGroup: reportGroup, reportId: this.workflowSelectedReportId });

      this.$store.commit('configuration/setCurrentDate', this.current.dates[0]?.valuationDate || null);
      this.$store.commit('configuration/setCurrentTeam', this.current.teams[0]?.id || null);
    }
  }

  async onRegisterTeamOk(result: { name: string }): Promise<void> {
    try {
      await this.$store.dispatch('configuration/registerTeamAsync', { name: result.name, reportGroupId: this.currentReportGroup.id });

      this.showSuccessToast(`Team '${result.name}' has created successfully.`);
    } catch (e) {
      this.showErrorToast(`Could not create team '${result.name}'. Please try again.`);
    } finally {
      this.onRegisterTeamCancel();
    }

    await this.$store.dispatch('configuration/getConfigurationForReportGroupAsync', { reportGroup: this.currentReportGroup });

    const team = this.current.teams.find(team => team.name === result.name) || null;

    if (team !== null) {
      await new Promise(resolve => setTimeout(resolve, 500));
      this.$store.commit('configuration/setCurrentTeam', team.id);
    }
  }

  onRegisterTeamCancel(): void {
    this.showRegisterTeamDialog = false;
  }

  onRegisterTeamClicked(): void {
    this.showRegisterTeamDialog = true;
  }

  async onAssignTeamMemberOk(result: { members: Array<{ name: string, email: string}> }): Promise<void> {
    try {
      for (const member of result.members) {
        await this.$store.dispatch('configuration/assignTeamMemberAsync', { email: member.email, teamId: this.currentTeam!.id, reportGroupId: this.currentReportGroup.id });
      }

      const message = result.members.length > 1
        ? `Successfully assigned ${result.members.length} members to ${this.currentTeam!.name}`
        : `Successfully assigned ${result.members[0].name} to ${this.currentTeam!.name}`;

      this.showSuccessToast(message);
    } catch (e) {
      this.showErrorToast(`Could not assign member(s) to team ${this.currentTeam!.name}. Please try again.`);
    } finally {
      this.onAssignTeamMemberCancel();
    }

    const members = this.$store.dispatch('configuration/getMembersForTeamAsync', { teamId: this.currentTeam!.id, reportGroupId: this.currentReportGroup.id });
    const activity = this.$store.dispatch('configuration/getTeamActivityForReportGroupAsync', { teamId: this.currentTeam!.id, reportGroupId: this.currentReportGroup.id });

    await Promise.all([members, activity]);
  }

  onAssignTeamMemberCancel(): void {
    this.showAssignTeamMemberDialog = false;
  }

  onAssignTeamMemberClicked(): void {
    this.showAssignTeamMemberDialog = true;
  }

  onRenameTeamClicked(): void {
    this.showRenameTeamDialog = true;
  }

  onRenameTeamCancel(): void {
    this.showRenameTeamDialog = false;
  }

  async onRenameTeamOk(result: { name: string }): Promise<void> {
    try {
      await this.$store.dispatch('configuration/renameTeamAsync', { teamId: this.currentTeam?.id, teamName: result.name, reportGroupId: this.currentReportGroup.id });

      this.showSuccessToast(`Team '${result.name}' has been renamed successfully.`);
    } catch (e) {
      this.showErrorToast(`Could not rename team '${result.name}'. Please try again.`);
    } finally {
      this.onRenameTeamCancel();
    }

    await this.$store.dispatch('configuration/getConfigurationForReportGroupAsync', { reportGroup: this.currentReportGroup });

    const team = this.current.teams.find(team => team.name === result.name) || null;

    if (team !== null) {
      await new Promise(resolve => setTimeout(resolve, 500));
      this.$store.commit('configuration/setCurrentTeam', team.id);
    }
  }

  onDeleteTeamClicked(): void {
    this.showDeleteTeamDialog = true;
  }

  onDeleteTeamCancel(): void {
    this.showDeleteTeamDialog = false;
  }

  async onDeleteTeamOk(result: { name: string }): Promise<void> {
    try {
      const response = await this.$store.dispatch('configuration/deleteTeamAsync', { teamId: this.currentTeam?.id, reportGroupId: this.currentReportGroup.id }) as DeleteTeamResponse;

      const h = this.$createElement;
      const closeButton = h(
        'div',
        { class: ['text-right'] },
        [
          h(
            'b-button',
            {
              on: { click: () => this.$bvToast.hide('deleteTeamToast') },
            },
            'Close'
          )
        ]
      );

      const deleteMsg = h(
        'p',
        [
          response.error === null ? `Team '${result.name}' has been deleted successfully.` : response.error
        ]
      );

      this.$bvToast.toast([deleteMsg, closeButton],
        {
          id: 'deleteTeamToast',
          toastClass: 'delete-team-toast',
          solid: true,
          noAutoHide: true,
          noCloseButton: true,
          variant: response.error === null ? 'notice-success' : 'notice-danger',
          toaster: 'b-toaster-top-center',
          appendToast: true,
        });
    } catch (e) {
      this.showErrorToast(`Could not delete team '${result.name}'. Please try again.`);
    } finally {
      this.onDeleteTeamCancel();
    }

    await this.$store.dispatch('configuration/getConfigurationForReportGroupAsync', { reportGroup: this.currentReportGroup });

    const team = this.current.teams.find(team => team.name === result.name) || null;

    if (team !== null) {
      await new Promise(resolve => setTimeout(resolve, 500));
      this.$store.commit('configuration/setCurrentTeam', team.id);
    }
  }
}
</script>

<template>
  <div>
    <filter-bar />
    <div class="container-fluid">
      <div class="row mt-4">
        <div class="col-8">
          <workflow-steps />
        </div>
        <div class="col-4" v-if="report !== null">
          <workflow-report-details />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FilterBar from '@/components/workflow/filter-bar.vue';
import WorkflowSteps from '@/components/workflow/workflow-steps.vue';
import WorkflowReportDetails from '@/components/workflow/workflow-report-details.vue';
import { WorkflowReport } from '@/store/workflow/state';

const workflowModule = namespace('workflow');

@Component({
  components: {
    FilterBar,
    WorkflowSteps,
    WorkflowReportDetails,
  },
})
export default class Workflow extends Vue {
  @workflowModule.Getter('selectedReport') report!: WorkflowReport | null;
}
</script>

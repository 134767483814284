<template>
  <div>
    <filter-bar />
    <div class="container-fluid">
      <div class="row mt-4">
        <div class="col">
          <!-- <document-table v-if="current !== null" /> -->
          <phrase-table v-if="phrases !== null" @phrase-saved="onPhraseSaved" @phrase-removed="onPhraseRemoved" />
        </div>
        <div class="col">
          <translation-table v-if="translations !== null && translations.length > 0" @translation-saved="onTranslationSaved" @translation-removed="onTranslationRemoved" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { Phrase, Translation } from '../store/language-maintenance/state';
import FilterBar from '@/components/language-maintenance/filter-bar.vue';
import PhraseTable from '@/components/language-maintenance/phrase-table.vue';
import TranslationTable from '@/components/language-maintenance/translation-table.vue';
import { Tenant } from '../store/tenant/state';
import * as Sentry from '@sentry/browser';

const languageMaintenanceModule = namespace('languageMaintenance');
const tenantModule = namespace('tenant');

@Component({
  components: {
    FilterBar,
    PhraseTable,
    TranslationTable,
  },
})
export default class LanguageMaintenance extends Mixins(BvToastMixin) {
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @languageMaintenanceModule.Getter private searchPhrase!: string | null;
  @languageMaintenanceModule.Getter private phrases!: Array<Phrase>;
  @languageMaintenanceModule.Getter private translations!: Array<Translation>;

  public mounted(): void {
    if (this.phrases !== null) {
      this.$store.commit('languageMaintenance/setSelectedPhrase', null);
      this.$store.commit('languageMaintenance/setPhrases', null);
      this.$store.commit('languageMaintenance/setTranslations', []);
    }
  }

  @Watch('phrases')
  private async onPhrasesChanged(phrases: Array<Phrase>): Promise<void> {
    if (phrases === null || phrases.length < 1) {
      return;
    }

    const exactMatch = this.phrases.find((p) => p.value === this.searchPhrase) || this.phrases.find((p) => p.value.toLowerCase() === this.searchPhrase) || null;

    await this.$store.dispatch('languageMaintenance/getTranslationsForPhraseAsync', { phrase: exactMatch?.value || phrases[0].value });

    this.$store.commit('languageMaintenance/setSelectedPhrase', exactMatch?.value || phrases[0].value || null);
  }

  private async onPhraseSaved(value: { phrase: string, to: string }): Promise<void> {
    try {
      await this.$store.dispatch('languageMaintenance/replacePhraseAsync', {
        phrase: value.phrase,
        to: value.to,
        language: this.currentTenant.defaultLanguage,
        languageId: this.currentTenant.defaultLanguageId,
      });

      this.showSuccessToast(`Phrase '${value.phrase}' successfully changed to '${value.to}'.`);
    } catch (e) {
      this.showErrorToast(`Phrase '${value.phrase}' could not be saved. Please try again.`);
      Sentry.captureException(e);
    } finally {
      this.$store.commit('languageMaintenance/setSearchPhrase', value.to);
      await this.$store.dispatch('languageMaintenance/getPhrasesAsync', { phrase: this.searchPhrase });
    }
  }

  private async onPhraseRemoved(phrase: Phrase): Promise<void> {
    try {
      await this.$store.dispatch('languageMaintenance/removePhraseAsync', {
        phrase: phrase.value,
        language: this.currentTenant.defaultLanguage,
        languageId: this.currentTenant.defaultLanguageId,
      });

      this.showSuccessToast(`Phrase '${phrase.value}' successfully removed.`);
    } catch (e) {
      this.showErrorToast(`Phrase '${phrase.value}' could not be removed. Please try again.`);
      Sentry.captureException(e);
    } finally {
      this.$store.commit('languageMaintenance/setSearchPhrase', this.searchPhrase);
      await this.$store.dispatch('languageMaintenance/getPhrasesAsync', { phrase: this.searchPhrase });
    }
  }

  private async onTranslationSaved(translation: Translation): Promise<void> {
    try {
      await this.$store.dispatch('languageMaintenance/replaceTranslationForPhraseAsync', translation);

      this.showSuccessToast(`${translation.language} translation '${translation.value}' successfully saved.`);
    } catch (e) {
      this.showErrorToast(`${translation.language} translation '${translation.value}' could not be saved. Please try again.`);
      Sentry.captureException(e);
    }
  }

  private async onTranslationRemoved(translation: Translation): Promise<void> {
    try {
      await this.$store.dispatch('languageMaintenance/removeTranslationForPhraseAsync', translation);

      this.showSuccessToast(`Translation for ${translation.language} successfully removed.`);
    } catch (e) {
      this.showErrorToast(`Translation '${translation.phrase}' for ${translation.language} could not be saved. Please try again.`);
      Sentry.captureException(e);
    } finally {
      await this.$store.dispatch('languageMaintenance/getTranslationsForPhraseAsync', { phrase: translation.phrase });
      this.$store.commit('languageMaintenance/setSelectedPhrase', translation.phrase || null);
    }
  }
}
</script>

<template>
  <div :class="classes" class="document-distribution-status text-center" :title="title" v-if="classes !== null">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DocumentDistributionResponse } from '@/api/responses/document-library/document-distribution-response';

@Component
export default class DocumentDistributionStatus extends Vue {
  @Prop({ type: Object, required: true }) public item!: DocumentDistributionResponse;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.item.status) {
      case 'AwaitingDistribution':
        return { 'is-background-warning': true };
      case 'Complete':
        return { 'is-background-success': true };
      case 'AwaitingBatchDistribution':
        return { 'is-background-info': true };
      case 'CompletedBatchDistribution':
        return { 'is-background-info': true };
      default:
        return null;
    }
  }

  public get title(): string | null {
    switch (this.item.status) {
      case 'AwaitingDistribution':
        return 'Awaiting distribution';
      case 'Complete':
        return 'Complete';
      case 'AwaitingBatchDistribution':
        return 'Awaiting batch distribution';
      case 'CompletedBatchDistribution':
        return 'Completed batch distribution';
      default:
        return null;
    }
  }

  public get text(): string | null {
    switch (this.item.status) {
      case 'AwaitingDistribution':
      case 'AwaitingBatchDistribution':
        return 'i';
      case 'Complete':
      case 'CompletedBatchDistribution':
        return '✓';
      default:
        return null;
    }
  }
}
</script>

<template>
  <div :class="classes" class="summary-period-entry-status">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SummaryPeriodEntry } from '@/store/summary/state';

@Component
export default class SummaryPeriodEntryStatusIcon extends Vue {
  @Prop({ type: Object, required: true }) public entry!: SummaryPeriodEntry;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.entry.status) {
      case 'Error':
        return { 'is-background-danger': true };
      case 'Warning':
        return { 'is-background-warning': true };
      case 'Information':
      case 'AwaitingDistribution':
        return { 'is-background-info': true };
      case 'Success':
        return { 'is-background-success': true };
      default:
        return null;
    }
  }

  public get text(): string | null {
    switch (this.entry.status) {
      case 'Error':
      case 'Warning':
        return '!';
      case 'Information':
        return 'i';
      case 'AwaitingDistribution':
      case 'Success':
        return '✓';
      default:
        return null;
    }
  }
}
</script>

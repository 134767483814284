import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State, EntitySchedule, EntityScheduleActivityLog, EntityScheduleCompletedRequestsPage } from '@/store/schedule/state';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setLoading = (state: State, value: boolean) => {
    state.loadingSchedule = value;
  }

  public setEntitySchedule = (state: State, value: EntitySchedule | null) => {
    state.entitySchedule = value;
  }

  public setCurrentEntry = (state: State, value: number | null) => {
    state.selectedEntryId = value;
  }

  public setEntityScheduleActivity = (state: State, value: EntityScheduleActivityLog | null) => {
    state.entityScheduleActivity = value;
  }

  public setEntityScheduleRequestPage = (state: State, page: EntityScheduleCompletedRequestsPage) => {
    Vue.set(state.requestPages, page.page - 1, page);
  }

  public clearEntityScheduleRequestPages = (state: State) => {
    state.requestPages = [];
  }
}

import { DocumentCheckInCompleted } from '../events/document-checkin-completed';
import store from '@/store';

async function handleDocumentCheckInCompleted(data: DocumentCheckInCompleted): Promise<void> {
  /* eslint-disable */
  console.log(`DocumentCheckInStarted event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */

  const documentId = data.payload.aggregateId.split('-')[1];

  await store.dispatch('documentLibrary/tryReplaceDocumentOnPage', { ...data, documentId });
}

export default handleDocumentCheckInCompleted;

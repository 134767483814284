<template>
  <div :class="classes" class="workflow-report-issue-type">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkflowReportIssueResponse } from '@/api/responses/workflow/workflow-report-issue-response';

@Component
export default class WorkflowReportIssueType extends Vue {
  @Prop({ type: Object, required: true }) public item!: WorkflowReportIssueResponse;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.item.type) {
      case 'Critical':
        return { 'is-background-critical': true };
      case 'Error':
        return { 'is-background-danger': true };
      case 'Warning':
        return { 'is-background-warning': true };
      case 'Information':
        return { 'is-background-info': true };
      default:
        return null;
    }
  }

  public get text(): string | null {
    switch (this.item.type) {
      case 'Critical':
        return this.item.blockingApproval?.toString() || '!';
      case 'Error':
      case 'Warning':
      case 'Information':
        return this.item.blockingApproval?.toString() || 'i';
      default:
        return null;
    }
  }
}
</script>

const KEY = 'redirect-path';

export function storeRedirectPath(path: string): void {
  sessionStorage.setItem(KEY, location.pathname);
}

export function loadRedirectPath(): string | null {
  return sessionStorage.getItem(KEY);
}

export function clearRedirectPath(): void {
  sessionStorage.removeItem(KEY);
}

<template>
  <div class="summary-period">
    <b-table class="summary-period-table"
             ref="table"
             striped
             hover
             show-empty
             sort-icon-left
             :no-sort-reset="true"
             :no-local-filtering="true"
             :no-local-paging="true"
             :fields="fields"
             :items="entries"
    >
      <template v-slot:cell(status)="{ item }">
        <summary-period-entry-status-icon :entry="item" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-btn variant="outline-primary" size="sm" :to="buildRoute(item)" v-if="(item.links.reports !== null || item.links.issues !== null) && !item.actions.canDistributeBatch">View</b-btn>
        <b-btn variant="outline-primary" size="sm" v-if="item.actions.canDistributeBatch && item.batchId < 0" @click="onScheduleWorkflowReportBatchClicked(item)">Send drafts now</b-btn>
        <b-btn variant="outline-primary" size="sm" v-if="item.actions.canDistributeBatch && item.batchId >= 0" @click="onScheduleWorkflowReportBatchClicked(item)">Send now</b-btn>
        <b-btn variant="outline-primary" size="sm" v-if="item.actions.canRunNow" @click="onRunScheduleForReportGroupClicked(item)">Run now</b-btn>
        <b-btn variant="outline-primary" size="sm" v-if="item.actions.canRunSystemReports" @click="onRunScheduleForReportGroupClicked(item)">Run now</b-btn>
        <b-btn variant="outline-primary" size="sm" v-if="item.actions.canReRun" @click="onRunScheduleForReportGroupClicked(item)">Run again</b-btn>
        <b-btn variant="outline-primary" size="sm" v-if="item.actions.canReRunSystemReports" @click="onRunScheduleForReportGroupClicked(item)">Run again</b-btn>
        <b-btn variant="outline-primary" size="sm" v-if="item.actions.canRunNewEntities" @click="onRunScheduleForReportGroupClicked(item)">Run now</b-btn>
        <b-btn variant="outline-primary" size="sm" v-if="item.actions.canRunNewEntity" @click="onRunScheduleForEntityClicked(item)">Run now</b-btn>
        <b-btn variant="outline-danger" class="ml-2" size="sm" v-if="item.actions.canDeleteReport" @click="onDeleteWorkflowReportClicked(item)">Delete</b-btn>
      </template>
    </b-table>

    <run-schedule-for-report-group-dialog v-if="showRunScheduleForReportGroupDialog" :report-group="reportGroupSelected" :period="period" :entry="entrySelected" :show="showRunScheduleForReportGroupDialog" @ok="onRunScheduleForReportGroupOk" @close="onRunScheduleForReportGroupCancelled" @cancel="onRunScheduleForReportGroupCancelled" />
    <run-schedule-for-entity-dialog v-if="showRunScheduleForEntityDialog" :report-group="reportGroupSelected" :period="period" :entry="entrySelected" :show="showRunScheduleForEntityDialog" @ok="onShowRunScheduleForEntityOk" @close="onRunScheduleForEntityCancelled" @cancel="onRunScheduleForEntityCancelled" />
    <schedule-workflow-report-batch-distribution-dialog v-if="showScheduleWorkflowReportBatchDialog" :report-group="reportGroupSelected" :period="period" :entry="entrySelected" :show="showScheduleWorkflowReportBatchDialog" @ok="onScheduleWorkflowReportBatchOk" @close="onScheduleWorkflowReportBatchCancelled" @cancel="onScheduleWorkflowReportBatchCancelled" />
    <summary-delete-report-dialog v-if="showDeleteWorkflowReportDialog" :show="showDeleteWorkflowReportDialog" :title="`Delete report #${(entrySelected && entrySelected.links && entrySelected.links.reports && entrySelected.links.reports.reportId ? entrySelected.links.reports.reportId : null)}`" @ok="onDeleteWorkflowReportOk" @close="onDeleteWorkflowReportCancelled" @cancel="onDeleteWorkflowReportCancelled" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { SummaryPeriod, SummaryPeriodEntry } from '../../store/summary/state';
import { BvTableFieldArray, BLink } from 'bootstrap-vue';
import SummaryPeriodEntryStatusIcon from './summary-period-entry-status-icon.vue';
import { date } from '@/filters/date';
import { kebabcase } from '@/utilities/text.utils';
import { Location } from 'vue-router';
import { ReportGroup } from '../../store/tenant/state';
import { namespace } from 'vuex-class';
import RunScheduleForReportGroupDialog from './run-schedule-for-report-group-dialog.vue';
import RunScheduleForEntityDialog from './run-schedule-for-entity-dialog.vue';
import ScheduleWorkflowReportBatchDistributionDialog from './schedule-workflow-report-batch-distribution-dialog.vue';
import SummaryDeleteReportDialog from './summary-delete-report-dialog.vue';
import { Dictionary } from 'vue-router/types/router';
import { EntityScheduleRunResponse } from '../../api/responses/schedule/entity-schedule-run-response';
import { FindWorkflowResponse } from '../../api/responses/workflow/find-workflow-response';
import { VNode } from 'vue';

const tenantModule = namespace('tenant');

@Component({
  components: {
    SummaryPeriodEntryStatusIcon,
    RunScheduleForReportGroupDialog,
    RunScheduleForEntityDialog,
    ScheduleWorkflowReportBatchDistributionDialog,
    SummaryDeleteReportDialog,
  },
})
export default class SummaryPeriodTable extends Mixins(BvToastMixin) {
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;
  @Prop({ type: Object, required: true }) public period!: SummaryPeriod;

  private entrySelected: SummaryPeriodEntry | null = null;
  private reportGroupSelected: ReportGroup | null = null;
  private showRunScheduleForReportGroupDialog: boolean = false;
  private showScheduleWorkflowReportBatchDialog: boolean = false;
  private showRunScheduleForEntityDialog: boolean = false;
  private showDeleteWorkflowReportDialog: boolean = false;

  private get entries(): Array<SummaryPeriodEntry> {
    if (this.period === null) {
      return [];
    }

    return this.period.entries;
  }

  private get fields(): BvTableFieldArray {
    return [
      { key: 'status', label: '', tdClass: ['col-auto'] },
      { key: 'description', label: this.period.name, tdClass: ['col'] },
      { key: 'actions', label: '', tdClass: ['col-auto', 'text-nowrap', 'text-right'] }
    ];
  }

  private buildRoute(item: SummaryPeriodEntry): Location | null {
    if (item.links.reports !== null) {
      const tenant = this.$route.params.tenant;
      const page = 'workflow';
      const reportGroup = this.$route.params.reportGroup;
      const valuationDate = kebabcase(date(item.links.reports.displayDate, item.links.reports.dateFormat!));
      const option = kebabcase(item.links.reports.step) || null;

      const params: Dictionary<string> = { tenant, reportGroup, valuationDate };

      if (option !== null) {
        params.option = option;
      }

      if (item.links.reports.reportId !== null) {
        params.reportId = item.links.reports.reportId.toString();
      }
      return { name: page, params: params };
    }

    if (item.links.issues !== null) {
      const tenant = this.$route.params.tenant;
      const page = 'data';
      const reportGroup = this.$route.params.reportGroup;
      const valuationDate = kebabcase(date(item.links.issues.displayDate, item.links.issues.dateFormat));
      const tab = kebabcase(item.links.issues.type);

      return { name: page, params: { tenant, reportGroup, valuationDate, tab } };
    }

    return null;
  }

  private onRunScheduleForReportGroupClicked(item: SummaryPeriodEntry) {
    this.reportGroupSelected = this.currentReportGroup;
    this.entrySelected = item;
    this.showRunScheduleForReportGroupDialog = true;
  }

  private onRunScheduleForEntityClicked(item: SummaryPeriodEntry) {
    this.reportGroupSelected = this.currentReportGroup;
    this.entrySelected = item;
    this.showRunScheduleForEntityDialog = true;
  }

  private onScheduleWorkflowReportBatchClicked(item: SummaryPeriodEntry) {
    this.reportGroupSelected = this.currentReportGroup;
    this.entrySelected = item;
    this.showScheduleWorkflowReportBatchDialog = true;
  }

  private onDeleteWorkflowReportClicked(item: SummaryPeriodEntry) {
    this.reportGroupSelected = this.currentReportGroup;
    this.entrySelected = item;
    this.showDeleteWorkflowReportDialog = true;
  }

  private async onRunScheduleForReportGroupOk(result: { reportGroup: ReportGroup, useApprovalWorkflow: boolean, useStandardDistribution: boolean, systemReportsOnly?: boolean, newEntitiesOnly?: boolean }): Promise<void> {
    try {
      const response = await this.$store.dispatch('schedule/runScheduleForReportGroupAsync', { reportGroupId: result.reportGroup.id, valuationDate: this.period.valuationDate, useApprovalWorkflow: result.useApprovalWorkflow, useStandardDistribution: result.useStandardDistribution, systemReportsOnly: result.systemReportsOnly, newEntitiesOnly: result.newEntitiesOnly }) as EntityScheduleRunResponse;

      this.showToast(
        `${response.message}`,
        response.requestCount < 1 ? 'notice-danger' : 'notice-success');
    } catch (e) {
      this.showErrorToast(`Could not run schedule for report group ${result.reportGroup.name}. Please try again.`);
    }

    this.onRunScheduleForReportGroupCancelled();
  }

  private async onShowRunScheduleForEntityOk(result: { reportGroup: ReportGroup, entry: SummaryPeriodEntry, useApprovalWorkflow: boolean, useStandardDistribution: boolean }): Promise<void> {
    try {
      if (result.entry.schedule === null) {
        throw new Error(`Cannot run schedule for ${result.entry.description}.`);
      }

      const response = await this.$store.dispatch('schedule/runEntityScheduleForRegionAsync', {
        entityId: result.entry.schedule.entityId,
        subEntityId: result.entry.schedule.subEntityId,
        regionId: result.entry.schedule.regionId,
        reportGroupId: result.reportGroup.id,
        valuationDate: this.period.valuationDate,
        useApprovalWorkflow: result.useApprovalWorkflow,
        useStandardDistribution: result.useStandardDistribution,
      }) as EntityScheduleRunResponse;

      const message = response.message;
      const variant = response.requestCount < 1 ? 'notice-danger' : 'notice-success';
      let content: string | VNode = message;

      if (response.workflowReportRequestId !== null) {
        const id = response.workflowReportRequestId.toString();
        const workflow = await this.$store.dispatch('workflow/findWorkflowReportAsync', id) as FindWorkflowResponse;

        const h = this.$createElement;

        content = h('div', { class: ['row'] }, [
          h('div', { class: ['toast-body-message', 'col'] }, message),
          h(BLink, { class: ['toast-body-link', 'col-auto'], props: { to: this.buildReportRequestRawLocation(id, workflow) } }, 'view')
        ]);
      }

      this.showToast(content, variant);
    } catch (e) {
      this.showErrorToast(`Could not run schedule for entity '${result.entry.description}'. Please try again.`);
    }

    this.onRunScheduleForEntityCancelled();
  }

  private async onScheduleWorkflowReportBatchOk(result: { reportGroup: ReportGroup, runAt: string, batchId: number }): Promise<void> {
    try {
      await this.$store.dispatch('workflow/scheduleWorkflowReportBatchDistribution', {
        reportGroupId: result.reportGroup.id,
        valuationDate: this.period.valuationDate,
        runAt: result.runAt,
        batchId: result.batchId,
      });

      await this.$store.dispatch('summary/getSummaryForReportGroupAsync', this.currentReportGroup!.id);
    } catch (e) {
      this.showErrorToast(`Could not schedule distribution for report group ${result.reportGroup.name}. Please try again.`);
    } finally {
      this.onScheduleWorkflowReportBatchCancelled();
    }
  }

  private async onDeleteWorkflowReportOk(result: { comments: string | null }): Promise<void> {
    try {
      if (this.entrySelected == null || this.entrySelected.links.reports == null || this.entrySelected.links.reports.reportId === null) {
        throw new Error('Cannot delete workflow report. No report id found in links');
      }
      await this.$store.dispatch('workflow/deleteWorkflowReportAsync', {
        workflowReportId: this.entrySelected!.links.reports!.reportId,
        reportGroupId: this.currentReportGroup!.id,
        comments: result.comments,
      });

      await this.$store.dispatch('summary/getSummaryForReportGroupAsync', this.currentReportGroup!.id);
    } catch (e) {
      this.showErrorToast(`Could not delete worfkflow report for report group ${this.currentReportGroup!.name}. Please try again.`);
    } finally {
      this.onDeleteWorkflowReportCancelled();
    }
  }

  private onRunScheduleForReportGroupCancelled(): void {
    this.showRunScheduleForReportGroupDialog = false;
    this.showRunScheduleForEntityDialog = false;
    this.showDeleteWorkflowReportDialog = false;
    this.reportGroupSelected = null;
    this.entrySelected = null;
  }

  private onRunScheduleForEntityCancelled(): void {
    this.showScheduleWorkflowReportBatchDialog = false;
    this.showRunScheduleForEntityDialog = false;
    this.showDeleteWorkflowReportDialog = false;
    this.reportGroupSelected = null;
    this.entrySelected = null;
  }

  private onScheduleWorkflowReportBatchCancelled(): void {
    this.showScheduleWorkflowReportBatchDialog = false;
    this.showRunScheduleForEntityDialog = false;
    this.showDeleteWorkflowReportDialog = false;
    this.reportGroupSelected = null;
    this.entrySelected = null;
  }

  private onDeleteWorkflowReportCancelled(): void {
    this.showScheduleWorkflowReportBatchDialog = false;
    this.showRunScheduleForEntityDialog = false;
    this.showDeleteWorkflowReportDialog = false;
    this.reportGroupSelected = null;
    this.entrySelected = null;
  }

  private buildReportRequestRawLocation(id: string, workflow: FindWorkflowResponse): Location | null {
    if (id === null) {
      return null;
    }

    const reportGroupName = this.currentReportGroup.name;
    const d = kebabcase(date(workflow.displayDate, workflow.dateFormat));
    const option = kebabcase(workflow.state);
    const reportGroup = kebabcase(reportGroupName);

    return { name: 'workflow', params: { ...this.$route.params, reportGroup, valuationDate: d, option, reportId: id } };
  }
}
</script>

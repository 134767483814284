<template>
  <div class="data-issues" :aria-busy="loading">
    <b-table ref="table"
             striped
             hover
             :sticky-header="headerStyle"
             show-empty
             sort-icon-left
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :busy="loading"
             :fields="fields"
             :items="filteredResults"
    >
      <!-- <template v-slot:thead-top>
    <b-tr>
      <b-th :colspan="fields.length - 3"></b-th>
      <b-th class="ml-2" colspan="3">Last Data Load</b-th>
    </b-tr>
  </template> -->

      <template v-slot:head(name)="data">
        <span>{{ data.label }}<b-button title="Clear filter" size="sm" variant="link" v-if="reportFilter" @click.stop="onReportFilterClear">(clear filter)</b-button></span>
        <span @click.stop="onShowFilter">
          <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
            <g data-v-11c9e491="">
              <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
            </g>
          </svg>
        </span>
        <b-form-input class="mt-3" v-if="showFilter" v-model="reportFilter" />
      </template>

      <template v-slot:head(fileType)="data">
        <span>{{ data.label }}<b-button title="Clear filter" size="sm" variant="link" v-if="fileTypeFilter" @click.stop="onFileTypeFilterClear">(clear filter)</b-button></span>
        <span @click.stop="onShowFilter">
          <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
            <g data-v-11c9e491="">
              <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
            </g>
          </svg>
        </span>
        <b-form-input class="mt-3" v-if="showFilter" v-model="fileTypeFilter" />
      </template>

      <template v-slot:head(fileName)="data">
        <span>{{ data.label }}<b-button title="Clear filter" size="sm" variant="link" v-if="nameFilter" @click.stop="onNameFilterClear">(clear filter)</b-button></span>
        <span @click.stop="onShowFilter">
          <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
            <g data-v-11c9e491="">
              <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
            </g>
          </svg>
        </span>
        <b-form-input class="mt-3" v-if="showFilter" v-model="nameFilter" />
      </template>

      <template v-slot:cell(status)="{ item }">
        <data-file-status-icon :item="item" />
      </template>

      <template v-slot:cell(fileName)="{ item }">
        <span v-if="item.fileName !== '[Document Library]'">{{ item.fileName }}</span>
        <span v-else>
          <router-link class="text-decoration-underline" :to="{ name: 'document-library', params: { ...$route.params, reportGroup: 'data-files', documentId: item.links.document.documentId } }">{{ item.fileName }}</router-link>
        </span>
      </template>

      <template v-slot:cell(mandatory)="{ item }">
        <b-checkbox :disabled="true" v-model="item.mandatory" />
      </template>

      <template v-slot:cell(businessReminderDay)="{ item }">
        <span v-if="item.reminder">{{ item.reminder.businessDay }}</span>
      </template>

      <template v-slot:cell(businessReminderEmail)="{ item }">
        <span v-if="item.reminder">{{ item.reminder.businessEmail }}</span>
      </template>

      <template v-slot:cell(dataLoad.id)="{ item }">
        <b-btn variant="link" v-if="item.dataLoad !== null" @click="$root.$emit('datafile-dataload-clicked', item.dataLoad.id)">{{ item.dataLoad.id }}</b-btn>
      </template>

      <template v-slot:cell(dataLoad.status)="{ item }">
        {{ (item.dataLoad !== null ? (item.dataLoad.status === "WaitingForOtherFiles" ? "Waiting for other files" : item.dataLoad.status) : null) | string }}
      </template>

      <template v-slot:cell(dataLoad.timestamp)="{ item }">
        {{ (item.dataLoad !== null ? item.dataLoad.timestamp : null) | datetime }}
      </template>
      <template v-slot:cell(actions)="{ item }">
        <b-button-group>
          <b-button size="sm" variant="outline-primary" :disabled="!item.dataLoad" :title="getDownloadFileTooltip(item)" @click="onDownloadDataLoadClicked(item)">
            <b-icon icon="download" aria-hidden="true"></b-icon>
          </b-button>
          <b-dropdown lazy :key="`${item.id}_actions_${item.actions}`" text="Actions" :no-caret="true" variant="outline-primary" size="sm" right :popper-opts="{ modifiers: { computeStyle: { gpuAcceleration: false } } }">
            <template v-slot:button-content>
              <div class="three-dots-vertical" aria-hidden="true">
                <b-icon icon="three-dots-vertical" aria-hidden="true" />
              </div>
            </template>
            <b-dropdown-item @click="onReplaceDataFileReminderDetailsClicked(item)">Edit reminder settings</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item v-if="!item.mandatory" @click="onDataFileMandatoryClicked(item)">Make mandatory</b-dropdown-item>
            <b-dropdown-item v-if="item.mandatory" @click="onDataFileMandatoryClicked(item)">Make optional</b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </template>
    </b-table>

    <data-file-mandatory-confirmation-dialog :title="`Make '${(actionItem !== null ? actionItem.name : null)}' ${(actionItem !== null && actionItem.mandatory ? 'optional' : 'mandatory')}?`" :item="actionItem" :show="showDataFileMandatoryConfirmation" @ok="onDataFileMandatoryConfirmed" @cancel="onDataFileMandatoryCancelled" @close="onDataFileMandatoryCancelled" />
    <replace-data-file-reminder-details-dialog v-if="actionItem" :item="actionItem" :show="showReplaceDataFileReminderDetailsDialog" @ok="onReplaceDataFileReminderDetailsConfirmed" @cancel="onReplaceDataFileReminderDetailsCancelled" @close="onReplaceDataFileReminderDetailsCancelled" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { Tenant } from '../../store/tenant/state';
import throttle from 'lodash.throttle';
import { Data, DataFile } from '../../store/data/state';
import DataFileStatusIcon from './data-file-status-icon.vue';
import { DateTime } from 'luxon';
import { parseFilters, applyFilters } from '@/components/table/input-filter';
import DataFileMandatoryConfirmationDialog from '@/components/data/datafile-mandatory-confirmation-dialog.vue';
import ReplaceDataFileReminderDetailsDialog from '@/components/data/replace-data-file-reminder-details-dialog.vue';
import { FileUtils } from '@/utilities/file.utils';

const tenantModule = namespace('tenant');
const dataModule = namespace('data');
const environmentModule = namespace('environment');

@Component({
  components: {
    DataFileStatusIcon,
    DataFileMandatoryConfirmationDialog,
    ReplaceDataFileReminderDetailsDialog,
  },
})
export default class DataFilesTable extends Mixins(BvToastMixin) {
  @Prop({ type: Boolean, required: true }) public loading!: boolean;
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @tenantModule.Getter private selectedReportGroup!: Tenant;
  @environmentModule.Getter('current') private environment!: { environment: string };
  @dataModule.Getter('dataFiles') private files!: Array<DataFile>;
  @dataModule.Getter private current!: Data;

  private sortBy: string | null = null;
  private sortDesc: boolean = false;
  private selectedRowIndex: number = -1;
  private preventScrollLoading: boolean = false;
  private showFilter: boolean = false;
  private reportFilter: string | null = null;
  private nameFilter: string | null = null;
  private fileTypeFilter: string | null = null;
  private showDataFileMandatoryConfirmation: boolean = false;
  private showReplaceDataFileReminderDetailsDialog: boolean = false;
  private actionItem: DataFile | null = null;

  public get filteredResults(): Array<DataFile> {
    let files = this.files;

    if (files == null || files.length < 1) {
      return [];
    }

    if (!this.reportFilter && !this.nameFilter && !this.fileTypeFilter) {
      return files;
    }

    if (this.nameFilter) {
      const fileNameFilters = parseFilters(this.nameFilter);
      files = files.filter((r) => applyFilters(r, x => x.fileName, fileNameFilters, { mode: 'include' }));
    }

    if (this.reportFilter) {
      const nameFilters = parseFilters(this.reportFilter);
      files = files.filter((r) => applyFilters(r, x => x.name, nameFilters, { mode: 'include' }));
    }

    if (this.fileTypeFilter) {
      const fileTypeFilters = parseFilters(this.fileTypeFilter);
      files = files.filter((r) => applyFilters(r, x => x.fileType, fileTypeFilters));
    }

    return files;
  }

  private get fields(): Array<{}> {
    const fields = [
      // {  key: 'id', label: 'ID', class: ['col-1'], sortable: true },
      { key: 'status', label: '', class: ['col-auto'], sortable: false },
      { key: 'name', label: 'Name', class: ['col-2'], sortable: true },
      { key: 'fileType', label: 'File Type', class: ['col-auto'], sortable: false },
      { key: 'fileName', label: 'File Name', class: ['col-auto'], sortable: false },
      { key: 'mandatory', label: 'Mandatory', class: ['col-auto'], tdClass: ['text-center'], sortable: true },
      { key: 'reminder.businessDay', label: 'Reminder Business Day', class: ['col-auto'], tdClass: ['text-center'], sortable: true },
      { key: 'reminder.email', label: 'Reminder Email Address', class: ['col-auto'], sortable: false },
      { key: 'filesReceived', label: 'Files Received', class: ['col-1', 'text-center'], tdClass: ['text-center'], sortable: false },
      { key: 'dataLoad.id', label: 'Last Data Load ID', class: ['col-1', 'text-center'], tdClass: ['text-center'], sortable: true },
      { key: 'dataLoad.timestamp', label: 'Last Data Load Date', class: ['col-1'], sortable: true, formatter: (value: string | null) => { if (value == null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); }, sortByFormatted: true },
      { key: 'dataLoad.status', label: 'Last Data Load Status', class: ['col-1'], sortable: false }
      // { key: 'businessReminderEmail', label: 'Reminder business email', class: ['col-auto'], sortable: true }
      // { key: 'actions', label: '', class: ['col-auto'], sortable: false }
    ];

    if (this.current.actions.canEditDataFiles) {
      fields.push({ key: 'actions', label: '', class: ['col-auto'], sortable: false });
    }

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  public async mounted(): Promise<void> {
    let lastScrollPos: number = 0;
    ((this.$refs.table as Vue).$el as HTMLDivElement).addEventListener('scroll', throttle((e) => {
      const element = ((this.$refs.table as Vue).$el as HTMLDivElement);
      const offsetHeight = element.offsetHeight;
      const scrolled = element.scrollTop;
      const height = element.scrollHeight;

      // NOTE(Dan): We're scrolling back up the list from the bottom, so we don't want to trigger here...
      if (lastScrollPos >= scrolled) {
        lastScrollPos = scrolled;
        return;
      }

      lastScrollPos = scrolled;

      if (offsetHeight + scrolled >= (height * 0.9)) {
        this.$emit('scroll-completed', { height, offsetHeight, scrolled });
      }
    }, 1000));
  }

  private onShowFilter(): void {
    this.showFilter = !this.showFilter;
  }

  private getDownloadFileTooltip(item: DataFile) {
    const lastDownloadFileName = item.dataLoad?.fileName;

    if (lastDownloadFileName) {
      return `Download ${this.getFileNameWithoutExtension(lastDownloadFileName)}`;
    }

    return 'Download';
  }

  private getFileNameWithoutExtension(fileName: string) {
    // const index = fileName.lastIndexOf('.');
    const index1 = fileName.lastIndexOf('\\');
    if (index1 > -1) {
      fileName = fileName.substring(index1 + 1);
    }

    return fileName;
  }

  private async onDownloadDataLoadClicked(item: DataFile): Promise<void> {
    try {
      const lastDownloadFileName = item.dataLoad?.fileName;
      let fileName = '';
      if (lastDownloadFileName) {
        fileName = this.getFileNameWithoutExtension(lastDownloadFileName);
      }
      const data = await this.$store.dispatch('data/downloadDataLoadAsync', { dataLoadId: item.dataLoad!.id, reportGroupId: this.selectedReportGroup!.id }) as Blob;
      FileUtils.downloadFile(data, fileName);
    } catch (e) {
      this.showErrorToast('Could not download file. Please try again.');
    }
  }

  @Watch('sortDesc')
  private onSortDescChanged(to: boolean, from: boolean) {
    if (!to && from) {
      this.sortBy = null;
    }
  }

  private onReportFilterClear(): void {
    this.reportFilter = null;
    this.showFilter = false;
  }

  private onNameFilterClear(): void {
    this.nameFilter = null;
    this.showFilter = false;
  }

  private onfileTypeFilterClear(): void {
    this.fileTypeFilter = null;
    this.showFilter = false;
  }

  private onDataFileMandatoryClicked(item: DataFile): void {
    this.actionItem = item;
    this.showDataFileMandatoryConfirmation = true;
  }

  private onDataFileMandatoryCancelled(): void {
    this.showDataFileMandatoryConfirmation = false;
    this.actionItem = null;
  }

  private async onDataFileMandatoryConfirmed(): Promise<void> {
    try {
      const isMandatory = this.actionItem!.mandatory;
      const action = isMandatory ? 'data/makeDataFileOptionalForReportGroupAsync' : 'data/makeDataFileMandatoryForReportGroupAsync';

      await this.$store.dispatch(action, { dataFileId: this.actionItem!.id, reportGroupId: this.selectedReportGroup!.id });

      this.actionItem!.mandatory = !isMandatory;
    } catch (e) {
      this.showErrorToast(`Could make data file '${this.actionItem!.name}' as ${this.actionItem!.mandatory ? 'optional' : 'mandatory'}. Please try again.`);
    }

    this.onDataFileMandatoryCancelled();
  }

  private onReplaceDataFileReminderDetailsClicked(item: DataFile): void {
    this.actionItem = item;
    this.showReplaceDataFileReminderDetailsDialog = true;
  }

  private onReplaceDataFileReminderDetailsCancelled(): void {
    this.showReplaceDataFileReminderDetailsDialog = false;
    this.actionItem = null;
  }

  private async onReplaceDataFileReminderDetailsConfirmed(result: { businessDay: number | null, email: string | null }): Promise<void> {
    try {
      await this.$store.dispatch('data/replaceDataFileReminderDetailsAsync', { dataFileId: this.actionItem!.id, businessDay: result.businessDay, email: result.email, reportGroupId: this.selectedReportGroup!.id });

      if (this.actionItem!.reminder === null) {
        this.actionItem!.reminder = { businessDay: result.businessDay, email: result.email };
      } else {
        this.actionItem!.reminder!.businessDay = result.businessDay;
        this.actionItem!.reminder!.email = result.email;
      }
    } catch (e) {
      this.showErrorToast(`Could update data file '${this.actionItem!.name}' reminder settings. Please try again.`);
    }

    this.onReplaceDataFileReminderDetailsCancelled();
  }
}
</script>

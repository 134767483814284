<template>
  <div class="support-ticket-list">
    <b-tabs v-if="current !== null" class="support-ticket-tabs" v-model="currentTabIndex" @input="currentTabChanged" @changed="onTabsChanged" @activate-tab="onTabActivated" :aria-busy="isLoading">
      <b-tab v-for="option in status" :key="`${option.id}-${kebabcase(option.name)}`" :title-link-attributes="{ href: buildTabUrl(option) }" :loading="isLoading" :title="`${option.name} (${option.count})`">
        <support-ticket-table :status="option" :loading="isLoading" @ticket-actioned="onTicketActioned" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SupportConfiguration, Status, SupportTicket } from '@/store/support/state';
import { kebabcase } from '@/utilities/text.utils';
import { BTab } from 'bootstrap-vue';
import SupportTicketTable from './support-ticket-table.vue';
import { Tenant } from '@/store/tenant/state';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';

const supportModule = namespace('support');
const tenantModule = namespace('tenant');
const DEFAULT_PAGE_SIZE: number = 250;

@Component({
  components: {
    SupportTicketTable,
  },
})
export default class SupportTicketList extends Vue {
  @tenantModule.Getter('current') currentTenant!: Tenant;
  @supportModule.Getter current!: SupportConfiguration | null;
  @supportModule.Getter status!: Array<Status>;
  @supportModule.Getter tickets!: Array<SupportTicket>;
  @supportModule.Getter currentStatus!: Status | null;

  tabIndex = -1;
  isLoading = true;
  isTenantChangedOnOtherTab = false;
  supportTicketFound = false;

  created() {
    this.$root.$on('support-ticket-found', this.onSupportTicketFound);
  }

  beforeDestroy() {
    this.$root.$off('support-ticket-found', this.onSupportTicketFound);
  }

  kebabcase = kebabcase;

  @Watch('currentTenant')
  async onTenantChanged(tenant: TenantResponse): Promise<void> {
    this.isTenantChangedOnOtherTab = false;
    if (this.currentTabIndex !== 0 && !this.supportTicketFound) {
      this.currentTabIndex = 0;
      this.isTenantChangedOnOtherTab = true;
    }
    this.supportTicketFound = false;
  }

  get currentOption(): Status | null {
    return this.currentStatus;
  }

  get currentTabIndex(): number {
    return this.tabIndex;
  }

  set currentTabIndex(index: number) {
    this.$store.commit('support/setCurrentStatus', index);
    this.tabIndex = index;
  }

  async currentTabChanged(index: number): Promise<void> {
    this.isLoading = true;

    const status = this.status[index];

    if (status === undefined) {
      return;
    }

    try {
      await this.$store.dispatch('support/getSupportTicketsAsync', { status: status.id, page: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (e) {
    }
  }

  async onTabActivated(newIndex: number, oldIndex: number): Promise<void> {
    const match = this.status[newIndex];

    if (match === undefined) {
      return;
    }

    this.isLoading = true;

    if (this.$route.params.tab === undefined || this.$route.params.status !== kebabcase(match.name)) {
      try {
        await this.$router.push({ name: 'support', params: { ...this.$route.params, status: kebabcase(match.name) } });
      } catch (e) {
      }
    }
  }

  async onTabsChanged(newTabs: Array<BTab>, oldTabs: Array<BTab>): Promise<void> {
    if (this.$route.params.status !== undefined) {
      const index = this.status.findIndex((s) => kebabcase(s.name) === this.$route.params.status);

      this.currentTabIndex = index;
    } else {
      await this.setInitialTabSelection();
    }
  }

  async setInitialTabSelection(): Promise<void> {
    this.isLoading = true;
    const status = this.status;

    if (status.length < 1) {
      return;
    }

    if (this.$route.params.status === undefined || this.$route.params.status !== kebabcase(this.status[0].name)) {
      try {
        await this.$router.push({ name: 'support', params: { ...this.$route.params, status: kebabcase(this.status[0].name) } });
      } catch (e) {
      }
    }
  }

  buildTabUrl(option: Status): string {
    if (this.current === null) {
      return '';
    }

    return `/support/${kebabcase(option.name)}`;
  }

  @Watch('tickets')
  onTicketsChanged(tickets: Array<SupportTicket>): void {
    if (this.isLoading) {
      this.isLoading = false;
    }
  }

  @Watch('current')
  async onCurrentChanged(): Promise<void> {
    if (this.currentOption === null || this.isTenantChangedOnOtherTab) {
      //  On Tenant changed from other tab, the tickets refresh is already being handled by CurrentTabChanged() when setting currentTabIndex to '0'
      this.isTenantChangedOnOtherTab = false;
      return;
    }
    await this.$store.dispatch('support/getSupportTicketsAsync', { status: this.currentOption!.id, page: 1, pageSize: DEFAULT_PAGE_SIZE });
  }

  async onTicketActioned(ticket: SupportTicket): Promise<void> {
    this.isLoading = true;
    this.isTenantChangedOnOtherTab = false;
    await this.$store.dispatch('support/getSupportAsync');
  }

  async onSupportTicketFound(detail: { id: string | null, tabIndex: number }): Promise<void> {
    this.supportTicketFound = true;
    this.currentTabIndex = detail.tabIndex;
    this.$root.$emit('filter-support-ticket-found', detail.id);
  }
}
</script>

import { WorkflowReportCommentaryReacquired } from '../events/workflow-report-commentary-reacquired';
import store from '@/store';

async function handleWorkflowReportCommentaryReacquired(data: WorkflowReportCommentaryReacquired): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportCommentaryReacquired event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  if (data.payload.fromStateId !== data.payload.toStateId) {
    store.commit('workflow/tryRemoveReportFromPage', reportId);
    await store.dispatch('workflow/tryMoveReportToPage', { ...data.payload, reportId });
    await store.dispatch('workflow/tryUpdateOptionCounts', data.payload);
    await store.dispatch('notification/tryUpdateWorkflowReportLinks', { ...data.payload, reportId });
  }
}

export default handleWorkflowReportCommentaryReacquired;

import { WorkflowReportCreated } from '../events/workflow-report-created';
import store from '@/store';
import { Notification } from '@/store/notification/state';
import { kebabcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';
import { Location } from 'vue-router';
import router from '@/router';

async function handleWorkflowReportCreated(data: WorkflowReportCreated): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportCreated event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */

  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryMoveReportToPage', { ...data.payload, fromStateId: -1, toStateId: 1, reportId });
  await store.dispatch('workflow/tryUpdateOptionCounts', { ...data.payload, fromStateId: -1, toStateId: 1, reportId });
  // delay dashboard and summary screen updates for 10 seconds
  setTimeout(async () => {
    await store.dispatch('dashboard/tryUpdateOrReloadReportGroupForValuationDate', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
    await store.dispatch('summary/tryUpdateOrReloadSummary', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
  }, 10000);

  const notification: Notification = {
    message: buildReportRequestNotificationMessage(reportId, data),
    read: false,
    timestamp: data.timestamp,
    variant: 'success',
    link: buildReportRequestRawLocation(reportId, data),
    metadata: {
      count: 1,
      correlationId: data.correlationId,
      reportGroup: data.payload.reportGroup,
      dateFormat: data.payload.dateFormat,
      displayDate: data.payload.displayDate,
      action: 'created',
    },
  };

  store.commit('notification/append', notification);

  // when report created for a new Valuation Date that does not currently exist in the Workflow Valuation Date dropdown
  await store.dispatch('workflow/tryUpdateValuationDates', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
}

function buildReportRequestNotificationMessage(id: number, data: WorkflowReportCreated): string {
  let message = '';

  if (data.payload.name !== null) {
    message = `A new report '${data.payload.name}' has been created (${id}) `;
  } else {
    message = `A new report has been created (${id}) `;
  }

  if (data.payload.reportGroup !== null) {
    message += `for report group '${data.payload.reportGroup}' `;
  }

  if (data.payload.dateFormat !== null) {
    message += `and valuation date '${date(data.payload.displayDate, data.payload.dateFormat!)}'`;
  }

  return message.trim() + '.';
}

function buildReportRequestRawLocation(id: number, data: WorkflowReportCreated): Location | null {
  if (data === null) {
    return null;
  }

  const reportGroupName = data.payload.reportGroup;

  if (reportGroupName === null) {
    return null;
  }

  if (data.payload.dateFormat === null) {
    return null;
  }

  const d = kebabcase(date(data.payload.displayDate, data.payload.dateFormat));
  const option = 'waiting';
  const reportGroup = kebabcase(reportGroupName);

  return { name: 'workflow', params: { ...router.currentRoute.params, reportGroup, valuationDate: d, option, reportId: id.toString() } };
}

export default handleWorkflowReportCreated;

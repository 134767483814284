<template>
  <div>
    <filter-bar />
    <div class="container-fluid">
      <div class="row mt-4">
        <div class="col-12">
          <!-- <data--table /> -->
          <data-content />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import FilterBar from '@/components/data/filter-bar.vue';
import DataContent from '@/components/data/data-content.vue';

@Component({
  components: {
    FilterBar,
    DataContent,
  },
})
export default class Data extends Vue {
}
</script>

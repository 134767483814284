import { GetterTree } from 'vuex';
import { State } from '@/store/notification/state';
import { DateTime } from 'luxon';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public notifications = (state: State) => {
    return state.notifications.sort((a, b) => {
      return DateTime.fromISO(a.timestamp).valueOf() - DateTime.fromISO(b.timestamp).valueOf();
    });
  }
}

import { DateTime } from 'luxon';

export function datetime(value: string | Date | null): string {
  if (value === null || value === undefined) {
    return '';
  }

  let date: DateTime | null = null;

  if (value instanceof Date) {
    date = DateTime.fromJSDate(value);
  } else {
    date = DateTime.fromISO(value);
  }

  if (date == null) {
    return '';
  }

  return date.toLocaleString(DateTime.DATETIME_SHORT)!;
}

import { ActionTree, ActionContext } from 'vuex';
import { State } from '@/store/summary/state';
import { SummaryApi } from '@/api/summary-api';
import { Dictionary } from 'vue-router/types/router';

type SummaryState = { tenant: string, reportGroup: string };

const api = new SummaryApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public getSummaryForReportGroupAsync = async (context: ActionContext<State, any>, reportGroupId: number) => {
    try {
      const result = await api.getSummaryForReportGroupAsync(reportGroupId);

      context.commit('setCurrent', result);
    } catch (e) {
    }
  }

  public getStatisticsForValuationDateAsync = async (context: ActionContext<State, any>, payload: { valuationDate: string, reportGroupId: number }) => {
    const result = await api.getStatisticsForValuationDateAsync(payload.valuationDate, payload.reportGroupId);

    return result;
  }

  public tryUpdateOrReloadSummary = async (context: ActionContext<State, any>, data: { reportGroupId: number, valuationDate: string }) => {
    if (context.state.current === null) {
      return;
    }

    if (context.state.fromValuationDate !== null && context.state.fromValuationDate !== data.valuationDate && context.state.toValuationDate !== null && context.state.toValuationDate !== data.valuationDate) {
      return;
    }

    const result = await api.getSummaryForReportGroupAsync(data.reportGroupId);
    const fromValuationDate = context.getters.fromValuationDate;
    const toValuationDate = context.getters.toValuationDate;

    context.commit('setCurrent', result);
    context.commit('setValuationDateFrom', fromValuationDate);
    context.commit('setValuationDateTo', toValuationDate);

    // TODO(Dan): Decide what we need to reload.

    // const result = await api.getDashboardReportGroupForValuationDateAsync(data.reportGroupId, data.valuationDate);

    // context.commit('replaceReportGroupForValuationDate', result);
  }

  public applyStateFromRoute = async (context: ActionContext<State, any>, data: SummaryState): Promise<Dictionary<string>> => {
    /* eslint-disable */
    console.log('summary/applyStateFromRoute', data);
    /* eslint-enable */
    return {};
  }
}

<template>
  <b-table class="schedule-request-table"
           striped
           hover
           :sticky-header="headerStyle"
           :fields="fields"
           :items="filteredResults"
           :busy="loading"
           :tbody-tr-class="applyTableRowClasses"
           sort-icon-left
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
  >
    <template v-slot:cell(fileName)="{ item }">
      <b-button size="sm" variant="outline-primary" v-if="item.fileName" :title="getDownloadFileTooltip(item)" @click="onDownloadClicked(item)">
        <b-icon icon="download" aria-hidden="true" v-if="getFileType(item) === null"></b-icon>
        <download-pdf-icon aria-hidden="true" v-if="getFileType(item) === 'pdf'"></download-pdf-icon>
        <view-excel-icon aria-hidden="true" v-if="getFileType(item) === 'excel'"></view-excel-icon>
        <view-powerpoint-icon aria-hidden="true" v-if="getFileType(item) === 'powerpoint'"></view-powerpoint-icon>
        <view-word-icon aria-hidden="true" v-if="getFileType(item) === 'word'"></view-word-icon>
      </b-button>
    </template>
  </b-table>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { ReportGroup } from '@/store/tenant/state';
import { EntityScheduleCompletedRequest } from '../../store/schedule/state';
import DownloadPdfIcon from '@/components/icons/download-pdf-icon.vue';
import ViewExcelIcon from '@/components/icons/view-excel-icon.vue';
import ViewWordIcon from '@/components/icons/view-word-icon.vue';
import ViewPowerpointIcon from '@/components/icons/view-powerpoint-icon.vue';
import { FileUtils } from '@/utilities/file.utils';

const tenantModule = namespace('tenant');
const environmentModule = namespace('environment');

@Component({
  components: {
    DownloadPdfIcon,
    ViewExcelIcon,
    ViewWordIcon,
    ViewPowerpointIcon,
  },
})
export default class RequestTable extends Mixins(BvToastMixin) {
  sortBy: string | null = null;
  sortDesc: string | null = null;
  @Prop({ type: Array, required: true }) requests!: Array<EntityScheduleCompletedRequest>;
  @Prop({ type: Boolean, required: true }) loading!: boolean;
  @environmentModule.Getter('current') environment!: { environment: string, version: string | null, runtime: string } | null;
  @tenantModule.Getter selectedReportGroup!: ReportGroup;

  get fields(): Array<{ key: string, label: string, class?: Array<string> | string, sortable?: boolean, formatter?: Function }> {
    return [
      { key: 'reportId', label: 'ID', class: ['col-auto'] },
      { key: 'name', label: 'Name', class: ['w-100'] },
      { key: 'fileName', label: '', class: ['col-auto'] }
      // { key: 'timestamp', label: 'Date', class: ['col-3'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); } }
    ];
  }

  applyTableRowClasses(item: EntityScheduleCompletedRequest, type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy') {
    if (item !== null && type !== 'table-busy') {
      return { replaced: item.replaced };
    }
  }

  get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  get filteredResults(): Array<EntityScheduleCompletedRequest> {
    if (this.requests == null || this.requests.length < 1) {
      return [];
    }

    return this.requests;
  }

  getFileType(item: EntityScheduleCompletedRequest) {
    if (item.fileName?.toLowerCase().endsWith('.xlsx')) {
      return 'excel';
    }

    if (item.fileName?.toLowerCase().endsWith('.pdf')) {
      return 'pdf';
    }

    if (item.fileName?.toLowerCase().endsWith('.docx')) {
      return 'word';
    }

    if (item.fileName?.toLowerCase().endsWith('.pptx')) {
      return 'powerpoint';
    }

    return null;
  }

  getDownloadFileTooltip(item: EntityScheduleCompletedRequest) {
    if (item.fileName?.toLowerCase().endsWith('.xlsx')) {
      return `Download XLSX
${this.getFileNameWithoutExtension(item.fileName)}`;
    }

    if (item.fileName?.toLowerCase().endsWith('.pdf')) {
      return `Download PDF
${this.getFileNameWithoutExtension(item.fileName)}`;
    }

    return 'Download';
  }

  getFileNameWithoutExtension(fileName: string) {
    const index = fileName.lastIndexOf('.');
    if (index > -1) {
      fileName = fileName.substring(0, index);
    }

    return fileName;
  }

  async onDownloadClicked(item: EntityScheduleCompletedRequest): Promise<void> {
    try {
      const data = await this.$store.dispatch('workflow/downloadWorkflowReportAsync', { workflowReportId: item.reportId, reportGroupId: this.selectedReportGroup!.id, isPreview: false }) as Blob;
      FileUtils.downloadFile(data, item.fileName!);
    } catch (e) {
      this.showErrorToast('Could not download file. Please try again.');
    }
  }
}
</script>

<template>
  <div class="document-library-filter-bar row">
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0 pt-1 pb-1">Report group:</label>
        <div class="col-auto pt-1 pb-1">
          <b-form-select v-model="selectedReportGroup" @change="changeReportGroup(reportGroup)">
            <b-form-select-option :value="group" v-for="group in reportGroups" :key="group.id">{{ group.name }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row align-items-center">
        <div class="col-auto pr-0 pt-1 pb-1">
          <entity-selector v-if="currentDocumentLibrary!== null && currentDocumentLibrary.searchType !== 'DataFiles' && currentDocumentLibrary.searchType !== 'StaticDocuments'" />
          <document-type-selector v-if="currentDocumentLibrary!== null && currentDocumentLibrary.searchType === 'StaticDocuments'" />
        </div>
        <div class="col-auto pr-0 pt-1 pb-1">
          <language-selector v-if="currentDocumentLibrary!== null && ((currentDocumentLibrary.searchType === 'StaticDocuments' && currentDocumentLibrary.regions.length > 0) || (currentDocumentLibrary.searchType === 'LanguageReports' && currentDocumentLibrary.languages.length > 0))" />
          <region-selector v-if="currentDocumentLibrary!== null && currentDocumentLibrary.searchType === 'RegionReports' && currentDocumentLibrary.regions.length > 0" />
          <commentary-type-selector v-if="currentDocumentLibrary!== null && currentDocumentLibrary.searchType === 'Commentary'" />
          <insert-type-selector v-if="currentDocumentLibrary!== null && currentDocumentLibrary.searchType === 'ManualInserts'" />
        </div>
        <!-- </div>
        <div class="row align-items-center pt-3"> -->
        <div class="col-auto pr-0 pt-1 pb-1">
          <region-selector v-if="currentDocumentLibrary!== null && currentDocumentLibrary.searchType === 'StaticDocuments' && currentDocumentLibrary.regions.length > 0" />
          <valuation-date-from-selector v-if="currentDocumentLibrary!== null && (currentDocumentLibrary.searchType === 'RegionReports' || currentDocumentLibrary.searchType === 'LanguageReports' )" />
          <language-selector v-if="currentDocumentLibrary!== null && (currentDocumentLibrary.searchType === 'Commentary' || currentDocumentLibrary.searchType === 'ManualInserts' ) && currentDocumentLibrary.languages.length > 0" />
        </div>
        <div class="col-auto pr-0 pt-1 pb-1">
          <valuation-date-to-selector v-if="currentDocumentLibrary!== null && (currentDocumentLibrary.searchType === 'RegionReports' || currentDocumentLibrary.searchType === 'LanguageReports' )" />
          <valuation-date-selector v-if="currentDocumentLibrary!== null && (currentDocumentLibrary.searchType === 'Commentary' || currentDocumentLibrary.searchType === 'ManualInserts' )" />
        </div>
      </div>
    </div>
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0 pt-1 pb-1">Find document:</label>
        <div class="col-auto pt-1 pb-1">
          <b-input-group>
            <template v-slot:append>
              <b-button variant="primary" @click="findDocument(findValue)">Find</b-button>
            </template>
            <b-form-input v-model="findValue" placeholder="Document ID" @keyup.enter="findDocument(findValue)"></b-form-input>
          </b-input-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { kebabcase } from '@/utilities/text.utils';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';
import { TenantGroupResponse } from '@/api/responses/tenant/tenant-group-response';
import { Route } from 'vue-router';
import { DocumentLibrary, Document } from '../../store/document-library/state';
import EntitySelector from './filter-bar-selectors/entity-selector.vue';
import RegionSelector from './filter-bar-selectors/region-selector.vue';
import ValuationDateSelector from './filter-bar-selectors/valuation-date-selector.vue';
import ValuationDateFromSelector from './filter-bar-selectors/valuation-date-from-selector.vue';
import ValuationDateToSelector from './filter-bar-selectors/valuation-date-to-selector.vue';
import LanguageSelector from './filter-bar-selectors/language-selector.vue';
import InsertTypeSelector from './filter-bar-selectors/insert-type-selector.vue';
import DocumentTypeSelector from './filter-bar-selectors/document-type-selector.vue';
import CommentaryTypeSelector from './filter-bar-selectors/commentary-type-selector.vue';
import { Tenant, ReportGroup } from '@/store/tenant/state';
import { FindDocumentResponse } from '@/api/responses/document-library/find-document-response';
import * as Sentry from '@sentry/browser';

const tenantModule = namespace('tenant');
const documentLibraryModule = namespace('documentLibrary');

@Component({
  components: {
    EntitySelector,
    RegionSelector,
    LanguageSelector,
    InsertTypeSelector,
    DocumentTypeSelector,
    CommentaryTypeSelector,
    ValuationDateSelector,
    ValuationDateFromSelector,
    ValuationDateToSelector,
  },
})
export default class DocumentLibraryFilterBar extends Mixins(BvToastMixin) {
  @tenantModule.Getter('current') currentTenant!: Tenant;
  @tenantModule.Getter tenants!: Array<Tenant>;
  @documentLibraryModule.Getter currentReportGroup!: ReportGroup;
  @documentLibraryModule.Getter('current') currentDocumentLibrary!: DocumentLibrary;
  @documentLibraryModule.Getter currentDocument!: Document | null;

  reportGroup: TenantGroupResponse | null = null;
  findValue: string | number | null = null;

  get selectedReportGroup(): ReportGroup {
    return this.currentReportGroup;
  }

  set selectedReportGroup(value: ReportGroup) {
    this.changeReportGroup(value);
  }

  public get reportGroups(): Array<TenantGroupResponse> {
    return this.currentTenant.reportGroups.filter((rg) => rg.visible.documentLibrary);
  }

  public async mounted(): Promise<void> {
    const { reportGroup } = this.$route.params;
    const setInitialState = async () => {
      const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.documentLibrary) || this.reportGroups.find((group) => group.active.all) || this.reportGroups[0] || null;
      if (matchingReportGroup !== null) {
        const groupName = kebabcase(matchingReportGroup.name);
        if (groupName !== reportGroup) {
          try {
            await this.$router.replace({ name: 'document-library', params: { reportGroup: groupName } });
          } catch (e) {
          }
        }
        await this.setCurrentReportGroup(matchingReportGroup);
      }

      const documentId = this.$route.params.documentId;

      if (documentId !== undefined) {
        // Wrapped in try catch as we don't want the component to fail to display.
        try {
          // NOTE(Dan): The id belongs to the current tenant and report group, so we should set the appropriate filters so a load is kicked off.
          this.$store.commit('documentLibrary/setSelectedDocumentId', documentId);
          await this.findDocument(documentId, true);
        } catch (e) {
          // We might not find the document. That's OK.
        }

        if (this.currentDocument === null) {
          // NOTE(Dan): We didn't find the document, so we should remove the route parameter.
          const { documentId, ...params } = this.$route.params;
          await this.$router.replace({ name: 'document-library', params: params });
        }
      }
    };

    if ((this.currentDocumentLibrary === null && this.currentReportGroup !== null) || (this.currentDocumentLibrary !== null && this.currentReportGroup !== null && this.currentDocumentLibrary.reportGroupId !== this.currentReportGroup.id)) {
      await this.$store.dispatch('documentLibrary/getDocumentLibraryForReportGroupAsync', this.currentReportGroup);
    }

    await setInitialState();
    this.reportGroup = this.currentReportGroup;
  }

  @Watch('$route')
  private async onRouteChanged(to: Route, from: Route) : Promise<void> {
    // NOTE(Dan): Don't destructure this below as we still need to keep this in the "params" object.
    const reportGroup = to.params.reportGroup;
    const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.documentLibrary) || this.reportGroups[0] || null;
    const { documentId, ...params } = to.params;
    let updateParams = false;

    // NOTE(Dan): We should probably compare the previous params to the new ones before trying to find matches.
    //            This will us to prevent setting off watchers on the report group and valuation date uneccessarily.
    //            For example, currently changing a tab on the workflow-steps changes the URL, but only the option.
    //            So we will end up needlessly triggering watchers in this case.
    //            We should probably also bail if there is no params diff either in this case.
    if (from?.params.reportGroup === reportGroup) {
      if (matchingReportGroup?.id === this.currentReportGroup.id) {
        return;
      }
    }

    if (matchingReportGroup !== null) {
      if (kebabcase(matchingReportGroup.name) !== reportGroup) {
        updateParams = true;
        params.reportGroup = kebabcase(matchingReportGroup.name);
      }
      await this.setCurrentReportGroup(matchingReportGroup);
    }

    if (updateParams) {
      try {
        await this.$router.replace({ name: 'document-library', params: { ...params } });
      } catch (e) {
      }
    }
  }

  @Watch('currentTenant')
  private async onTenantChanged(tenant: TenantResponse): Promise<void> {
    const { reportGroup } = this.$route.params;
    const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.documentLibrary) || this.reportGroups[0] || null;

    if (matchingReportGroup !== null) {
      if (matchingReportGroup.name !== this.currentReportGroup?.name) {
        await this.changeReportGroup(matchingReportGroup);
      } else if (matchingReportGroup.id !== this.currentReportGroup?.id) {
        await this.setCurrentReportGroup(matchingReportGroup);
      } else {
        this.reportGroup = matchingReportGroup;
        await this.$store.dispatch('documentLibrary/getDocumentLibraryForReportGroupAsync', this.currentReportGroup);
      }
    }
  }

  async changeReportGroup(group: TenantGroupResponse): Promise<void> {
    if (this.$route.params.reportGroup === undefined || this.$route.params.reportGroup !== kebabcase(group.name)) {
      const { reportId, reportGroup, valuationDate, option, documentId, ...params } = this.$route.params;
      await this.$router.push({ name: 'document-library', params: { ...params, reportGroup: kebabcase(group.name) } });
      this.$store.commit('documentLibrary/setCurrentCommentaryType', null);
      this.$store.commit('documentLibrary/setCurrentDocumentType', null);
      this.$store.commit('documentLibrary/setCurrentEntity', null);
      this.$store.commit('documentLibrary/setCurrentInsertType', null);
      this.$store.commit('documentLibrary/setCurrentLanguage', null);
      this.$store.commit('documentLibrary/setCurrentRegion', null);
      this.$store.commit('documentLibrary/setCurrentValuationDateFrom', null);
      this.$store.commit('documentLibrary/setCurrentValuationDateTo', null);
      this.$store.commit('documentLibrary/setCurrentValuationDate', null);
    }
  }

  async setCurrentReportGroup(reportGroup: TenantGroupResponse): Promise<void> {
    const previous = { ...this.currentReportGroup! };
    this.reportGroup = reportGroup;
    this.$store.commit('documentLibrary/setReportGroup', reportGroup);
    await this.$store.dispatch('tryApplyReportGroup', reportGroup);

    if (previous?.id !== reportGroup.id) {
      await this.$store.dispatch('documentLibrary/getDocumentLibraryForReportGroupAsync', reportGroup);
    }
  }

  async findDocument(id: string | number, onlyForCurrentTenantAndReportGroup: boolean = false): Promise<void> {
    try {
      if (id === null || id === undefined || id.toString().trim().length < 1) {
        return;
      }

      const result = await this.$store.dispatch('documentLibrary/findDocumentAsync', id) as FindDocumentResponse;

      const { tenant, reportGroup, documentId, ...params } = this.$route.params;

      if (!onlyForCurrentTenantAndReportGroup) {
        const tenantMatch = this.tenants.find((t) => t.id === result.tenantId) || null;
        if (this.currentTenant.id !== result.tenantId) {
          if (tenantMatch !== null) {
            params.tenant = kebabcase(tenantMatch.name);
          }
        }

        if (this.currentReportGroup.id !== result.reportGroupId) {
          const match = tenantMatch?.reportGroups.find((t) => t.id === result.reportGroupId) || null;

          if (match !== null) {
            params.reportGroup = kebabcase(match.name);
          }
        }
      }

      if (this.currentDocument?.id !== id.toString()) {
        params.documentId = id.toString();
      }

      if (params.tenant !== undefined || params.reportGroup !== undefined || params.documentId !== undefined) {
        const newParams: { tenant?: string, reportGroup?: string, documentId?: string } = { tenant, reportGroup, documentId, ...params };
        if (newParams.tenant !== tenant || newParams.reportGroup !== reportGroup || newParams.documentId !== documentId) {
          await this.$router.push({ name: 'document-library', params: newParams });
        }
      }

      const matchingTenant = this.tenants.find((t) => t.id === result.tenantId)!;
      const matchingReportGroup = matchingTenant.reportGroups.find((rg) => rg.id === result.reportGroupId)!;

      const newDocLibrary = await this.$store.dispatch('documentLibrary/getDocumentLibraryForReportGroupAsync', matchingReportGroup) as DocumentLibrary;
      // const rg = this.currentTenant.reportGroups.find((t) => t.id === result.reportGroupId) || null;
      const from = newDocLibrary?.dates.find((d) => d.valuationDate === result.valuationDateFrom) || null;
      const to = newDocLibrary?.dates.find((d) => d.valuationDate === result.valuationDateTo) || null;
      const date = newDocLibrary?.dates.find((d) => d.valuationDate === result.valuationDate) || null;
      const entity = newDocLibrary?.entities.find((e) => e.id === result.entityId) || null;
      const region = newDocLibrary?.regions.find((r) => r.id === result.regionId) || null;
      const language = newDocLibrary?.languages.find((l) => l.id === result.languageId) || null;
      const commentaryType = newDocLibrary?.commentaryTypes.find((t) => t.name === result.commentaryType) || null;
      const documentType = newDocLibrary?.documentTypes.find((t) => t.name === result.documentType) || null;
      const insertType = newDocLibrary?.insertTypes.find((t) => t.name === result.insertType) || null;

      if (matchingReportGroup.id !== this.currentReportGroup.id) {
        this.$store.commit('documentLibrary/setReportGroup', matchingReportGroup);
      }
      this.$store.commit('documentLibrary/setCurrentValuationDateFrom', from);
      this.$store.commit('documentLibrary/setCurrentValuationDateTo', to);
      this.$store.commit('documentLibrary/setCurrentValuationDate', date);
      this.$store.commit('documentLibrary/setCurrentEntity', entity);
      this.$store.commit('documentLibrary/setCurrentRegion', region);
      this.$store.commit('documentLibrary/setCurrentLanguage', language);
      this.$store.commit('documentLibrary/setCurrentCommentaryType', commentaryType);
      this.$store.commit('documentLibrary/setCurrentDocumentType', documentType);
      this.$store.commit('documentLibrary/setCurrentInsertType', insertType);
    } catch (e) {
      this.showErrorToast(`Could not find document '${id}'. Please try again.`);
      Sentry.captureException(e);
    }
    this.findValue = null;
  }
}
</script>

function buildUrl(): string {
  const host = window.location.host;

  let baseUrl = host.includes('.azurewebsites.net')
    ? `https://${host.replace('webapp', 'api')}`
    : host.includes('localhost')
      ? `${process.env.VUE_APP_API_URL}`
      : `https://api.${host}`;

  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }

  return baseUrl;
}

export const baseUrl = buildUrl();

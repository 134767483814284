<template>
  <div class="workflow-report-flag-icon" :title="title">
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Document } from '@/store/document-library/state';

@Component
export default class DocumentFlag extends Vue {
  @Prop({ type: Object, required: true }) public item!: Document;

  public get title(): string | null {
    return this.item.flaggedReason;
  }
}
</script>

import { SupportTicketOpened } from '../events/support-ticket-opened';
import store from '@/store';

async function handleSupportTicketOpened(data: SupportTicketOpened): Promise<void> {
  /* eslint-disable */
  console.log(`SupportTicketOpened event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  if (store.getters['support/current'] === null) {
    return;
  }

  const ticketId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('support/tryMoveTicketToPage', { ...data.payload, ticketId });

  // NOTE(Dan): We need to check if the correlationId is set, if so and its matching the messages correlation id, set the openingTicketId
  if (store.getters['support/openingTicketCorrelationId'] === data.correlationId) {
    store.commit('support/setOpeningTicketId', ticketId);
  }
}

export default handleSupportTicketOpened;

import { Api } from '@/api/api';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';

export class TenantApi extends Api {
  public constructor() {
    super();
  }

  public getTenantLogoAsync(tenantId:number): Promise<Blob> {
    return this.getFileAsync(`v1/tenant/${tenantId}/logo`);
  }

  public getTenantsAsync(): Promise<Array<TenantResponse>> {
    return this.getAsync<Array<TenantResponse>>('v1/tenant');
  }
}

<template>
  <div class="container-fluid support-ticket-message-list">
    <div class="support-ticket-message border p-4 row" :class="{ reply: entry.userType === 'Agent' }" v-for="entry, index in entries" :key="index">
      <div class="col">
        <div class="row">
          <div class="col-auto title">
            <span class="user">
              <strong>{{ entry.createdBy }}</strong> <b-badge variant="primary" v-if="false && entry.userType === 'Agent'">Customer support</b-badge>
            </span>
            <span class="type">
              {{ getEntryStatusLabel(entry) }}
            </span>
          </div>
          <div class="col-auto ml-auto timestamp">
            {{ entry.timestamp | datetime }}
          </div>
        </div>

        <div class="row">
          <div class="col status text-muted">
            Status: {{ entry.status }}
          </div>
        </div>

        <div class="row mt-4 body-container">
          <editable-support-ticket-message :key="entry.supportIssueDetailID" :message="entry" @saved="onMessageSaved" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { SupportTicketMessage } from '@/store/support/state';
import EditableSupportTicketMessage from '@/components/support/editable-support-ticket-message.vue';

@Component({
  components: {
    EditableSupportTicketMessage,
  },
})
export default class SupportTicketEntryList extends Mixins(BvToastMixin) {
  @Prop({ required: true }) entries!: Array<SupportTicketMessage>;
  @Prop({ required: true }) getEntryStatusLabel!: (entry: SupportTicketMessage) => string

  async onMessageSaved(entry: { supportIssueDetailID: number, detail: string }): Promise<void> {
    this.$emit('saved', entry);
  }
}
</script>

<template>
  <a class="text-center" :title="title" @click="onClick(item)">
    <data-check-waiting-icon v-if="item.renderingStatus === 'DataCheckWaiting'" />
    <data-check-running-icon v-if="item.renderingStatus === 'DataCheckRunning'" />
    <render-pending-icon v-if="item.renderingStatus === 'RenderPending'" />
    <render-waiting-icon v-if="item.renderingStatus === 'RenderWaiting'" />
    <render-running-icon v-if="item.renderingStatus === 'RenderRunning'" />
    <distribution-waiting-icon v-if="item.renderingStatus === 'DistributionWaiting'" />
    <distribution-running-icon v-if="item.renderingStatus === 'DistributionRunning'" />
    <awaiting-batch-distribution-icon :report="item" v-if="item.renderingStatus === 'AwaitingBatchDistribution' && item.remainingDistributionBatches > 0" />
    <error-icon v-if="item.renderingStatus === 'Error'" />
  </a>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WorkflowReport } from '@/store/workflow/state';
import DataCheckWaitingIcon from './workflow-report-rendering-icons/data-check-waiting.vue';
import DataCheckRunningIcon from './workflow-report-rendering-icons/data-check-running.vue';
import RenderPendingIcon from './workflow-report-rendering-icons/render-pending.vue';
import RenderWaitingIcon from './workflow-report-rendering-icons/render-waiting.vue';
import RenderRunningIcon from './workflow-report-rendering-icons/render-running.vue';
import DistributionWaitingIcon from './workflow-report-rendering-icons/distribution-waiting.vue';
import DistributionRunningIcon from './workflow-report-rendering-icons/distribution-running.vue';
import AwaitingBatchDistributionIcon from './workflow-report-rendering-icons/awaiting-batch-distribution.vue';
import ErrorIcon from './workflow-report-rendering-icons/error.vue';

const tenantModule = namespace('tenant');

@Component({
  components: {
    DataCheckWaitingIcon,
    DataCheckRunningIcon,
    RenderPendingIcon,
    RenderWaitingIcon,
    RenderRunningIcon,
    DistributionWaitingIcon,
    DistributionRunningIcon,
    AwaitingBatchDistributionIcon,
    ErrorIcon,
  },
})
export default class WorkflowReportRenderingStatus extends Vue {
  @Prop({ type: Object, required: true }) public item!: WorkflowReport;
  @tenantModule.Getter private selectedReportGroup!: { id: number, name: string } | null;

  public get title(): string | null | undefined {
    const tooltip = this.item.tooltips.renderingStatus;

    if (tooltip !== null && tooltip !== undefined) {
      return tooltip;
    }

    switch (this.item.renderingStatus) {
      case 'DataCheckWaiting':
        return 'Data Check Waiting in Queue';
      case 'DataCheckRunning':
        return 'Data Check Running';
      case 'RenderPending':
        return 'Render Pending';
      case 'RenderWaiting':
        return 'Render Waiting in Queue';
      case 'RenderRunning':
        return 'Rendering';
      case 'DistributionWaiting':
        return 'Awaiting Distribution';
      case 'AwaitingBatchDistribution':
        return `${this.item.remainingDistributionBatches} Awaiting Batch Distribution${(this.item.remainingDistributionBatches > 1 ? 's' : '')}`;
      case 'DistributionRunning':
        return 'Distributing';
      case 'Error':
        return 'Error';
    }

    return null;
  }

  async onClick(item: WorkflowReport): Promise<void> {
    if (item.renderingStatus !== 'RenderPending') {
      return;
    }

    if (!item.actions.canReRender) {
      return;
    }

    await this.$store.dispatch('workflow/reRenderWorkflowReportAsync', { workflowReportId: item.id, reportGroupId: this.selectedReportGroup!.id, comments: null });
  }
}
</script>

import { GetterTree } from 'vuex';
import { State } from '@/store/schedule/state';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public regions = (state: State) => {
    if (state.entitySchedule === null) {
      return [];
    }

    return state.entitySchedule.regions;
  }

  public activity = (state: State) => {
    return state.entityScheduleActivity?.activity || [];
  }

  public requests = (state: State) => {
    return state.requestPages.flatMap((p) => p.requests);
  }

  public entitySchedule = (state: State) => {
    return state.entitySchedule;
  }

  public isLoading = (state: State) => {
    return state.loadingSchedule;
  }

  public currentEntry = (state: State) => {
    if (state.selectedEntryId === null) {
      return null;
    }

    if (state.selectedEntryId < 0) {
      return null;
    }

    return this.entitySchedule(state)?.entities.find((e) => e.id === state.selectedEntryId) || null;
  }
}

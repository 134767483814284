<template>
  <div class="support-ticket-status text-center" :title="title">
    <b-badge :variant="variant">{{ title }}</b-badge>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SupportTicket } from '../../store/support/state';

@Component
export default class SupportTicketStatusIcon extends Vue {
  @Prop({ type: Object, required: true }) public item!: SupportTicket;

  public get title(): string | null {
    return this.item.status;
  }

  public get variant(): string {
    switch (this.item.status) {
      case 'New':
        return 'success';
      case 'Waiting for Client':
        return 'info';
      case 'Support Investigating':
        return 'warning';
      case 'In Development':
        return 'warning';
      case 'On Hold':
        return 'warning';
      case 'Fixed':
        return 'success';
      case 'Closed':
        return 'danger';
      default:
        return 'outline-primary';
    }
  }
}
</script>

import { UserManagerSettings } from 'oidc-client';

function buildBaseUrl(): string {
  let host = window.location.host;

  if (host.includes('.azurewebsites.net')) {
    host = `https://${host.replace('webapp', 'identity')}`;
  } else if (host.includes('localhost')) {
    host = `${process.env.VUE_APP_AUTHORITY_URL}`;
  } else {
    host = `https://id.${host}`;
  }

  return host;
}

const baseUrl = buildBaseUrl();

const settings: UserManagerSettings = {
  authority: baseUrl,
  client_id: `${process.env.VUE_APP_CLIENT_ID}`,
  redirect_uri: window.location.origin + '/signed-in.html',
  response_type: 'code',
  scope: `openid profile ${process.env.VUE_APP_API_SCOPE}`,
  post_logout_redirect_uri: window.location.origin,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 10 * 60,
  silent_redirect_uri: window.location.origin + '/sign-in-refresh.html',
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
};

export default settings;

export class State {
  public current: Data | null = null;
  public currentValuationDate: string | null = null;
  public dataIssuePages: Array<DataIssuePage> = [];
  public translationIssuePages: Array<TranslationIssuePage> = [];
  public dataFiles: Array<DataFile> = [];
  public dataLoadsPages: Array<DataLoadPage> = [];
  public loadingData: boolean = false;
  public selectedDataLoadId: number | null = null;
  public currentOption: DataOption | null = null;
}

export interface Data {
  reportGroupId: number;
  dateFormat: string;
  queuedDataChecks: number;
  dates: Array<DataDate>;
  options: Array<DataOption>;
  allowedFileTypes: Array<string>;
  actions: { [key in keyof Actions]: boolean }
}

interface Actions {
  canRecheckData: boolean;
  canUploadData: boolean;
  canEditDataFiles: boolean;
}

export interface DataDate {
  valuationDate: string;
  displayDate: string;
  active: boolean;
  showCounts: boolean;
  options: { [key: string]: number | undefined; };
}

export interface DataOption {
  id: number;
  order: number;
  name: string;
}

export interface DataIssuePage {
  page: number;
  pageSize: number;
  reportGroupId: number;
  reportsAffected: number;
  issues: Array<DataIssue>;
}

export interface DataIssue {
  id: number;
  name: string;
  description: string | null;
  reportsAffected: number;
  links: DataIssueLink;
  status: 'Unknown' | 'Pending' | 'Ignored';
  type: 'Unknown' | 'Critical' | 'Error' | 'Warning' | 'Information';
  canBeIgnored: boolean;
  canBeUnignored: boolean;
  blockingApproval: number | null;
}

export interface DataIssueLink {
  reports: Array<ReportLink>;
  document: DocumentLink | null;
}

export interface ReportLink {
  reportId: number;
  reportGroup: string | null;
  valuationDate: string | null;
  dateFormat: string | null;
  state: string | null;
}

export interface TranslationIssuePage {
  page: number;
  pageSize: number;
  reportGroupId: number;
  reportsAffected: number;
  issues: Array<TranslationIssue>;
}

export interface TranslationIssue {
  id: number;
  name: string;
  description: string | null;
  reportsAffected: number;
  links: TranslationIssueLink;
  blockingApproval: number | null;
  type: 'Unknown' | 'Critical' | 'Error' | 'Warning' | 'Information';
}

export interface TranslationIssueLink {
  languages: Array<LanguageLink>;
}

export interface LanguageLink {
  language: string | null;
  languageId: number;
  phrase: string | null;
}

export interface DataFiles {
  files: Array<DataFile>;
}
export interface DataFile {
  id: number;
  name: string;
  fileName: string;
  fileType: string;
  mandatory: boolean;
  status: 'NotLoaded' | 'Loaded';
  dataLoads: number;
  reminder: DataFileReminder | null;
  dataLoad: DataFileLoad | null;
  links: DataFileLink | null;
}

export interface DataFileLink {
  document: DocumentLink | null;
}

export interface DocumentLink {
  documentId: string | null;
}

export interface DataFileReminder {
  businessDay: number | null;
  email: string | null;
}

export interface DataFileLoad {
  id: number;
  fileName: string;
  submittedBy: string;
  status: 'Failed' | 'Succeeded' | 'WaitingForOtherFiles' | 'Unknown';
  timestamp: string;
}

export interface DataLoadPage {
  page: number;
  pageSize: number;
  reportGroupId: number;
  dataLoads: Array<DataLoad>;
}

export interface DataLoad {
  id: number;
  fileName: string | null;
  fileSize: number;
  downloadFileName: string | null;
  submittedBy: string | null;
  status: 'Running' | 'Failed' | 'Succeeded' | 'WaitingForOtherFiles';
  reportsUpdated: number;
  reportsAffected: number;
  timestamp: string;
  tooltips: { [key in keyof DataLoadTooltips]: string | null },
  content: { [key: string]: boolean | undefined }
}

export interface DataLoadTooltips {
  status: string;
}

<template>
  <validation-observer v-slot="{ passes, invalid, pending }">
    <b-modal id="modal-center"
             v-model="visible"
             ok-variant="primary"
             ok-title="Submit"
             cancel-variant="link"
             centered
             title="Open New Support Ticket"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             size="lg"
             :ok-disabled="(invalid && !pending) || submitting"
             @ok.prevent="passes(onOk)"
             @cancel="onCancel"
             @close="onClose"
    >
      <div class="row" v-if="currentUser.roles.administrator">
        <div class="col">
          <b-form-group
            id="input-group-7"
            label="Tenant:"
            label-for="input-tenant"
          >
            <b-form-select v-model="tenant">
              <b-form-select-option v-for="tenant in tenants" :key="tenant.id" :value="tenant">{{ tenant.name }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
      </div>

      <div class="row" v-if="currentUser.roles.administrator">
        <validation-provider rules="required" v-slot="{ valid, errors }" tag="div" class="col">
          <b-form-group
            id="input-group-8"
            label="Raised by:"
            label-for="input-raisedby"
          >
            <b-form-select v-model="raisedBy" :state="errors[0] ? false : (valid ? true : null)">
              <b-form-select-option :value="null">Please select...</b-form-select-option>
              <b-form-select-option v-for="user in users" :key="user.id" :value="user">{{ user.name }}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>

      <validation-provider rules="required|max:250" v-slot="{ valid, errors }" tag="div">
        <b-form-group label="Subject">
          <b-form-input
            id="ticket-detail"
            v-model="subject"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="cc mail" :rules="{ regex: emailRegex }" v-slot="{ errors }" tag="div">
        <b-form-group label="CC Mail">
          <b-form-input
            id="ticket-detail"
            v-model="ccmail"
            :state="errors[0] ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>{{ emailValidationErrorMsg }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ valid, errors }" tag="div" class="reply">
        <b-form-group label="Detail">
          <b-form-textarea
            id="ticket-detail"
            v-model="detail"
            rows="10"
            :state="errors[0] ? false : (valid ? true : null)"
            class="border-bottom-0"
            @paste="onPaste"
          ></b-form-textarea>

          <div>
            <div class="attachment-upload-list border border-top-0">
              <div class="attachment-upload-list-item px-3 py-2" v-for="attachment in attachmentfiles" :key="attachment.name">
                <b-icon scale="1.2" icon="file-earmark" />
                {{ attachment.name }}
                (<file-size :bytes="attachment.size" />)
                <b-button type="button" variant="link" size="sm" @click="onRemoveAttachmentFile(attachment)">
                  <b-icon icon="x" variant="danger" />
                </b-button>
              </div>
            </div>
            <b-form-file class="support-ticket-attachment-file-upload" v-model="files" multiple placeholder="Drag &amp; drop files here, paste files directly or click here to browse" drop-placeholder="Drop file here..." @input="onAttachmentAdded"></b-form-file>
          </div>

          <b-form-invalid-feedback v-if="errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="row">
        <validation-provider rules="required" v-slot="{ valid, errors }" tag="div" class="col">
          <b-form-group
            id="input-group-3"
            label="Priority:"
            label-for="input-priority"
            label-cols="4"
          >
            <b-form-select v-model="priority" :state="errors[0] ? false : (valid ? true : null)">
              <b-form-select-option :value="null">Please select...</b-form-select-option>
              <b-form-select-option v-for="priority in priorities" :key="priority.id" :value="priority">{{ priority.name }}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback v-if="errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider rules="required" v-slot="{ valid, errors }" tag="div" class="col">
          <b-form-group
            id="input-group-4"
            label="Issue Type:"
            label-for="input-issue-type"
            label-cols="4"
          >
            <b-form-select v-model="issueType" :state="errors[0] ? false : (valid ? true : null)">
              <b-form-select-option :value="null">Please select...</b-form-select-option>
              <b-form-select-option v-for="type in issueTypes" :key="type.id" :value="type">{{ type.name }}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback v-if="errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>

      <div class="row">
        <validation-provider rules="required" v-slot="{ valid, errors }" tag="div" class="col">
          <b-form-group
            id="input-group-5"
            label="Category:"
            label-for="input-category"
            label-cols="4"
          >
            <b-form-select v-model="category" :state="errors[0] ? false : (valid ? true : null)">
              <b-form-select-option :value="null">Please select...</b-form-select-option>
              <b-form-select-option v-for="category in categories" :key="category.id" :value="category">{{ category.name }}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback v-if="errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <div class="col">
          <b-form-group
            id="input-group-6"
            label="Report Group:"
            label-for="input-category"
            label-cols="4"
          >
            <b-form-select v-model="reportGroup">
              <b-form-select-option :value="null">All</b-form-select-option>
              <b-form-select-option v-for="group in reportGroups" :key="group.id" :value="group">{{ group.name }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <validation-provider name="valuationDate" rules="" v-slot="{ errors }" tag="div" class="col">
          <b-form-group
            id="input-group-8"
            label="Valuation Date:"
            label-for="input-valuation-date"
            label-cols="4"
          >
            <b-form-select v-model="valuationDate" :state="errors[0] ? false : null">
              <b-form-select-option :value="null">Please select...</b-form-select-option>
              <b-form-select-option v-for="date in dates" :key="date.valuationDate" :value="date">{{ date.displayDate | date }}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback v-if="errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider name="report id" rules="numeric" v-slot="{ errors }" tag="div" class="col">
          <b-form-group
            id="input-group-9"
            label="Report Id:"
            label-for="input-report-id"
            label-cols="4"
          >
            <b-form-input
              id="input-report-id"
              v-model="reportId"
              :state="errors[0] ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>
    </b-modal>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { Category, IssueType, Priority, User, ValuationDate } from '@/store/support/state';
import { ReportGroup, Tenant } from '@/store/tenant/state';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import FileSize from '@/components/utils/file-size.vue';
import { UserResponse } from '../../api/responses/user/user-response';
import uuid from 'uuid';
import { validEmailRegex, invalidEmailErrorMsg } from '../../utilities/email';

const supportModule = namespace('support');
const tenantModule = namespace('tenant');
const userModule = namespace('user');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    FileSize,
  },
})
export default class OpenSupportTicketDialog extends Mixins(BvToastMixin) {
  @Prop({ type: Boolean, required: true }) show!: boolean;
  @supportModule.Getter categories!: Array<Category>;
  @supportModule.Getter issueTypes!: Array<IssueType>;
  @supportModule.Getter priorities!: Array<Priority>;
  @supportModule.Getter dates!: Array<ValuationDate>;
  @supportModule.Getter usersForTenant!: (tenantId: number) => Array<User>;
  @tenantModule.Getter current!: Tenant;
  @tenantModule.Getter tenants!: Array<Tenant>;
  @userModule.Getter('current') currentUser!: UserResponse;
  @supportModule.Getter openingTicketId!: number;
  @supportModule.Getter openingTicketCorrelationId!: number;

  ccmail: string | null = null;
  subject: string | null = null;
  detail: string | null = null;
  priority: Priority | null = null;
  issueType: IssueType | null = null;
  category: Category | null = null;
  reportGroup: ReportGroup | null = null;
  tenant: Tenant | null = null;
  raisedBy: User | null = null;
  valuationDate: ValuationDate | null = null;
  visible = false;
  reportId: number | null = null;
  submitting = false;
  files: Array<File> = [];
  attachmentfiles: Array<File> = [];

  emailRegex = validEmailRegex;
  emailValidationErrorMsg = invalidEmailErrorMsg;

  get reportGroups(): Array<ReportGroup> {
    return this.current.reportGroups;
  }

  get users(): Array<User> {
    if (this.tenant === null) {
      return [];
    }

    return this.usersForTenant(this.tenant!.id);
  }

  async onOk(): Promise<void> {
    this.submitting = true;
    const correlationId = uuid.v4();

    try {
      await this.$store.dispatch('support/openTicketAsync', {
        subject: this.subject,
        detail: this.detail,
        priority: this.priority,
        issueType: this.issueType,
        category: this.category,
        reportGroup: this.reportGroup,
        tenant: this.currentUser.roles.administrator ? this.tenant : null,
        raisedBy: this.currentUser.roles.administrator ? this.raisedBy : null,
        valuationDate: this.valuationDate,
        reportId: this.reportId || null,
        correlationId,
        ccmail: this.ccmail,
      });
    } catch (e) {
      this.showErrorToast('Could not open ticket. Please try again.');
      this.submitting = false;
    }
  }

  onCancel(): void {
    this.$emit('cancel');
    this.clear();
  }

  onClose(): void {
    this.$emit('close');
    this.clear();
  }

  @Watch('show', { immediate: true })
  onShowChanged(value: boolean): void {
    this.visible = value;
    this.clear();
  }

  clear(): void {
    this.subject = null;
    this.detail = null;
    this.priority = null;
    this.issueType = null;
    this.category = null;
    this.reportGroup = null;
    this.ccmail = null;
  }

  @Watch('current', { immediate: true })
  onCurrentTenantChanged(tenant: Tenant): void {
    this.tenant = tenant;
  }

  @Watch('openingTicketId')
  async onOpeningTicketIdChanged(id: number): Promise<void> {
    const correlationId = this.openingTicketCorrelationId;
    const files = this.attachmentfiles.map((f) => this.$store.dispatch('support/submitTicketAttachmentAsync', { ticketId: id, file: f, correlationId }));

    await Promise.all([files]);

    this.$emit('ok');
  }

  onAttachmentAdded(files: Array<File>): void {
    for (const file of files) {
      if (!this.attachmentfiles.some((f) => f.name === file.name)) {
        this.attachmentfiles.push(file);
      }
    }

    this.files = [];
  }

  onRemoveAttachmentFile(file: File): void {
    this.attachmentfiles.splice(this.attachmentfiles.indexOf(file), 1);
  }

  onPaste(event: ClipboardEvent): void {
    const items = event.clipboardData?.items || [];

    if (items.length < 1) {
      return;
    }

    const files = new Array<File>();

    for (const index in items) {
      const item = items[index];

      if (item.kind === 'file') {
        let file = item.getAsFile();

        if (file !== null) {
          let i: number = 0;
          const parts: Array<string> = file.name.split('.');
          const ext = parts.pop();
          const name = parts.join('');

          while (this.attachmentfiles.some((f) => f.name === file!.name)) {
            file = new File([file.slice(0, file.size, file.type)], `${name}_${++i}.${ext}`, { type: file.type, lastModified: file.lastModified });
          }

          files.push(file);
        }
      }
    }

    this.onAttachmentAdded(files);
  }
}
</script>

import { DocumentCheckOutCancellationRequested } from '../events/document-checkout-cancellation-requested';
import store from '@/store';

async function handleDocumentCheckOutCancellationRequested(data: DocumentCheckOutCancellationRequested): Promise<void> {
  /* eslint-disable */
  console.log(`DocumentCheckOutCancellationRequested event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */

  const documentId = data.payload.aggregateId.split('-')[1];
  // status: 'Available' | 'CheckingOut' | 'CheckedOut' | 'CheckingIn' | 'Locked';
  await store.dispatch('documentLibrary/tryUpdateDocumentProperties', {
    documentId: documentId,
    reportGroupId: data.payload.reportGroupId,
    status: 'Locked',
    statusDescription: data.payload.statusDescription,
    actions: {
      canCheckOut: false,
      canCancelCheckout: false,
    },
  });
}

export default handleDocumentCheckOutCancellationRequested;

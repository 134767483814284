import { GetterTree } from 'vuex';
import { State, TenantPermission } from '@/store/tenant/state';
import { Route } from 'vue-router';

export class Getters implements GetterTree<State, any> {
    [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

    public tenants = (state: State) => {
      return state.tenants;
    }

    public currentLogo = (state: State) => {
      return state.currentLogo;
    }

    public current = (state: State) => {
      return state.current;
    }

    public selectedReportGroup = (state: State) => {
      return state.selectedReportGroup;
    }

    public currentTenantAndReportGroup = (state: State) => {
      return { tenant: state.current, reportGroup: state.selectedReportGroup };
    }

    public hasPermissionForRoute = (state: State) => (route: Route & {meta:any}) => {
      if (state.current === null) {
        return false;
      }

      if (!route.meta.requiresAuthentication || route.meta.requiresAnyPermission === null || route.meta.requiresAnyPermission === undefined || route.meta.requiresAnyPermission.length < 1) {
        return true;
      }

      return this.hasAnyPermission(state)(route.meta.requiresAnyPermission || []);
    }

    public canViewLanguages = (state: State): boolean => this.hasAnyPermission(state)(['canViewLanguages', 'canManageLanguages']);
    public canManageLanguages = (state: State): boolean => this.hasAnyPermission(state)(['canManageLanguages']);
    public canManageSettings = (state: State): boolean => this.hasAnyPermission(state)(['canManageSettings']);

    private hasAnyPermission = (state: State) => (roles: Array<keyof TenantPermission>): boolean => {
      if (state.current === null) {
        return false;
      }

      if (state.current.permissions === null) {
        return false;
      }

      const permissions = state.current.permissions;

      return roles.some((r) => permissions[r]);
    }

    public reportGroupsForTenant = (state: State) => (tenantId: number) => {
      if (state.current === null) {
        return [];
      }

      const match = state.tenants.find((t) => t.id === tenantId) || null;

      if (match === null) {
        return [];
      }

      return match.reportGroups;
    }
}

<template>
  <div class="row align-items-center" v-if="entities.length > 0">
    <label class="col-auto mb-0 pr-0">{{ current !== null ? current.entityType : null }}:</label>
    <div class="col-auto">
      <b-form-select v-model="selectedEntity">
        <b-form-select-option v-for="entity in entities" :key="entity.id" :value="entity">{{ entity.name }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Configuration, ConfigurationEntity } from '@/store/configuration/state';

const configurationModule = namespace('configuration');

@Component
export default class ScheduleFilterBarEntitySelector extends Vue {
  @configurationModule.Getter current!: Configuration;
  @configurationModule.Getter entities!: Array<ConfigurationEntity>;
  @configurationModule.Getter currentEntity!: ConfigurationEntity | null;

  get selectedEntity(): ConfigurationEntity | null {
    return this.currentEntity;
  }

  set selectedEntity(value: ConfigurationEntity | null) {
    this.$store.commit('configuration/setCurrentEntity', value?.id || null);
  }
}
</script>

<template>
  <validation-observer v-slot="{ passes, invalid, pending }">
    <b-modal id="modal-center"
             v-model="visible"
             ok-variant="primary"
             ok-title="Schedule"
             cancel-variant="link"
             centered
             :title="title"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             :ok-disabled="(invalid && !pending) || submitting"
             @ok.prevent="passes(onOk)"
             @cancel="onCancel"
             @close="onClose"
    >
      <b-container fluid>
        <validation-provider
          name="date"
          ref="date"
          rules="required"
          v-slot="{ valid, errors }"
          tag="div"
        >
          <div class="row align-items-center mb-3">
            <div class="col">
              <label class="">Choose a date:</label>
              <b-form-datepicker hide-header v-model="date" :min="minDate" class="mb-2" :state="errors[0] ? false : (valid ? true : null)"></b-form-datepicker>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </div>
          </div>
        </validation-provider>
        <validation-provider
          name="time"
          ref="time"
          rules="required|after_now:@date,@time"
          v-slot="{ valid, errors }"
          tag="div"
        >
          <div class="row align-items-center mb-3">
            <div class="col">
              <label class="">Choose a time:</label>
              <b-form-timepicker hide-header v-model="time" :state="errors[0] ? false : (valid ? true : null)"></b-form-timepicker>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </div>
          </div>
        </validation-provider>
      </b-container>
    </b-modal>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ReportGroup } from '../../store/tenant/state';
import { SummaryPeriod, SummaryPeriodEntry } from '../../store/summary/state';
import { DateTime } from 'luxon';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ScheduleWorkflowReportBatchDistributionDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ required: true }) public reportGroup!: ReportGroup | null;
  @Prop({ type: Object, required: true }) public period!: SummaryPeriod;
  @Prop({ type: Object, required: true }) public entry!: SummaryPeriodEntry;

  private visible: boolean = false;
  private date: string | null = DateTime.local().set({ second: 0 }).toFormat('yyyy-MM-dd');
  private time: string | null = DateTime.local().plus({ minutes: 1 }).set({ second: 0 }).toFormat('TT');
  private minDate: string = DateTime.local().plus({ minutes: 1 }).set({ second: 0 }).toString();
  private submitting: boolean = false;

  private get title(): string {
    return `${this.entry.description}`;
  }

  private onOk(): void {
    const reportGroup = this.reportGroup;
    const runAt = DateTime.fromISO(`${this.date}T${this.time}`).toUTC();
    this.$emit('ok', { reportGroup, runAt, batchId: this.entry.batchId });
  }

  private onCancel(): void {
    this.$emit('cancel');
  }

  private onClose(): void {
    this.$emit('close');
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
  }
}
</script>

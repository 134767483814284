import { ActionTree, ActionContext } from 'vuex';
import { State } from '@/store/notification/state';

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public tryUpdateWorkflowReportLinks = async (context: ActionContext<State, any>, data: { reportId: number, aggregateId: string, tenantId: number, reportGroupId: number, valuationDate: string, displayDate?: string | null, fromState: string, fromStateId: number, toState: string, toStateId: number }) => {
    if (context.state.notifications.length < 1) {
      return;
    }

    context.commit('updateWorkflowReportLinks', { reportId: data.reportId, toState: data.toState });
  }
}

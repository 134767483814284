<template>
  <b-tabs class="document-details" :aria-busy="loading">
    <!-- <b-tab :title="`Commentary (${commentary.length})`" v-if="commentary !== null && commentary.length > 0">
      <document-commentary-table :commentary="commentary" :loading="loading" />
    </b-tab> -->
    <b-tab :title="`Distribution (${distributions.length})`" v-if="distributions !== null && distributions.length > 0">
      <document-distribution-table :distribution="distributions" :loading="loading" />
    </b-tab>
    <b-tab :title="`Activity log (${activity.length})`">
      <document-activity-table :activity="activity" :loading="loading" />
    </b-tab>
  </b-tabs>
</template>

<script lang="ts">
import { namespace } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import debounce from 'lodash.debounce';

import { Document } from '@/store/document-library/state';
import { ReportGroup } from '@/store/tenant/state';
import DocumentActivityTable from './document-activity-table.vue';
import DocumentDistributionTable from './document-distribution-table.vue';

const documentLibraryModule = namespace('documentLibrary');

@Component({
  components: {
    DocumentActivityTable,
    DocumentDistributionTable,
  },
})
export default class DocumentDetails extends Vue {
  @documentLibraryModule.Getter private currentDocument!: Document | null;
  @documentLibraryModule.Getter('currentReportGroup') private selectedReportGroup!: ReportGroup;

  loading: boolean = false;
  activity: Array<{ timestamp: string, description: string | null }> = [];
  distributions: Array<{ description: string, status: string }> = [];

  private issues: Array<{ canBeIgnored: boolean; canBeUnignored: boolean; commentaryReceivedDate: string | null; description: string; id: number; ignored: boolean; ignoredBy: string | null; ignoredDate: string | null; reportId: number; type: string; }> = [];
  private commentary: Array<{ description: string, complete: boolean, receivedDate: string }> = [];

  @Watch('currentDocument', { deep: true })
  private async onDocumentChanged(document: Document | null, old: Document | null | undefined = undefined): Promise<void> {
    if (document === null || document === undefined) {
      this.activity = [];
      this.issues = [];
      this.commentary = [];
      this.distributions = [];
      return;
    }

    if (document.id !== old?.id) {
      this.loading = true;
      this.activity = [];
      this.issues = [];
      this.commentary = [];
      this.distributions = [];
    }

    // NOTE(Dan): If we change selected report don't use the debounced `loadReportDetailsAsync` call.
    if (document.id !== old?.id) {
      await this.loadDocumentDetailsInternalAsync(document);
    } else {
      await this.loadDocumentDetailsAsync(document);
    }

    if (document.id !== old?.id) {
      this.loading = false;
    }
  }

  async mounted(): Promise<void> {
    this.loading = true;
    await this.onDocumentChanged(this.currentDocument!);
    this.loading = false;
  }

  private loadDocumentDetailsAsync = debounce(this.loadDocumentDetailsInternalAsync, 500, { leading: true });

  private async loadDocumentDetailsInternalAsync(document: Document): Promise<void> {
    const result = await this.$store.dispatch('documentLibrary/getDocumentActivityAsync', { documentId: document!.id, reportGroupId: this.selectedReportGroup!.id });
    this.activity = result.activity;

    if (document.hasDistribution) {
      const distributions = await this.$store.dispatch('documentLibrary/getDocumentDistributionAsync', { documentId: document!.id, reportGroupId: this.selectedReportGroup!.id });
      this.distributions = distributions.distribution;
    }
  }
}
</script>

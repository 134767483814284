import { ActionTree, ActionContext } from 'vuex';
import { State, Translation } from '@/store/language-maintenance/state';
import { LanguageMaintenanceApi } from '@/api/language-maintenance-api';
import { Dictionary } from 'vue-router/types/router';

type LanguageMaintenanceState = { tenant: string };

const languageApi = new LanguageMaintenanceApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public getPhrasesAsync = async (context: ActionContext<State, any>, payload: { phrase: string, exact: boolean }) => {
    const results = await languageApi.getPhrasesAsync(payload.phrase, payload.exact);

    context.commit('setPhrases', results);
    context.commit('setTranslations', []);
  }

  public getPhrasesWithoutStoringAsync = async (context: ActionContext<State, any>, payload: { phrase: string, exact: boolean }) => {
    const results = await languageApi.getPhrasesAsync(payload.phrase, payload.exact);

    return results;
  }

  public getTranslationsForPhraseAsync = async (context: ActionContext<State, any>, payload: { phrase: string }) => {
    const results = await languageApi.getTranslationsForPhraseAsync(payload.phrase);

    context.commit('setTranslations', results);
  }

  public createPhraseAsync = async (context: ActionContext<State, any>, payload: Omit<Translation, 'value'>) => {
    await languageApi.createPhraseAsync(payload.phrase, payload.language, payload.languageId);
  }

  public replacePhraseAsync = async (context: ActionContext<State, any>, payload: { phrase: string, to: string, language: string, languageId: number }) => {
    await languageApi.replacePhraseAsync(payload.phrase, payload.to, payload.language, payload.languageId);
  }

  public removePhraseAsync = async (context: ActionContext<State, any>, payload: { phrase: string, language: string, languageId: number }) => {
    await languageApi.removePhraseAsync(payload.phrase, payload.language, payload.languageId);
  }

  public replaceTranslationForPhraseAsync = async (context: ActionContext<State, any>, payload: Translation) => {
    await languageApi.replaceTranslationForPhraseAsync(payload.phrase, payload.language, payload.languageId, payload.value);
  }

  public removeTranslationForPhraseAsync = async (context: ActionContext<State, any>, payload: Translation) => {
    await languageApi.removeTranslationForPhraseAsync(payload.phrase, payload.language, payload.languageId);
  }

  public applyStateFromRoute = async (context: ActionContext<State, any>, data: LanguageMaintenanceState): Promise<Dictionary<string>> => {
    /* eslint-disable */
    console.log('languageMaintenance/applyStateFromRoute', data);
    /* eslint-enable */
    return {};
  }
}

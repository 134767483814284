import { MutationTree } from 'vuex';
import { State, Tenant, ReportGroup } from '@/store/tenant/state';

export class Mutations implements MutationTree<State> {
    [key: string]: (state: State, payload: any) => any;

    public setTenants = (state: State, tenants: Array<Tenant>) => {
      state.tenants = tenants;
    }

    public setCurrentTenant = (state: State, tenant: Tenant) => {
      state.current = tenant;
    }

    public setCurrentTenantLogo = (state: State, logo: string) => {
      state.currentLogo = logo;
    }

    public setReportGroup = (state: State, reportGroup: ReportGroup) => {
      state.selectedReportGroup = reportGroup;
    }

    public setReportGroupInactiveForAll = (state: State, reportGroupId: number) => {
      const match = state.current?.reportGroups.find((rg) => rg.id === reportGroupId);

      if (match !== undefined) {
        match.active.all = false;
      }
    }

    public setReportGroupActiveForAll = (state: State, reportGroupId: number) => {
      const match = state.current?.reportGroups.find((rg) => rg.id === reportGroupId);

      if (match !== undefined) {
        match.active.all = true;
      }
    }

    public setReportGroupInactiveForDocumentLibrary = (state: State, reportGroupId: number) => {
      const match = state.current?.reportGroups.find((rg) => rg.id === reportGroupId);

      if (match !== undefined) {
        match.active.documentLibrary = false;
      }
    }

    public setReportGroupActiveForDocumentLibrary = (state: State, reportGroupId: number) => {
      const match = state.current?.reportGroups.find((rg) => rg.id === reportGroupId);

      if (match !== undefined) {
        match.active.documentLibrary = true;
      }
    }

    public setReportGroupInactiveForConfiguration = (state: State, reportGroupId: number) => {
      const match = state.current?.reportGroups.find((rg) => rg.id === reportGroupId);

      if (match !== undefined) {
        match.active.configuration = false;
      }
    }

    public setReportGroupActiveForConfiguration = (state: State, reportGroupId: number) => {
      const match = state.current?.reportGroups.find((rg) => rg.id === reportGroupId);

      if (match !== undefined) {
        match.active.configuration = true;
      }
    }
}

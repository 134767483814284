import store from '@/store';
import { DataLoadForReportGroupValuationDateRequested } from '../events/data-load-for-report-group-valuation-date-requested';

async function handleDataLoadForReportGroupValuationDateRequested(data: DataLoadForReportGroupValuationDateRequested): Promise<void> {
  /* eslint-disable */
  console.log(`DataLoadForReportGroupValuationDateRequested event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const dataLoadId = Number(data.payload.aggregateId.split('-')[1]);

  const pushed = await store.dispatch('data/tryPushDataLoadToPage', { ...data, dataLoadId });

  if (pushed) {
    await store.dispatch('data/tryIncrementDataLoadCount', { ...data, dataLoadId });
  }
}

export default handleDataLoadForReportGroupValuationDateRequested;

import { GetterTree } from 'vuex';
import { State } from '@/store/configuration/state';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public current = (state: State) => {
    return state.current;
  }

  public entities = (state: State) => {
    if (state.current === null) {
      return [];
    }
    return state.current.entities;
  }

  public teams = (state: State) => {
    if (state.current === null) {
      return [];
    }
    return state.current.teams;
  }

  public teamMembers = (state: State) => {
    if (state.current === null) {
      return [];
    }
    return state.teamMembers;
  }

  public dates = (state: State) => {
    if (state.current === null) {
      return [];
    }
    return state.current.dates;
  }

  public currentEntity = (state: State) => {
    if (state.selectedEntityId === null) {
      return null;
    }

    return this.entities(state).find((d) => d.id === state.selectedEntityId) || this.entities(state)[0] || null;
  }

  public currentTeam = (state: State) => {
    if (state.selectedTeamId === null) {
      return null;
    }

    return this.teams(state).find((t) => t.id === state.selectedTeamId) || this.teams(state)[0] || null;
  }

  public currentDate = (state: State) => {
    if (state.selectedValuationDate === null) {
      return null;
    }

    return this.dates(state).find((d) => d.valuationDate === state.selectedValuationDate) || this.dates(state)[0] || null;
  }

  public isLoading = (state: State) => {
    return state.loadingConfiguration;
  }

  public entityContentControls = (state: State) => {
    return state.entityContentControls;
  }

  public activity = (state: State) => {
    return state.entityContentControlActivity?.activity || [];
  }

  public entitySettings = (state: State) => {
    return state.entitySettings;
  }

  public entitySettingsActivity = (state: State) => {
    return state.entitySettingsActivity?.activity || [];
  }

  public teamActivity = (state: State) => {
    return state.teamActivity?.activity || [];
  }

  public reportGroupSettings = (state: State) => {
    return state.reportGroupSettings;
  }

  public reportGroupSettingsActivity = (state: State) => {
    return state.reportGroupSettingsActivity?.activity || [];
  }

  public currentReportGroup = (state: State) => {
    return state.currentReportGroup;
  }
}

import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State, Data, DataIssuePage, TranslationIssuePage, DataLoadPage, DataFiles, DataLoad, DataDate, DataOption, DataFile } from '@/store/data/state';
import merge from 'lodash.merge';

export class Mutations implements MutationTree<State> {
    [key: string]: (state: State, payload: any) => any;

    public setCurrent = (state: State, data: Data) => {
      state.current = data;
    }

    public mergeCurrent = (state: State, data: Data) => {
      merge(state.current, data);
    }

    public setSelectedDataLoadId = (state: State, id: number | null) => {
      state.selectedDataLoadId = id;
    }

    public setCurrentValuationDate = (state: State, date: string) => {
      state.currentValuationDate = date;
    }

    public addDataIssuePage = (state: State, page: DataIssuePage) => {
      Vue.set(state.dataIssuePages, page.page - 1, page);
    }

    public addTranslationIssuePage = (state: State, page: TranslationIssuePage) => {
      Vue.set(state.translationIssuePages, page.page - 1, page);
    }

    public addDataFiles = (state: State, files: DataFiles) => {
      state.dataFiles = files.files;
    }

    public addDataLoadsPage = (state: State, page: DataLoadPage) => {
      Vue.set(state.dataLoadsPages, page.page - 1, page);
    }

    public setLoading = (state: State, value: boolean) => {
      state.loadingData = value;
    }

    public incrementDataCheckQueueCount = (state: State) => {
      if (state.current === null) {
        return;
      }

      state.current.queuedDataChecks++;
    }

    public decrementDataCheckQueueCount = (state: State) => {
      if (state.current === null) {
        return;
      }

      if (state.current.queuedDataChecks < 1) {
        return;
      }

      state.current!.queuedDataChecks--;
    }

    public setDataCheckQueueCount = (state: State, remaining: number) => {
      if (state.current === null) {
        return;
      }

      state.current!.queuedDataChecks = remaining;
    }

    public updateDataLoadProperties = (state: State, data: { source: Partial<DataLoad>, destination: DataLoad }) => {
      // NOTE(Dan): Destructure the data to exclude the report id and take the source. This is to prevent us merging the "reportId" into
      //            the actual workflow report.
      const destination = data.destination;
      const { ...source } = data.source;
      merge(destination, source);
    }

    public clearDataIssuePages = (state: State) => {
      state.dataIssuePages = [];
    }

    public clearDataLoadPages = (state: State) => {
      state.dataLoadsPages = [];
    }

    public clearTranslationIssuePages = (state: State) => {
      state.translationIssuePages = [];
    }

    public pushDataLoadToPage = (state: State, payload: { dataLoad: DataLoad, pageNumber: number }) => {
      const page = state.dataLoadsPages.find((p) => p.page === payload.pageNumber);

      if (page === undefined) {
        return;
      }

      page.dataLoads.unshift(payload.dataLoad);
      page.pageSize += 1;
    }

    public incrementDataLoadCount = (state: State) => {
      if (state.current === null) {
        return;
      }

      const date = state.current.dates.find((d) => d.valuationDate === state.currentValuationDate) || null;

      if (date === null) {
        return;
      }

      const option = date.options.dataLoads;

      if (option === undefined) {
        Vue.set(date.options, 'dataLoads', 1);
      } else {
        date.options.dataLoads! += 1;
      }
    }

    public setCurrentOption = (state: State, option: DataOption | null) => {
      state.currentOption = option;
    }

    public reset = (state: State) => {
      state.current = null;
      state.currentValuationDate = null;
      state.dataFiles = [];
      state.dataIssuePages = [];
      state.dataLoadsPages = [];
      state.loadingData = false;
      state.translationIssuePages = [];
    }

    public setStateFromRoute = (state: State, data: { configuration: Data, valuationDate: DataDate, option: DataOption, dataLoadId?: string, dataIssuePages: Array<DataIssuePage>, translationIssuePages: Array<TranslationIssuePage>, dataFiles: Array<DataFile>, dataLoadsPages: Array<DataLoadPage> }) => {
      state.current = data.configuration;
      state.currentValuationDate = data.valuationDate.valuationDate;
      state.selectedDataLoadId = data.dataLoadId !== undefined ? Number(data.dataLoadId) : null;
      state.dataIssuePages = data.dataIssuePages;
      state.translationIssuePages = data.translationIssuePages;
      state.dataFiles = data.dataFiles;
      state.dataLoadsPages = data.dataLoadsPages;
      state.currentOption = data.option;
      state.loadingData = false;
    }
}

<template>
  <validation-observer v-slot="{ passes, invalid, pending }" class="col pl-3">
    <div class="row align-items-top">
      <div class="col">
        <validation-provider
          ref="phrase"
          :rules="`required`"
          v-slot="{ valid, errors }"
          tag="div"
          :mode="validationMode"
        >
          <b-input-group v-if="isEditing">
            <b-form-textarea ref="input" rows="10" no-resize v-model="value" @keyup.esc="cancel" :state="errors[0] ? false : (valid ? true : null)" />
            <b-form-invalid-feedback v-if="errors.length > 0">{{ errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
          <p class="mt-2 description" v-else>{{ ticket.details }}</p>
        </validation-provider>
      </div>

      <div class="col-auto ml-auto pl-0" v-if="canEdit && !isEditing">
        <b-dropdown variant="link" no-caret v-if="!isEditing">
          <template v-slot:button-content>
            <b-icon icon="three-dots-vertical" />
          </template>
          <b-dropdown-item title="Edit" @click="toggleEditing">Edit</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="row">
      <div class="col-auto ml-auto pt-3" v-if="canEdit && isEditing">
        <b-button-group>
          <b-button v-if="isEditing" variant="primary" size="md" :disabled="(invalid && !pending) || ticket.details === value" @click="passes(save)" title="Save">
            <b-icon icon="check2" aria-hidden="true"></b-icon> Save
          </b-button>
          <b-button v-if="isEditing" variant="outline-primary" size="md" @click="cancel" title="Cancel">
            <b-icon icon="X" aria-hidden="true"></b-icon> Cancel
          </b-button>
        </b-button-group>
      </div>
    </div>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ValidationFlags } from 'vee-validate/dist/types/types';
import { namespace } from 'vuex-class';
import { UserResponse } from '@/api/responses/user/user-response';
import { SupportTicketDetails } from '../../store/support/state';

const userModule = namespace('user');

type ValidationModeContext = { errors: Array<string>, value: any, flags: ValidationFlags };

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EditableSupportTicketDescription extends Vue {
  @userModule.Getter('current') private currentUser!: UserResponse;
  @Prop({ required: true }) public ticket!: SupportTicketDetails;

  get canEdit(): boolean {
    return this.currentUser.roles.administrator || this.ticket.createdBy === this.currentUser.name;
  }

  isEditing = false;
  value: string | null = null;

  toggleEditing(): void {
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      this.value = this.ticket?.details;
      this.$nextTick(() => {
        (this.$refs.input as BFormInput).focus();
      });
    }
  }

  save(): void {
    if (this.ticket.details === null || this.ticket.details === this.value) {
      return;
    }

    this.toggleEditing();

    this.$emit('saved', { from: this.ticket.details, to: this.value! });
  }

  cancel(): void {
    this.toggleEditing();
  }

  validationMode({ errors }: ValidationModeContext) {
    if (errors.length) {
      return {
        on: ['input', 'change'],
      };
    }

    return {
      on: ['input', 'change'],
    };
  }

  @Watch('ticket')
  onTicketChanged(ticket: SupportTicketDetails): void {
    if (ticket === null || ticket === undefined) {
      this.value = null;
      return;
    }

    this.value = ticket?.details ?? '';
  }
}
</script>

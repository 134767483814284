<template>
  <div class="workflow-reports" :aria-busy="loading">
    <b-table class="workflow-reports-table"
             ref="table"
             striped
             hover
             selectable
             :sticky-header="headerStyle"
             show-empty
             select-mode="single"
             sort-icon-left
             :tbody-tr-class="applyTableRowClasses"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :fields="fields"
             :items="filteredResults"
             :busy="loading"
             primary-key="id"
             @row-clicked="onRowClicked"
             @row-selected="onRowSelected"
             @sort-changed="onSortChanged"
    >
      <template v-slot:head(selected)>
        <b-checkbox v-model="allReportsSelected" @change="onSelectAll" />
      </template>

      <template v-slot:head(id)="data">
        <span>{{ data.label }}<table-filter-header :ref="`${data.label}-filter`" v-model="idFilter" @input="onIdFiltered" :show="showFilter" @cleared="onFiltersCleared" @hidden="onShowFilter(data.label)" @shown="onShowFilter(data.label)" /></span>
      </template>

      <template v-slot:head(name)="data">
        <span>{{ data.label }}<table-filter-header :ref="`${data.label}-filter`" v-model="nameFilter" @input="onNameFiltered" :show="showFilter" @cleared="onFiltersCleared" @hidden="onShowFilter(data.label)" @shown="onShowFilter(data.label)" /></span>
      </template>

      <template v-slot:head(numberOfPages)="data">
        <span>{{ data.label }}<span v-if="showFilterCount && !hasActionsColumn" class="mb-0 float-right">{{ filteredResults.length }}/{{ reports.length }}</span></span>
      </template>

      <template v-slot:head(actions)>
        <span v-if="hasActionsColumn"><p v-if="showFilterCount" class="mb-0 text-right">{{ filteredResults.length }}/{{ reports.length }}</p></span>
      </template>

      <template v-slot:head(renderingStatus)="data">
        <span>{{ data.label }}</span>
        <span @click.stop="onReleasePending" style="cursor: pointer;" v-if="filteredResults.length > 0 && filteredResults.some((r) => r.renderingStatus === 'RenderPending')" title="Release pending renders">
          <svg xmlns="http://www.w3.org/2000/svg" height="1.25rem" width="1.25rem" viewBox="-27 0 512 512" class="position-relative" style="top: -2px;">
            <g>
              <path d="m32.308594 256.488281c0 65.777344 32.679687 123.921875 82.6875 159.082031 8.8125 6.199219 9.28125 19.070313.816406 25.734376l-.238281.1875c-5.527344 4.351562-13.277344 4.640624-19.035157.597656-58.378906-41-96.539062-108.847656-96.539062-185.601563 0-124.496093 103.050781-225.5625 227.296875-226.625v32.316407c-106.414063 1.054687-194.988281 87.648437-194.988281 194.308593zm0 0" fill="#1ed688" data-original="#1ED688" class="active-path" style="fill:#EB7D16" data-old_color="#1ed688" />
              <path d="m233.230469.964844 59.375 40.613281c3.179687 2.175781 3.179687 6.867187 0 9.042969l-59.375 40.613281c-3.636719 2.488281-8.570313-.117187-8.570313-4.519531v-81.226563c0-4.40625 4.933594-7.007812 8.570313-4.523437zm0 0" fill="#1ab975" data-original="#1AB975" class="" style="fill:#EB7D16" data-old_color="#1ab975" />
              <path d="m425.382812 255.511719c0-65.777344-32.683593-123.921875-82.691406-159.082031-8.8125-6.199219-9.28125-19.070313-.816406-25.734376l.238281-.1875c5.527344-4.351562 13.277344-4.640624 19.035157-.597656 58.382812 41 96.539062 108.847656 96.539062 185.601563 0 124.496093-102.875 225.5625-227.125 226.625v-32.316407c106.414062-1.054687 194.820312-87.644531 194.820312-194.308593zm0 0" fill="#1ed688" data-original="#1ED688" class="active-path" style="fill:#EB7D16" data-old_color="#1ed688" />
              <path d="m224.460938 511.035156-59.375-40.613281c-3.179688-2.175781-3.179688-6.867187 0-9.042969l59.375-40.613281c3.632812-2.488281 8.570312.117187 8.570312 4.519531v81.226563c0 4.40625-4.9375 7.011719-8.570312 4.523437zm0 0" fill="#1ab975" data-original="#1AB975" class="" style="fill:#EB7D16" data-old_color="#1ab975" />
              <path d="m329.628906 230.820312-97.257812 80.65625-24.519532 20.335938c-5.777343 4.785156-13.089843 7.382812-20.527343 7.382812-1.238281 0-2.476563-.070312-3.726563-.214843-8.675781-1.019531-16.660156-5.621094-21.898437-12.617188l-37.820313-50.414062c-8.539062-11.386719-6.230468-27.550781 5.160156-36.105469 11.398438-8.539062 27.5625-6.230469 36.105469 5.160156l25.53125 34.042969.207031-.167969 105.828126-87.765625c10.964843-9.085937 27.222656-7.570312 36.3125 3.394531 9.089843 10.964844 7.570312 27.222657-3.394532 36.3125zm0 0" fill="#1ed688" data-original="#1ED688" class="active-path" style="fill:#EB7D16" data-old_color="#1ed688" />
              <path d="m329.628906 230.820312-97.257812 80.65625c-15.90625-7.941406-29.894532-18.917968-41.488282-32.597656l105.828126-87.765625c10.964843-9.085937 27.222656-7.570312 36.3125 3.394531 9.089843 10.964844 7.570312 27.222657-3.394532 36.3125zm0 0" fill="#35e298" data-original="#35E298" class="" style="fill:#EB7D16" data-old_color="#35e298" />
            </g>
          </svg>
        </span>
      </template>

      <template v-slot:cell(selected)="{ item }">
        <b-checkbox :key="`${item.id}_selected`" v-if="canShowSelect(item)" v-model="item.selected" @input="onReportSelectionChanged($event, item)" />
      </template>

      <template v-slot:cell(deadlineDate)="{ item }">
        <workflow-report-status :key="`${item.id}_deadlineDate`" :item="item"></workflow-report-status>
      </template>

      <template v-slot:cell(lastRenderedDate)="{ item }">
        {{ item.lastRenderedDate | datetime }}
      </template>

      <template v-slot:cell(completedDate)="{ item }">
        {{ item.completedDate | datetime }}
      </template>

      <template v-slot:cell(commentary)="{ item }">
        {{ item.commentary }}
      </template>

      <template v-slot:cell(renderingStatus)="{ item }">
        <workflow-report-rendering-status :key="`${item.id}_renderingStatus`" :item="item" />
      </template>

      <template v-slot:cell(hasNewData)="{ item }">
        <div class="workflow-report-rendering-status is-background-warning text-center" :title="'New data available'" v-if="item.hasNewData">
          <div class="text">i</div>
        </div>
      </template>

      <template v-slot:cell(flagged)="{ item }">
        <workflow-report-flag-icon :key="`${item.id}_flagged`" :item="item" v-if="item.flagged" :flag-colour="flagColours.filter(f => f.flagNumber === item.flagColour)[0]" />
      </template>

      <template v-slot:cell(numberOfPages)="{ item }">
        <workflow-report-page-count :key="`${item.id}_numberOfPages`" :item="item" v-if="item.numberOfPages > 0">{{ item.numberOfPages }}</workflow-report-page-count>
        <span v-else>&ndash;</span>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-button-group>
          <b-button :key="`${item.id}_actions_view`" size="sm" variant="outline-primary" v-if="item.fileTypePreview && item.filenamePreview" :class="{ stale: fileIsStale(item) }" :title="getViewFileTooltip(item)" @click="onViewReportClicked(item)">
            <view-pdf-icon aria-hidden="true" v-if="getFileTypePrievew(item) === 'pdf'" />
            <view-powerpoint-icon aria-hidden="true" v-else-if="getFileTypePrievew(item) === 'pptx'" />
            <view-word-icon aria-hidden="true" v-else-if="getFileTypePrievew(item) === 'docx'" />
            <view-excel-icon aria-hidden="true" v-else-if="['xls', 'xlsx'].includes(getFileTypePrievew(item))" />
            <b-icon icon="eye" aria-hidden="true" v-else />
          </b-button>
          <b-button :key="`${item.id}_actions_download`" size="sm" variant="outline-primary" v-if="item.fileTypeDownload && item.filenameDownload && getFileTypeDownload(item) !== 'pdf'" :class="{ stale: fileIsStale(item) }" :title="getDownloadFileTooltip(item)" @click="onDownloadClicked(item)">
            <download-powerpoint-icon aria-hidden="true" v-if="getFileTypeDownload(item) === 'pptx'" />
            <download-word-icon aria-hidden="true" v-else-if="getFileTypeDownload(item) === 'docx'" />
            <download-excel-icon aria-hidden="true" v-else-if="['xls', 'xlsx'].includes(getFileTypeDownload(item))" />
            <b-icon icon="download" aria-hidden="true" v-else />
          </b-button>

          <b-dropdown lazy :key="`${item.id}_actions_${item.actions}`" text="Actions" :no-caret="true" :disabled="!canShowActions(item)" :class="{ disabled: !canShowActions(item), 'pr-3': true }" variant="outline-primary" size="sm" right :popper-opts="{ modifiers: { computeStyle: { gpuAcceleration: false } } }">
            <template v-slot:button-content>
              <div class="three-dots-vertical" aria-hidden="true">
              </div>
            </template>
            <b-dropdown-item :class="{ stale: fileIsStale(item) }" v-if="item.filenamePreview!==null" @click="onViewReportClicked(item)">Preview {{ item.fileTypePreview }}</b-dropdown-item>
            <b-dropdown-item v-if="item.filenameDownload" :class="{ stale: fileIsStale(item) }" @click="onDownloadClicked(item)">Download {{ item.fileTypeDownload }}</b-dropdown-item>
            <b-dropdown-divider v-if="item.filenamePreview && item.filenameDownload && isNotRendering(item) && !item.actions.canRunReplacement && !item.actions.canIgnoreNewDataWarnings" />
            <b-dropdown-item v-if="item.actions.canFlag" @click="onFlagClicked(item)">Flag</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canEditFlag" @click="onEditFlagClicked(item)">Edit flag</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canUnflag" @click="onUnflagClicked(item)">Unflag</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canSendDelayedCommentary" @click="onSendDelayedCommentaryClicked(item)">Send delayed commentary now</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canApprove && isNotRendering(item)" @click="onApproveClicked(item)">Approve</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canReject && isNotRendering(item)" @click="onRejectClicked(item)">Reject</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canReturnToApprovalOne && isNotRendering(item)" @click="onReturnForApprovalOneClicked(item)">Return to {{ getApprovalStageName(3) }}</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canReturnToApprovalTwo && isNotRendering(item)" @click="onReturnForApprovalTwoClicked(item)">Return to {{ getApprovalStageName(4) }}</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canReturnToApprovalThree && isNotRendering(item)" @click="onReturnForApprovalThreeClicked(item)">Return to {{ getApprovalStageName(5) }}</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canReturnToApprovalFour && isNotRendering(item)" @click="onReturnForApprovalFourClicked(item)">Return to {{ getApprovalStageName(6) }}</b-dropdown-item>
            <b-dropdown-divider v-if="(item.actions.canReAcquireCommentary || item.actions.canCancelReAcquireCommentary || item.actions.canResendCommentary || item.actions.canResendApprovalEmail || item.actions.canResendApprovalEmailForDeadline || item.actions.canRunReplacement || item.actions.canIgnoreNewDataWarnings || item.actions.canReleaseDraftData || item.actions.canRecheckData) && isNotRendering(item)" />
            <b-dropdown-item v-if="item.actions.canReAcquireCommentary && isNotRendering(item)" @click="onReacquireCommentaryClicked(item)">Re-aquire commentary</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canCancelReAcquireCommentary && isNotRendering(item)" @click="onCancelCommentaryReaquisitionClicked(item)">Cancel re-aquiring commentary</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canResendCommentary && isNotRendering(item)" @click="onResendCommentaryEmailClicked(item)">Resend commentary e-mail</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canResendApprovalEmail && isNotRendering(item)" @click="onResendApprovalEmailClicked(item)">Resend approval e-mail</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canResendApprovalEmailForDeadline && isNotRendering(item)" @click="onResendApprovalEmailForDeadlineClicked(item)">Resend approval deadline e-mail</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canRunReplacement" @click="onRunReplacementClicked(item)">Run replacement</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canIgnoreNewDataWarnings" @click="onIgnoreNewDataWarningsClicked(item)">Ignore new data warnings</b-dropdown-item>
            <b-dropdown-item v-if="item.actions.canReleaseDraftData && isNotRendering(item)" @click="onReleaseDraftClicked(item)">Release draft data</b-dropdown-item>
            <b-dropdown-item v-if="canShowRecheckData(item)" @click="onRecheckDataClicked(item)">Re-check data</b-dropdown-item>
            <b-dropdown-item v-if="canShowReRender(item)" @click="onRerenderClicked(item)">Re-render</b-dropdown-item>
            <b-dropdown-divider v-if="item.actions.canDelete && isNotRenderingOrAdministrator(item)" variant="danger"></b-dropdown-divider>
            <b-dropdown-item v-if="item.actions.canDelete && isNotRenderingOrAdministrator(item)" variant="danger" @click="onDeleteClicked(item)">Delete</b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </template>
    </b-table>

    <run-replacement-workflow-report-dialog v-if="showReplacementDialog" :report="reportSelectedForReplacement" :show="showReplacementDialog" @ok="onRunReplacementOk" @cancel="onRunReplacementCancel" @close="onRunReplacementCancel" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { Workflow, WorkflowReport, WorkflowOption, WorkflowDate, WorkflowFlag } from '@/store/workflow/state';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { DateTime } from 'luxon';
import WorkflowReportStatus from './workflow-report-status.vue';
import WorkflowReportPageCount from './workflow-report-page-count.vue';
import WorkflowReportRenderingStatus from './workflow-report-rendering-status.vue';
import TableFilterHeader from '@/components/table/table-header-filter.vue';
import RunReplacementWorkflowReportDialog from './run-replacement-workflow-report-dialog.vue';
import { BTable, BvTableFieldArray, BvTableField } from 'bootstrap-vue';
import { UserResponse } from '../../api/responses/user/user-response';
import ViewPdfIcon from '@/components/icons/view-pdf-icon.vue';
import DownloadPdfIcon from '@/components/icons/download-pdf-icon.vue';
import ViewExcelIcon from '@/components/icons/view-excel-icon.vue';
import DownloadExcelIcon from '@/components/icons/download-excel-icon.vue';
import ViewWordIcon from '@/components/icons/view-word-icon.vue';
import ViewPowerpointIcon from '@/components/icons/view-powerpoint-icon.vue';
import DownloadPowerpointIcon from '@/components/icons/download-powerpoint-icon.vue';
import DownloadWordIcon from '@/components/icons/download-word-icon.vue';
import WorkflowReportFlagIcon from './workflow-report-flag.vue';
import { parseFilters, applyFilters } from '@/components/table/input-filter';
import { FileUtils } from '@/utilities/file.utils';

const workflowModule = namespace('workflow');
const tenantModule = namespace('tenant');
const environmentModule = namespace('environment');
const userModule = namespace('user');

@Component({
  components: {
    WorkflowReportStatus,
    WorkflowReportPageCount,
    WorkflowReportRenderingStatus,
    TableFilterHeader,
    RunReplacementWorkflowReportDialog,
    ViewPdfIcon,
    DownloadPdfIcon,
    ViewExcelIcon,
    DownloadExcelIcon,
    ViewWordIcon,
    ViewPowerpointIcon,
    DownloadPowerpointIcon,
    DownloadWordIcon,
    WorkflowReportFlagIcon,
  },
})
export default class WorkflowReportsTable extends Mixins(BvToastMixin) {
  @Prop({ type: String, required: true }) step!: string;
  @Prop({ type: Array, required: true }) reports!: Array<WorkflowReport>;
  @Prop({ type: Boolean, required: true }) loading!: boolean;
  @tenantModule.Getter selectedReportGroup!: { id: number, name: string };
  @workflowModule.Getter('current') selectedWorkflow!: Workflow;
  @environmentModule.Getter('current') environment!: { environment: string, version: string | null, runtime: string } | null;
  @workflowModule.Getter selectedReport!: WorkflowReport | null;
  @workflowModule.Getter selectedValuationDate!: WorkflowDate | null;
  @workflowModule.Getter('options') currentWorkflowOptions!: Array<WorkflowOption>;
  @workflowModule.Getter flagColours!: Array<WorkflowFlag>;
  @userModule.Getter('current') currentUser!: UserResponse;
  showFilter = false;
  sortBy: string | null = null;
  sortDesc = false;
  idFilter: string | null = null;
  nameFilter: string | null = null;
  preventScrollLoading = false;
  selectedRowIndex = -1;
  allReportsSelected = false;
  reportSelectedForReplacement: WorkflowReport | null = null;
  showReplacementDialog = false;
  reportIdsToSelectedState: { [key: number]: boolean } = {};

  get showFilterCount(): boolean {
    return this.idFilter !== null || this.nameFilter !== null;
  }

  get hasActionsColumn(): boolean {
    return this.step !== 'Deleted';
  }

  get fields(): BvTableFieldArray {
    let fields: Array<{ key: string } & BvTableField> = [
      { key: 'selected', label: '', sortable: false, class: ['col-auto'], thClass: ['align-middle'] },
      { key: 'deadlineDate', label: '', class: ['col-auto'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); }, sortByFormatted: true },
      { key: 'deadlineBusinessDay', label: 'DL Day', tdClass: ['text-center'], class: ['col-auto', 'text-nowrap'], sortable: true },
      { key: 'id', label: 'ID', class: ['col-1'], sortable: true },
      { key: 'name', label: 'Description', class: ['col-10'], sortable: true, tdAttr: (value: string | null, key: number, item: WorkflowReport) => { if (item.active) { return {}; } return { title: 'This report is no longer scheduled' }; } },
      { key: 'commentary', label: 'Commentary', class: ['col-auto'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return Number(value.split('/')[0]) || null; }, sortByFormatted: true },
      { key: 'renderingStatus', label: '', class: ['col-auto'], sortable: false },
      { key: 'lastRenderedDate', label: 'Last Rendered', class: ['col-auto', 'text-nowrap'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); }, sortByFormatted: true },
      { key: 'flagged', label: '', class: ['col-auto'], sortable: true },
      { key: 'numberOfPages', label: 'Pages', class: ['col-auto'], sortable: true },
      { key: 'actions', label: '', tdClass: ['text-right'], class: ['col-auto'] }
    ];

    let step = this.step;

    if (step.startsWith('Approval')) {
      step = 'Approval';
    }

    if (step === 'Approval') {
      fields = [
        { key: 'selected', label: '', sortable: false, class: ['col-auto'], thClass: ['align-middle'] },
        { key: 'deadlineDate', label: '', class: ['col-auto'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); }, sortByFormatted: true },
        { key: 'deadlineBusinessDay', label: 'Deadline BD', class: ['col-auto'], sortable: true },
        { key: 'id', label: 'ID', class: ['col-1'], sortable: true },
        { key: 'name', label: 'Description', class: ['col-8'], sortable: true, tdAttr: (value: string | null, key: number, item: WorkflowReport) => { if (item.active) { return {}; } return { title: 'This report is no longer scheduled' }; } },
        { key: 'renderingStatus', label: '', class: ['col-auto'], sortable: false },
        { key: 'lastRenderedDate', label: 'Last Rendered', class: ['col-auto', 'text-nowrap'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); }, sortByFormatted: true },
        { key: 'flagged', label: '', class: ['col-auto'], sortable: true },
        { key: 'numberOfPages', label: 'Pages', class: ['col-auto'], sortable: true },
        { key: 'actions', label: '', tdClass: ['text-right'], class: ['col-auto'] }
      ];
    }

    if (step === 'Completed') {
      fields = [
        { key: 'selected', label: '', sortable: false, class: ['col-auto'], thClass: ['align-middle'] },
        { key: 'deadlineDate', label: '', class: ['col-auto'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); }, sortByFormatted: true },
        { key: 'id', label: 'ID', class: ['col-1'], sortable: true },
        { key: 'name', label: 'Description', class: ['col-8'], sortable: true, tdAttr: (value: string | null, key: number, item: WorkflowReport) => { if (item.active) { return {}; } return { title: 'This report is no longer scheduled' }; } },
        { key: 'hasNewData', label: '', class: ['col-auto'], sortable: true },
        { key: 'renderingStatus', label: '', class: ['col-auto'], sortable: true },
        { key: 'completedDate', label: 'Completed', tdClass: ['col-2'], sortable: true },
        { key: 'flagged', label: '', class: ['col-auto'], sortable: true },
        { key: 'numberOfPages', label: 'Pages', class: ['col-auto'], sortable: true },
        { key: 'actions', label: '', tdClass: ['text-right'], class: ['col-auto'] }
      ];
    }

    if (step === 'Deleted') {
      fields = [
        { key: 'selected', label: '', sortable: false, class: ['col-auto'], thClass: ['align-middle'] },
        { key: 'id', label: 'ID', class: ['col-auto'], sortable: true },
        { key: 'name', label: 'Description', class: ['col-10'], sortable: true, tdAttr: (value: string | null, key: number, item: WorkflowReport) => { if (item.active) { return {}; } return { title: 'This report is no longer scheduled' }; } },
        { key: 'renderingStatus', label: '', class: ['col-auto'], sortable: false },
        { key: 'flagged', label: '', class: ['col-auto'], sortable: true },
        { key: 'numberOfPages', label: 'Pages', class: ['col-1'], sortable: true }
      ];
    }

    if (!this.selectedWorkflow.hasCommentary) {
      const index = fields.findIndex((f) => f.key === 'commentary');

      if (index > -1) {
        fields.splice(index, 1);
      }
    }

    if (!this.selectedWorkflow.hasBatchActionsAvailable) {
      const index = fields.findIndex((f) => f.key === 'selected');

      if (index > -1) {
        fields.splice(index, 1);
      }
    }

    if (!this.reports.some((r) => r.flagged)) {
      const index = fields.findIndex((f) => f.key === 'flagged');

      if (index > -1) {
        fields.splice(index, 1);
      }
    }

    if (!this.selectedWorkflow.displayDeadlineBusinessDay) {
      const index = fields.findIndex((f) => f.key === 'deadlineBusinessDay');

      if (index > -1) {
        fields.splice(index, 1);
      }
    }

    return fields;
  }

  get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  get filteredResults(): Array<WorkflowReport & { selected: boolean }> {
    const reports = this.reports;

    if (reports == null || reports.length < 1) {
      return [];
    }

    if (!this.idFilter && !this.nameFilter) {
      return reports.map((r) => ({ ...r, selected: ((this.canShowSelect(r) && this.reportIdsToSelectedState[r.id]) || false) }));
    }

    if (this.idFilter && !this.nameFilter) {
      const filters = parseFilters(this.idFilter);
      return reports.filter((r) => applyFilters(r, x => x.id, filters)).map((r) => ({ ...r, selected: ((this.canShowSelect(r) && this.reportIdsToSelectedState[r.id]) || false) }));
    }

    if (!this.idFilter && this.nameFilter) {
      const filters = parseFilters(this.nameFilter);
      return reports.filter((r) => applyFilters(r, x => x.name, filters, { mode: 'include' })).map((r) => ({ ...r, selected: ((this.canShowSelect(r) && this.reportIdsToSelectedState[r.id]) || false) }));
    }

    const idFilters = parseFilters(this.idFilter!);
    const nameFilters = parseFilters(this.nameFilter!);

    return reports
      .filter((r) => applyFilters(r, x => x.id, idFilters))
      .filter((r) => applyFilters(r, x => x.name, nameFilters, { mode: 'include' }))
      .map((r) => ({ ...r, selected: ((this.canShowSelect(r) && this.reportIdsToSelectedState[r.id]) || false) }));
  }

  created() {
    this.$root.$on('bulk-workflow-action-completed', this.onBulkActionCompleted);
  }

  beforeDestroy() {
    this.$root.$off('bulk-workflow-action-completed', this.onBulkActionCompleted);
  }

  async onRowClicked(item: WorkflowReport, index: number): Promise<void> {
    if (index === this.selectedRowIndex) {
      this.selectedRowIndex = -1;
      this.$emit('workflow-report-row-deselected');
      const { reportId, ...params } = this.$route.params;
      try {
        await this.$router.push({ name: 'workflow', params: { ...params } });
      } catch (e) {
        // TODO(Dan): Navigation errors.
      }
    } else {
      this.selectedRowIndex = index;
      this.$emit('workflow-report-row-selected', item);
      try {
        await this.$router.push({ name: 'workflow', params: { ...this.$route.params, reportId: item.id.toString() } });
      } catch (e) {
        // TODO(Dan): Navigation errors.
      }
    }
  }

  async onRowSelected(items: Array<WorkflowReport>): Promise<void> {
    if (this.selectedRowIndex > -1 && this.selectedReport !== null) {
      return;
    }

    if (items.length < 1 || items[0] === undefined) {
      return;
    }

    const item = items[0];
    const index = this.filteredResults.findIndex((r) => r.id === item.id);

    this.selectedRowIndex = index;
  }

  @Watch('sortDesc')
  onSortDescChanged(to: boolean, from: boolean) {
    if (!to && from) {
      this.sortBy = null;
    }
  }

  onSortChanged(context: any): void {
    if (this.selectedReport === null) {
      return;
    }

    const table = (this.$refs.table as any);
    const computedItems = table.computedItems as Array<WorkflowReport>;
    const newIndex = computedItems.findIndex((r) => r.id === this.selectedReport!.id);
    this.selectedRowIndex = newIndex;
    (this.$refs.table as BTable).selectRow(newIndex);
  }

  onShowFilter(id: string): void {
    this.showFilter = !this.showFilter;

    if (this.showFilter) {
      this.$nextTick(() => (this.$refs[`${id}-filter`] as TableFilterHeader).focus());
    }
  }

  onIdFiltered = debounce(this.onIdFilteredInternal, 250);
  onIdFilteredInternal(value: string | null) {
    this.preventScrollLoading = true;
    this.idFilter = value;
    this.$emit('workflow-report-table-id-filter-changed', value);
    this.preventScrollLoading = false;

    if (this.filteredResults.length < 1 || this.selectedReport === null) {
      return;
    }

    if (!this.filteredResults.some((r) => r.id === this.selectedReport!.id)) {
      this.selectedRowIndex = -1;
    }
  }

  onNameFiltered = debounce(this.onNameFilteredInternal, 250);
  onNameFilteredInternal(value: string | null) {
    this.preventScrollLoading = true;
    this.nameFilter = value;
    this.$emit('workflow-report-table-name-filter-changed', value);
    this.preventScrollLoading = false;

    if (this.filteredResults.length < 1 || this.selectedReport === null) {
      return;
    }

    if (!this.filteredResults.some((r) => r.id === this.selectedReport!.id)) {
      this.selectedRowIndex = -1;
    }
  }

  mounted(): void {
    let lastScrollPos: number = 0;
    (this.$el.querySelector('.workflow-reports-table') as HTMLDivElement).addEventListener('scroll', throttle((e) => {
      const element = this.$el.querySelector('.workflow-reports-table') as HTMLDivElement;
      const offsetHeight = element.offsetHeight;
      const scrolled = element.scrollTop;
      const height = element.scrollHeight;

      // NOTE(Dan): We're scrolling back up the list from the bottom, so we don't want to trigger here...
      if (lastScrollPos >= scrolled) {
        lastScrollPos = scrolled;
        return;
      }

      lastScrollPos = scrolled;

      if (offsetHeight + scrolled >= (height * 0.9) && !this.preventScrollLoading) {
        this.$emit('workflow-report-table-scroll-completed', { height, offsetHeight, scrolled });
      }
    }, 1000));

    this.sortBy = this.getInitialSortBy();
    this.sortDesc = this.getInitialSortDesc();
    this.$nextTick(() => {
      if (this.selectedReport !== null) {
        const index = ((this.$refs.table as BTable).computedItems as Array<WorkflowReport>).findIndex((r) => r.id === this.selectedReport!.id);

        if (index !== this.selectedRowIndex) {
          this.selectedRowIndex = index;
          (this.$refs.table as BTable).selectRow(this.selectedRowIndex);
          this.scrollToSelectedReport();
        }
      }
    });
  }

  updated(): void {
    this.$nextTick(() => {
      if (this.selectedReport !== null) {
        const index = ((this.$refs.table as BTable).computedItems as Array<WorkflowReport>).findIndex((r) => r.id === this.selectedReport!.id);

        if (index !== this.selectedRowIndex) {
          this.selectedRowIndex = index;
          (this.$refs.table as BTable).selectRow(this.selectedRowIndex);
        } else if (!(this.$refs.table as BTable).isRowSelected(index)) {
          (this.$refs.table as BTable).selectRow(this.selectedRowIndex);
        }
      }
    });
  }

  canShowActions(item: WorkflowReport): boolean {
    if (item.replaced && !item.actions.canFlag && !item.actions.canUnflag) {
      return false;
    }

    if (Object.keys(item.actions).length < 1) {
      return false;
    }

    if (Object.values(item.actions).every((a) => !a)) {
      return false;
    }

    if (item.renderingStatus === 'RenderPending' && (item.actions.canReRender || item.actions.canRecheckData)) {
      return this.canShowReRender(item) || this.canShowRecheckData(item);
    }

    if (item.renderingStatus === 'AwaitingBatchDistribution' && item.actions.canRunReplacement) {
      return true;
    }

    if (item.renderingStatus !== 'Blank' && item.renderingStatus !== 'Error' && !this.currentUser.roles.administrator) {
      return false;
    }

    return true;
  }

  isNotRendering(item: WorkflowReport): boolean {
    return item.renderingStatus === 'Blank';
  }

  isNotRenderingOrAdministrator(item: WorkflowReport): boolean {
    return item.renderingStatus === 'Blank' || !!this.currentUser.roles.administrator;
  }

  canShowReRender(item: WorkflowReport): boolean {
    if (item.renderingStatus === 'RenderPending' || item.renderingStatus === 'Error') {
      return item.actions.canReRender;
    }

    if (item.renderingStatus !== 'Blank') {
      return false;
    }

    return item.actions.canReRender;
  }

  canShowRecheckData(item: WorkflowReport): boolean {
    if (item.renderingStatus === 'RenderPending' || item.renderingStatus === 'Error') {
      return item.actions.canRecheckData;
    }

    if (item.renderingStatus !== 'Blank') {
      return false;
    }

    return item.actions.canRecheckData;
  }

  canShowSelect(item: WorkflowReport): boolean {
    if (item.replaced) {
      return false;
    }

    if (Object.keys(item.actions).length < 1) {
      return false;
    }

    if (!Object.values(item.actions).some((a) => a)) {
      return false;
    }

    if (item.renderingStatus !== 'Blank' && this.step !== 'Completed') {
      return this.canShowReRender(item);
    }

    return true;
  }

  onSelectAll(value: boolean): void {
    const selectableReports = this.filteredResults.filter((r) => this.canShowSelect(r));

    for (const item of selectableReports) {
      if (!item.replaced && Object.keys(item.actions).length > 0) {
        item.selected = value;
      }
    }

    this.allReportsSelected = value;

    this.$root.$emit(value ? 'all-workflow-reports-selected' : 'all-workflow-reports-deselected', [...selectableReports]);
  }

  onReportSelectionChanged(selected: boolean, report: WorkflowReport & { selected: boolean }): void {
    const evt = selected ? 'workflow-report-selected' : 'workflow-report-deselected';
    this.$root.$emit(evt, { ...report, selected });
    this.reportIdsToSelectedState[report.id] = selected;
  }

  applyTableRowClasses(item: WorkflowReport, type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy') {
    if (item !== null && type !== 'table-busy') {
      return { replaced: item.replaced, inactive: !item.active };
    }
  }

  async onDownloadClicked(item: WorkflowReport): Promise<void> {
    try {
      const data = await this.$store.dispatch('workflow/downloadWorkflowReportAsync', { workflowReportId: item.id, reportGroupId: this.selectedReportGroup.id, isPreview: false }) as Blob;
      FileUtils.downloadFile(data, item.fileName!);
    } catch (e) {
      this.showErrorToast('Could not download file. Please try again.');
    }
  }

  async onViewReportClicked(item: WorkflowReport): Promise<void> {
    try {
      const data = await this.$store.dispatch('workflow/downloadWorkflowReportAsync', { workflowReportId: item.id, reportGroupId: this.selectedReportGroup.id, isPreview: true }) as Blob;
      FileUtils.previewFile(data);
    } catch (e) {
      this.showErrorToast('Could not view file. Please try again.');
    }
  }

  onApproveClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-approve-clicked', item);
  }

  onRejectClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-reject-clicked', item);
  }

  onFlagClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-flag-clicked', item);
  }

  onEditFlagClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-edit-flag-clicked', item);
  }

  onUnflagClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-unflag-clicked', item);
  }

  onSendDelayedCommentaryClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-send-delayed-commentary-clicked', item);
  }

  onCancelCommentaryReaquisitionClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-cancel-commentary-reaquisition-clicked', item);
  }

  onDeleteClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-delete-clicked', item);
  }

  onReacquireCommentaryClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-reacquire-commentary-clicked', item);
  }

  onRerenderClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-rerender-clicked', item);
  }

  onRecheckDataClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-recheck-data-clicked', item);
  }

  onReleaseDraftClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-release-draft-clicked', item);
  }

  onResendCommentaryEmailClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-resend-commentary-clicked', item);
  }

  onResendApprovalEmailClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-resend-approval-clicked', item);
  }

  onResendApprovalEmailForDeadlineClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-resend-approval-for-deadline-clicked', item);
  }

  onReturnForApprovalOneClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-return-for-approval-one-clicked', item);
  }

  onReturnForApprovalTwoClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-return-for-approval-two-clicked', item);
  }

  onReturnForApprovalThreeClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-return-for-approval-three-clicked', item);
  }

  onReturnForApprovalFourClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-return-for-approval-four-clicked', item);
  }

  onIgnoreNewDataWarningsClicked(item: WorkflowReport) {
    this.$root.$emit('workflow-report-ignore-data-issues-clicked', item);
  }

  onRunReplacementClicked(item: WorkflowReport) {
    this.reportSelectedForReplacement = item;
    this.showReplacementDialog = true;
  }

  async onRunReplacementOk(result: { report: WorkflowReport, comments: string | null, useApprovalWorkflow: number, useStandardDistribution: boolean, includeCommentary: boolean }): Promise<void> {
    try {
      await this.$store.dispatch('workflow/runReplacementForWorkflowReportAsync', { workflowReportId: result.report.id, comments: result.comments, useApprovalWorkflow: result.useApprovalWorkflow, useStandardDistribution: result.useStandardDistribution, includeCommentary: result.includeCommentary, reportGroupId: this.selectedReportGroup.id });

      this.showSuccessToast(`Successfully run replacement for report #${result.report.id}.`);
    } catch (e) {
      this.showErrorToast('Could not run replacement. Please try again.');
    }

    this.onRunReplacementCancel();
  }

  onRunReplacementCancel(): void {
    this.showReplacementDialog = false;
    this.reportSelectedForReplacement = null;
  }

  onFiltersCleared(): void {
    this.idFilter = null;
    this.nameFilter = null;
  }

  @Watch('selectedReportGroup')
  onSelectedReportGroupChanged(item: { id: number, name: string }): void {
    this.idFilter = null;
    this.nameFilter = null;
    this.showFilter = false;
    this.onSelectAll(false);
  }

  @Watch('selectedValuationDate')
  onValuationDateChanged(): void {
    this.onSelectAll(false);
  }

  @Watch('$route')
  onRouteChanged(): void {
    const { reportId } = this.$route.params;

    if (reportId === undefined) {
      return;
    }

    this.scrollToSelectedReport();
  }

  getInitialSortBy(): string | null {
    const step = this.step;

    if (step === 'Completed') {
      return 'completedDate';
    }

    if (this.fields.some((f) => (f as { key: string }).key === 'lastRenderedDate') && this.selectedWorkflow?.hasPresortingEnabled) {
      return 'lastRenderedDate';
    }

    return null;
  }

  getInitialSortDesc(): boolean {
    const step = this.step;

    if (step === 'Completed') {
      return true;
    }

    if (this.fields.some((f) => (f as { key: string }).key === 'lastRenderedDate') && this.selectedWorkflow?.hasPresortingEnabled) {
      return true;
    }

    return false;
  }

  getViewFileTooltip(item: WorkflowReport) {
    if (item.fileTypePreview && item.fileName) {
      return `View ${item.fileTypePreview} ${this.getFileNameWithoutExtension(item.fileName)}`;
    }

    return 'View';
  }

  getDownloadFileTooltip(item: WorkflowReport) {
    if (item.fileTypeDownload && item.fileName) {
      return `Download ${item.fileTypeDownload} ${this.getFileNameWithoutExtension(item.fileName)}`;
    }

    return 'Download';
  }

  getFileTypePrievew(item: WorkflowReport) {
    return item.fileTypePreview?.toLowerCase();
  }

  getFileTypeDownload(item: WorkflowReport) {
    return item.fileTypeDownload?.toLowerCase();
  }

  getFileNameWithoutExtension(fileName: string) {
    const index = fileName.lastIndexOf('.');
    if (index > -1) {
      fileName = fileName.substring(0, index);
    }

    return fileName;
  }

  fileIsStale(item: WorkflowReport): boolean {
    // NOTE(Dan): When rendering status is one of the following states it means that the
    //            file that's currently available is state, but still downloadable.
    switch (item.renderingStatus) {
      case 'RenderPending':
      case 'RenderWaiting':
      case 'RenderRunning':
        return true;
    }

    return false;
  }

  onReleasePending(): void {
    this.$root.$emit('workflow-report-release-pending-clicked', this.filteredResults.filter((r) => r.renderingStatus === 'RenderPending'));
  }

  scrollToSelectedReport(): void {
    this.$nextTick(() => {
      const div = this.$el.querySelector('.workflow-reports-table') as HTMLDivElement;
      const table = this.$refs.table as BTable;
      const header = table.$el.querySelector('thead') as HTMLTableSectionElement;
      const row = table.$el.querySelector('.b-table-row-selected') as HTMLTableRowElement | null;

      if (row === null) {
        return;
      }

      const containerHeight = table.$el.clientHeight;
      const rowTop = row.getBoundingClientRect().top - div.getBoundingClientRect().top;
      const rowBottom = rowTop + row.offsetHeight;
      const total = rowTop >= 0 && rowBottom <= containerHeight;
      const partial = (rowTop < 0 && rowBottom > 0) || (rowTop > 0 && rowTop <= containerHeight);

      if (total || partial) {
        return;
      }

      div.scrollTop = div.scrollTop + rowTop - header.offsetHeight;
    });
  }

  onBulkActionCompleted(reports: Array<WorkflowReport>): void {
    for (const report of reports) {
      this.reportIdsToSelectedState[report.id] = false;
      this.$root.$emit('workflow-report-deselected', { ...report, selected: false });
    }
    this.$root.$emit('all-workflow-reports-deselected');
    this.allReportsSelected = false;
  }

  getApprovalStageName(approvalStageID: number): string {
    if (this.currentWorkflowOptions === null) {
      return 'Unkown';
    }

    const approvalStageName = this.currentWorkflowOptions.find(option => option.id === approvalStageID)?.name;

    if (approvalStageName === undefined || approvalStageName === null) {
      return 'Unkown';
    } else {
      return approvalStageName;
    }
  }
}
</script>

import { WorkflowReportApproved } from '../events/workflow-report-approved';
import store from '@/store';

async function handleWorkflowReportApproved(data: WorkflowReportApproved): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportApproved event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  if (data.payload.fromStateId !== data.payload.toStateId) {
    store.commit('workflow/tryRemoveReportFromPage', reportId);
    await store.dispatch('workflow/tryMoveReportToPage', { ...data.payload, reportId });
    await store.dispatch('workflow/tryUpdateOptionCounts', data.payload);
    await store.dispatch('notification/tryUpdateWorkflowReportLinks', { ...data.payload, reportId });
  }

  // delay dashboard and summary screen updates for 10 seconds
  setTimeout(async () => {
    await store.dispatch('dashboard/tryUpdateOrReloadReportGroupForValuationDate', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
    await store.dispatch('summary/tryUpdateOrReloadSummary', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
  }, 10000);
}

export default handleWorkflowReportApproved;

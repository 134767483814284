<template>
  <div class="document-status text-center" :title="title">
    <b-icon icon="lock-fill" variant="primary" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LockedIcon extends Vue {
  @Prop({ type: String }) public title!: string | null;
}
</script>

<template>
  <div class="language-maintenance-filter-bar row">
    <div class="col-4">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">Phrase:</label>
        <div class="col">
          <b-input-group>
            <template v-slot:append>
              <b-button variant="primary" @click="findMatchingPhrases(phraseValue)">Search</b-button>
            </template>
            <b-form-input placeholder="Phrase..." v-model="phraseValue" @keypress.enter="findMatchingPhrases(phraseValue)" />
          </b-input-group>
        </div>
      </div>
    </div>
    <div class="col-auto" v-if="canManageLanguages">
      <div class="row align-items-center">
        <b-button variant="primary" @click="addNewPhraseClicked">Add new phrase</b-button>
      </div>
    </div>

    <create-new-phrase-dialog :show="showCreateDialog" @ok="onCreateDialogOk" @cancel="onCreateDialogCancelled" @close="onCreateDialogCancelled" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TenantGroupResponse } from '@/api/responses/tenant/tenant-group-response';
import { Route } from 'vue-router';
import { Tenant } from '@/store/tenant/state';
import CreateNewPhraseDialog from './create-new-phrase-dialog.vue';

const tenantModule = namespace('tenant');
const languageMaintenanceModule = namespace('languageMaintenance');

@Component({
  components: {
    CreateNewPhraseDialog,
  },
})
export default class LanguageMaintenanceFilterBar extends Vue {
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @tenantModule.Getter private tenants!: Array<Tenant>;
  @tenantModule.Getter private canManageLanguages!: boolean;
  @languageMaintenanceModule.Getter private searchPhrase!: string | null;

  private reportGroup: TenantGroupResponse | null = null;
  private phraseValue: string | null = null;
  private showCreateDialog: boolean = false;

  public async mounted(): Promise<void> {
    const { phrase } = this.$route.params;

    const setInitialState = async() => {
      if (phrase !== null && phrase !== undefined) {
        await this.findMatchingPhrases(phrase, true);
      }
    };

    await setInitialState();
  }

  @Watch('$route')
  private async onRouteChanged(to: Route, from: Route) : Promise<void> {
  }

  @Watch('currentTenant')
  private async onTenantChanged(): Promise<void> {
    this.$store.commit('languageMaintenance/setSelectedPhrase', null);
    await this.findMatchingPhrases(this.phraseValue);
  }

  private async findMatchingPhrases(value: string | null, exactMatch: boolean = false): Promise<void> {
    if (!value) {
      this.$router.replace({ name: this.$route.name!, params: { } });
      return;
    }

    this.$store.commit('languageMaintenance/setSearchPhrase', value);
    await this.$store.dispatch('languageMaintenance/getPhrasesAsync', { phrase: value, exact: exactMatch });

    if (this.$route.params.phrase !== value) {
      this.$router.push({ name: this.$route.name!, params: { ...this.$route.params, phrase: value } });
    }
  }

  @Watch('searchPhrase')
  private onSearchPhraseChanged(value: string | null): void {
    this.phraseValue = value;
  }

  private async onCreateDialogOk(result: { phrase: string }): Promise<void> {
    await this.findMatchingPhrases(result.phrase);
    this.onCreateDialogCancelled();
  }

  private onCreateDialogCancelled(): void {
    this.showCreateDialog = false;
  }

  private addNewPhraseClicked(): void {
    this.showCreateDialog = true;
  }
}
</script>

import store from '@/store';
import { DataLoadForReportGroupValuationDateOnHold } from '../events/data-load-for-report-group-valuation-date-on-hold';

async function handleDataLoadForReportGroupValuationDateOnHold(data: DataLoadForReportGroupValuationDateOnHold): Promise<void> {
  /* eslint-disable */
  console.log(`DataLoadForReportGroupValuationDateOnHold event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const dataLoadId = Number(data.payload.aggregateId.split('-')[1]);

  const pushed = await store.dispatch('data/tryPushDataLoadToPage', { ...data, dataLoadId });
  await store.dispatch('data/tryUpdateDataLoadStatus', { status: 'OnHold', dataLoadId: dataLoadId });

  if (pushed) {
    await store.dispatch('data/tryIncrementDataLoadCount', { ...data, dataLoadId });
  }

  await store.dispatch('data/tryUpdateDataLoadReportsUpdated', { reportsUpdated: data.payload.recordsUpdated || 0, dataLoadId: dataLoadId, reportGroupId: data.payload.reportGroupId });
  await store.dispatch('data/tryUpdateDataLoadReportsAffected', { reportsAffected: data.payload.reportsAffected || 0, dataLoadId: dataLoadId, reportGroupId: data.payload.reportGroupId });
}

export default handleDataLoadForReportGroupValuationDateOnHold;

export class State {
  public tenants: Array<Tenant> = [];
  public current: Tenant | null = null;
  public currentLogo: String | null = null;
  public selectedReportGroup: ReportGroup | null = null;
}

export interface Tenant {
  id: number;
  name: string;
  defaultLanguage: string | null;
  defaultLanguageId: number | null;

  permissions: TenantPermission;

  reportGroups: Array<ReportGroup>;
  users: Array<TenantUser>;
}

export interface TenantPermission {
  canViewLanguages: boolean;
  canManageLanguages: boolean;
  canManageSettings: boolean;
}

export interface ReportGroup {
  id: number;
  name: string;
  displayName: { singular: string | null, plural: string | null };
  active: ReportGroupActiveStatus;
  visible: ReportGroupVisibility;
}

export interface TenantUser {
  name: string;
  email: string;
  isActive: boolean;
}

export interface ReportGroupActiveStatus {
  all: boolean;
  documentLibrary: boolean;
  configuration: boolean;
}

export interface ReportGroupVisibility {
  all: boolean;
  documentLibrary: boolean;
  configuration: boolean;
}

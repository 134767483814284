import { MutationTree } from 'vuex';
import { State, Phrase, Translation } from '@/store/language-maintenance/state';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setSearchPhrase = (state: State, phrase: string | null) => {
    state.searchPhrase = phrase;
  }

  public setSelectedPhrase = (state: State, phrase: string | null) => {
    state.selectedPhrase = phrase;
  }

  public setPhrases = (state: State, phrases: Array<Phrase>) => {
    state.phrases = phrases;
  }

  public setTranslations = (state: State, translations: Array<Translation>) => {
    state.translations = translations;
  }
}

<template>
  <validation-observer v-slot="{ passes, invalid, pending }">
    <b-modal id="modal-center"
             v-model="visible"
             ok-variant="primary"
             ok-title="Submit"
             cancel-variant="link"
             centered
             :title="`Fix '${language}' translation issue for '${phrase}'`"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             :ok-disabled="(invalid && !pending) || submitting"
             @ok.prevent="passes(onOk)"
             @cancel="onCancel"
             @close="onClose"
    >
      <b-container fluid>
        <validation-provider
          ref="translation"
          rules="required"
          v-slot="{ valid, errors }"
          tag="div"
        >
          <b-form-group :label="`${language} translation:`"
                        label-cols="auto"
                        label-cols-lg="auto"
                        label-for="translation-input"
          >
            <b-form-input
              id="translation-input"
              v-model="translation"
              :state="errors[0] ? false : (valid ? true : null)"
              @keypress.enter="passes(onOk)"
            ></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-container>
    </b-modal>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { Tenant } from '../../store/tenant/state';
import { BvModalEvent } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Translation } from '../../store/language-maintenance/state';
import { TranslationIssue } from '../../store/data/state';
import * as Sentry from '@sentry/browser';

const tenantModule = namespace('tenant');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class FixTranslationIssueDialog extends Mixins(BvToastMixin) {
  @tenantModule.Getter('current') private current!: Tenant;

  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ type: Object, required: true }) public issue!: TranslationIssue;

  private visible: boolean = false;
  private translation: string | null = null;
  private submitting: boolean = false;

  private get phrase(): string {
    return this.issue.links.languages[0].phrase!;
  }

  private get language(): string {
    return this.issue.links.languages[0].language!;
  }

  private get languageId(): number {
    return this.issue.links.languages[0].languageId;
  }

  private async onOk(event: BvModalEvent): Promise<void> {
    this.submitting = true;
    const translation = this.translation;

    const payload: Translation = {
      phrase: this.phrase!,
      language: this.language!,
      languageId: this.languageId,
      value: translation,
    };

    try {
      await this.$store.dispatch('languageMaintenance/replaceTranslationForPhraseAsync', payload);

      this.showSuccessToast(`Successfully created '${payload.language}' translation for phrase '${payload.phrase}'.`);
      this.$emit('ok', payload);
    } catch (e) {
      this.showErrorToast(`Could not create '${payload.language}' translation for phrase '${payload.phrase}'. Please try again.`);
      Sentry.captureException(e);
    } finally {
      this.submitting = false;
    }
  }

  private onCancel(): void {
    this.$emit('cancel');
  }

  private onClose(): void {
    this.$emit('close');
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.translation = null;
    this.visible = value;
  }
}
</script>

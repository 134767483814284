<template>
  <div class="dashboard-filter-bar row">
    <div class="col-auto">
      <valuation-date-selector />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import ValuationDateSelector from './filter-bar-selectors/valuation-date-selector.vue';
import { Dashboard, DashboardValuationDate } from '@/store/dashboard/state';
import { namespace } from 'vuex-class';
import { Tenant } from '@/store/tenant/state';
import { WorkflowDateResponse } from '@/api/responses/workflow/workflow-date-response';
import { Workflow } from '@/store/workflow/state';
import { kebabcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';

const dashboardModule = namespace('dashboard');
const tenantModule = namespace('tenant');
const workflowModule = namespace('workflow');

@Component({
  components: {
    ValuationDateSelector,
  },
})
export default class DashboardFilterBar extends Vue {
  @dashboardModule.Getter private current!: Dashboard | null;
  @dashboardModule.Getter private currentValuationDate!: DashboardValuationDate | null;
  @dashboardModule.Getter private valuationDates!: Array<DashboardValuationDate>;
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @workflowModule.Getter private selectedValuationDate!: WorkflowDateResponse;
  @workflowModule.Getter('current') private currentWorkflow!: Workflow;

  public async mounted(): Promise<void> {
    const { valuationDate } = this.$route.params;

    // If the valuation date exists in the URL but the workflow screen hasn't been loaded, remove
    // it as a parameter.
    if ((this.selectedValuationDate === null || this.currentWorkflow === null) && valuationDate !== undefined) {
      delete this.$route.params.valuationDate;
      this.$router.replace({ name: 'home', params: { ...this.$route.params } });
      return;
    }

    if (this.selectedValuationDate !== null && this.currentWorkflow !== null) {
      const matchedValuationDate = this.valuationDates.find((d) => kebabcase(date(d.valuationDate, this.currentWorkflow.dateFormat)) === valuationDate) || this.valuationDates.find((d) => d.valuationDate === this.selectedValuationDate.valuationDate) || this.valuationDates[0] || null;
      this.$store.commit('dashboard/setCurrentValuationDate', matchedValuationDate);
    }

    await this.$store.dispatch('dashboard/getDashboardConfigurationAsync');

    if (this.current !== null || this.currentTenant === null) {
      await this.$store.dispatch('dashboard/getDashboardForValuationDateAsync', this.currentValuationDate!.valuationDate);
    }
  }

  @Watch('current')
  private async onCurrentChanged(to: Dashboard | null, from?: Dashboard | null): Promise<void> {
    if (from?.valuationDate === undefined || (from?.valuationDate === to?.valuationDate && this.current?.valuationDate === to?.valuationDate)) {
      return;
    }

    await this.$store.dispatch('dashboard/getDashboardForValuationDateAsync', this.current!.valuationDate);
  }

  @Watch('currentValuationDate')
  private async onCurrentValuationDateChanged(to: DashboardValuationDate | null, from?: DashboardValuationDate | null): Promise<void> {
    if (from?.valuationDate === undefined || (from?.valuationDate === to?.valuationDate && this.current?.valuationDate === to?.valuationDate)) {
      return;
    }

    if (this.selectedValuationDate !== null && this.currentWorkflow !== null && this.$route.params.valuationDate !== undefined) {
      if (this.$route.params.valuationDate !== kebabcase(date(this.currentValuationDate!.valuationDate, this.currentWorkflow.dateFormat))) {
        this.$router.replace({ name: 'home', params: { ...this.$route.params, valuationDate: kebabcase(date(this.currentValuationDate!.valuationDate, this.currentWorkflow.dateFormat)) } });
      }
    } else if (this.$route.params.valuationDate !== undefined) {
      delete this.$route.params.valuationDate;
      this.$router.replace({ name: 'home', params: { ...this.$route.params } });
    }
    await this.$store.dispatch('dashboard/getDashboardForValuationDateAsync', this.currentValuationDate!.valuationDate);
  }

  @Watch('currentTenant')
  private async onCurrentTenantChanged(): Promise<void> {
    await this.$store.dispatch('dashboard/getDashboardConfigurationAsync');
  }
}
</script>

<template>
  <span>{{ size }}</span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

enum SizeUnit {
  Kb = 'kb',
  Mb = 'mb',
}

@Component
export default class FileSize extends Vue {
  @Prop({ type: Number, required: true }) readonly bytes!: number;
  @Prop({
    default: SizeUnit.Kb,
    validator: (unit: string) => Object.values(SizeUnit).includes(unit as SizeUnit),
  }) readonly unit!: SizeUnit;

  get size(): string {
    switch (this.unit) {
      case SizeUnit.Kb:
        return this.sizeInKb;

      case SizeUnit.Mb:
        return this.sizeInMb;
    }
  }

  get sizeInKb(): string {
    const kb = this.bytes / 1024;
    return kb.toFixed(2) + 'KB';
  }

  get sizeInMb(): string {
    const kb = this.bytes / 1024 / 1024;

    return kb.toFixed(2) + 'MB';
  }
}
</script>

import { VNode } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class BvToastMixin extends Vue {
  showErrorToast(message: string | VNode | VNode[]): void {
    this.showToast(message, 'notice-danger');
  }

  showSuccessToast(message: string | VNode | VNode[]): void {
    this.showToast(message, 'notice-success');
  }

  showToast(
    message: string | VNode | VNode[],
    variant: 'notice-danger' | 'notice-success'
  ): void {
    this.$bvToast.toast(message, {
      autoHideDelay: 3000,
      solid: true,
      noCloseButton: true,
      variant: variant,
      toaster: 'b-toaster-top-center',
      appendToast: true,
    });
  }
}

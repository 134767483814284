import { Api } from '@/api/api';
import { EnvironmentResponse } from '@/api/responses/environment/environment-response';
import { EnvironmentConfigResponse } from './responses/environment/environment-config-response';

export class EnvironmentApi extends Api {
  public constructor() {
    super();
  }

  public getEnvironmentAsync(): Promise<EnvironmentResponse> {
    return this.getAsync<EnvironmentResponse>('v1/environment');
  }

  public getEnvironmentConfigAsync(): Promise<EnvironmentConfigResponse> {
    return this.getAsync<EnvironmentConfigResponse>('v1/environment/config');
  }
}

import { ActionTree, ActionContext } from 'vuex';
import { State, DataLoad, DataDate, DataOption } from '@/store/data/state';
import { DataApi } from '@/api/data-api';
import { WorkflowReportDataCheckCompleted } from '@/services/notifications/events/workflow-report-data-check-completed';
import { WorkflowReportDataCheckQueued } from '@/services/notifications/events/workflow-report-data-check-queued';
import { State as TenantState, ReportGroup } from '@/store/tenant/state';
import { DataLoadForReportGroupValuationDateStarted } from '@/services/notifications/events/data-load-for-report-group-valuation-date-started';
import { Dictionary } from 'vue-router/types/router';
import { DataResponse } from '@/api/responses/data/data-response';
import { date } from '@/filters/date';
import { UserResponse } from '@/api/responses/user/user-response';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';
import { camelcase, kebabcase } from '@/utilities/text.utils';
import { DataIssuePageResponse } from '@/api/responses/data/data-issues-page-response';
import { TranslationIssuePageResponse } from '@/api/responses/data/translation-issues-page-response';
import { DataFileResponse } from '@/api/responses/data/data-file-response';
import { DataLoadPageResponse } from '@/api/responses/data/data-load-page-response';
import { DataLoadResponse } from '@/api/responses/data/data-load-response';
import { DataUploadFileResponse } from '@/api/responses/data/data-upload-file-response';

type DataState = { tenant: string, reportGroup: string, valuationDate: string, tab: string, dataLoadId?: string };

const api = new DataApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public getAndMergeDataForReportGroupNoLoaderAsync = async (context: ActionContext<State, any>, reportGroup: { id: number; name: string }) => {
    try {
      const result = await api.getDataForReportGroupAsync(reportGroup.id);

      context.commit('mergeCurrent', result);
    } catch (e) {
    }
  }

  public getDataForReportGroupAsync = async (context: ActionContext<State, any>, reportGroup: { id: number; name: string }) => {
    try {
      const result = await api.getDataForReportGroupAsync(reportGroup.id);

      context.commit('setCurrent', result);
    } catch (e) {
    }
  }

  public getDataIssuesAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, valuationDate: string | Date, page: number, pageSize: number }) => {
    const result = await api.getDataIssuesAsync(payload.reportGroupId, payload.valuationDate, payload.page, payload.pageSize);

    if (context.state.dataIssuePages.some((p) => p.reportGroupId !== payload.reportGroupId) || context.state.currentValuationDate !== payload.valuationDate || payload.page === 1) {
      context.commit('clearDataIssuePages');
    }

    context.commit('addDataIssuePage', result);
  }

  public getTranslationIssuesAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, valuationDate: string | Date, page: number, pageSize: number }) => {
    const result = await api.getTranslationIssuesAsync(payload.reportGroupId, payload.valuationDate, payload.page, payload.pageSize);

    if (context.state.translationIssuePages.some((p) => p.reportGroupId !== payload.reportGroupId) || context.state.currentValuationDate !== payload.valuationDate || payload.page === 1) {
      context.commit('clearTranslationIssuePages');
    }

    context.commit('addTranslationIssuePage', result);
  }

  public getDataFilesAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, valuationDate: string | Date }) => {
    const result = await api.getDataFilesAsync(payload.reportGroupId, payload.valuationDate);

    context.commit('addDataFiles', result);
  }

  public getDataLoadsAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, valuationDate: string | Date, page: number, pageSize: number }) => {
    const result = await api.getDataLoadsAsync(payload.reportGroupId, payload.valuationDate, payload.page, payload.pageSize);

    if (context.state.dataLoadsPages.some((p) => p.reportGroupId !== payload.reportGroupId) || context.state.currentValuationDate !== payload.valuationDate || payload.page === 1) {
      context.commit('clearDataLoadPages');
    }

    context.commit('addDataLoadsPage', result);
  }

  public findDataLoadAsync = async (context: ActionContext<State, any>, payload: { dataLoadId: number, reportGroupId: number | null, valuationDate: string | null }) => {
    const result = await api.findDataLoadAsync(payload.dataLoadId, payload.reportGroupId, payload.valuationDate);
    return result;
  }

  public reloadDataLoadAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, dataLoadId: number }) => {
    await api.reloadDataLoadAsync(payload.dataLoadId, payload.reportGroupId);
    api.refreshCorrelationId();
  }

  public ignoreDataIssueAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, issueId: number, comments: string | null }) => {
    await api.ignoreDataIssueAsync(payload.issueId, payload.reportGroupId, payload.comments);
    api.refreshCorrelationId();
  }

  public unignoreDataIssueAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, issueId: number, comments: string | null }) => {
    await api.unignoreDataIssueAsync(payload.issueId, payload.reportGroupId, payload.comments);
    api.refreshCorrelationId();
  }

  public recheckDataIssuesAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, valuationDate: string | Date, comments: string, resetIgnoredIssues: boolean }) => {
    await api.recheckDataIssuesAsync(payload.reportGroupId, payload.valuationDate, payload.comments, payload.resetIgnoredIssues);
    api.refreshCorrelationId();
  }

  public tryIncrementDataCheckQueueCount = async (context: ActionContext<State, any>, payload: WorkflowReportDataCheckQueued) => {
    const currentValuationDate = context.getters.currentValuationDate;

    if (currentValuationDate === null || currentValuationDate !== payload.payload.valuationDate) {
      return;
    }

    const reportGroup = (context.rootState.tenant as TenantState).selectedReportGroup;

    if (reportGroup == null || reportGroup.id !== payload.payload.reportGroupId) {
      return;
    }

    context.commit('incrementDataCheckQueueCount');
  }

  public tryDecrementDataCheckQueueCount = async (context: ActionContext<State, any>, payload: WorkflowReportDataCheckCompleted) => {
    const currentValuationDate = context.getters.currentValuationDate;

    if (currentValuationDate === null || currentValuationDate !== payload.payload.valuationDate) {
      return;
    }

    const reportGroup = (context.rootState.tenant as TenantState).selectedReportGroup;

    if (reportGroup == null || reportGroup.id !== payload.payload.reportGroupId) {
      return;
    }

    context.commit('decrementDataCheckQueueCount');
  }

  public tryUpdateDataLoadStatus = (context: ActionContext<State, any>, data: Partial<DataLoad> & { dataLoadId: number }) => {
    const pages = context.state.dataLoadsPages;
    const page = pages.find((p) => p.dataLoads.some((l) => l.id === data.dataLoadId));

    if (page === undefined) {
      return;
    }

    const match = page.dataLoads.find((l) => l.id === data.dataLoadId);

    if (match === undefined) {
      return;
    }

    context.commit('updateDataLoadProperties', { source: { status: data.status }, destination: match });
  }

  public tryUpdateDataLoadReportsUpdated = (context: ActionContext<State, any>, data: Partial<DataLoad> & { dataLoadId: number, reportGroupId: number }) => {
    const pages = context.state.dataLoadsPages;
    const page = pages.find((p) => p.reportGroupId === data.reportGroupId && p.dataLoads.some((l) => l.id === data.dataLoadId));

    if (page === undefined) {
      return;
    }

    const match = page.dataLoads.find((l) => l.id === data.dataLoadId);

    if (match === undefined) {
      return;
    }

    context.commit('updateDataLoadProperties', { source: { reportsUpdated: data.reportsUpdated }, destination: match });
  }

  public tryUpdateDataLoadReportsAffected = (context: ActionContext<State, any>, data: Partial<DataLoad> & { dataLoadId: number, reportGroupId: number }) => {
    const pages = context.state.dataLoadsPages;
    const page = pages.find((p) => p.reportGroupId === data.reportGroupId && p.dataLoads.some((l) => l.id === data.dataLoadId));

    if (page === undefined) {
      return;
    }

    const match = page.dataLoads.find((l) => l.id === data.dataLoadId);

    if (match === undefined) {
      return;
    }

    context.commit('updateDataLoadProperties', { source: { reportsAffected: data.reportsAffected }, destination: match });
  }

  public tryPushDataLoadToPage = async (context: ActionContext<State, any>, data: DataLoadForReportGroupValuationDateStarted & { dataLoadId: number }) => {
    const pages = context.state.dataLoadsPages;
    let page = pages.find((p) => p.dataLoads.some((l) => l.id === data.dataLoadId));

    if (page !== undefined) {
      return false;
    }

    if (context.state.current === null) {
      return false;
    }

    if (context.state.current.reportGroupId !== data.payload.reportGroupId) {
      return false;
    }

    if (context.state.currentValuationDate !== null && context.state.currentValuationDate !== data.payload.valuationDate) {
      return false;
    }

    const dataLoad = await api.getDataLoadAsync(data.dataLoadId, data.payload.reportGroupId);

    page = context.state.dataLoadsPages.find((p) => p.dataLoads.some((l) => l.id === data.dataLoadId));

    if (page !== undefined) {
      return false;
    }

    context.commit('pushDataLoadToPage', { dataLoad, pageNumber: 1 });

    return true;
  }

  public tryIncrementDataLoadCount = async (context: ActionContext<State, any>, data: DataLoadForReportGroupValuationDateStarted & { dataLoadId: number }) => {
    if (context.state.current === null) {
      return;
    }

    if (context.state.current.reportGroupId !== data.payload.reportGroupId) {
      return;
    }

    if (context.state.currentValuationDate !== null && context.state.currentValuationDate !== data.payload.valuationDate) {
      return;
    }

    context.commit('incrementDataLoadCount');
  }

  public trySetDataCheckQueueCount = async (context: ActionContext<State, any>, remaining: number) => {
    const data = context.state.current;

    if (data === null) {
      return;
    }

    const currentDate = context.getters.currentValuationDate;

    if (currentDate === null) {
      return;
    }

    const match = context.state.current!.dates.find((d) => d.valuationDate === currentDate.valuationDate);

    if (match === undefined) {
      return;
    }

    const reportGroup = (context.rootState.tenant as TenantState).selectedReportGroup;

    if (reportGroup == null) {
      return;
    }

    if (reportGroup.id !== data.reportGroupId) {
      return;
    }

    context.commit('setDataCheckQueueCount', remaining);
  }

  public downloadDataLoadAsync = async (context: ActionContext<State, any>, options: { dataLoadId: number, reportGroupId: number }): Promise<Blob> => {
    return api.downloadDataLoadAsync(options.dataLoadId, options.reportGroupId);
  }

  public uploadDataLoadAsync = async (context: ActionContext<State, any>, options: { files: Array<File>, reportGroupId: number }): Promise<DataUploadFileResponse> => {
    api.refreshCorrelationId();
    const result = await api.uploadDataLoadAsync(options.files, options.reportGroupId);
    return result;
  }

  public applyStateFromRoute = async (context: ActionContext<State, any>, data: DataState): Promise<Dictionary<string>> => {
    /* eslint-disable */
    console.log('data/applyStateFromRoute started', data);
    /* eslint-enable */

    // TODO(Dan): Tenant
    const currentUser = context.rootGetters['user/current'] as UserResponse;
    const tenants = context.rootGetters['tenant/tenants'] as Array<TenantResponse>;
    const currentTenant = context.rootGetters['tenant/current'] as TenantResponse | null;
    const matchingTenant = tenants.find((t) => kebabcase(t.name) === kebabcase(data.tenant)) || tenants.find((t) => t.id === currentUser.currentTenantId) || tenants[0] || null;

    if (matchingTenant === null) {
      throw Error(`No tenants found for user '${currentUser.email}'.`);
    }

    // TODO(Dan): Report Group
    const matchingReportGroup = (data.reportGroup !== undefined && matchingTenant.reportGroups.find((group) => kebabcase(group.name) === kebabcase(data.reportGroup!))) || matchingTenant.reportGroups.find((group) => group.active.all) || matchingTenant.reportGroups[0] || null;

    if (matchingReportGroup === null) {
      throw Error(`No report groups found for tenant '${matchingTenant.name}'.`);
    }

    // TODO(Dan): Configuration
    let configuration = context.getters.current as DataResponse | null;

    if (configuration === null || configuration.reportGroupId !== matchingReportGroup.id || (currentTenant !== null && currentTenant.id !== matchingTenant.id)) {
      configuration = await api.getDataForReportGroupAsync(matchingReportGroup.id);
    }

    if (configuration === null) {
      throw Error(`Could not load workflow configuration for report group '${matchingReportGroup.name}'.`);
    }

    // TODO(Dan): Valuation Date
    const matchingValuationDate = (data.valuationDate !== undefined && configuration.dates.find((d) => kebabcase(date(d.displayDate, configuration!.dateFormat!)) === kebabcase(data.valuationDate!))) || configuration?.dates.find((d) => d.active) || configuration.dates[0] || null;

    if (matchingValuationDate === null) {
      throw Error(`No valuation dates found for data belonging to report group '${matchingReportGroup.name}'.`);
    }

    // TODO(Dan): Option (Tab)
    // TODO(Dan): If the current option exists, use it, else revert to the first option with > 0 count else waiting
    // TODO(Dan): Don't reload unless we need to.
    let matchingOption = (data.tab !== undefined && configuration.options.find(o => kebabcase(o.name) === kebabcase(data.tab!))) || null;

    if (matchingOption === null) {
      for (let index = 0; index < configuration.options.length; index++) {
        const option = configuration.options[index];
        const name = camelcase(option.name);
        const count = matchingValuationDate.options[name] as number | undefined;

        if (count !== undefined && count > 0) {
          matchingOption = option;
          break;
        }
      }

      if (matchingOption === null) {
        matchingOption = configuration.options.find(o => o.name === 'Waiting') || configuration.options[0] || null;
      }
    }

    if (matchingOption === null) {
      throw Error(`No status found for data belonging to report group '${matchingReportGroup.name}'.`);
    }

    context.commit('setLoading', true);

    let dataLoadId = data.dataLoadId; // Should Only be on the data loads tab
    const tenantChanged = currentTenant?.id !== matchingTenant.id;
    const reportGroupChanged = tenantChanged || matchingReportGroup.id !== (context.getters.current as DataResponse | null)?.reportGroupId || matchingReportGroup.id !== (context.rootGetters['tenant/selectedReportGroup'] as ReportGroup).id;
    const valuationDateChanged = reportGroupChanged || matchingValuationDate.valuationDate !== (context.getters.currentValuationDate as DataDate | null)?.valuationDate;
    const optionChanged = valuationDateChanged || matchingOption.id !== (context.getters.currentOption as DataOption | null)?.id;
    const dataLoadIdChanged = dataLoadId !== context.state.selectedDataLoadId?.toString();

    const dataIssuePages: Array<DataIssuePageResponse> = [];
    const translationIssuePages: Array<TranslationIssuePageResponse> = [];
    const dataFiles: Array<DataFileResponse> = [];
    const dataLoadsPages: Array<DataLoadPageResponse> = [];

    const loadPagesUntilDataLoadFoundOrAllPages = async () => {
      let page = 1;
      let result = await api.getDataLoadsAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, 1, 250);

      dataLoadsPages.push(result);

      while (result.dataLoads.length === result.pageSize && !result.dataLoads.some(dl => dl.id === Number(dataLoadId))) {
        result = await api.getDataLoadsAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, ++page, 250);
        dataLoadsPages.push(result);
      }
    };

    if (tenantChanged || reportGroupChanged || valuationDateChanged || optionChanged) {
      // TODO(Dan): Data load id...
      switch (kebabcase(matchingOption.name)) {
        case 'data-issues': {
          const results = await api.getDataIssuesAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, 1, 250);
          dataIssuePages.push(results);
          dataLoadId = undefined;
          break;
        }
        case 'translation-issues': {
          const results = await api.getTranslationIssuesAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, 1, 250);
          translationIssuePages.push(results);
          dataLoadId = undefined;
          break;
        }
        case 'data-files': {
          const results = await api.getDataFilesAsync(matchingReportGroup.id, matchingValuationDate.valuationDate);
          dataFiles.push(...results.files);
          dataLoadId = undefined;
          break;
        }
        case 'data-loads': {
          if (dataLoadId !== undefined) {
            await loadPagesUntilDataLoadFoundOrAllPages();
          } else {
            const results = await api.getDataLoadsAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, 1, 250);
            dataLoadsPages.push(results);
          }
          break;
        }
      }
      // Data Issues Tab count should be actual no.of records returned
      if (kebabcase(matchingOption.name) !== 'data-issues') {
        const results = await api.getDataIssuesAsync(matchingReportGroup.id, matchingValuationDate.valuationDate, 1, 250);
        dataIssuePages.push(results);
      }
    } else if (dataLoadIdChanged && dataLoadId !== undefined) {
      const dataLoads = context.getters.dataLoads as Array<DataLoadResponse>;
      const match = dataLoads.find(dl => dl.id === Number(data.dataLoadId));

      if (match === null) {
        await loadPagesUntilDataLoadFoundOrAllPages();
      } else {
        dataLoadsPages.push(...(context.state.dataLoadsPages as Array<DataLoadPageResponse>));
      }
    } else {
      dataIssuePages.push(...(context.state.dataIssuePages as Array<DataIssuePageResponse>));
      translationIssuePages.push(...(context.state.translationIssuePages as Array<TranslationIssuePageResponse>));
      dataFiles.push(...(context.state.dataFiles as Array<DataFileResponse>));
      dataLoadsPages.push(...(context.state.dataLoadsPages as Array<DataLoadPageResponse>));
    }

    if (dataLoadId !== undefined) {
      const hasMatch = dataLoadsPages.some(p => p.dataLoads.some(dl => dl.id === Number(dataLoadId)));

      if (!hasMatch) {
        dataLoadId = undefined;
      }
    }

    const result: Dictionary<string> = { tenant: kebabcase(matchingTenant.name), reportGroup: kebabcase(matchingReportGroup.name), valuationDate: kebabcase(date(matchingValuationDate.displayDate, configuration!.dateFormat!)), tab: kebabcase(matchingOption.name) };

    if (dataLoadId !== undefined) {
      result.dataLoadId = dataLoadId;
    }

    if (reportGroupChanged) {
      await context.dispatch('tryApplyReportGroup', matchingReportGroup, { root: true });
    }

    if (valuationDateChanged) {
      await context.dispatch('tenant/setCurrentReportGroupValuationDateAsync', { id: matchingReportGroup.id, name: matchingReportGroup.name, tenantId: matchingTenant.id, tenantName: matchingTenant.name, valuationDate: matchingValuationDate.valuationDate }, { root: true });
    }

    context.commit('setStateFromRoute', { configuration: configuration, valuationDate: matchingValuationDate, option: matchingOption, dataLoadId: result.dataLoadId, dataIssuePages, translationIssuePages, dataFiles, dataLoadsPages });

    /* eslint-disable */
    console.log('data/applyStateFromRoute completed', result);
    /* eslint-enable */

    return result;
  }

  public replaceDataFileReminderDetailsAsync = async (context: ActionContext<State, any>, data: { dataFileId: number, businessDay: number | null, email: string | null, reportGroupId: number }): Promise<void> => {
    api.refreshCorrelationId();
    await api.replaceDataFileReminderDetailsAsync(data.dataFileId, data.businessDay, data.email, data.reportGroupId);
  }

  public makeDataFileMandatoryForReportGroupAsync = async (context: ActionContext<State, any>, data: { dataFileId: number, reportGroupId: number }): Promise<void> => {
    api.refreshCorrelationId();
    await api.makeDataFileMandatoryForReportGroupAsync(data.dataFileId, data.reportGroupId);
  }

  public makeDataFileOptionalForReportGroupAsync = async (context: ActionContext<State, any>, data: { dataFileId: number, reportGroupId: number }): Promise<void> => {
    api.refreshCorrelationId();
    await api.makeDataFileOptionalForReportGroupAsync(data.dataFileId, data.reportGroupId);
  }
}

<template>
  <div>
    <filter-bar />
    <div class="container-fluid">
      <div class="row mt-4">
        <div class="col-8">
          <b-tabs class="documents">
            <b-tab v-if="current !== null" :title="tabDisplayName">
              <document-table v-if="current !== null" />
            </b-tab>
          </b-tabs>
        </div>
        <div class="col-4" v-if="currentDocument !== null">
          <document-details />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FilterBar from '@/components/document-library/filter-bar.vue';
import DocumentTable from '@/components/document-library/document-table.vue';
import DocumentDetails from '@/components/document-library/document-details.vue';
import { DocumentLibrary as Library, Document } from '../store/document-library/state';
import { ReportGroup } from '../store/tenant/state';
import { pluralize } from '@/utilities/text.utils';

const documentLibraryModule = namespace('documentLibrary');

@Component({
  components: {
    FilterBar,
    DocumentTable,
    DocumentDetails,
  },
})
export default class DocumentLibrary extends Vue {
  @documentLibraryModule.Getter currentDocument!: Document | null;
  @documentLibraryModule.Getter current!: Library | null;
  @documentLibraryModule.Getter currentReportGroup!: ReportGroup | null;
  @documentLibraryModule.Getter totalDocuments!: number;

  get tabDisplayName(): string {
    if (this.current === null) {
      return `(${this.totalDocuments})`;
    }

    if (this.currentReportGroup === null) {
      return `(${this.totalDocuments})`;
    }

    return `${this.currentReportGroup.displayName.plural || pluralize(this.currentReportGroup.name)} (${this.totalDocuments})`;
  }
}
</script>

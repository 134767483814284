import { GetterTree } from 'vuex';
import { State } from '@/store/data/state';
import { camelcase } from '@/utilities/text.utils';

export class Getters implements GetterTree<State, any> {
    [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

    public current = (state: State) => {
      return state.current;
    }

    public valuationDates = (state: State) => {
      if (state.current === null) {
        return [];
      }
      return state.current!.dates;
    }

    public selectedDataLoad = (state: State) => {
      if (state.selectedDataLoadId === null) {
        return null;
      }

      return this.dataLoads(state).find((r) => r.id === state.selectedDataLoadId) || null;
    }

    public currentValuationDate = (state: State) => {
      if (state.current === null) {
        return null;
      }
      return this.valuationDates(state).find((d) => d.valuationDate === state.currentValuationDate) || null;
    }

    public options = (state: State) => {
      if (state.current === null) {
        return [];
      }

      const date = state.current.dates.find((d) => d.valuationDate === state.currentValuationDate) || null;

      if (date === null || date === undefined) {
        return [];
      }

      const results = state.current.options.filter((item) => {
        const name = camelcase(item.name);

        const count = date.options[name] as number | undefined;

        if (count === undefined) {
          return false;
        }

        // NOTE(Dan): Hide translation tab if 0 to show.
        if (count < 1 && item.id === 2) {
          return false;
        }

        return true;
      });

      return results;
    }

    public currentOption = (state: State) => {
      if (state.current === null) {
        return null;
      }

      return state.currentOption;
    }

    public dataIssues = (state: State) => {
      return state.dataIssuePages.flatMap((p) => p.issues);
    }

    public dataIssueReportsAffected = (state: State) => {
      if (state.dataIssuePages.length < 1) {
        return 0;
      }

      return state.dataIssuePages[0].reportsAffected;
    }

    public translationIssueReportsAffected = (state: State) => {
      if (state.translationIssuePages.length < 1) {
        return 0;
      }

      return state.translationIssuePages[0].reportsAffected;
    }

    public translationIssues = (state: State) => {
      return state.translationIssuePages.flatMap((p) => p.issues);
    }

    public dataLoads = (state: State) => {
      return state.dataLoadsPages.flatMap((p) => p.dataLoads);
    }

    public dataFiles = (state: State) => {
      return state.dataFiles;
    }

    public isLoading = (state: State) => {
      return state.loadingData;
    }
}

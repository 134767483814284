/**
 * Rotates a 2D Array / Matrix clockwise 90 degrees.
 * Supports both even matrices (e.g. 3x3) and uneven matrices
 * (e.g. 3x5) and even jagged arrays, e.g.
 *
 * [1, 2, 3]
 * [4, 5, 6, 7, 8, 9]
 * [10, 11, 12, 13]
 *
 * =>
 *
 * [10, 4, 1]
 * [11, 5, 2]
 * [12, 6, 3]
 * [13, 7,  ]
 * [  , 8,  ]
 * [  , 9,  ]
 */
export function rotate<T>(matrix: T[][]): T[][] {
  const result: Array<Array<T>> = [];
  const n = matrix.length;

  for (let i = 0; i < n; ++i) {
    const m = matrix[i].length;

    for (let j = 0; j < m; ++j) {
      if (result[j] === undefined) {
        result[j] = [];
      }

      result[j][i] = matrix[n - 1 - i][j];
    }
  }

  return result;
}

import { WorkflowReportDistributionCompleted } from '../events/workflow-report-distribution-completed';
import store from '@/store';

async function handleWorkflowReportDistributionCompleted(data: WorkflowReportDistributionCompleted): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportDistributionCompleted event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);
  const remainingDistributionBatches = data.payload.remainingDistributionBatches || 0;

  await store.dispatch('workflow/tryUpdateReportProperties', {
    renderingStatus: remainingDistributionBatches < 1 ? 'Blank' : 'AwaitingBatchDistribution',
    reportId: reportId,
    remainingDistributionBatches: remainingDistributionBatches,
  });
}

export default handleWorkflowReportDistributionCompleted;

<template>
  <b-icon-flag-fill v-if="flagColour" :title="title" :color="'#'+flagColour.hexColour" class="mt-1" style="width: 16px;height: 16px"></b-icon-flag-fill>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkflowReport, WorkflowFlag } from '@/store/workflow/state';

@Component
export default class WorkflowReportFlag extends Vue {
@Prop({ type: Object, required: true }) public item!: WorkflowReport;
@Prop({ type: Object, required: false }) public flagColour!: WorkflowFlag | null;

public get title(): string | null {
  return this.item.flagReason;
}
}
</script>

import { required } from 'vee-validate/dist/rules';
import * as rules from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { ValidationRuleResult } from 'vee-validate/dist/types/types';
import store from '@/store';
import { Phrase } from '@/store/language-maintenance/state';
import { DateTime } from 'luxon';

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  });
}

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('uniquephrase', {
  validate: async (value: string | null, params: Record<string, any>): Promise<boolean | string | ValidationRuleResult> => {
    if (value === params.value) {
      return true;
    }

    const results = await store.dispatch('languageMaintenance/getPhrasesWithoutStoringAsync', { phrase: value, exact: false }) as Array<Phrase>;

    if (results.length < 1) {
      return true;
    }

    const isValid = !results.some((match) => match.value === value);

    return {
      valid: isValid,
      data: {
        value: value,
      },
    };
  },
  message: 'A phrase for \'{_value_}\' already exists.',
  params: ['value'],
});

extend('after_now', {
  params: ['date', 'time'],
  validate: (value, { date, time }: Record<string, any>) => {
    if (date === undefined || date === null) {
      return false;
    }

    if (time === undefined || time === null) {
      return false;
    }

    const now = DateTime.local().set({ second: 0, millisecond: 0 });
    const current = DateTime.fromFormat(`${date} ${time}`, 'yyyy-MM-dd HH:mm:ss').toLocal();

    return {
      valid: current >= now,
      data: {
        now: now.toFormat('T'),
        date: date,
        time: time,
      },
    };
  },
  message: (field: string, { date, time }) => `The time cannot be on or before ${DateTime.local().toFormat('T')}.`,
});

const notOneOf = (value: any, params: any[] | Record<string, any>): boolean => {
  if (Array.isArray(value)) {
    return value.every(val => notOneOf(val, params));
  }

  const match = Array.from(params as any[]).some(item => {
    // eslint-disable-next-line eqeqeq
    return item == value;
  });

  return !match;
};

extend('notOneOf', {
  validate: (value: any, params: any[] | Record<string, any>) => {
    return notOneOf(value, params);
  },
});

import { DateTime } from 'luxon';

export function valuationdate(value: string | Date | null, format: string = 'dd/MM/yyyy'): string {
  let date: DateTime | null = null;

  if (value === null) {
    return '';
  }

  if (value instanceof Date) {
    date = DateTime.fromJSDate(value);
  } else {
    date = DateTime.fromISO(value, { setZone: true });
  }

  if (date == null) {
    return 'Invalid date.';
  }
  return date.toFormat(format);
}

import { Api } from '@/api/api';
import { SupportResponse } from './responses/support/support-response';
import { SupportTicketPageResponse } from './responses/support/support-ticket-page-response';
import axios, { CancelTokenSource } from 'axios';
import { SupportTicketDetailsResponse } from './responses/support/support-ticket-details-response';
import { SupportTicketMessagesResponse } from './responses/support/support-ticket-messages-response';
import { PriorityResponse } from './responses/support/priority-response';
import { IssueTypeResponse } from './responses/support/issue-type-response';
import { CategoryResponse } from './responses/support/category-response';
import { ReportGroup, Tenant } from '@/store/tenant/state';
import { User, ValuationDate } from '@/store/support/state';
import { SupportTicketActivityResponse } from './responses/support/support-ticket-activity-response';
import { SupportTicketAttachmentResponse } from './responses/support/support-ticket-attachment-response';
import { FindTicketResponse } from './responses/support/find-ticket-response';

export class SupportApi extends Api {
  private pageCancellationToken: CancelTokenSource | null = axios.CancelToken.source();

  public constructor() {
    super();
  }

  public getSupportAsync(): Promise<SupportResponse> {
    return this.getAsync<SupportResponse>('v1/support');
  }

  public getSupportTicketsAsync(status: number, page: number, pageSize: number): Promise<SupportTicketPageResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<SupportTicketPageResponse>('v1/support/ticket', { status, page, pageSize }, this.pageCancellationToken.token);
  }

  public findTicketAsync(id: number): Promise<FindTicketResponse> {
    return this.getAsync<FindTicketResponse>(`v1/support/${id}`);
  }

  public openTicketAsync(subject: string, detail: string, priority: PriorityResponse, issueType: IssueTypeResponse, category: CategoryResponse, reportGroup: ReportGroup | null, tenant: Tenant | null, raisedBy: User | null, valuationDate: ValuationDate | null, reportId: number | null, correlationId: string | null = null, ccmail: string | null): Promise<number> {
    return this.postAsync<number>('v1/support/ticket', { subject, detail, priority, issueType, category, reportGroup, tenant, onBehalfOf: raisedBy, valuationDate: valuationDate?.valuationDate || null, reportId: reportId, ccmail }, correlationId);
  }

  public getSupportTicketAsync(id: number): Promise<SupportTicketDetailsResponse> {
    return this.getAsync<SupportTicketDetailsResponse>(`v1/support/ticket/${id}`);
  }

  public getSupportTicketMessagesAsync(id: number): Promise<SupportTicketMessagesResponse> {
    return this.getAsync<SupportTicketMessagesResponse>(`v1/support/ticket/${id}/message`);
  }

  public getSupportTicketNotesAsync(id: number): Promise<SupportTicketMessagesResponse> {
    return this.getAsync<SupportTicketMessagesResponse>(`v1/support/ticket/${id}/note`);
  }

  public getSupportTicketActivityAsync(id: number): Promise<SupportTicketActivityResponse> {
    return this.getAsync<SupportTicketActivityResponse>(`v1/support/ticket/${id}/activity`);
  }

  public getSupportTicketAttachmentsAsync(id: number): Promise<Array<SupportTicketAttachmentResponse>> {
    return this.getAsync<Array<SupportTicketAttachmentResponse>>(`v1/support/ticket/${id}/attachment`);
  }

  public downloadSupportTicketAttachmentAsync(attachmentId: number, ticketId: number): Promise<Blob> {
    return this.getFileAsync(`v1/support/ticket/${ticketId}/attachment/${attachmentId}`);
  }

  public assignTicketAsync(id: number, assignToUserId: number, assignToUser: string): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/assign`, { to: { id: assignToUserId, name: assignToUser } });
  }

  public reassignTicketAsync(id: number, assignFromUserId: number, assignFromUser: string, assignToUserId: number, assignToUser: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/assign`, { from: { id: assignFromUserId, name: assignFromUser }, to: { id: assignToUserId, name: assignToUser } });
  }

  public amendTicketPriorityAsync(id: number, fromPriorityId: number, fromPriority: string, toPriorityId: number, toPriority: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/priority`, { from: { id: fromPriorityId, name: fromPriority }, to: { id: toPriorityId, name: toPriority } });
  }

  public amendTicketIssueTypeAsync(id: number, fromIssueTypeId: number, fromIssueType: string, toIssueTypeId: number, toIssueType: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/issuetype`, { from: { id: fromIssueTypeId, name: fromIssueType }, to: { id: toIssueTypeId, name: toIssueType } });
  }

  public amendTicketRaisedByAsync(id: number, fromRaisedById: number, fromRaisedBy: string, toRaisedById: number, toRaisedBy: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/raisedby`, { from: { id: fromRaisedById, name: fromRaisedBy }, to: { id: toRaisedById, name: toRaisedBy } });
  }

  public amendTicketCategoryAsync(id: number, fromCategoryId: number, fromCategory: string, toCategoryId: number, toCategory: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/category`, { from: { id: fromCategoryId, name: fromCategory }, to: { id: toCategoryId, name: toCategory } });
  }

  public amendTicketReportGroupAsync(id: number, fromReportGroupId: number, fromReportGroup: string, toReportGroupId: number, toReportGroup: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/reportgroup`, { from: { id: fromReportGroupId, name: fromReportGroup }, to: { id: toReportGroupId, name: toReportGroup } });
  }

  public amendTicketSubjectAsync(id: number, from: string, to: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/subject`, { from, to });
  }

  public amendTicketDescriptionAsync(id: number, from: string, to: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/description`, { from, to });
  }

  public amendTicketCcmailAsync(id: number, from: string, to: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/ccmail`, { from, to });
  }

  public amendTicketValuationDateAsync(id: number, from: ValuationDate | null, to: ValuationDate | null): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/valuationdate`, { from, to });
  }

  public amendTicketReportIdAsync(id: number, from: number | null, to: number | null): Promise<void> {
    return this.putAsync(`v1/support/ticket/${id}/report`, { from, to });
  }

  public submitTicketReplyAsync(id: number, message: string): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/message`, { message });
  }

  public submitTicketNoteAsync(id: number, message: string): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/note`, { message });
  }

  public submitTicketAttachmentAsync(id: number, file: File, correlationId: string | null = null): Promise<void> {
    return this.postFileAsync(`v1/support/ticket/${id}/attachment`, file, correlationId);
  }

  public placeTicketUnderInvestigationAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/investigating`, null);
  }

  public ticketWaitingOnCustomerAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/waiting`, null);
  }

  public reopenTicketAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/reopen`, null);
  }

  public resolveTicketAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/resolve`, null);
  }

  public unresolveTicketAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/unresolve`, null);
  }

  public closeTicketAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/close`, null);
  }

  public placeTicketOnHoldAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/hold`, null);
  }

  public placeTicketInDevelopmentAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/development`, null);
  }

  public ticketReadyForTestingAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/testing`, null);
  }

  public ticketReadyForReleaseAsync(id: number): Promise<void> {
    return this.postAsync(`v1/support/ticket/${id}/release`, null);
  }

  public removeSupportTicketAttachmentAsync(attachmentId: number, ticketId: number): Promise<Blob> {
    return this.deleteAsync(`v1/support/ticket/${ticketId}/attachment/${attachmentId}`);
  }

  public amendSupportTicketMessageAsync(ticketId: number, supportIssueDetailID: number, detail: string): Promise<void> {
    return this.putAsync(`v1/support/ticket/${ticketId}/message`, { supportIssueDetailID, detail });
  }
}

<template>
  <validation-observer v-slot="{ passes, invalid, pending }">
    <b-modal v-model="visible"
             ok-variant="danger"
             ok-title="Delete"
             cancel-variant="link"
             centered
             :title="modalTitle"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             size="sm"
             :ok-disabled="(invalid && !pending) || submitting"
             @ok.prevent="passes(onOk)"
             @cancel="onCancel"
             @close="onClose"
             footer-class="borderstyle"
             header-class="borderstyle"
    >
      <span>{{ modalMsg }}</span>
    </b-modal>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Tenant, TenantUser } from '@/store/tenant/state';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { UserResponse } from '../../api/responses/user/user-response';
import { removeNonAlphanumeric } from '@/utilities/text.utils';

const tenantModule = namespace('tenant');
const userModule = namespace('user');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreateTeamDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ type: String, required: true }) public team!: string;
  @tenantModule.Getter private current!: Tenant;
  @userModule.Getter('current') private currentUser!: UserResponse;

  private name: string | null = this.team;
  private visible: boolean = false;
  private submitting: boolean = false;
  private modalTitle: string = `Delete team '${this.team}'`
  private modalMsg: string = `Are you sure that you want to delete the team '${this.team}' ?`

  private get users(): Array<TenantUser> {
    if (this.current === null) {
      return [];
    }

    return this.current.users;
  }

  private onOk(): void {
    this.submitting = true;
    this.$emit('ok', {
      name: this.name,
    });
  }

  private onCancel(): void {
    this.$emit('cancel');
    this.clear();
  }

  private onClose(): void {
    this.$emit('close');
    this.clear();
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
    this.clear();
  }

  private clear(): void {
    this.name = null;
  }

  private format(value: string): string {
    return removeNonAlphanumeric(value);
  }
}
</script>

<style>
.borderstyle{
     border: none !important;
}
</style>

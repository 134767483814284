import { GetterTree } from 'vuex';
import { State } from '@/store/dashboard/state';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public current = (state: State) => {
    return state.current;
  }

  public currentValuationDate = (state: State) => {
    if (state.current == null) {
      return null;
    }

    return this.valuationDates(state).find((d) => d.valuationDate === state.valuationDate) || this.valuationDates(state)[0] || null;
  }

  public reportGroups = (state: State) => {
    if (state.current === null) {
      return null;
    }

    return state.reportGroups;
  }

  public valuationDates = (state: State) => {
    if (state.current == null) {
      return [];
    }

    return state.current.valuationDates;
  }
}

import { WorkflowReportUnflagged } from '../events/workflow-report-unflagged';
import store from '@/store';

async function handleWorkflowReportUnflagged(data: WorkflowReportUnflagged): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportUnflagged event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryUpdateReportProperties', {
    reportId: reportId,
    flagged: false,
    flagColour: 0,
    flagReason: data.payload.comments,
    actions: {
      canFlag: true,
      canEditFlag: false,
      canUnflag: false,
    },
  });
}

export default handleWorkflowReportUnflagged;

<template>
  <div :class="classes" class="workflow-report-activity-status text-center" :title="title" v-if="classes !== null">
    <div class="text user-select-none" v-if="item.postCompletion" style="font-size: 0.75em;">i</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkflowReportActivityResponse } from '@/api/responses/workflow/workflow-report-activity-response';

@Component
export default class WorkflowReportActivityStatus extends Vue {
  @Prop({ type: Object, required: true }) public item!: WorkflowReportActivityResponse;

  public get classes(): { [key: string]: boolean } | null {
    switch (this.item.status) {
      case 'Blank':
        return null;
      case 'Error':
        return { 'is-background-danger': true };
      case 'Warning':
        return { 'is-background-warning': true };
      case 'OK':
        return { 'is-background-success': true };
      default:
        return null;
    }
  }

  public get title(): string | null {
    switch (this.item.status) {
      case 'Error':
        return this.item.postCompletion ? 'Activity after deadline and new data since completion' : 'Activity after deadline';
      case 'Warning':
        return this.item.postCompletion ? 'Activity approaching deadline and new data since completion' : 'Activity approaching deadline';
      case 'OK':
        return this.item.postCompletion ? 'Activity before deadline and new data since completion' : 'Activity before deadline';
      default:
        return null;
    }
  }
}
</script>

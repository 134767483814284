<template>
  <div class="workflow-report-rendering-status is-background-danger text-center" title="Error">
    <div class="text">!</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Error extends Vue {
}
</script>

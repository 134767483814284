import Vue from 'vue';
import { MutationTree } from 'vuex';
import merge from 'lodash.merge';
import {
  State,
  DocumentLibrary,
  DocumentLibraryDate,
  DocumentLibraryRegion,
  DocumentLibraryEntity,
  DocumentLibraryLanguage,
  DocumentLibraryInsertType,
  DocumentLibraryCommentaryType,
  DocumentLibraryDocumentType,
  DocumentsPage,
  Document
} from '@/store/document-library/state';
import { ReportGroup } from '../tenant/state';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  setCurrent = (state: State, documentLibrary: DocumentLibrary) => {
    state.current = documentLibrary;
  }

  addPage = (state: State, page: DocumentsPage) => {
    Vue.set(state.pages, page.page - 1, page);
  }

  clearPages = (state: State) => {
    state.pages = [];
  }

  setCurrentValuationDate = (state: State, date: DocumentLibraryDate | null) => {
    state.currentValuationDate = date;
  }

  setCurrentValuationDateFrom = (state: State, date: DocumentLibraryDate | null) => {
    state.currentValuationDateFrom = date;
  }

  setCurrentValuationDateTo = (state: State, date: DocumentLibraryDate | null) => {
    state.currentValuationDateTo = date;
  }

  setCurrentRegion = (state: State, region: DocumentLibraryRegion | null) => {
    state.currentRegion = region;
  }

  setCurrentEntity = (state: State, entity: DocumentLibraryEntity | null) => {
    state.currentEntity = entity;
  }

  setCurrentLanguage = (state: State, value: DocumentLibraryLanguage | null) => {
    state.currentLanguage = value;
  }

  setCurrentInsertType = (state: State, value: DocumentLibraryInsertType | null) => {
    state.currentInsertType = value;
  }

  setCurrentCommentaryType = (state: State, value: DocumentLibraryCommentaryType | null) => {
    state.currentCommentaryType = value;
  }

  setCurrentDocumentType = (state: State, value: DocumentLibraryDocumentType | null) => {
    state.currentDocumentType = value;
  }

  setSelectedDocumentId = (state: State, value: string | null) => {
    state.selectedDocumentId = value;
  }

  updateDocumentProperties = (state: State, data: { source: Partial<Document>, destination: Document }) => {
    // NOTE(Dan): Destructure the data to exclude the report id and take the source. This is to prevent us merging the "reportId" into
    //            the actual workflow report.
    const destination = data.destination;
    const { ...source } = data.source;
    merge(destination, source);
  }

  setReportGroup = (state: State, reportGroup: ReportGroup | null) => {
    state.currentReportGroup = reportGroup;
  }
}

<template>
  <div id="app">
    <layout>
      <router-view />
    </layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/layouts/app-layout.vue';

@Component({
  components: {
    Layout,
  },
})
export default class App extends Vue {
}
</script>

<template>
  <div class="row align-items-center">
    <label class="col-auto mb-0 pr-0">Region:</label>
    <div class="col-auto">
      <b-form-select v-model="selectedRegion">
        <template v-slot:first>
          <b-form-select-option :value="null">All</b-form-select-option>
        </template>
        <b-form-select-option v-for="region in regions" :key="region.id" :value="region">{{ region.name }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DocumentLibraryRegion, DocumentLibrary, DocumentLibraryLanguage } from '@/store/document-library/state';

const documentLibraryModule = namespace('documentLibrary');

@Component
export default class DocumentLibraryFilterBarRegionSelector extends Vue {
  @documentLibraryModule.Getter private current!: DocumentLibrary;
  @documentLibraryModule.Getter private regions!: Array<DocumentLibraryRegion>;
  @documentLibraryModule.Getter private currentRegion!: DocumentLibraryRegion | null;
  @documentLibraryModule.Getter private languages!: Array<DocumentLibraryLanguage>;
  @documentLibraryModule.Getter private currentLanguage!: DocumentLibraryLanguage | null;

  private get selectedRegion(): DocumentLibraryRegion | null {
    return this.currentRegion;
  }

  private set selectedRegion(value: DocumentLibraryRegion | null) {
    this.$store.commit('documentLibrary/setCurrentRegion', value);
  }

  @Watch('currentLanguage')
  private onLanguageChanged(language: DocumentLibraryLanguage | null): void {
    const match = this.regions.find((r) => r.id === this.currentRegion?.id) || null;

    if (match === null) {
      this.selectedRegion = null;
    }
  }
}
</script>

<template>
  <validation-observer
    tag="div"
    class="settings-table report-group-settings-table"
    :aria-busy="loading"
  >
    <div class="fake-table-header">
      {{ header }}
    </div>
    <b-table striped
             hover
             :thead-class="['d-none']"
             :sticky-header="headerStyle"
             :fields="fields"
             :items="visibleItems"
             :busy="loading"
             :no-sort-reset="true"
             :no-local-sorting="true"
             :no-local-filtering="true"
             :no-local-paging="true"
    >
      <template v-slot:cell(name)="{ item }">
        {{ item.displayName || item.name }}
      </template>

      <template v-slot:cell(info)="{ item }">
        <setting-control-tooltip :setting="item" v-if="item.metadata.tooltip !== null" />
      </template>

      <template v-slot:cell(value)="{ item }">
        <report-group-settings-control :key="uuid()" :setting="item" :group="groupFor(item.metadata.group)" @change="onSettingValueChanged" />
      </template>
    </b-table>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { EntityScheduleEntry } from '@/store/schedule/state';
import { BvTableFieldArray } from 'bootstrap-vue';
import { ReportGroup, Tenant } from '@/store/tenant/state';
import { sortable } from '@/directives/sortable';
import { Configuration, ConfigurationEntity, ReportGroupSetting } from '../../store/configuration/state';
import { UserResponse } from '../../api/responses/user/user-response';
import { ValidationObserver } from 'vee-validate';
import ReportGroupSettingsControl from './report-group-settings-control.vue';
import SettingControlTooltip from './setting-control-tooltip.vue';
import { v4 } from 'uuid';

const configurationModule = namespace('configuration');
const scheduleModule = namespace('schedule');
const environmentModule = namespace('environment');
const tenantModule = namespace('tenant');
const userModule = namespace('user');

@Component({
  directives: {
    sortable,
  },
  components: {
    ReportGroupSettingsControl,
    ValidationObserver,
    SettingControlTooltip,
  },
})
export default class ReportGroupSettingsTable extends Mixins(BvToastMixin) {
  @configurationModule.Getter public current!: Configuration | null;
  @configurationModule.Getter public currentEntity!: ConfigurationEntity | null;
  @scheduleModule.Getter('isLoading') public loading!: boolean;
  @scheduleModule.Getter('currentEntry') public selectedEntry!: EntityScheduleEntry | null;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;
  @configurationModule.Getter private currentReportGroup!: ReportGroup;
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @configurationModule.Getter public reportGroupSettings!: Array<ReportGroupSetting>;
  @userModule.Getter('current') public currentUser!: UserResponse;

  public get fields(): BvTableFieldArray {
    const fields: BvTableFieldArray = [
      { key: 'info', label: '', class: ['col-auto'], tdClass: ['text-nowrap'] },
      { key: 'name', label: 'Name', class: ['col-auto'], tdClass: ['text-nowrap'] },
      { key: 'value', label: '', class: ['col-5']/*, thAttr: { style: 'min-width: 200px;' } */ },
      { key: 'spacer', label: '', class: ['w-25']/*, thAttr: { style: 'min-width: 200px;' } */ }
    ];

    return fields;
  }

  public get headerStyle(): string {
    const isReportGroupSettings = this.$route.params.settings === 'report-group';

    if (this.environment === null) {
      if (isReportGroupSettings) {
        return 'calc(100vh - 270px - 54px)';
      }

      return 'calc(100vh - 270px)';
    }

    if (this.environment.environment === 'Production') {
      if (isReportGroupSettings) {
        return 'calc(100vh - 270px - 54px)';
      }

      return 'calc(100vh - 270px)';
    }

    if (isReportGroupSettings) {
      return 'calc(100vh - 294px - 54px)';
    }

    return 'calc(100vh - 294px)';
  }

  public get items() {
    const settings = this.reportGroupSettings;

    return settings;
  }

  public get visibleItems() {
    const filtered = this.items.filter(s => this.isVisible(s));

    return filtered;
  }

  public get header(): string | null {
    if (this.current === null) {
      return null;
    }

    const template = this.current.metadata.reportGroupSettingsHeaderTemplate;

    if (template === undefined || template === null) {
      return `${this.currentReportGroup.displayName.singular} settings`;
    }

    const result = template.replaceAll('{entityName}', this.currentEntity?.name || '')
      .replaceAll('{entityType}', this.current.entityType || '')
      .replaceAll('{entitySubType}', this.current.entitySubType || '')
      .replaceAll('{regionOrLanguage}', this.current.regionOrLanguage || '')
      .replaceAll('{reportGroup}', this.currentReportGroup.displayName.singular || '');

    return result;
  }

  private groupFor(group: string | null | undefined) {
    if (group === undefined || group === null) {
      return null;
    }

    return this.reportGroupSettings.filter(s => s.metadata.group === group);
  }

  private isVisible(item: ReportGroupSetting): boolean {
    const fields = item.metadata.visibility?.fields || [];

    if (fields.length < 1) {
      return true;
    }

    const operator = item.metadata.visibility?.operator;

    if (operator === 'And') {
      return this.reportGroupSettings.filter(setting => fields.some(field => field === setting.name)).every(setting => this.isVisible(setting) && setting.value);
    } else if (operator === 'Or') {
      return this.reportGroupSettings.filter(setting => fields.some(field => field === setting.name)).some(setting => this.isVisible(setting) && setting.value);
    }

    return false;
  }

  private async onSettingValueChanged(change: { newValue: string | number | null, currentValue: string | number | null, setting: ReportGroupSetting }): Promise<void> {
    let name = (change.setting.displayName || change.setting.name);

    if (name.indexOf('(') > -1) {
      name = name.substring(0, name.indexOf('(')).trim();
    }

    try {
      await this.$store.dispatch('configuration/replaceSettingValueForReportGroupAsync', { reportGroupId: this.currentReportGroup!.id, name: change.setting.name, value: change.newValue, currentValue: change.currentValue });

      this.showSuccessToast(`Value for ${name} has updated`);

      change.setting.value = change.newValue;
    } catch (e) {
      change.setting.value = change.currentValue;
      this.showErrorToast(`Could not update value for ${name}`);
    } finally {
      await this.$store.dispatch('configuration/getSettingsActivityForReportGroupAsync', { reportGroupId: this.currentReportGroup.id });
    }
  }

  public uuid() {
    return v4();
  }
}
</script>

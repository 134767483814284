import { MutationTree } from 'vuex';
import { State, Configuration, EntityContentControl, EntityContentControlActivityLog, EntitySetting, EntitySettingsActivityLog, ReportGroupSetting, ReportGroupSettingsActivityLog, ConfigurationTeamMember, TeamActivityLog } from '@/store/configuration/state';
import { ReportGroup } from '../tenant/state';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setCurrent = (state: State, data: Configuration) => {
    state.current = data;
  }

  public setLoading = (state: State, value: boolean) => {
    state.loadingConfiguration = value;
  }

  public setCurrentEntity = (state: State, value: number | null) => {
    state.selectedEntityId = value;
  }

  public setCurrentTeam = (state: State, value: number | null) => {
    state.selectedTeamId = value;
  }

  public setCurrentDate = (state: State, value: string | null) => {
    state.selectedValuationDate = value;
  }

  public setEntityContentControls = (state: State, value: Array<EntityContentControl>) => {
    state.entityContentControls = value;
  }

  public setEntityContentControlActivity = (state: State, value: EntityContentControlActivityLog | null) => {
    state.entityContentControlActivity = value;
  }

  public setEntitySettings = (state: State, value: Array<EntitySetting>) => {
    state.entitySettings = value;
  }

  public setEntitySettingsActivity = (state: State, value: EntitySettingsActivityLog | null) => {
    state.entitySettingsActivity = value;
  }

  public setReportGroupSettings = (state: State, value: Array<ReportGroupSetting>) => {
    state.reportGroupSettings = value;
  }

  public setReportGroupSettingsActivity = (state: State, value: ReportGroupSettingsActivityLog | null) => {
    state.reportGroupSettingsActivity = value;
  }

  public setReportGroup = (state: State, reportGroup: ReportGroup | null) => {
    state.currentReportGroup = reportGroup;
  }

  public setReportGroupTeamMembers = (state: State, members: Array<ConfigurationTeamMember>) => {
    state.teamMembers = members;
  }

  public setTeamActivity = (state: State, value: TeamActivityLog | null) => {
    state.teamActivity = value;
  }
}

export class State {
  public searchPhrase: string | null = null;
  public phrases: Array<Phrase> | null = null;
  public selectedPhrase: string | null = null;
  public translations: Array<Translation> = [];
}

export interface Phrase {
  value: string;
}

export interface Translation {
  phrase: string;
  language: string;
  languageId: number;
  value: string | null;
}

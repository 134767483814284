import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import { UserStore } from './user/store';
import { TenantStore } from './tenant/store';
import { WorkflowStore } from './workflow/store';
import { EnvironmentStore } from './environment/store';
import { DocumentLibraryStore } from './document-library/store';
import { DataStore } from './data/store';
import { ScheduleStore } from './schedule/store';
import { LanguageMaintenanceStore } from './language-maintenance/store';
import { SupportStore } from './support/store';
import { SummaryStore } from './summary/store';
import { NotificationStore } from './notification/store';
import { DashboardStore } from './dashboard/store';
import { HelpStore } from './help/store';
import { ConfigurationStore } from './configuration/store';
import { camelcase, kebabcase } from '@/utilities/text.utils';
import { ReportGroup, Tenant } from './tenant/state';

Vue.use(Vuex);

type DashboardState = { route: 'home', tenant: string, valuationDate: string };
type SummaryState = { route: 'summary', tenant: string, reportGroup: string };
type ConfigurationState = { route: 'configuration', tenant: string, reportGroup: string, entity: string, settings: string };
type DataState = { route: 'data', tenant: string, reportGroup: string, valuationDate: string, option: string };
type WorkflowState = { route: 'workflow', tenant: string, reportGroup: string, valuationDate: string, option: string, reportId?: string };
type DocumentLibraryState = { route: 'document-library', tenant: string, reportGroup: string, entity: string, languageOrRegion: string, documentId?: string };
type LanguageMaintenanceState = { route: 'language-maintenance', tenant: string };
type SupportState = { route: 'support', tenant: string, tab: string };
type SupportTicketState = { route: 'support-ticket', tenant: string };

export default new Vuex.Store({
  modules: {
    user: new UserStore(),
    tenant: new TenantStore(),
    workflow: new WorkflowStore(),
    environment: new EnvironmentStore(),
    documentLibrary: new DocumentLibraryStore(),
    data: new DataStore(),
    schedule: new ScheduleStore(),
    languageMaintenance: new LanguageMaintenanceStore(),
    support: new SupportStore(),
    summary: new SummaryStore(),
    notification: new NotificationStore(),
    dashboard: new DashboardStore(),
    help: new HelpStore(),
    configuration: new ConfigurationStore(),
  },
  actions: {
    // [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);
    /* eslint-disable */
    async applyStateFromRoute(context: ActionContext<any, any>, payload: DashboardState | SummaryState | ConfigurationState | DataState | WorkflowState | DocumentLibraryState | LanguageMaintenanceState | SupportState | SupportTicketState): Promise<void> { 
      const { route, ...state } = payload;
      switch (payload.route) {
        case 'home': {
          return await this.dispatch(`dashboard/applyStateFromRoute`, state);
        }
        case 'configuration': 
        case 'data':
        case 'document-library':
        case 'language-maintenance':
        case 'summary':
        case 'workflow': {
          return await this.dispatch(`${camelcase(payload.route)}/applyStateFromRoute`, state);
        }
        case 'support':
        case 'support-ticket': {
          return await this.dispatch(`support/applyStateFromRoute`, state);
        }
        default:
          throw new Error(`Invalid route: '${payload}'`);
      }
    },
    /* eslint-enable */

    async tryApplyReportGroup(context: ActionContext<any, any>, reportGroup: ReportGroup) {
      const tenant = context.rootGetters['tenant/current'] as Tenant;
      const reportGroups = tenant.reportGroups;

      if (reportGroup.visible.all) {
        await context.dispatch('tenant/setCurrentReportGroupAsync', { id: reportGroup!.id, name: reportGroup!.name, tenantId: tenant.id, tenantName: tenant.name }, { root: true });
        context.commit('tenant/setReportGroup', reportGroup, { root: true });
        // NOTE(Dan): We're setting a global report group.
        //            If configuration is already on a global report group, set the configuration report group
        //            If document library is already on a global report group, set the document library report group
        const configurationReportGroup = context.rootGetters['configuration/currentReportGroup'] as ReportGroup | null || reportGroups.find((group) => kebabcase(group.name) === reportGroup.name) || reportGroups.find((group) => group.active.configuration) || reportGroups.find((group) => group.active.all) || reportGroups[0] || null;
        const docLibraryReportGroup = context.rootGetters['documentLibrary/currentReportGroup'] as ReportGroup | null || reportGroups.find((group) => kebabcase(group.name) === reportGroup.name) || reportGroups.find((group) => group.active.documentLibrary) || reportGroups.find((group) => group.active.all) || reportGroups[0] || null;

        if (configurationReportGroup !== null && configurationReportGroup.visible.all) {
          await context.dispatch('tenant/setCurrentConfigurationReportGroupAsync', { id: reportGroup!.id, name: reportGroup!.name, tenantId: tenant.id, tenantName: tenant.name }, { root: true });
          context.commit('configuration/setReportGroup', reportGroup, { root: true });
        }

        if (docLibraryReportGroup !== null && docLibraryReportGroup.visible.all) {
          await context.dispatch('tenant/setCurrentDocumentLibraryReportGroupAsync', { id: reportGroup!.id, name: reportGroup!.name, tenantId: tenant.id, tenantName: tenant.name }, { root: true });
          context.commit('documentLibrary/setReportGroup', reportGroup, { root: true });
        }
      } else if (reportGroup.visible.configuration) {
        // NOTE(Dan): Configuration-only report group.
        await context.dispatch('tenant/setCurrentConfigurationReportGroupAsync', { id: reportGroup!.id, name: reportGroup!.name, tenantId: tenant.id, tenantName: tenant.name }, { root: true });
        context.commit('configuration/setReportGroup', reportGroup, { root: true });
      } else if (reportGroup.visible.documentLibrary) {
        // NOTE(Dan): Document library-only report group.
        await context.dispatch('tenant/setCurrentDocumentLibraryReportGroupAsync', { id: reportGroup!.id, name: reportGroup!.name, tenantId: tenant.id, tenantName: tenant.name }, { root: true });
        context.commit('documentLibrary/setReportGroup', reportGroup, { root: true });
      }
    },
  },
});

<template>
  <b-modal id="modal-center"
           v-model="visible"
           ok-variant="primary"
           ok-title="Submit"
           cancel-variant="link"
           centered
           :title="title"
           :no-close-on-backdrop="true"
           @ok="onOk"
           @cancel="onCancel"
           @close="onClose"
  >
    <span>Are you sure?</span>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class DataFileMandatoryConfirmationDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ required: true }) public title!: string | null;
  private visible: boolean = false;

  private onOk(): void {
    this.$emit('ok');
  }

  private onCancel(): void {
    this.$emit('cancel');
  }

  private onClose(): void {
    this.$emit('close');
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
  }
}
</script>

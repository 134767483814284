import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State, Dashboard, DashboardValuationDate, DashboardStatisticsReportGroup } from '@/store/dashboard/state';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setCurrent = (state: State, data: Dashboard) => {
    state.current = data;
  }

  public setReportGroups = (state: State, data: Array<DashboardStatisticsReportGroup>) => {
    state.reportGroups = data;
  }

  public setCurrentValuationDate = (state: State, date: DashboardValuationDate) => {
    state.valuationDate = date?.valuationDate || null;
  }

  public replaceReportGroupForValuationDate = (state: State, reportGroup: DashboardStatisticsReportGroup) => {
    const index = state.reportGroups.findIndex((rg) => rg.name === reportGroup.name && rg.valuationDate === reportGroup.valuationDate);

    if (index < 0) {
      return;
    }

    Vue.set(state.reportGroups, index, reportGroup);
  }
}

<template>
  <div>
    <svg
      width="2em"
      height="2em"
      viewBox="0 0 200 200"
    >
      <g transform="translate(0.0383083,-10.724966)">
        <g transform="matrix(5.8001012,0,0,5.8001012,4.9616917,15.724966)">
          <path style="fill:#a9a9a9;fill-opacity:1;stroke-width:1.08912" d="M 5.4176089,2.2341684 A 1.4907672,1.4138231 0 0 0 3.9268032,3.648045 v 6.0106294 7.4244126 7.424411 6.010629 a 1.4907672,1.4138231 0 0 0 1.4908057,1.413782 H 29.276557 a 1.4907672,1.4138231 0 0 0 1.490806,-1.413782 V 24.507498 17.083087 9.6586744 3.648045 A 1.4907672,1.4138231 0 0 0 29.276557,2.2341684 Z" />
          <path d="m 3.4463134,0.65967124 a 1.4952716,1.415241 0 0 0 -1.49531,1.41529456 v 6.0166574 7.4318578 7.431857 6.016657 a 1.4952716,1.415241 0 0 0 1.49531,1.415201 H 27.377349 a 1.4952716,1.415241 0 0 0 1.49531,-1.415201 V 22.955338 15.523481 8.0916232 2.0749658 a 1.4952716,1.415241 0 0 0 -1.49531,-1.41529456 z" style="fill:#e8e8e8;fill-opacity:1;stroke-width:1.09131" />
          <path fill="none" d="M 0,0 H 32 V 32 H 0 Z" />
          <path style="stroke-width:0.797792" d="M 3.3861815,1.4234418 H 27.261294 V 28.721743 H 3.3861815 Z" fill="none" />
          <path data-name="Back Plate" d="M 5.7471272,4.1462824 H 25.053315 a 1.93004,1.9446672 0 0 1 1.930039,1.944666 V 25.543456 a 1.93004,1.9446672 0 0 1 -1.930039,1.944665 H 5.7471272 a 1.93004,1.9446672 0 0 1 -1.93004,-1.944665 V 6.0909484 a 1.93004,1.9446672 0 0 1 1.93004,-1.944666 z" fill="#107c41" style="fill:#cc4b4c;fill-opacity:1;stroke-width:1.45336" />
          <path style="fill:#ffffff;fill-opacity:1;stroke-width:0.851985" d="m 6.8230952,26.204432 v 0 c -0.318517,0 -0.624389,-0.08968 -0.885303,-0.258465 -0.953142,-0.619212 -1.081191,-1.308192 -1.020779,-1.777557 0.166585,-1.290741 2.009627,-2.641757 5.4795708,-4.018134 1.37703,-2.613215 2.687143,-5.832959 3.468103,-8.523078 -0.913803,-1.7220546 -1.801718,-3.9562926 -1.154449,-5.2668656 0.226995,-0.459052 0.50999,-0.811076 1.038241,-0.963311 0.208731,-0.06031 0.73618,-0.136437 0.930262,-0.136437 0.461418,0 0.867039,0.514548 1.154447,0.831689 0.270147,0.29811 0.882495,0.930011 -0.341397,5.3929286 1.234127,2.207279 2.982878,4.455785 4.658334,5.995488 1.200209,-0.187897 2.233028,-0.283839 3.074419,-0.283839 1.433829,0 2.302613,0.289384 2.656917,0.885603 0.293026,0.493153 0.172605,1.069541 -0.357053,1.712547 -0.509989,0.617634 -1.213053,0.944281 -2.032527,0.944281 -1.113303,0 -2.409728,-0.608906 -3.855377,-1.811648 -2.597429,0.470157 -5.630645,1.30899 -8.082481,2.237406 -0.765485,1.406513 -1.498654,2.53948 -2.1817678,3.370381 -0.938491,1.137724 -1.74773,1.66894 -2.548898,1.66894 z m 2.437207,-4.064121 c -1.956461,0.952206 -2.753978,1.734743 -2.81166,2.175566 -0.0092,0.07299 -0.03411,0.264808 0.394585,0.548648 0.136482,-0.03718 0.93287,-0.352024 2.417054,-2.724214 z M 21.745658,18.618498 c 0.746217,0.497114 0.928454,0.748454 1.416368,0.748454 0.214149,0 0.82489,-0.0079 1.107884,-0.349644 0.136481,-0.165635 0.18866,-0.271952 0.210537,-0.329027 -0.112403,-0.05163 -0.26192,-0.156251 -1.075772,-0.156251 -0.462421,7.8e-4 -1.043659,0.01826 -1.659017,0.08639 z M 14.9065,13.399997 c -0.654697,1.961495 -1.518927,4.079183 -2.448185,5.997074 1.91351,-0.642992 3.993643,-1.204326 5.947415,-1.601539 -1.235935,-1.243189 -2.471067,-2.795573 -3.49923,-4.395535 z M 14.350749,6.6909464 c -0.09033,0.02624 -1.217668,1.393026 0.08831,2.549787 0.868843,-1.676866 -0.04815,-2.560887 -0.08831,-2.549787 z" />
          <g transform="matrix(1.1217054,0,0,1.1211122,-2.4936146,-2.8584232)">
            <g>
              <path transform="matrix(2.190629,0,0,3.0733002,-24.726698,-50.392987)" style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.0255418;stroke-opacity:1" d="m 17.659158,22.185449 0.163486,-0.226192 2.019642,0.0086 0.02424,-1.927708 3.987832,0.01714 v 1.910403 l 2.059206,-0.0087 0.01885,0.336162 0.0076,0.387608 -3.601086,4.276548 -0.5084,0.0068 -0.494112,-0.01001 -3.83433,-4.552281 z" />
              <g transform="matrix(1.7980234,0,0,2.3926161,-10.485526,-38.451952)" style="fill:#233264;fill-opacity:1">
                <path style="fill:#233264;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1" transform="matrix(0.11224705,0.00402688,-0.00567597,0.07963492,-5.5901957,16.017455)" d="m 180.20709,87.443958 24.94671,-1.262975 -1.56126,-30.875168 31.64023,-1.322643 1.28472,30.609965 24.39561,-1.235075 -36.81451,71.936408 z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DownloadPdfIcon extends Vue {
}
</script>

<template>
  <div class="websocket-connection-status-bar row no-gutters" v-if="current !== null && !isWebsocketConnected">
    <div class="col-auto">
      <span>{{ description }}</span>
      <b-btn
        v-if="disconnectedDuration && disconnectedDuration > 120"
        size="sm"
        variant="critical"
        @click="reload"
      >
        Reload now
      </b-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DateTime } from 'luxon';

const environmentModule = namespace('environment');

@Component
export default class WebsocketConnectionStatusBar extends Vue {
  @environmentModule.Getter current!: { environment: string, version: string | null, runtime: string } | null;
  @environmentModule.Getter isWebsocketConnected!: boolean;

  disconnectedDuration: number | null = null;
  private disconnectedAt: DateTime | null = null;
  private timer: number | null = null;

  get description() {
    if (this.isWebsocketConnected) {
      return '';
    }

    if (this.disconnectedDuration !== null && this.disconnectedDuration > 120) {
      return "You've been disconnected from the Opus Nebula server for too long.";
    }

    return 'Attemping to restore connection. Live updates are currently not available.';
  }

  @Watch('isWebsocketConnected', { immediate: true })
  private onIsWebsocketConnectedChanged(value: boolean): void {
    if (value) {
      this.disconnectedAt = null;
      this.disconnectedDuration = null;

      if (this.timer !== null) {
        clearInterval(this.timer);
      }
    } else {
      this.disconnectedAt = DateTime.utc();
      const handler: TimerHandler = () => {
        if (this.disconnectedAt === null) {
          return;
        }

        const diff = DateTime.utc().diff(this.disconnectedAt, 'seconds');
        this.disconnectedDuration = diff.seconds;
      };

      this.timer = setInterval(handler, 10000);
    }
  }

  reload(): void {
    location.reload();
  }
}
</script>

<template>
  <div class="container-fluid support-ticket-attachment-list">
    <div
      class="support-ticket-attachment border-left border border-top-0 p-3 row align-items-center"
      v-for="attachment in attachments"
      :key="attachment.id"
    >
      <div class="col-auto">
        <!-- <b-avatar icon="file-earmark" @click="previewImage(attachment)" /> -->
        <!-- <b-icon  scale="1.5" /> -->
        <img v-if="attachment.imageData" @click="previewImage(attachment)" style="max-height: 40px; width: 40px; cursor: pointer;" :src="'data:image/png;base64,' + attachment.imageData" alt="Image thumbnail">
        <b-avatar icon="file-earmark" style="max-height: 40px; width: 40px;" v-else />
      </div>
      <div class="col">
        <b-button variant="link" @click="downloadAttachmentAsync(attachment)">
          {{
            attachment.name
          }}
        </b-button>
        <div style="padding-left: 9.7px;">
          {{
            activities
              .flatMap(activity => activity.entries)
              .filter(entry => entry.description.includes(attachment.name))
              .map(entry => entry.name)[0]
          }}
        </div>
      </div>
      <div class="col-auto">
        <file-size :bytes="attachment.length" />
      </div>
      <div class="col-auto">
        {{ attachment.createdDate | datetime }}
      </div>
      <div v-if="isAdministrator" class="col-auto">
        <b-dropdown
          title="Change Status"
          :no-caret="true"
          variant="outline-primary"
          size="sm"
          right
          :popper-opts="{
            modifiers: { computeStyle: { gpuAcceleration: false } }
          }"
        >
          <template v-slot:button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-item
            title="Remove attachment"
            @click="onRemoveAttachmentClicked(attachment)"
          >
            Remove
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <attachment-remove-dialog
      v-if="showRemoveAttachmentDialog"
      :title="`Delete attachment ${entrySelected && entrySelected.name}?`"
      @ok="removeAttachmentAsync"
      @close="onRemoveAttachmentCancelled"
      @cancel="onRemoveAttachmentCancelled"
    />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { SupportTicketAttachment, SupportTicketActivityEntryGroup } from '@/store/support/state';
import { namespace } from 'vuex-class';
import { UserResponse } from '@/api/responses/user/user-response';
import AttachmentRemoveDialog from './support-ticket-attachment-remove-dialog.vue';
import FileSize from '@/components/utils/file-size.vue';
import { FileUtils } from '@/utilities/file.utils';

const supportModule = namespace('support');
const userModule = namespace('user');

@Component({
  components: {
    AttachmentRemoveDialog,
    FileSize,
  },
})
export default class SupportTicketMessageList extends Mixins(BvToastMixin) {
  @supportModule.Getter attachments!: Array<SupportTicketAttachment>;
  @userModule.Getter('current') currentUser!: UserResponse;
  @supportModule.Getter('activity') activities!: Array<SupportTicketActivityEntryGroup>;

  entrySelected: SupportTicketAttachment | null = null;
  showRemoveAttachmentDialog = false;

  get isAdministrator(): boolean {
    return this.currentUser.roles.administrator || false;
  }

  async downloadAttachmentAsync(
    attachment: SupportTicketAttachment
  ): Promise<void> {
    try {
      const ticketId = Number(attachment.ticketId.split('-')[1]);
      const data = (await this.$store.dispatch(
        'support/downloadSupportTicketAttachmentAsync',
        { ticketId: ticketId, attachmentId: attachment.id }
      )) as Blob;
      FileUtils.downloadFile(data, attachment.name!);
    } catch (e) {
      this.showErrorToast('Could not download file. Please try again.');
    }
  }

  async previewImage(
    attachment: SupportTicketAttachment
  ): Promise<void> {
    try {
      const ticketId = Number(attachment.ticketId.split('-')[1]);
      const data = (await this.$store.dispatch(
        'support/downloadSupportTicketAttachmentAsync',
        { ticketId: ticketId, attachmentId: attachment.id }
      )) as Blob;
      FileUtils.previewFile(data);
    } catch (e) {
      this.showErrorToast('Could not download file. Please try again.');
    }
  }

  onRemoveAttachmentClicked(item: SupportTicketAttachment) {
    this.entrySelected = item;
    this.showRemoveAttachmentDialog = true;
  }

  onRemoveAttachmentCancelled() {
    this.entrySelected = null;
    this.showRemoveAttachmentDialog = false;
  }

  async removeAttachmentAsync(): Promise<void> {
    const attachment = this.entrySelected;
    if (attachment === null) {
      return;
    }
    try {
      const ticketId = Number(attachment!.ticketId.split('-')[1]);
      await this.$store.dispatch('support/removeSupportTicketAttachmentAsync', {
        ticketId: ticketId,
        attachmentId: attachment!.id,
      });
    } catch {
      this.showErrorToast('Could not remove file. Please try again.');
    }
    this.onRemoveAttachmentCancelled();
  }
}
</script>

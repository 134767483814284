import { UserManager } from 'oidc-client';
import configuration from '@/services/user-manager/user-manager-configuration';
import * as Sentry from '@sentry/browser';

const userManager = new UserManager(configuration);

userManager.events.addSilentRenewError(async (error: any) => {
  Sentry.captureException(error);
  await userManager.signinRedirect();
});

export default userManager;

import { ActionTree, ActionContext } from 'vuex';
import { DashboardValuationDate, State } from '@/store/dashboard/state';
import { DashboardApi } from '@/api/dashboard-api';
import { Dictionary } from 'vue-router/types/router';

type DashboardState = { tenant: string, valuationDate: string };

const api = new DashboardApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public getDashboardConfigurationAsync = async (context: ActionContext<State, any>) => {
    try {
      const result = await api.getDashboardConfigurationAsync();

      context.commit('setCurrent', result);

      return result;
    } catch (e) {
      return null;
    }
  }

  public getDashboardForValuationDateAsync = async (context: ActionContext<State, any>, valuationDate: string) => {
    try {
      const result = await api.getDashboardForValuationDateAsync(valuationDate);

      context.commit('setReportGroups', result.reportGroups);

      return result;
    } catch (e) {
      return null;
    }
  }

  public tryUpdateOrReloadReportGroupForValuationDate = async (context: ActionContext<State, any>, data: { reportGroupId: number, valuationDate: string }) => {
    if (context.getters.current === null) {
      return;
    }

    if (context.getters.reportGroups.length < 1) {
      return;
    }

    const currentValuationDate = context.getters.currentValuationDate as DashboardValuationDate;

    if (currentValuationDate === null) {
      return;
    }

    if (new Date(currentValuationDate.valuationDate).toDateString() !== new Date(data.valuationDate).toDateString()) {
      return;
    }

    const result = await api.getDashboardReportGroupForValuationDateAsync(data.reportGroupId, data.valuationDate);

    context.commit('replaceReportGroupForValuationDate', result);
  }

  public applyStateFromRoute = async (context: ActionContext<State, any>, data: DashboardState): Promise<Dictionary<string>> => {
    /* eslint-disable */
    console.log('dashboard/applyStateFromRoute', data);
    /* eslint-enable */
    return {};
  }
}

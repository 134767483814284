<template>
  <b-form-select v-model="selectedValuationDateTo">
    <template v-slot:first>
      <b-form-select-option :value="null" disabled>Please select...</b-form-select-option>
    </template>
    <b-form-select-option v-for="date in valuationDates" :key="date.valuationDate" :value="date">{{ date.displayDate | valuationdate(current.dateFormat) }}</b-form-select-option>
  </b-form-select>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Summary from '@/views/Summary.vue';
import { SummaryValuationDate } from '../../../store/summary/state';

const summaryModule = namespace('summary');

@Component
export default class SummaryFilterBarValuationDateToSelector extends Vue {
  @summaryModule.Getter private current!: Summary | null;
  @summaryModule.Getter('availableToValuationDates') private valuationDates!: Array<SummaryValuationDate>;
  @summaryModule.Getter private toValuationDate!: SummaryValuationDate | null;

  private get selectedValuationDateTo(): SummaryValuationDate | null {
    return this.toValuationDate;
  }

  private set selectedValuationDateTo(value: SummaryValuationDate | null) {
    this.$store.commit('summary/setValuationDateTo', value);
  }
}
</script>

<template>
  <div class="row align-items-center">
    <label class="col-auto mb-0 pr-0">From:</label>
    <div class="col-auto">
      <b-form-select v-model="selectedValuationDateFrom">
        <template v-slot:first>
          <b-form-select-option :value="null">All</b-form-select-option>
        </template>
        <b-form-select-option v-for="date in valuationDates" :key="date.valuationDate" :value="date">{{ date.displayDate | valuationdate(current.dateFormat) }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DocumentLibraryDate, DocumentLibrary } from '@/store/document-library/state';

const documentLibraryModule = namespace('documentLibrary');

@Component
export default class DocumentLibraryFilterBarValuationDateFromSelector extends Vue {
  @documentLibraryModule.Getter private current!: DocumentLibrary;
  @documentLibraryModule.Getter private valuationDates!: Array<DocumentLibraryDate>;
  @documentLibraryModule.Getter private currentValuationDateFrom!: DocumentLibraryDate | null;

  private get selectedValuationDateFrom(): DocumentLibraryDate | null {
    return this.currentValuationDateFrom;
  }

  private set selectedValuationDateFrom(value: DocumentLibraryDate | null) {
    this.$store.commit('documentLibrary/setCurrentValuationDateFrom', value);
  }
}
</script>

<template>
  <div :class="classes" class="setting-control-tooltip text-center" :title="title" v-if="classes !== null">
    <div class="text user-select-none" style="font-size: 0.75em;">i</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EntitySetting } from '../../store/configuration/state';

@Component
export default class SettingControlTooltip extends Vue {
  @Prop({ type: Object, required: true }) public setting!: EntitySetting;

  public get classes(): { [key: string]: boolean } | null {
    return { 'is-background-info': true };
  }

  public get title(): string | null {
    return this.setting.metadata.tooltip;
  }
}
</script>

<template>
  <div :class="classes" class="workflow-report-commentary-status text-center" :title="title" v-if="classes !== null">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkflowReportContentItemResponse } from '../../api/responses/workflow/workflow-report-content-item-response';

@Component
export default class WorkflowReportContentStatus extends Vue {
  @Prop({ type: Object, required: true }) public item!: WorkflowReportContentItemResponse;

  public get classes(): { [key: string]: boolean } | null {
    return {
      'is-background-danger': this.item.status === 'Incomplete',
      'is-background-success': this.item.status === 'Complete',
      'is-background-warning': this.item.status === 'InProgress',
    };
  }

  public get title(): string | null {
    switch (this.item.status) {
      case 'Complete':
        return 'Complete';
      case 'InProgress':
        return 'In progress';
      case 'Incomplete':
        return 'Incomplete';
      default:
        return null;
    }
  }

  public get text(): string | null {
    switch (this.item.status) {
      case 'Complete':
        return '✓';
      case 'InProgress':
      case 'Incomplete':
        return '!';
      default:
        return null;
    }
  }
}
</script>

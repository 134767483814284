import { Api } from '@/api/api';
import { ConfigurationResponse } from '@/api/responses/configuration/configuration-response';
import { EntityContentControlResponse } from './responses/configuration/entity-content-control-response';
import { EntityContentControlActivityLogResponse } from './responses/configuration/entity-content-control-activity-log-response';
import { EntitySettingResponse } from './responses/configuration/entity-setting-response';
import { EntitySettingsActivityLogResponse } from './responses/configuration/entity-settings-activity-log-response';
import { TeamMemberResponse } from './responses/configuration/team-member-response';
import { TeamActivityLogResponse } from './responses/configuration/team-activity-log-response';
import { DeleteTeamResponse } from './responses/configuration/delete-team-response';

export class ConfigurationApi extends Api {
  public constructor() {
    super();
  }

  public getConfigurationForReportGroupAsync(reportGroupId: number, reportId: number = 0): Promise<ConfigurationResponse> {
    return this.getAsync<ConfigurationResponse>(`v1/configuration/${reportGroupId}/report/${reportId}`);
  }

  public getContentControlForEntityAsync(entityId: number, reportGroupId: number): Promise<Array<EntityContentControlResponse>> {
    return this.getAsync<Array<EntityContentControlResponse>>(`v1/configuration/${reportGroupId}/entity/${entityId}/content-control`);
  }

  public enableContentControlForEntityAsync(code: string, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/configuration/${reportGroupId}/entity/${entityId}/content-control/${encodeURIComponent(code)}`, { enable: true });
  }

  public disableContentControlForEntityAsync(code: string, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/configuration/${reportGroupId}/entity/${entityId}/content-control/${encodeURIComponent(code)}`, { enable: false });
  }

  public getContentControlActivityForEntityAsync(entityId: number, reportGroupId: number): Promise<EntityContentControlActivityLogResponse> {
    return this.getAsync<EntityContentControlActivityLogResponse>(`v1/configuration/${reportGroupId}/entity/${entityId}/content-control/activity`);
  }

  public getSettingsForEntityAsync(entityId: number, reportGroupId: number): Promise<Array<EntitySettingResponse>> {
    return this.getAsync<Array<EntitySettingResponse>>(`v1/configuration/${reportGroupId}/entity/${entityId}/settings`);
  }

  public replaceSettingValueForEntityAsync(name: string, value: string | number | null, currentValue: string | number | null, entityId: number, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/configuration/${reportGroupId}/entity/${entityId}/settings/${encodeURIComponent(name)}`, { value: value?.toString(), currentValue });
  }

  public getSettingsActivityForEntityAsync(entityId: number, reportGroupId: number): Promise<EntitySettingsActivityLogResponse> {
    return this.getAsync<EntitySettingsActivityLogResponse>(`v1/configuration/${reportGroupId}/entity/${entityId}/settings/activity`);
  }

  public getSettingsForReportGroupAsync(reportGroupId: number): Promise<Array<EntitySettingResponse>> {
    return this.getAsync<Array<EntitySettingResponse>>(`v1/configuration/${reportGroupId}/settings`);
  }

  public getSettingsActivityForReportGroupAsync(reportGroupId: number): Promise<EntitySettingsActivityLogResponse> {
    return this.getAsync<EntitySettingsActivityLogResponse>(`v1/configuration/${reportGroupId}/settings/activity`);
  }

  public replaceSettingValueForReportGroupAsync(name: string, value: string | number | null, currentValue: string | number | null, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/configuration/${reportGroupId}/settings/${encodeURIComponent(name)}`, { value: value?.toString(), currentValue });
  }

  public getMembersForTeamAsync(teamId: number, reportGroupId: number): Promise<Array<TeamMemberResponse>> {
    return this.getAsync<Array<TeamMemberResponse>>(`v1/configuration/${reportGroupId}/team/${teamId}`);
  }

  public assignTeamMemberAsync(email: string, teamId: number, reportGroupId: number): Promise<void> {
    return this.postAsync(`v1/configuration/${reportGroupId}/team/${teamId}`, { email });
  }

  public removeTeamMemberAsync(email: string, comments: string | null, teamId: number, reportGroupId: number): Promise<void> {
    return this.deleteAsync(`v1/configuration/${reportGroupId}/team/${teamId}/${encodeURIComponent(email)}`, { comments });
  }

  public registerTeamAsync(name: string, reportGroupId: number): Promise<void> {
    return this.postAsync(`v1/configuration/${reportGroupId}/team`, { name });
  }

  public getTeamActivityForReportGroupAsync(teamId: number, reportGroupId: number): Promise<TeamActivityLogResponse> {
    return this.getAsync<TeamActivityLogResponse>(`v1/configuration/${reportGroupId}/team/${teamId}/activity`);
  }

  public renameTeamAsync(teamId: number, teamName: string, reportGroupId: number): Promise<void> {
    return this.putAsync(`v1/configuration/${reportGroupId}/rename-team`, { teamId: teamId, teamName: teamName });
  }

  public deleteTeamAsync(teamId: number, reportGroupId: number): Promise<DeleteTeamResponse> {
    return this.deleteAsync(`v1/configuration/${reportGroupId}/delete-team`, { teamId: teamId });
  }
}

import { WorkflowReportDataCheckCompleted } from '../events/workflow-report-data-check-completed';
import store from '@/store';

async function handleWorkflowReportDataCheckCompleted(data: WorkflowReportDataCheckCompleted): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportDataCheckCompleted event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryUpdateReportProperties', {
    renderingStatus: 'Blank',
    reportId: reportId,
  });

  if (data.payload.fromStateId !== data.payload.toStateId) {
    store.commit('workflow/tryRemoveReportFromPage', reportId);
    await store.dispatch('workflow/tryMoveReportToPage', { ...data.payload, reportId });
    await store.dispatch('workflow/tryUpdateOptionCounts', data.payload);
    // delay dashboard and summary screen updates for 10 seconds
    setTimeout(async () => {
      await store.dispatch('dashboard/tryUpdateOrReloadReportGroupForValuationDate', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
      await store.dispatch('summary/tryUpdateOrReloadSummary', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
    }, 10000);
    await store.dispatch('notification/tryUpdateWorkflowReportLinks', { ...data.payload, reportId });
  } else if (data.payload.issues !== null) {
    await store.dispatch('workflow/tryUpdateReportProperties', {
      reportId: reportId,
      hasDataIssues: data.payload.issues > 0,
      renderingStatus: data.payload.status || 'Blank',
    });
  }

  await store.dispatch('data/tryDecrementDataCheckQueueCount', data);
}

export default handleWorkflowReportDataCheckCompleted;

import { MutationTree } from 'vuex';
import { State } from '@/store/user/state';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setUser = (state: State, user: { email: string, name: string, currentTenantId: number } | null) => {
    state.current = user;
  }
}

<template>
  <support-ticket-entry-list
    :entries="notes"
    :get-entry-status-label="getNoteStatusLabel"
    @saved="onNoteSaved"
  />
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { SupportTicketMessage, SupportTicketDetails } from '@/store/support/state';
import { namespace } from 'vuex-class';
import SupportTicketEntryList from '@/components/support/support-ticket-entry-list.vue';

const supportModule = namespace('support');

@Component({
  components: {
    SupportTicketEntryList,
  },
})
export default class SupportTicketNoteList extends Mixins(BvToastMixin) {
  @supportModule.Getter notes!: Array<SupportTicketMessage>;
  @supportModule.Getter ticket!: SupportTicketDetails | null;

  async onNoteSaved(result: { supportIssueDetailID: number, detail: string }): Promise<void> {
    try {
      await this.$store.dispatch('support/amendSupportTicketMessageAsync', { ticketId: this.ticket!.id, supportIssueDetailID: result.supportIssueDetailID, detail: result.detail });

      this.showSuccessToast('Note successfully updated.');
      await this.$store.dispatch('support/getSupportTicketNotesAsync', this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update Note. Please try again.');
    }
  }

  getNoteStatusLabel() {
    return 'added note';
  }
}
</script>

import { Module } from 'vuex';
import { State } from '@/store/document-library/state';
import { Actions } from '@/store/document-library/actions';
import { Getters } from '@/store/document-library/getters';
import { Mutations } from '@/store/document-library/mutations';

export class DocumentLibraryStore implements Module<State, any> {
  namespaced: boolean = true;
  state: State = new State();
  actions = new Actions();
  getters: Getters = new Getters();
  mutations = new Mutations();
}

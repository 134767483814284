import { ReportGroup } from '../tenant/state';

export class State {
  public current: Configuration | null = null;
  public loadingConfiguration: boolean = false;
  public selectedEntityId: number | null = null;
  public selectedTeamId: number | null = null;
  public selectedValuationDate: string | null = null;
  public entityContentControls: Array<EntityContentControl> = [];
  public entityContentControlActivity: EntityContentControlActivityLog | null = null;
  public entitySettings: Array<EntitySetting> = [];
  public entitySettingsActivity: EntitySettingsActivityLog | null = null;
  public reportGroupSettings: Array<ReportGroupSetting> = [];
  public reportGroupSettingsActivity: ReportGroupSettingsActivityLog | null = null;
  public currentReportGroup: ReportGroup | null = null;
  public teamMembers: Array<ConfigurationTeamMember> = [];
  public teamActivity: TeamActivityLog | null = null;
}

export interface Configuration {
  entityType: string | null;
  entitySubType: string;
  selectedEntityID: number | null;
  regionOrLanguage: string | null;
  canRunReports: boolean;
  canScheduleReports: boolean;
  canReorderReports: boolean;
  canViewSettings: boolean;
  canEditSettings: boolean;
  canViewContentControl: boolean;
  canViewReportGroupSettings: boolean;
  canEditReportGroupSettings: boolean;
  canEditContentControl: boolean;
  canViewContentControlCodes: boolean;
  canViewSchedule: boolean;
  canViewTeams: boolean;
  hasSubEntities: boolean;
  entities: Array<ConfigurationEntity>;
  dates: Array<ConfigurationDate>;
  teams: Array<ConfigurationTeam>;
  metadata: {
    entitySettingsHeaderTemplate?: string | null,
    reportGroupSettingsHeaderTemplate?: string | null,
  }
}

export interface ConfigurationEntity {
  id: number;
  name: string;
}

export interface ConfigurationTeam {
  id: number;
  name: string;
}

export interface ConfigurationTeamMember {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface ConfigurationDate {
  valuationDate: string;
  displayDate: string;
}

export interface EntityContentControl {
  code: string;
  name: string;
  description: string | null;
  enabled: boolean;
  editable: boolean;
  lastUpdated: string | null;
  lastUpdatedBy: string | null;
}

export interface EntityContentControlActivityLog {
  reportGroupId: number;
  entityId: string;
  activity: Array<EntityContentControlActivity>;
}

export interface EntityContentControlActivity {
  entityId: number;
  timestamp: string;
  description: string | null;
}

export interface TeamActivityLog {
  reportGroupId: number;
  teamId: number;
  activity: Array<TeamActivity>;
}

export interface TeamActivity {
  teamId: number;
  timestamp: string;
  description: string | null;
}

export interface EntitySetting {
  name: string;
  displayName: string | null;
  type: 'Unknown' | 'Text' | 'Textarea' | 'Password' | 'Radio' | 'Checkbox' | 'Select';
  value: string | number | null;
  editable: boolean;
  options: Array<EntitySettingOption>;
  metadata: EntitySettingMetadata;
}

export interface EntitySettingOption {
  label: string;
  value: string | number | null;
}

export interface EntitySettingMetadata {
  group: string | null;
  tooltip: string | null;
  visiblity: EntitySettingVisibilityControl | null;
  validation: EntitySettingValidationResponse | null;
}

export interface EntitySettingVisibilityControl {
  fields: string[];
  operator: 'And' | 'Or';
}

export interface EntitySettingValidationResponse {
  rules: string | null;
}

export interface EntitySettingsActivityLog {
  reportGroupId: number;
  entityId: string;
  activity: Array<EntityContentControlActivity>;
}

export interface EntitySettingsActivity {
  entityId: number;
  timestamp: string;
  description: string | null;
}

export interface ReportGroupSetting {
  name: string;
  displayName: string | null;
  type: 'Unknown' | 'Text' | 'Textarea' | 'Password' | 'Radio' | 'Checkbox' | 'Select';
  value: string | number | null;
  editable: boolean;
  options: Array<ReportGroupSettingOption>;
  metadata: ReportGroupSettingMetadata;
}

export interface ReportGroupSettingOption {
  label: string;
  value: string | number | null;
}

export interface ReportGroupSettingMetadata {
  group: string | null;
  tooltip: string | null;
  visibility: ReportGroupSettingVisibilityControl | null;
  validation: ReportGroupSettingValidationResponse | null;
}

export interface ReportGroupSettingVisibilityControl {
  fields: string[];
  operator: 'And' | 'Or';
}

export interface ReportGroupSettingValidationResponse {
  rules: string | null;
}

export interface ReportGroupSettingsActivityLog {
  reportGroupId: number;
  entityId: string;
  activity: Array<ReportGroupSettingsActivity>;
}

export interface ReportGroupSettingsActivity {
  entityId: number;
  timestamp: string;
  description: string | null;
}

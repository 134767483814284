import { Module } from 'vuex';
import { State } from '@/store/language-maintenance/state';
import { Actions } from '@/store/language-maintenance/actions';
import { Getters } from '@/store/language-maintenance/getters';
import { Mutations } from '@/store/language-maintenance/mutations';

export class LanguageMaintenanceStore implements Module<State, any> {
  public namespaced: boolean = true;
  public state: State = new State();
  public actions = new Actions();
  public getters: Getters = new Getters();
  public mutations = new Mutations();
}

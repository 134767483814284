import { Api } from '@/api/api';
import { HelpResponse } from '@/api/responses/help/help-response';

export class HelpApi extends Api {
  public constructor() {
    super();
  }

  public getHelpAsync(): Promise<HelpResponse> {
    return this.getAsync<HelpResponse>('v1/help');
  }

  public downloadHelpVideoAsync(file: string) {
    return this.getFileAsync(`v1/help/${file}`);
  }
}

import { WorkflowReportArchivalCompleted } from '../events/workflow-report-archival-completed';
import store from '@/store';

async function handleWorkflowReportArchivalCompleted(data: WorkflowReportArchivalCompleted): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportArchivalCompleted event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  if (data.payload.fromStateId !== data.payload.toStateId) {
    store.commit('workflow/tryRemoveReportFromPage', reportId);
    await store.dispatch('workflow/tryMoveReportToPage', { ...data.payload, reportId });
    await store.dispatch('workflow/tryUpdateOptionCounts', data.payload);
    await store.dispatch('notification/tryUpdateWorkflowReportLinks', { ...data.payload, reportId });
  } else {
    await store.dispatch('workflow/tryUpdateReportProperties', {
      renderingStatus: 'Blank',
      reportId: reportId,
    });
  }

  // delay dashboard and summary screen updates for 10 seconds
  setTimeout(async () => {
    await store.dispatch('dashboard/tryUpdateOrReloadReportGroupForValuationDate', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
    await store.dispatch('summary/tryUpdateOrReloadSummary', { reportGroupId: data.payload.reportGroupId, valuationDate: data.payload.valuationDate });
  }, 10000);
}

export default handleWorkflowReportArchivalCompleted;

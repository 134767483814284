<template>
  <div class="row align-items-center" v-if="teams.length > 0">
    <label class="col-auto mb-0 pr-0">{{ current !== null ? 'Team' : null }}:</label>
    <div class="col-auto">
      <b-form-select v-model="selectedTeam" @change="onSelectedTeamChanged">
        <!-- <template v-slot:first>
          <b-form-select-option :value="null">Please select...</b-form-select-option>
        </template> -->
        <b-form-select-option v-for="team in teams" :key="team.id" :value="team">{{ team.name }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Configuration, ConfigurationTeam } from '@/store/configuration/state';
import { ReportGroup } from '@/store/tenant/state';

const configurationModule = namespace('configuration');

@Component
export default class ConfigurationFilterBarTeamSelector extends Vue {
  @configurationModule.Getter private current!: Configuration;
  @configurationModule.Getter private teams!: Array<ConfigurationTeam>;
  @configurationModule.Getter private currentTeam!: ConfigurationTeam | null;
  @configurationModule.Getter private currentReportGroup!: ReportGroup;

  private selectedTeam: ConfigurationTeam | null = null;

  private onSelectedTeamChanged(value: ConfigurationTeam | null): void {
    this.$store.commit('configuration/setCurrentTeam', value?.id || null);
  }

  @Watch('currentTeam')
  private onCurrentTeamChanged(team: ConfigurationTeam | null) {
    this.selectedTeam = team;
  }

  public mounted(): void {
    this.selectedTeam = this.currentTeam;
  }
}
</script>

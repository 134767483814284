import { DocumentCheckOutStarted } from '../events/document-checkout-started';
import store from '@/store';

async function handleDocumentCheckOutStarted(data: DocumentCheckOutStarted): Promise<void> {
  /* eslint-disable */
  console.log(`DocumentCheckOutStarted event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */

  const documentId = data.payload.aggregateId.split('-')[1];
  // status: 'Available' | 'CheckingOut' | 'CheckedOut' | 'CheckingIn' | 'Locked';
  await store.dispatch('documentLibrary/tryUpdateDocumentProperties', {
    documentId: documentId,
    reportGroupId: data.payload.reportGroupId,
    status: 'CheckingOut',
    statusDescription: data.payload.statusDescription,
    actions: {
      canCheckOut: false,
      canCancelCheckout: false,
    },
  });
}

export default handleDocumentCheckOutStarted;

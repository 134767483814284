import { DataLoadFailed } from '../events/data-load-failed';
import store from '@/store';
import { Notification } from '@/store/notification/state';
import { kebabcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';
import { Location } from 'vue-router';
import router from '@/router';

async function handleDataLoadFailed(data: DataLoadFailed): Promise<void> {
  /* eslint-disable */
  console.log(`DataLoadFailed event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const dataLoadId = Number(data.payload.aggregateId.split('-')[1]);

  const notification: Notification = {
    message: buildDataLoadNotificationMessage(dataLoadId, data),
    read: false,
    timestamp: data.timestamp,
    variant: 'danger',
    link: buildDataLoadRawLocation(dataLoadId, data),
  };

  store.commit('notification/append', notification);
}

function buildDataLoadNotificationMessage(id: number, data: DataLoadFailed): string {
  let message = `Data load (${id}) failed `;

  if (data.payload.reportGroup !== null) {
    message += `for report group '${data.payload.reportGroup}' `;
  }

  if (data.payload.dateFormat !== null) {
    message += `and valuation date '${date(data.payload.valuationDate, data.payload.dateFormat!)}'`;
  }

  return message.trim() + '.';
}

function buildDataLoadRawLocation(id: number, data: DataLoadFailed): Location | null {
  if (data === null) {
    return null;
  }

  const reportGroupName = data.payload.reportGroup;

  if (reportGroupName === null) {
    return null;
  }

  if (data.payload.dateFormat === null) {
    return null;
  }

  const d = kebabcase(date(data.payload.valuationDate, data.payload.dateFormat));
  const tab = 'data-loads';
  const reportGroup = kebabcase(reportGroupName);
  const dataLoadId = id.toString();

  return { name: 'data', params: { ...router.currentRoute.params, reportGroup, valuationDate: d, tab, dataLoadId } };
}

export default handleDataLoadFailed;

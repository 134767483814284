import { Api } from '@/api/api';
import { SummaryResponse } from './responses/summary/summary-response';
import { SummaryStatisticsResponse } from './responses/summary/summary-statistics-response';

export class SummaryApi extends Api {
  public constructor() {
    super();
  }

  public getSummaryForReportGroupAsync(reportGroupId: number): Promise<SummaryResponse> {
    return this.getAsync<SummaryResponse>(`v1/statistics/${reportGroupId}`);
  }

  public getStatisticsForValuationDateAsync(valuationDate: string, reportGroupId: number): Promise<SummaryStatisticsResponse> {
    return this.getAsync<SummaryStatisticsResponse>(`v1/statistics/${reportGroupId}/statistics`, { valuationDate });
  }
}

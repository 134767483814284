import { Api } from '@/api/api';
import { TranslationResponse } from './responses/language/translation-response';
import { PhraseResponse } from './responses/language/phrase-response';

export class LanguageMaintenanceApi extends Api {
  public constructor() {
    super();
  }

  public getPhrasesAsync(phrase: string, exact: boolean = false): Promise<Array<PhraseResponse>> {
    return this.getAsync<Array<PhraseResponse>>('v1/language/phrase', { phrase, exact });
  }

  public getTranslationsForPhraseAsync(phrase: string): Promise<Array<TranslationResponse>> {
    return this.getAsync<Array<TranslationResponse>>('v1/language/translation', { phrase });
  }

  public createPhraseAsync(phrase: string, language: string, languageId: number): Promise<void> {
    return this.postAsync('v1/language/phrase', { phrase, language, languageId });
  }

  public replacePhraseAsync(phrase: string, to: string, language: string, languageId: number): Promise<void> {
    return this.putAsync('v1/language/phrase', { phrase, to, language, languageId });
  }

  public removePhraseAsync(phrase: string, language: string, languageId: number): Promise<void> {
    return this.deleteAsync('v1/language/phrase', { phrase, language, languageId });
  }

  public replaceTranslationForPhraseAsync(phrase: string, language: string, languageId: number, translation: string | null): Promise<void> {
    return this.putAsync('v1/language/translation', { phrase, language, languageId, translation });
  }

  public removeTranslationForPhraseAsync(phrase: string, language: string, languageId: number): Promise<void> {
    return this.deleteAsync('v1/language/translation', { phrase, language, languageId });
  }
}

<template>
  <div class="row align-items-center">
    <label class="col-auto mb-0 pr-0">{{ current !== null ? current.entityDescription : null }}:</label>
    <div class="col-auto">
      <b-form-select v-model="selectedEntity">
        <template v-slot:first>
          <b-form-select-option :value="null">Please select...</b-form-select-option>
        </template>
        <b-form-select-option v-for="entity in entities" :key="entity.id" :value="entity">{{ entity.name }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DocumentLibraryEntity, DocumentLibrary } from '@/store/document-library/state';

const documentLibraryModule = namespace('documentLibrary');

@Component
export default class DocumentLibraryFilterBarEntitySelector extends Vue {
  @documentLibraryModule.Getter private current!: DocumentLibrary;
  @documentLibraryModule.Getter private entities!: Array<DocumentLibraryEntity>;
  @documentLibraryModule.Getter private currentEntity!: DocumentLibraryEntity | null;

  private get selectedEntity(): DocumentLibraryEntity | null {
    return this.currentEntity;
  }

  private set selectedEntity(value: DocumentLibraryEntity | null) {
    this.$store.commit('documentLibrary/setCurrentEntity', value);
  }
}
</script>

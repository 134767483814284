import { HubConnection, HubConnectionBuilder, JsonHubProtocol } from '@microsoft/signalr';
import userManager from '../user-manager/user-manager';
import handleWorkflowReportApproved from './event-handlers/workflow-report-approved';
import handleWorkflowReportArchivalCompleted from './event-handlers/workflow-report-archival-completed';
import handleWorkflowReportArchivalQueued from './event-handlers/workflow-report-archival-queued';
import handleWorkflowReportArchivalStarted from './event-handlers/workflow-report-archival-started';
import handleWorkflowReportCreated from './event-handlers/workflow-report-created';
import handleWorkflowReportDataCheckCompleted from './event-handlers/workflow-report-data-check-completed';
import handleWorkflowReportDataCheckQueued from './event-handlers/workflow-report-data-check-queued';
import handleWorkflowReportDataCheckStarted from './event-handlers/workflow-report-data-check-started';
import handleWorkflowReportDeleted from './event-handlers/workflow-report-deleted';
import handleWorkflowReportDistributionCompleted from './event-handlers/workflow-report-distribution-completed';
import handleWorkflowReportDistributionStarted from './event-handlers/workflow-report-distribution-started';
import handleWorkflowReportReapprovalRequested from './event-handlers/workflow-report-reapproval-requested';
import handleWorkflowReportRejected from './event-handlers/workflow-report-rejected';
import handleWorkflowReportRenderCompleted from './event-handlers/workflow-report-render-completed';
import handleWorkflowReportRenderQueued from './event-handlers/workflow-report-render-queued';
import handleWorkflowReportRenderStarted from './event-handlers/workflow-report-render-started';
import handleWorkflowReportReplaced from './event-handlers/workflow-report-replaced';
import handleWorkflowReportAvailableActionsReplaced from './event-handlers/workflow-report-available-actions-replaced';
import handleWorkflowReportCommentaryReacquired from './event-handlers/workflow-report-commentary-reacquired';
import handleDocumentCheckInStarted from './event-handlers/document-checkin-started';
import handleDocumentCheckInCompleted from './event-handlers/document-checkin-completed';
import handleDocumentCheckOutStarted from './event-handlers/document-checkout-started';
import handleDocumentCheckOutCompleted from './event-handlers/document-checkout-completed';
import handleDocumentCheckOutCancellationRequested from './event-handlers/document-checkout-cancellation-requested';
import handleDocumentCheckOutCancelled from './event-handlers/document-checkout-cancelled';
import handleDataCheckBatchDequeued from './event-handlers/data-check-batch-dequeued';
import handleDataCheckBatchEnqueued from './event-handlers/data-check-batch-enqueued';
import handleDataLoadForReportGroupValuationDateRequested from './event-handlers/data-load-for-report-group-valuation-date-requested';
import handleDataLoadForReportGroupValuationDateStarted from './event-handlers/data-load-for-report-group-valuation-date-started';
import handleDataLoadForReportGroupValuationDateSucceeded from './event-handlers/data-load-for-report-group-valuation-date-succeeded';
import handleDataLoadForReportGroupValuationDateFailed from './event-handlers/data-load-for-report-group-valuation-date-failed';
import handleDataLoadForReportGroupValuationDateOnHold from './event-handlers/data-load-for-report-group-valuation-date-on-hold';
import handleDataLoadForReportGroupValuationDateWaitingForOtherFiles from './event-handlers/data-load-for-report-group-valuation-date-waiting-for-other-files';
import handleDataLoadSucceeded from './event-handlers/data-load-succeeded';
import handleDataLoadFailed from './event-handlers/data-load-failed';
import handleDataLoadOnHold from './event-handlers/data-load-on-hold';
import handleDataLoadWaitingForOtherFiles from './event-handlers/data-load-waiting-for-other-files';
import handleSupportTicketOpenedOnBehalfOf from './event-handlers/support-ticket-opened-on-behalf-of';
import handleSupportTicketOpened from './event-handlers/support-ticket-opened';
import store from '@/store';
import handleRunEntityScheduleRequested from './event-handlers/run-entity-schedule-requested';
import handleWorkflowReportBatchDistributionRequested from './event-handlers/workflow-report-batch-distribution-requested';
import handleWorkflowReportBatchImmediateDistributionRequested from './event-handlers/workflow-report-batch-immediate-distribution-requested';
import * as Sentry from '@sentry/browser';
import handleWorkflowReportFlagged from './event-handlers/workflow-report-flagged';
import handleWorkflowReportUnflagged from './event-handlers/workflow-report-unflagged';
import handleSupportTicketAttachmentsUpdated from './event-handlers/support-ticket-attachments-updated';

class WebsocketManager {
  private socket: HubConnection | null = null;

  public async onTenantSwitched(from: { id: number, name: string }, to: { id: number, name: string }): Promise<void> {
    if (this.socket === null) {
      return;
    }

    await this.socket.send('OnTenantSwitched', from.id, to.id);
  }

  public async startAsync(): Promise<void> {
    const host = window.location.host;

    let baseUrl = host.includes('.azurewebsites.net')
      ? `https://${host.replace('webapp', 'api')}`
      : host.includes('localhost')
        ? `${process.env.VUE_APP_API_URL}`
        : `https://api.${host}`;

    if (!baseUrl.endsWith('/')) {
      baseUrl += '/';
    }

    this.socket = new HubConnectionBuilder()
      .withUrl(`${baseUrl}v1/notification`, {
        accessTokenFactory: async (): Promise<string> => {
          const user = await userManager.getUser();

          if (user == null) {
            return '';
          }

          return user.access_token;
        },
      })
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect({ nextRetryDelayInMilliseconds: (context) => 5000 })
      .build();

    this.socket!.onclose((e) => {
      store.commit('environment/setWebsocketConnected', false);
    });
    this.socket!.onreconnecting((e) => {
      store.commit('environment/setWebsocketConnected', false);
    });

    this.socket.onreconnected((e) => {
      store.commit('environment/setWebsocketConnected', true);
    });

    await this.setupSocketEventHandlers();
    await this.startSocketAsync();
  }

  public async stopAsync(): Promise<void> {
    if (this.socket === null) {
      return;
    }

    await this.socket.stop();
    store.commit('environment/setWebsocketConnected', false);
  }

  private async startSocketAsync(): Promise<void> {
    try {
      const user = await userManager.getUser();

      if (user !== null) {
        await this.socket!.start();
        store.commit('environment/setWebsocketConnected', true);
        return;
      }

      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000));
      await this.startSocketAsync();
      store.commit('environment/setWebsocketConnected', true);
    } catch (e) {
      await this.handleSocketCloseOrError(e);
    }
  }

  private async handleSocketCloseOrError(error: Error | undefined | any): Promise<void> {
    Sentry.captureException(error);
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000));
    await this.startAsync();
  }

  private async setupSocketEventHandlers(): Promise<void> {
    const hub = this.socket!;

    hub.on('WorkflowReportApproved', handleWorkflowReportApproved);
    hub.on('WorkflowReportArchivalCompleted', handleWorkflowReportArchivalCompleted);
    hub.on('WorkflowReportArchivalQueued', handleWorkflowReportArchivalQueued);
    hub.on('WorkflowReportArchivalStarted', handleWorkflowReportArchivalStarted);
    hub.on('WorkflowReportCreated', handleWorkflowReportCreated);
    hub.on('WorkflowReportDataCheckCompleted', handleWorkflowReportDataCheckCompleted);
    hub.on('WorkflowReportDataCheckQueued', handleWorkflowReportDataCheckQueued);
    hub.on('WorkflowReportDataCheckStarted', handleWorkflowReportDataCheckStarted);
    hub.on('WorkflowReportDeleted', handleWorkflowReportDeleted);
    hub.on('WorkflowReportDistributionCompleted', handleWorkflowReportDistributionCompleted);
    hub.on('WorkflowReportDistributionStarted', handleWorkflowReportDistributionStarted);
    hub.on('WorkflowReportReapprovalRequested', handleWorkflowReportReapprovalRequested);
    hub.on('WorkflowReportRejected', handleWorkflowReportRejected);
    hub.on('WorkflowReportRenderCompleted', handleWorkflowReportRenderCompleted);
    hub.on('WorkflowReportRenderQueued', handleWorkflowReportRenderQueued);
    hub.on('WorkflowReportRenderStarted', handleWorkflowReportRenderStarted);
    hub.on('WorkflowReportReplaced', handleWorkflowReportReplaced);
    hub.on('WorkflowReportAvailableActionsReplaced', handleWorkflowReportAvailableActionsReplaced);
    hub.on('WorkflowReportCommentaryReacquired', handleWorkflowReportCommentaryReacquired);
    hub.on('DocumentCheckInStarted', handleDocumentCheckInStarted);
    hub.on('DocumentCheckInCompleted', handleDocumentCheckInCompleted);
    hub.on('DocumentCheckOutStarted', handleDocumentCheckOutStarted);
    hub.on('DocumentCheckOutCompleted', handleDocumentCheckOutCompleted);
    hub.on('DocumentCheckOutCompleted', handleDocumentCheckOutCompleted);
    hub.on('DocumentCheckOutCancellationRequested', handleDocumentCheckOutCancellationRequested);
    hub.on('DocumentCheckOutCancelled', handleDocumentCheckOutCancelled);
    hub.on('DataCheckBatchDequeued', handleDataCheckBatchDequeued);
    hub.on('DataCheckBatchEnqueued', handleDataCheckBatchEnqueued);
    hub.on('DataLoadForReportGroupValuationDateRequested', handleDataLoadForReportGroupValuationDateRequested);
    hub.on('DataLoadForReportGroupValuationDateStarted', handleDataLoadForReportGroupValuationDateStarted);
    hub.on('DataLoadForReportGroupValuationDateSucceeded', handleDataLoadForReportGroupValuationDateSucceeded);
    hub.on('DataLoadForReportGroupValuationDateFailed', handleDataLoadForReportGroupValuationDateFailed);
    hub.on('DataLoadForReportGroupValuationDateOnHold', handleDataLoadForReportGroupValuationDateOnHold);
    hub.on('DataLoadForReportGroupValuationDateWaitingForOtherFiles', handleDataLoadForReportGroupValuationDateWaitingForOtherFiles);
    hub.on('DataLoadSucceeded', handleDataLoadSucceeded);
    hub.on('DataLoadFailed', handleDataLoadFailed);
    hub.on('DataLoadOnHold', handleDataLoadOnHold);
    hub.on('DataLoadWaitingForOtherFiles', handleDataLoadWaitingForOtherFiles);
    hub.on('SupportTicketAttachmentsUpdated', handleSupportTicketAttachmentsUpdated);
    hub.on('SupportTicketOpenedOnBehalfOf', handleSupportTicketOpenedOnBehalfOf);
    hub.on('SupportTicketOpened', handleSupportTicketOpened);
    hub.on('RunEntityScheduleRequested', handleRunEntityScheduleRequested);
    hub.on('WorkflowReportBatchDistributionRequested', handleWorkflowReportBatchDistributionRequested);
    hub.on('WorkflowReportBatchImmediateDistributionRequested', handleWorkflowReportBatchImmediateDistributionRequested);
    hub.on('WorkflowReportFlagged', handleWorkflowReportFlagged);
    hub.on('WorkflowReportUnflagged', handleWorkflowReportUnflagged);
  }
}

export default new WebsocketManager();

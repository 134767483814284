<template>
  <b-modal id="modal-center"
           v-model="visible"
           ok-variant="link"
           ok-title="Cancel"
           cancel-variant="primary"
           cancel-title="Submit"
           centered
           :title="title"
           :no-close-on-backdrop="true"
           @ok="onCancel"
           @cancel="onOk"
           @close="onClose"
           @hide="onClose"
  >
    <b-form-group label="Comments" v-if="!hideComments">
      <b-form-textarea
        id="action-comments"
        v-model="comments"
        rows="3"
        autofocus
      ></b-form-textarea>
    </b-form-group>
    <span v-else>Are you sure?</span>
    <div v-if="actionFlag" class="row">
      <b-form-group label="Colour:" label-cols-sm class="col-auto">
        <b-form-radio-group v-model="selectedFlagColour" id="flag-radios" buttons button-variant="light">
          <b-form-radio
            v-for="colour in flagColours"
            :key="'flag-colour-'+colour.flagNumber"
            :value="colour.flagNumber"
            class="col-auto m-2 border"
          >
            <b-icon-flag-fill :color="'#'+colour.hexColour" style="width: 16px;height: 16px"></b-icon-flag-fill>
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <b-form-group v-if="actionRecheck">
      <b-form-checkbox
        id="action-reset"
        v-model="reset"
      >
        Reset all ignored issues
      </b-form-checkbox>
    </b-form-group>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { WorkflowFlag } from '@/store/workflow/state';

@Component
export default class WorkflowReportActionDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ required: true }) public title!: string | null;
  @Prop({ required: false }) public flagComments!: string | null;
  @Prop({ required: false }) public flagColour!: number;
  @Prop({ required: false, type: Boolean, default: false }) public hideComments!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) public actionRecheck!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) public actionFlag!: boolean;
  @Prop({ required: false }) public flagColours!: Array<WorkflowFlag>;
  private comments: string | null = null;
  private reset: boolean = false;
  private visible: boolean = false;
  private selectedFlagColour: number = 1;

  private onOk(): void {
    this.$emit('ok', { comments: this.comments, reset: this.reset, flagColour: this.selectedFlagColour });
    this.comments = null;
    this.reset = false;
    this.selectedFlagColour = 0;
  }

  private onCancel(): void {
    this.$emit('cancel');
    this.comments = null;
    this.reset = false;
    this.selectedFlagColour = 0;
  }

  private onClose(): void {
    this.$emit('close');
    this.comments = null;
    this.reset = false;
    this.selectedFlagColour = 0;
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
  }

  @Watch('flagComments')
  private onFlagComments(value: boolean): void {
    this.comments = this.flagComments;
  }

  @Watch('flagColour')
  private onFlagColour(value: boolean): void {
    this.selectedFlagColour = this.flagColour;
  }
}
</script>

<template>
  <b-modal id="modal-center"
           v-model="visible"
           ok-variant="primary"
           ok-title="Recheck"
           cancel-variant="link"
           centered
           :title="title"
           :no-close-on-backdrop="true"
           @ok="onOk"
           @cancel="onCancel"
           @close="onClose"
  >
    <div class="row">
      <div class="col">
        <b-form-group
          id="input-group-1"
          label="Comments"
          label-for="input-comments"
        >
          <b-form-textarea
            id="input-comments"
            v-model="comments"
            rows="3"
          >
          </b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-form-group
          id="input-group-2"
        >
          <b-form-checkbox
            id="input-reset"
            v-model="reset"
          >
            Reset all ignored issues for reports in approval
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class DataIssueIgnoreDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ type: String, required: true }) public title!: string;
  private comments: string | null = null;
  private reset: boolean = false;
  private visible: boolean = false;

  private onOk(): void {
    this.$emit('ok', { comments: this.comments, reset: this.reset });
    this.comments = null;
    this.reset = false;
  }

  private onCancel(): void {
    this.$emit('cancel');
    this.comments = null;
    this.reset = false;
  }

  private onClose(): void {
    this.$emit('close');
    this.comments = null;
    this.reset = false;
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
  }
}
</script>

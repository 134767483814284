import { ActionTree, ActionContext } from 'vuex';
import { State } from '@/store/configuration/state';
import { ConfigurationApi } from '@/api/configuration-api';
import { Dictionary } from 'vue-router/types/router';

type ConfigurationState = { tenant: string, reportGroup: string, entity: string, settings: string };

const api = new ConfigurationApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public getConfigurationForReportGroupAsync = async (context: ActionContext<State, any>, payload: { reportGroup: { id: number; name: string }, reportId: number }) => {
    try {
      context.commit('setLoading', true);
      const result = await api.getConfigurationForReportGroupAsync(payload.reportGroup.id, payload.reportId);

      context.commit('setCurrent', result);
      context.commit('setCurrentEntity', result.selectedEntityID);
      context.commit('setLoading', false);
    } catch (e) {
    }
  }

  public getContentControlForEntityAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number }) => {
    const result = await api.getContentControlForEntityAsync(payload.entityId, payload.reportGroupId);

    context.commit('setEntityContentControls', result);
  }

  public enableContentControlForEntityAsync = async (context: ActionContext<State, any>, payload: { code: string, entityId: number, reportGroupId: number }) => {
    await api.enableContentControlForEntityAsync(payload.code, payload.entityId, payload.reportGroupId);
  }

  public disableContentControlForEntityAsync = async (context: ActionContext<State, any>, payload: { code: string, entityId: number, reportGroupId: number }) => {
    await api.disableContentControlForEntityAsync(payload.code, payload.entityId, payload.reportGroupId);
  }

  public getContentControlActivityForEntityAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number }) => {
    try {
      const result = await api.getContentControlActivityForEntityAsync(payload.entityId, payload.reportGroupId);
      context.commit('setEntityContentControlActivity', result);
    } catch (e) {
    }
  }

  public getSettingsForReportGroupAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number }) => {
    const result = await api.getSettingsForReportGroupAsync(payload.reportGroupId);

    context.commit('setReportGroupSettings', result);
  }

  public getSettingsForEntityAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number }) => {
    const result = await api.getSettingsForEntityAsync(payload.entityId, payload.reportGroupId);

    context.commit('setEntitySettings', result);
  }

  public getSettingsActivityForReportGroupAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number }) => {
    try {
      const result = await api.getSettingsActivityForReportGroupAsync(payload.reportGroupId);
      context.commit('setReportGroupSettingsActivity', result);
    } catch (e) {
    }
  }

  public replaceSettingValueForEntityAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number, name: string, value: string | number | null, currentValue: string | number | null }) => {
    await api.replaceSettingValueForEntityAsync(payload.name, payload.value, payload.currentValue, payload.entityId, payload.reportGroupId);
  }

  public getSettingsActivityForEntityAsync = async (context: ActionContext<State, any>, payload: { entityId: number, reportGroupId: number }) => {
    try {
      const result = await api.getSettingsActivityForEntityAsync(payload.entityId, payload.reportGroupId);
      context.commit('setEntitySettingsActivity', result);
    } catch (e) {
    }
  }

  public replaceSettingValueForReportGroupAsync = async (context: ActionContext<State, any>, payload: { reportGroupId: number, name: string, value: string | number | null, currentValue: string | number | null }) => {
    await api.replaceSettingValueForReportGroupAsync(payload.name, payload.value, payload.currentValue, payload.reportGroupId);
  }

  public getMembersForTeamAsync = async (context: ActionContext<State, any>, payload: { teamId: number, reportGroupId: number }) => {
    try {
      const result = await api.getMembersForTeamAsync(payload.teamId, payload.reportGroupId);
      context.commit('setReportGroupTeamMembers', result);
    } catch (e) {
    }
  }

  public assignTeamMemberAsync = async (context: ActionContext<State, any>, payload: { email: string, teamId: number, reportGroupId: number }) => {
    await api.assignTeamMemberAsync(payload.email, payload.teamId, payload.reportGroupId);
  }

  public removeTeamMemberAsync = async (context: ActionContext<State, any>, payload: { email: string, comments: string | null, teamId: number, reportGroupId: number}) => {
    await api.removeTeamMemberAsync(payload.email, payload.comments, payload.teamId, payload.reportGroupId);
  }

  public registerTeamAsync = async (context: ActionContext<State, any>, payload: { name: string, reportGroupId: number }) => {
    await api.registerTeamAsync(payload.name, payload.reportGroupId);
  }

  public getTeamActivityForReportGroupAsync = async (context: ActionContext<State, any>, payload: { teamId: number, reportGroupId: number }) => {
    try {
      const result = await api.getTeamActivityForReportGroupAsync(payload.teamId, payload.reportGroupId);
      context.commit('setTeamActivity', result);
    } catch (e) {
    }
  }

  public applyStateFromRoute = async (context: ActionContext<State, any>, data: ConfigurationState): Promise<Dictionary<string>> => {
    /* eslint-disable */
    console.log('configuration/applyStateFromRoute', data);
    /* eslint-enable */
    return {};
  }

  public renameTeamAsync = async (context: ActionContext<State, any>, payload: { teamId: number, teamName: string, reportGroupId: number }) => {
    await api.renameTeamAsync(payload.teamId, payload.teamName, payload.reportGroupId);
  }

  public deleteTeamAsync = async (context: ActionContext<State, any>, payload: { teamId: number, reportGroupId: number }) => {
    const result = await api.deleteTeamAsync(payload.teamId, payload.reportGroupId);
    return result;
  }
}

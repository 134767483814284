// TODO(Dan): We should refactor this to just parse the input into a expression tree and evaluate using the correct precedence rules.
export interface FilterCollection {
  match: string;
  and: Array<string>;
  or: Array<string>;
}

export const parseFilters = (input: string): FilterCollection => {
  const splitFilterText = input.match(/^.+?(?=( AND | OR |$))| AND .+?(?=( AND | OR |$))| OR .+?(?=( AND | OR |$))/g);
  const orValues: string[] = [];
  const andValues: string[] = [];
  let firstValue = '';

  splitFilterText?.forEach(t => {
    if (t.startsWith(' OR ')) {
      orValues.push(t.replace(' OR ', '').trim());
    } else if (t.startsWith(' AND ')) {
      andValues.push(t.replace(' AND ', '').trim());
    } else {
      firstValue = t.trim();
    }
  });

  return { match: firstValue, and: andValues, or: orValues };
};

/**
 * @remarks
 * This function calls 'toString()' on the selected property and does a case-insensitive match using the mode supplied by the options parameter.
 *
 * @param item - The object the filters are applied to
 * @param selector - A callback to select the required property or properties of the object
 * @param options - Used to indicate if we should apply the filters using 'startsWith' or 'include'. Defaults to 'startsWith'.
 * @returns Returns a boolean to indicate that the item and its selected property match the filters supplied.
 */
export const applyFilters = <T>(item: T, selector: (obj: T) => any, filters: FilterCollection, options: { mode: 'startsWith' | 'include' } = { mode: 'startsWith' }): boolean => {
  let match: boolean = false;

  if (options.mode === 'include') {
    match = selector(item).toString().toLowerCase().includes(filters.match.toLowerCase());
  } else {
    match = selector(item).toString().toLowerCase().startsWith(filters.match.toLowerCase());
  }

  if (filters.or.length === 0 && filters.and.length > 0) {
    if (options.mode === 'include') {
      match = match && filters.and.every(filter => selector(item).toString().toLowerCase().includes(filter.toLowerCase()));
    } else {
      match = match && filters.and.every(filter => selector(item).toString().toLowerCase().startsWith(filter.toLowerCase()));
    }
  }

  if (filters.or.length > 0) {
    if (options.mode === 'include') {
      match = match || filters.or.some(filter => selector(item).toString().toLowerCase().includes(filter.toLowerCase()));
    } else {
      match = match || filters.or.some(filter => selector(item).toString().toLowerCase().startsWith(filter.toLowerCase()));
    }
  }

  return match;
};

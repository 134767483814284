import { Api } from '@/api/api';
import { UserResponse } from '@/api/responses/user/user-response';

export class UserApi extends Api {
  public constructor() {
    super();
  }

  public getMeAsync(): Promise<UserResponse> {
    return this.getAsync<UserResponse>('v1/user/me');
  }

  public updateActiveTenantAsync(userId: number, tenant: { id: number, name: string, logo: string }): Promise<void> {
    return this.putAsync(`v1/user/${userId}/tenant`, tenant);
  }

  public updateActiveReportGroupAsync(userId: number, request: { tenantId: number, tenantName: string, id: number, name: string }): Promise<void> {
    return this.putAsync(`v1/user/${userId}/report-group`, request);
  }

  public updateActiveReportGroupValuationDateAsync(userId: number, request: { tenantId: number, tenantName: string, id: number, name: string, valuationDate: string }): Promise<void> {
    return this.putAsync(`v1/user/${userId}/report-group/${request.id}`, request);
  }

  public updateActiveDocumentLibraryReportGroupAsync(userId: number, request: { tenantId: number, tenantName: string, id: number, name: string }): Promise<void> {
    return this.putAsync(`v1/user/${userId}/report-group/document-library`, request);
  }

  public updateActiveConfigurationReportGroupAsync(userId: number, request: { tenantId: number, tenantName: string, id: number, name: string }): Promise<void> {
    return this.putAsync(`v1/user/${userId}/report-group/configuration`, request);
  }
}

import { WorkflowReportBatchImmediateDistributionRequested } from '../events/workflow-report-batch-immediate-distribution-requested';
import { BToast } from 'bootstrap-vue';

async function handleWorkflowReportBatchImmediateDistributionRequested(data: WorkflowReportBatchImmediateDistributionRequested): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportBatchImmediateDistributionRequested event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */

  const message = data.payload.message;

  const bootStrapToaster = new BToast();
  bootStrapToaster.$bvToast.toast(message,
    {
      // title: `Error`,
      autoHideDelay: 3000,
      solid: true,
      // noAutoHide: true,
      noCloseButton: true,
      variant: 'notice-success',
      toaster: 'b-toaster-top-center',
      toastClass: 'white-space-wrap',
      appendToast: true,
    });
}

export default handleWorkflowReportBatchImmediateDistributionRequested;

<template>
  <div class="dataload-status text-center" title="Running">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g transform="rotate(0 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.7638888888888887s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.6944444444444443s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.6249999999999999s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.5555555555555555s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.48611111111111105s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.41666666666666663s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.34722222222222215s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.27777777777777773s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.20833333333333331s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.13888888888888887s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="-0.06944444444444443s" repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect x="45" y="1.5" rx="2.31" ry="2.31" width="10" height="33" fill="#2873b7">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8333333333333333s" begin="0s" repeatCount="indefinite" />
        </rect>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DataLoadRunning extends Vue {
}
</script>

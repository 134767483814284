<template>
  <div class="document-status text-center" title="Checking out">
    <b-iconstack font-scale="1">
      <b-icon stacked icon="envelope-fill" variant="primary" />
    </b-iconstack>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 100 100" y="0" x="0" version="1.1" width="16px" height="16px">
      <g class="ldl-scale" style="transform-origin: 50% 50%; transform: rotate(0deg) scale(-1, 1);">
        <g class="ldl-ani" style="transform-origin: 50px 50px; transform: translate(0px, 0px) rotate(0deg) scale(1, 1) skewX(0deg) skewY(0deg); opacity: 1; animation: 1.42857s linear 0s infinite reverse forwards running slide-ltr-e8829d23-d158-421b-96d1-e3463c8ade55;">
          <g class="ldl-layer">
            <g class="ldl-ani" style="transform-origin: 80.3px 50.3px;">
              <g>
                <g class="ldl-layer">
                  <g class="ldl-ani" style="transform-origin: 77.65px 59.95px;">
                    <path d="M68.1 58h19.1v3.9H68.1z" fill="#a8b980" style="fill: rgb(29, 113, 184);" />
                  </g>
                </g>
                <g class="ldl-layer">
                  <g class="ldl-ani" style="transform-origin: 80.3px 40.65px;">
                    <path d="M68.1 38.7h24.4v3.9H68.1z" fill="#a8b980" style="fill: rgb(29, 113, 184);" />
                  </g>
                </g>
                <g class="ldl-layer">
                  <g class="ldl-ani" style="transform-origin: 79.1px 50.25px;">
                    <path d="M68.1 48.3h22v3.9h-22z" fill="#a8b980" style="fill: rgb(29, 113, 184);" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g class="ldl-layer">
            <g class="ldl-ani" style="transform-origin: 35.05px 55.95px;">
              <path fill="#f4e6c8" d="M13 66.8h44.1c.1 0 .2-.1.2-.2V45.1L37.8 57.3c-1.7 1.1-3.8 1.1-5.6 0L12.8 45.1v21.4c0 .2.1.3.2.3z" style="fill: rgb(36, 47, 98);" />
            </g>
          </g>
          <g class="ldl-layer">
            <g class="ldl-ani" style="transform-origin: 35.1px 42.7625px;">
              <path fill="#f7b26a" d="M15.4 40.6l17.9 11.1c1.1.7 2.4.7 3.5 0l17.9-11.1c1.7-1 2.7-2.8 2.7-4.8v-2.3c0-.1-.1-.2-.2-.2H13c-.1 0-.2.1-.2.2v2.3c0 2 1 3.8 2.6 4.8z" style="fill: rgb(29, 113, 184);" />
            </g>
          </g>
          <g class="ldl-layer">
            <g class="ldl-ani" style="transform-origin: 35.1px 50px;">
              <path fill="#333" d="M12 72h46.2c2.5 0 4.5-2 4.5-4.5V32.3c0-2.4-1.9-4.3-4.3-4.3H11.8c-2.4 0-4.3 1.9-4.3 4.3v35.3c0 2.4 2 4.4 4.5 4.4zm.8-38.5c0-.1.1-.2.2-.2h44.1c.1 0 .2.1.2.2v2.3c0 2-1 3.8-2.7 4.8L36.8 51.7c-1.1.7-2.4.7-3.5 0L15.4 40.6c-1.7-1-2.7-2.8-2.7-4.8v-2.3zm0 11.6l19.5 12.1c1.7 1.1 3.8 1.1 5.6 0l19.5-12.1v21.4c0 .1-.1.2-.2.2H13c-.1 0-.2-.1-.2-.2V45.1z" style="fill: rgb(148, 169, 206);" />
            </g>
          </g>
        </g>
      </g>
    </svg> -->
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CheckingOutIcon extends Vue {
}
</script>

<template>
  <div class="help-filter-bar row">
    <div class="col-auto">
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Tenant } from '@/store/tenant/state';

const tenantModule = namespace('tenant');

@Component
export default class HelpFilterBar extends Vue {
  @tenantModule.Getter('current') private currentTenant!: Tenant;

  public async mounted(): Promise<void> {
    // TODO(Dan): Load videos from the API
  }
}
</script>

<template>
  <div class="data-content">
    <b-tabs class="data-tabs" v-model="currentTabIndex" @changed="onTabsChanged" @activate-tab="onTabActivated" :aria-busy="isLoading">
      <b-tab v-for="option in options" :key="option.id" :title-link-attributes="{ href: buildTabUrl(option) }" :loading="isLoading" :title="getTabTitle(option)">
        <data-issues-table v-if="option.id === 1" :loading="isLoading" @scroll-completed="onDataIssueTableScrollCompleted" />
        <translation-issues-table v-if="option.id === 2" :loading="isLoading" @scroll-completed="onTranslationIssueTableScrollCompleted" />
        <data-files-table v-if="option.id === 3" :loading="isLoading" @scroll-completed="onDataFilesTableScrollCompleted" />
        <data-loads-table v-if="option.id === 4" :loading="isLoading" @scroll-completed="onDataLoadsTableScrollCompleted" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Data, DataDate, DataIssue, DataOption, State } from '../../store/data/state';
import { kebabcase, camelcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';
import { Tenant, ReportGroup } from '../../store/tenant/state';
import DataIssuesTable from './data-issue-table.vue';
import TranslationIssuesTable from './translation-issue-table.vue';
import DataFilesTable from './data-files-table.vue';
import DataLoadsTable from './data-loads-table.vue';
import { Route } from 'vue-router';
import { BTab } from 'bootstrap-vue';

const dataModule = namespace('data');
const tenantModule = namespace('tenant');
const DEFAULT_PAGE_SIZE = 250;

@Component({
  components: {
    DataIssuesTable,
    TranslationIssuesTable,
    DataFilesTable,
    DataLoadsTable,
  },
})
export default class DataContent extends Vue {
  @dataModule.Getter private current!: Data;
  @dataModule.Getter private valuationDates!: Array<DataDate>;
  @dataModule.Getter private currentValuationDate!: DataDate | null;
  @dataModule.Getter private options!: Array<DataOption>;
  @dataModule.Getter private isLoading!: boolean;
  @dataModule.Getter private dataIssues!: Array<DataIssue>
  @tenantModule.Getter('current') private currentTenant!: Tenant | null;
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;

  private currentTabIndex: number = -1;

  private get currentOption(): DataOption | null {
    return this.options[this.currentTabIndex] || null;
  }

  private format(displayName: string): string {
    const count = this.getTabCount(displayName);

    if (count !== undefined) {
      return ` (${count})`;
    }

    return '';
  }

  private getTabCount(tabName: string): number | undefined {
    const date = this.currentValuationDate;

    if (date === null || date === undefined) {
      return undefined;
    }

    const name = camelcase(tabName);
    let count: number | undefined;
    if (name === 'dataIssues') {
      count = this.dataIssues.length;
    } else {
      count = date.options[name] as number | undefined;
    }

    return count;
  }

  private buildTabUrl(option: DataOption): string {
    if (this.currentReportGroup === null || this.currentValuationDate === null) {
      return '';
    }

    return `/${kebabcase(this.currentTenant!.name)}/data/${kebabcase(this.currentReportGroup!.name)}/${kebabcase(date(this.currentValuationDate!.displayDate, this.current!.dateFormat))}/${kebabcase(option.name)}`;
  }

  private async onTabActivated(newIndex: number, oldIndex: number): Promise<void> {
    const match = this.options[newIndex];

    if (match === undefined) {
      return;
    }

    if (this.$route.params.tab === undefined || this.$route.params.tab !== kebabcase(match.name)) {
      try {
        await this.$router.push({ name: 'data', params: { ...this.$route.params, tab: kebabcase(match.name) } });
      } catch (e) {
      }
    }
  }

  private async onTabsChanged(newTabs: Array<BTab>, oldTabs: Array<BTab>): Promise<void> {
    if (this.$route.params.tab !== undefined) {
      const index = this.options.findIndex((o) => kebabcase(o.name) === this.$route.params.tab);

      this.currentTabIndex = index;
    }
  }

  @Watch('$route')
  private async onRouteChanged(to: Route, from: Route): Promise<void> {
    const toTab = to.params.tab;
    const fromTab = from?.params.tab;

    if (toTab !== fromTab) {
      const index = this.options.findIndex((o) => kebabcase(o.name) === toTab);

      if (index < 0) {
        return;
      }
      this.currentTabIndex = index;
    }
  }

  public async mounted(): Promise<void> {
    const { tab } = this.$route.params;
    const index = this.options.findIndex((o) => kebabcase(o.name) === tab);

    this.currentTabIndex = index;
  }

  private async onDataIssueTableScrollCompleted(): Promise<void> {
    const state = this.$store.state.data as State;
    const currentPage = state.dataIssuePages[state.dataIssuePages.length - 1];

    if (currentPage === null || currentPage === undefined) {
      return;
    }

    if (currentPage.pageSize > currentPage.issues.length) {
      return;
    }

    await this.$store.dispatch('data/getDataIssuesAsync', { reportGroupId: this.currentReportGroup!.id, valuationDate: this.currentValuationDate!.valuationDate, page: currentPage.page + 1, pageSize: DEFAULT_PAGE_SIZE });
  }

  private async onTranslationIssueTableScrollCompleted(): Promise<void> {
    const state = this.$store.state.data as State;
    const currentPage = state.translationIssuePages[state.translationIssuePages.length - 1];

    if (currentPage === null || currentPage === undefined) {
      return;
    }

    if (currentPage.pageSize > currentPage.issues.length) {
      return;
    }

    await this.$store.dispatch('data/getTranslationIssuesAsync', { reportGroupId: this.currentReportGroup!.id, valuationDate: this.currentValuationDate!.valuationDate, page: currentPage.page + 1, pageSize: DEFAULT_PAGE_SIZE });
  }

  private async onDataFilesTableScrollCompleted(): Promise<void> {
    // NOTE(Dan): No-op - currently we return the entire list without paging.
  }

  private async onDataLoadsTableScrollCompleted(): Promise<void> {
    const state = this.$store.state.data as State;
    const currentPage = state.dataLoadsPages[state.dataLoadsPages.length - 1];

    if (currentPage === null || currentPage === undefined) {
      return;
    }

    if (currentPage.pageSize > currentPage.dataLoads.length) {
      return;
    }

    await this.$store.dispatch('data/getDataLoadsAsync', { reportGroupId: this.currentReportGroup!.id, valuationDate: this.currentValuationDate!.valuationDate, page: currentPage.page + 1, pageSize: DEFAULT_PAGE_SIZE });
  }

  private getTabTitle(tab: DataOption): string {
    return `${tab.name}${this.format(tab.name)}`;
  }
}
</script>

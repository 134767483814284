<template>
  <div class="forbidden d-flex align-items-center justify-content-center mt-5">
    <div class="col-1 text-center">
      <p>
        <svg style="width: 70px; height: 70px; color: #222F62" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg>
        <br />
        <small>Status Code: 403</small>
      </p>
    </div>
    <div class="col-4">
      <h3>Access denied</h3>
      <p>You do not have permission to access the requested feature.</p>
      <p><router-link class="btn btn-primary" :to="{ name: 'home' }">Back to home</router-link></p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
}
</script>

import { SupportTicketAttachmentsUpdated } from '../events/support-ticket-attachments-updated';
import store from '@/store';
import { SupportTicketDetails } from '@/store/support/state';

async function handleSupportTicketAttachmentsUpdated(data: SupportTicketAttachmentsUpdated): Promise<void> {
  /* eslint-disable-next-line */
  console.log(`SupportTicketAttachmentsUpdated event received (${data.payload.aggregateId}): `, JSON.stringify(data));

  if (store.getters['support/current'] === null) {
    return;
  }

  const currentTicket = store.getters['support/ticket'] as SupportTicketDetails | null;

  if (currentTicket === null) {
    return;
  }

  if (currentTicket.id !== data.payload.ticketId) {
    return;
  }

  await store.dispatch('support/getSupportTicketAttachmentsAsync', data.payload.ticketId);
  await store.dispatch('support/getSupportTicketActivityAsync', data.payload.ticketId);
}

export default handleSupportTicketAttachmentsUpdated;

<template>
  <div class="schedule-table" :aria-busy="loading">
    <b-table striped
             hover
             selectable
             primary-key="name"
             :tbody-tr-class="applyTableRowClasses"
             :sticky-header="headerStyle"
             :fields="fields"
             :items="subEntities"
             :busy="loading"
             select-mode="single"
             @row-clicked="onRowClicked"
             v-sortable="dragAndDropOptions"
             :no-sort-reset="true"
             :no-local-sorting="true"
             :no-local-filtering="true"
             :no-local-paging="true"
    >
      <template v-slot:head(name)>
        {{ current.entitySubType }}
      </template>

      <template v-slot:head(schedule)>
        <div class="d-inline-block" :title="generateRunEntityTooltip()">
          <b-icon class="mt-1" v-if="current.canRunReports" icon="play-fill" aria-hidden="true" scale="1" style="padding-left: 1.9rem; cursor: pointer;" @click="onRunEntityClicked" />
        </div>
      </template>

      <template v-slot:[`head(${field.key})`]="{ item }" v-for="field in fields.filter((f) => f.key.startsWith('regions.'))">
        {{ field.label }}
        <div :key="`${field.key}`" class="d-inline-block" :title="generateRunRegionTooltip(field)">
          <b-icon style="cursor: pointer;" @click.prevent="onRunRegionClicked(regions.find((r) => r.name === field.label))" v-if="current.canRunReports && canShowRegionRunOption(regions.find((r) => r.name === field.label))" icon="play-fill" aria-hidden="true" scale="1.25" />
        </div>
      </template>

      <template v-slot:cell(schedule)="{ item, field }">
        <div class="row no-gutters align-items-center">
          <div class="col">
            <b-icon icon="three-dots-vertical" aria-hidden="true" class="mt-1 drag-handle" :class="{ 'mr-1': item.active === null && item.subActive === null }" v-if="current.canScheduleReports && current.canReorderReports && item.active === null" scale="1.1" variant="secondary" />
            <div class="d-inline-block" :title="generateSubEntitySwitchTooltip(item, field)" style="vertical-align: top;" v-if="item.active !== null">
              <b-checkbox v-model="item.active" style="margin-right: 0;" :switch="true" inline :disabled="!current.canScheduleReports" v-if="item.active !== null" @change="onActiveChanged(item)" />
            </div>
            <div class="d-inline-block" :title="generateSubEntitySwitchTooltip(item, field)" style="vertical-align: top;" v-if="item.active === null && item.subActive !== null">
              <b-checkbox v-model="item.subActive" :switch="true" inline :disabled="disableSubEntitySwitch()" v-if="item.active === null && item.subActive !== null" class="sub-active" style="margin-left: 1.5rem; margin-right: 0;" @change="onSubActiveChanged(item)" />
            </div>
            <div class="d-inline-block" :title="generateRunSubEntityTooltip(item, field)" style="vertical-align: top;">
              <b-icon icon="play-fill" aria-hidden="true" class="mt-1" v-if="current.canScheduleReports && item.active !== null && current.canRunReports" scale="1.25" @click="onRunSubEntityClicked(item)" />
            </div>
            <div class="d-inline-block" :title="primaryTooltip" style="vertical-align: top;">
              <b-icon icon="star" aria-hidden="true" class="mt-1 make-primary" v-if="current.canScheduleReports && item.active === null" scale="1" variant="primary" @click.stop="onMakePrimaryClicked(item)" />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:cell(name)="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`cell(${field.key})`]="{ item }" v-for="field in fields.filter((f) => f.key.startsWith('regions.'))">
        <div class="row no-gutters align-items-center" :key="`${field.key}`">
          <div class="col">
            <div class="d-inline-block" :title="generateRegionSwitchTooltip(item, field)" style="vertical-align: top;">
              <b-checkbox :key="`${field.key}_schedule`" style="margin-right: 0;" :switch="true" inline :disabled="!current.canScheduleReports" v-if="item.regions[field.key.replace('regions.', '')] !== null" v-model="item.regions[field.key.replace('regions.', '')]" @change="onRegionActiveChanged(item, field.key.replace('regions.', ''))" />
            </div>
            <div class="d-inline-block" :title="generateRunRegionForSubEntityTooltip(item, field)" style="vertical-align: top;">
              <b-icon :key="`${field.key}_run`" class="mt-1" v-if="current.canRunReports && item.regions[field.key.replace('regions.', '')] !== null" icon="play-fill" aria-hidden="true" scale="1.25" @click="onRunSubEntityForRegionClicked(item, regions.find((r) => r.name === field.label))" />
            </div>
          </div>
        </div>
      </template>
    </b-table>

    <run-schedule-dialog :show="showRunDialog" v-model="runDialogScheduleEntries" :region="runDialogRegion" :selection-mode="runDialogSelectionMode" @region-changed="onRunDialogRegionChanged" @ok="onRunDialogSubmitted" @close="onRunDialogClose" @cancel="onRunDialogClose" />
    <run-schedule-dialog :show="showRunEntityScheduleDialog" v-model="runDialogScheduleEntries" :selection-mode="'All'" :allow-region-selection="false" @ok="onRunEntityDialogSubmitted" @close="onRunDialogClose" @cancel="onRunDialogClose" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { EntitySchedule, EntityScheduleRegion, EntityScheduleEntry } from '@/store/schedule/state';
import { BvTableFieldArray, BvTableField, BLink } from 'bootstrap-vue';
import { ReportGroup, Tenant } from '@/store/tenant/state';
import RunScheduleDialog from './run-schedule-dialog.vue';
import { sortable } from '@/directives/sortable';
import { SortableEvent, MoveEvent } from 'sortablejs';
import { kebabcase, lowercase, camelcase } from '@/utilities/text.utils';
import { VNode } from 'vue';
import { date } from '../../filters/date';
import { FindWorkflowResponse } from '../../api/responses/workflow/find-workflow-response';
import { BadRequest } from '../../api/responses/bad-request';
import { Location } from 'vue-router';
import { Configuration, ConfigurationEntity } from '../../store/configuration/state';
import { EntityScheduleRunResponse } from '../../api/responses/schedule/entity-schedule-run-response';

const configurationModule = namespace('configuration');
const scheduleModule = namespace('schedule');
const environmentModule = namespace('environment');
const tenantModule = namespace('tenant');

@Component({
  directives: {
    sortable,
  },
  components: {
    RunScheduleDialog,
  },
})
export default class ScheduleTable extends Mixins(BvToastMixin) {
  @configurationModule.Getter public current!: Configuration | null;
  @configurationModule.Getter public currentEntity!: ConfigurationEntity | null;
  @scheduleModule.Getter public entitySchedule!: EntitySchedule | null;
  @scheduleModule.Getter public regions!: Array<EntityScheduleRegion>;
  @scheduleModule.Getter('isLoading') public loading!: boolean;
  @configurationModule.Getter('currentEntry') public selectedEntry!: EntityScheduleEntry | null;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;
  @tenantModule.Getter('current') private currentTenant!: Tenant;

  private showRunDialog: boolean = false;
  private runDialogScheduleEntries: Array<EntityScheduleEntry> = [];
  private runDialogRegion: EntityScheduleRegion | null = null;
  private selectedRowIndex: number = -1;
  private showRunEntityScheduleDialog: boolean = false;
  private runDialogSelectionMode: 'SingleRegion' | 'SingleEntity' | 'Single' | 'All' = 'All';

  public get fields(): BvTableFieldArray {
    if (this.entitySchedule === null) {
      return [];
    }

    const fields: BvTableFieldArray = [
      { key: 'name', label: this.currentEntity?.name || '', stickyColumn: true, class: ['col-auto']/*, thAttr: { style: 'min-width: 200px;' } */ }
    ];

    fields.unshift({ key: 'schedule', label: '', stickyColumn: true, class: ['col-auto']/*, thAttr: { style: 'min-width: 200px;' } */ });

    this.regions.map((region) => {
      fields.push({ key: `regions.${camelcase(region.name)}`, label: region.name, class: ['col-auto', 'text-center'], thAttr: { style: 'min-width: 100px;' } });
    });

    if (this.regions.length < 5) {
      fields.push({ key: 'spacer', label: '', class: ['w-100', 'text-center'] });
    }

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  public get subEntities(): Array<EntityScheduleEntry> {
    if (this.entitySchedule === null) {
      return [];
    }

    return this.entitySchedule.entities;
  }

  public get dragAndDropOptions(): Object {
    return {
      chosenClass: 'is-dragging',
      filter: '.non-dragable',
      handle: '.drag-handle',
      onChange: (e: SortableEvent) => {
        const dest = this.subEntities.find((entity) => entity.order === e.newIndex! + 1);
        if (dest?.active !== null) {
          return false;
        }
      },
      onMove: (e: MoveEvent) => {
        if (e.related.classList.contains('non-dragable')) {
          return false;
        }

        return e.willInsertAfter ? 1 : -1;
      },
      onEnd: async (e: SortableEvent) => {
        const source = this.subEntities.find((entity) => entity.order === e.oldIndex! + 1)!;
        const currentOrder = source.order;
        const newOrder = e.newIndex! + 1;

        const reorder = (target: EntityScheduleEntry, from: number, to: number) => {
          const direction = to > from ? 'down' : 'up';

          for (let i = 0; i < this.subEntities.length; i++) {
            const entity = this.subEntities[i];

            if (direction === 'up') {
              if (entity.order >= to && entity.order < from) {
                entity.order += 1;
              }
            } else if (direction === 'down') {
              if (entity.order > from && entity.order <= to) {
                entity.order -= 1;
              }
            }
          }

          target.order = to;

          this.subEntities.splice(to - 1, 0, this.subEntities.splice(from - 1, 1)[0]);
        };

        reorder(source, currentOrder, newOrder);

        try {
          await this.$store.dispatch('schedule/reorderScheduledEntity', { from: currentOrder, to: newOrder, subEntityId: source.id, entityId: this.currentEntity!.id, reportGroupId: this.currentReportGroup.id });

          this.showSuccessToast('Order successfully updated.');
          await this.$store.dispatch('schedule/getScheduleActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
        } catch (e) {
          // NOTE(Dan): Undo the move as we failed to submit the changes.
          reorder(source, newOrder, currentOrder);

          this.showErrorToast(`Could not update order for '${source.name}'. Please try again.`);
        }
      },
    };
  }

  public get primaryTooltip(): string {
    if (this.current === null) {
      return 'Make primary';
    }

    return `Make primary ${this.current.entitySubType.toLowerCase()}`;
  }

  private disableSubEntitySwitch(): boolean {
    if (this.current === null) {
      return true;
    }

    if (!this.current.canScheduleReports) {
      return true;
    }

    if (this.entitySchedule === null) {
      return true;
    }

    if (!this.current.hasSubEntities) {
      return true;
    }

    const primary = this.subEntities.find((s) => s.active !== null) || null;

    if (primary == null) {
      return true;
    }

    return !primary.active;
  }

  private applyTableRowClasses(item: EntityScheduleEntry | null | undefined, type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy') {
    if (type !== 'row') {
      return {};
    }

    if (item === null || item === undefined) {
      return {};
    }

    /*
     * NOTE(Dan): If there are sub entities and the primary entity is 'active' (false) then the sub entities should also have the 'replaced' class added.
     *            If there are sub entities which are 'subActive' (false) then the row should have the 'replaced' class added.
     *            If the row is 'active' (false) then the row should have the 'replaced' class added.
     */

    const hasSubEntities = this.current!.hasSubEntities;

    if (hasSubEntities) {
      const primary = this.subEntities.find((e) => e.active !== null) || null;

      if (item === primary) {
        return { replaced: primary.active === false, 'non-dragable': item.active !== null };
      }

      if (primary !== null && !primary.active) {
        return { replaced: !primary.active, 'non-dragable': item.active !== null };
      }

      return { replaced: !item.subActive, 'non-dragable': item.active !== null };
    }

    return { replaced: !item.active, 'non-dragable': item.active !== null };
  }

  private async onActiveChanged(item: EntityScheduleEntry): Promise<void> {
    if (!this.current!.canScheduleReports) {
      return;
    }

    let shouldRefreshSchedule: boolean = false;

    if (this.subEntities.every((e) => !e.active) && this.subEntities.every((e) => e.subActive === null)) {
      shouldRefreshSchedule = true;
    }

    try {
      const action = item.active ? 'schedule/enableEntityScheduleAsync' : 'schedule/disableEntityScheduleAsync';
      await this.$store.dispatch(action, { subEntityId: item.id, entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
      this.showSuccessToast(`Schedule for ${item.name} has been ${item.active ? 'enabled' : 'disabled'} `);

      if (shouldRefreshSchedule) {
        await this.$store.dispatch('schedule/getScheduleForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
      }

      await this.$store.dispatch('schedule/getScheduleActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
    } catch (e) {
      item.active = !item.active;
      this.showErrorToast(`Could not ${item.active ? 'enable' : 'disable'} schedule for ${item.name}`);
    }
  }

  private async onSubActiveChanged(item: EntityScheduleEntry): Promise<void> {
    if (!this.current!.canScheduleReports) {
      return;
    }

    try {
      const action = item.subActive ? 'schedule/enableEntityScheduleAsync' : 'schedule/disableEntityScheduleAsync';
      await this.$store.dispatch(action, { subEntityId: item.id, entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
      this.showSuccessToast(`Schedule for ${item.name} has been ${item.subActive ? 'enabled' : 'disabled'} `);

      await this.$store.dispatch('schedule/getScheduleActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
    } catch (e) {
      item.subActive = !item.subActive;
      this.showErrorToast(`Could not ${item.subActive ? 'enable' : 'disable'} schedule for ${item.name}`);
    }
  }

  private async onRegionActiveChanged(item: EntityScheduleEntry, region: string): Promise<void> {
    if (!this.current!.canScheduleReports) {
      return;
    }

    const match = this.regions.find((r) => camelcase(r.name) === region);

    try {
      // NOTE(Dan): This handler @change fires before the models 'item.regions[region]' property is updated.
      const isRegion = lowercase(this.current!.regionOrLanguage) === 'region';
      const action = isRegion
        ? item.regions[region]
          ? 'schedule/enableEntityScheduleForRegionAsync'
          : 'schedule/disableEntityScheduleForRegionAsync'
        : item.regions[region]
          ? 'schedule/enableEntityScheduleForLanguageAsync'
          : 'schedule/disableEntityScheduleForLanguageAsync';
      await this.$store.dispatch(action, { regionId: isRegion ? match!.id : undefined, languageId: isRegion ? undefined : match!.id, subEntityId: item.id, entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
      this.showSuccessToast(`Schedule for ${match!.name} has been ${item.regions[region] ? 'enabled' : 'disabled'} `);

      await this.$store.dispatch('schedule/getScheduleActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
    } catch (e) {
      item.regions[region] = !item.regions[region];
      this.showErrorToast(`Could not ${item.regions[region] ? 'enable' : 'disable'} schedule for ${match!.name}`);
    }
  }

  private async onMakePrimaryClicked(item: EntityScheduleEntry): Promise<void> {
    try {
      this.$store.commit('schedule/setLoading', true);

      await this.$store.dispatch('schedule/makeEntityPrimaryAsync', {
        reportGroupId: this.currentReportGroup.id,
        entityId: this.currentEntity?.id || 0,
        subEntityId: item.id,
      });

      const schedule = this.$store.dispatch('schedule/getScheduleForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
      const activity = this.$store.dispatch('schedule/getScheduleActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });

      await Promise.all([schedule, activity]);

      this.showSuccessToast(`${item.name} has successfully been promoted to the primary ${this.current!.entitySubType.toLowerCase()}`);
    } catch (e) {
      this.showErrorToast(`Could not make '${item.name}' the primary ${this.current!.entitySubType}.`);
    } finally {
      this.$store.commit('schedule/setLoading', false);
    }
  }

  private onRunEntityClicked(): void {
    this.runDialogSelectionMode = 'All';
    this.runDialogScheduleEntries.push(...this.subEntities.filter((e) => e.active));
    this.showRunEntityScheduleDialog = true;
  }

  private onRunSubEntityClicked(item: EntityScheduleEntry): void {
    this.runDialogSelectionMode = 'SingleEntity';
    this.showRunDialog = true;
    this.runDialogScheduleEntries.push({ ...item });
  }

  private onRunRegionClicked(item: EntityScheduleRegion): void {
    this.showRunDialog = true;
    this.runDialogRegion = item;
    this.runDialogSelectionMode = 'SingleRegion';

    const regionKey = camelcase(item.name);
    const matches = this.subEntities.filter((e) => (e.active || e.subActive) && e.regions[regionKey] !== undefined && e.regions[regionKey] !== null && e.regions[regionKey]);
    this.runDialogScheduleEntries.push(...matches);
  }

  private onRunSubEntityForRegionClicked(item: EntityScheduleEntry, region: EntityScheduleRegion): void {
    this.runDialogScheduleEntries.push({ ...item });
    this.runDialogRegion = region;
    this.runDialogSelectionMode = 'Single';
    this.showRunDialog = true;
  }

  private onRunDialogRegionChanged(regions: Array<string>): void {
    if (regions.length !== 1) {
      return;
    }

    if (this.runDialogSelectionMode !== 'SingleRegion') {
      return;
    }

    this.runDialogRegion = this.regions.find((r) => r.name === regions[0]) || null;
    const regionKey = camelcase(regions[0]);
    const matches = this.subEntities.filter((e) => (e.active || e.subActive) && e.regions[regionKey] !== undefined && e.regions[regionKey] !== null && e.regions[regionKey]);

    if (matches.length !== this.runDialogScheduleEntries.length || matches.some((e) => !this.runDialogScheduleEntries.some((s) => s.id === e.id))) {
      this.runDialogScheduleEntries = matches;
    }
  }

  private async onRunDialogSubmitted(result: { entities: Array<EntityScheduleEntry>, regions: Array<EntityScheduleRegion>, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean }): Promise<void> {
    try {
      const parameters = result.entities.flatMap((e) => {
        return result.regions.filter((r) => {
          if (result.entities.length === 1) {
            return true;
          }

          return e.regions[camelcase(r.name)];
        }).map((r) => ({
          reportGroupId: this.currentReportGroup.id,
          entityId: this.currentEntity?.id || 0,
          subEntityId: e.id,
          regionId: lowercase(this.current!.regionOrLanguage) === 'region' ? r.id : undefined,
          languageId: lowercase(this.current!.regionOrLanguage) === 'language' ? r.id : undefined,
          valuationDate: result.valuationDate,
          useApprovalWorkflow: result.useApprovalWorkflow,
          useStandardDistribution: result.useStandardDistribution,
        }));
      });

      const action = lowercase(this.current!.regionOrLanguage) === 'region' ? 'schedule/runEntityScheduleForRegionAsync' : 'schedule/runEntityScheduleForLanguageAsync';
      const requests = parameters.map((p) => this.$store.dispatch(action, p) as Promise<EntityScheduleRunResponse>);

      const results = await Promise.all(requests);

      let message = requests.length === 1 ? '1 report request has been run.' : `${requests.length} report requests have been run.`;
      let variant : 'notice-success' | 'notice-danger' = 'notice-success';
      const h = this.$createElement;

      let content: string | VNode = message;

      if (requests.length === 1) {
        message = results[0]!.message;
        variant = results[0]!.requestCount < 1 ? 'notice-danger' : 'notice-success';

        if (results[0]!.workflowReportRequestId !== null) {
          const id = results[0]!.workflowReportRequestId.toString();
          const workflow = await this.$store.dispatch('workflow/findWorkflowReportAsync', id) as FindWorkflowResponse;

          content = h('div', { class: ['row'] }, [
            h('div', { class: ['toast-body-message', 'col'] }, message),
            h(BLink, { class: ['toast-body-link', 'col-auto'], props: { to: this.buildReportRequestRawLocation(id, workflow) } }, 'view')
          ]);
        }
      }

      this.showToast(content, variant);
      await this.$store.dispatch('schedule/getScheduleActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
    } catch (e) {
      if (e instanceof BadRequest) {
        this.showErrorToast(`${e.errors![0]}`);
      } else {
        let entityName = result.entities[0].name;

        if (result.entities.length > 1) {
          entityName = result.entities.map((e) => e.name).join('\', \'');
        }

        this.showErrorToast(`One or more schedules could not be run for ${result.entities.length > 1 ? 'the following entities: ' : ''}'${entityName}'. Please try again.`);
      }
    }

    this.onRunDialogClose();
  }

  private async onRunEntityDialogSubmitted(result: { entities: Array<EntityScheduleEntry>, regions: Array<EntityScheduleRegion>, valuationDate: string, useApprovalWorkflow: boolean, useStandardDistribution: boolean }): Promise<void> {
    try {
      await this.$store.dispatch('schedule/runEntityScheduleAsync', {
        reportGroupId: this.currentReportGroup.id,
        entityId: this.currentEntity?.id || 0,
        valuationDate: result.valuationDate,
        useApprovalWorkflow: result.useApprovalWorkflow,
        useStandardDistribution: result.useStandardDistribution,
      });

      await this.$store.dispatch('schedule/getScheduleActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
    } catch (e) {
      let entityName = result.entities[0].name;

      if (result.entities.length > 1) {
        entityName = result.entities.map((e) => e.name).join('\', \'');
      }

      this.showErrorToast(`One or more schedules could not be run for ${result.entities.length > 1 ? 'the following entities: ' : ''}'${entityName}'. Please try again.`);
    }

    this.onRunDialogClose();
  }

  private onRunDialogClose(): void {
    this.showRunEntityScheduleDialog = false;
    this.showRunDialog = false;
    this.runDialogRegion = null;
    this.runDialogScheduleEntries.length = 0;
  }

  private canShowRegionRunOption(item: EntityScheduleRegion): boolean {
    const regionKey = camelcase(item.name);
    const matches = this.subEntities.filter((e) => (e.active || e.subActive) && e.regions[regionKey] !== undefined && e.regions[regionKey] !== null && e.regions[regionKey]);

    return matches.length > 0;
  }

  private onRowClicked(item: EntityScheduleEntry, index: number): void {
    if (this.selectedRowIndex === index && this.selectedEntry !== null) {
      this.selectedRowIndex = -1;
      this.$store.commit('schedule/setCurrentEntry', null);
      return;
    }

    this.$store.commit('schedule/setCurrentEntry', item.id);
    this.selectedRowIndex = index;
  }

  private generateSubEntitySwitchTooltip(item: EntityScheduleEntry, field: BvTableField & { key: string }): string {
    const active = item.active || item.subActive;

    if (active) {
      return `Disable ${item.name}`;
    }

    return `Enable ${item.name}`;
  }

  private generateRegionSwitchTooltip(item: EntityScheduleEntry, field: BvTableField & { key: string }): string {
    const active = item.regions[field.key.replace('regions.', '')];

    if (active) {
      return `Disable ${item.name} ${field.label} ${this.currentReportGroup.displayName.singular}`;
    }

    return `Enable ${item.name} ${field.label} ${this.currentReportGroup.displayName.singular}`;
  }

  private generateRunSubEntityTooltip(item: EntityScheduleEntry, field: BvTableField & { key: string }): string {
    return `Run all active ${item.name} ${this.currentReportGroup.displayName.plural}`;
  }

  private generateRunRegionTooltip(field: BvTableField & { key: string }): string {
    return `Run all active ${field.label} ${this.currentReportGroup.displayName.plural}`;
  }

  private generateRunRegionForSubEntityTooltip(item: EntityScheduleEntry, field: BvTableField & { key: string }): string {
    return `Run ${item.name} ${field.label} ${this.currentReportGroup.displayName.singular}`;
  }

  private generateRunEntityTooltip(): string {
    return `Run all active ${this.currentEntity?.name} ${this.currentReportGroup.displayName.plural}`;
  }

  private buildReportRequestHref(id: string, workflow: FindWorkflowResponse): string | null {
    if (id === null) {
      return null;
    }

    const reportGroupName = this.currentReportGroup.name;
    const d = kebabcase(date(workflow.displayDate, workflow.dateFormat));
    const option = kebabcase(workflow.state);

    return `/${kebabcase(this.currentTenant.name)}/workflow/${kebabcase(reportGroupName)}/${d}/${option}/${id}`;
  }

  private buildReportRequestRawLocation(id: string, workflow: FindWorkflowResponse): Location | null {
    if (id === null) {
      return null;
    }

    const reportGroupName = this.currentReportGroup.name;
    const d = kebabcase(date(workflow.displayDate, workflow.dateFormat));
    const option = kebabcase(workflow.state);
    const reportGroup = kebabcase(reportGroupName);

    return { name: 'workflow', params: { ...this.$route.params, reportGroup, valuationDate: d, option, reportId: id } };
  }
}
</script>

import { render, staticRenderFns } from "./fix-translation-issue-dialog.vue?vue&type=template&id=7acc7801"
import script from "./fix-translation-issue-dialog.vue?vue&type=script&lang=ts"
export * from "./fix-translation-issue-dialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
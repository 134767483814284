<template>
  <div class="forbidden d-flex align-items-center justify-content-center mt-5">
    <div class="col-1 text-center">
      <p>
        <svg style="width: 70px; height: 70px; color: #222F62" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
        <br />
        <small>Status Code: 503</small>
      </p>
    </div>
    <div class="col-4">
      <h3>In progress</h3>
      <p>Account setup is in progress, please try again later.</p>
      <p><router-link class="btn btn-primary" :to="{ name: 'home' }">Back to home</router-link></p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SetupRequired extends Vue {
}
</script>

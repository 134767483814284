import { Api } from '@/api/api';
import { DataResponse } from './responses/data/data-response';
import { DataIssuePageResponse } from './responses/data/data-issues-page-response';
import { TranslationIssuePageResponse } from './responses/data/translation-issues-page-response';
import { DataLoadPageResponse } from './responses/data/data-load-page-response';
import { DataFilesResponse } from './responses/data/data-files-response';
import axios, { CancelTokenSource } from 'axios';
import { DataLoadResponse } from './responses/data/data-load-response';
import { FoundDataLoadResponse } from './responses/data/found-data-load-response';
import { DataUploadFileResponse } from './responses/data/data-upload-file-response';

export class DataApi extends Api {
  private pageCancellationToken: CancelTokenSource | null = axios.CancelToken.source();

  public constructor() {
    super();
  }

  public getDataForReportGroupAsync(reportGroupId: number): Promise<DataResponse> {
    return this.getAsync<DataResponse>(`v1/data/${reportGroupId}`);
  }

  public getDataIssuesAsync(reportGroupId: number, valuationDate: string | Date, page: number, pageSize: number): Promise<DataIssuePageResponse> {
    return this.getAsync<DataIssuePageResponse>(`v1/data/${reportGroupId}/dataissue`, { valuationDate, page, pageSize }/*, this.pageCancellationToken.token */);
  }

  public getTranslationIssuesAsync(reportGroupId: number, valuationDate: string | Date, page: number, pageSize: number): Promise<TranslationIssuePageResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<TranslationIssuePageResponse>(`v1/data/${reportGroupId}/translationissue`, { valuationDate, page, pageSize }, this.pageCancellationToken.token);
  }

  public getDataFilesAsync(reportGroupId: number, valuationDate: string | Date): Promise<DataFilesResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<DataFilesResponse>(`v1/data/${reportGroupId}/datafile`, { valuationDate }, this.pageCancellationToken.token);
  }

  public getDataLoadsAsync(reportGroupId: number, valuationDate: string | Date, page: number, pageSize: number): Promise<DataLoadPageResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<DataLoadPageResponse>(`v1/data/${reportGroupId}/dataload`, { valuationDate, page, pageSize }, this.pageCancellationToken.token);
  }

  public getDataLoadAsync(dataLoadId: number, reportGroupId: number): Promise<DataLoadResponse> {
    return this.getAsync<DataLoadResponse>(`v1/data/${reportGroupId}/dataload/${dataLoadId}`);
  }

  public findDataLoadAsync(dataLoadId: number, reportGroupId: number | null, valuationDate: string | null): Promise<FoundDataLoadResponse> {
    return this.getAsync<FoundDataLoadResponse>(`v1/data/dataload/${dataLoadId}`, { reportGroupId, valuationDate });
  }

  public downloadDataLoadAsync(dataLoadId: number, reportGroupId: number): Promise<Blob> {
    return this.getFileAsync(`v1/data/${reportGroupId}/dataload/${dataLoadId}/download`);
  }

  public uploadDataLoadAsync(files: Array<File>, reportGroupId: number): Promise<DataUploadFileResponse> {
    return this.postFilesAsync<DataUploadFileResponse>(`v1/data/${reportGroupId}/dataload/upload`, files);
  }

  public reloadDataLoadAsync(dataLoadId: number, reportGroupId: number): Promise<void> {
    return this.postAsync<void>(`v1/data/${reportGroupId}/dataload/${dataLoadId}/reload`, null);
  }

  public ignoreDataIssueAsync(issueId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/data/${reportGroupId}/dataissue/${issueId}/ignore`, { comments: comments });
  }

  public unignoreDataIssueAsync(issueId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/data/${reportGroupId}/dataissue/${issueId}/unignore`, { comments: comments });
  }

  public recheckDataIssuesAsync(reportGroupId: number, valuationDate: string | Date, comments: string, resetIgnoredIssues: boolean): Promise<void> {
    return this.postAsync<void>(`v1/data/${reportGroupId}/dataissue/recheck`, { valuationDate: valuationDate, comments: comments, resetIgnoredIssues: resetIgnoredIssues });
  }

  public replaceDataFileReminderDetailsAsync(dataFileId: number, businessDay: number | null, email: string | null, reportGroupId: number): Promise<void> {
    return this.putAsync<void>(`v1/data/${reportGroupId}/datafile/${dataFileId}/reminder`, { businessDay, email });
  }

  public makeDataFileMandatoryForReportGroupAsync(dataFileId: number, reportGroupId: number): Promise<void> {
    return this.postAsync<void>(`v1/data/${reportGroupId}/datafile/${dataFileId}/mandatory`, {});
  }

  public makeDataFileOptionalForReportGroupAsync(dataFileId: number, reportGroupId: number): Promise<void> {
    return this.deleteAsync<void>(`v1/data/${reportGroupId}/datafile/${dataFileId}/mandatory`, {});
  }
}

import { RunEntityScheduleRequested } from '../events/run-entity-schedule-requested';
import { BToast } from 'bootstrap-vue';

async function handleRunEntityScheduleRequested(data: RunEntityScheduleRequested): Promise<void> {
  /* eslint-disable */
  console.log(`RunEntityScheduleRequested event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */

  const message = data.payload.requestMessage.replace('\r\nClick here to go to new Request', '');

  const bootStrapToaster = new BToast();
  bootStrapToaster.$bvToast.toast(message,
    {
      // title: `Error`,
      autoHideDelay: 3000,
      solid: true,
      // noAutoHide: true,
      noCloseButton: true,
      variant: 'notice-success',
      toaster: 'b-toaster-top-center',
      toastClass: 'white-space-wrap',
      appendToast: true,
    });
}

export default handleRunEntityScheduleRequested;

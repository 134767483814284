<template>
  <div class="schedule-filter-bar row">
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">Report group:</label>
        <div class="col-auto">
          <b-form-select v-model="selectedReportGroup" @change="changeReportGroup(reportGroup)">
            <b-form-select-option :value="group" v-for="group in reportGroups" :key="group.id">{{ group.name }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <!-- <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 px-0">Compare period:</label>
        <div class="col-auto">
          <valuation-date-from-selector />
        </div>
      </div>
    </div>
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 px-0">to:</label>
        <div class="col-auto">
          <valuation-date-to-selector />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { kebabcase } from '@/utilities/text.utils';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';
import { TenantGroupResponse } from '@/api/responses/tenant/tenant-group-response';
import { Route } from 'vue-router';
import { Tenant, ReportGroup } from '@/store/tenant/state';
import Summary from '@/views/Summary.vue';
import ValuationDateFromSelector from './filter-bar-selectors/valuation-date-from-selector.vue';
import ValuationDateToSelector from './filter-bar-selectors/valuation-date-to-selector.vue';

const tenantModule = namespace('tenant');
const summaryModule = namespace('summary');

@Component({
  components: {
    ValuationDateFromSelector,
    ValuationDateToSelector,
  },
})
export default class SummaryFilterBar extends Vue {
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @tenantModule.Getter private tenants!: Array<Tenant>;
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;
  @summaryModule.Getter private current!: Summary | null;

  private reportGroup: TenantGroupResponse | null = null;

  private get selectedReportGroup(): ReportGroup {
    return this.currentReportGroup;
  }

  private set selectedReportGroup(value: ReportGroup) {
    this.changeReportGroup(value);
  }

  public get reportGroups(): Array<TenantGroupResponse> {
    return this.currentTenant.reportGroups.filter((rg) => rg.visible.all);
  }

  public async mounted(): Promise<void> {
    const { reportGroup } = this.$route.params;
    const setInitialState = async () => {
      const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.all) || this.reportGroups[0] || null;
      if (matchingReportGroup !== null) {
        const groupName = kebabcase(matchingReportGroup.name);
        if (groupName !== reportGroup) {
          try {
            await this.$router.replace({ name: 'summary', params: { reportGroup: groupName } });
          } catch (e) {
          }
        }
        await this.setCurrentReportGroup(matchingReportGroup);
      }
    };

    if ((this.current === null && this.currentReportGroup !== null) || (this.current !== null && this.currentReportGroup !== null)) {
      await this.$store.dispatch('summary/getSummaryForReportGroupAsync', this.currentReportGroup!.id);
    }

    await setInitialState();
    this.reportGroup = this.currentReportGroup;
  }

  @Watch('$route')
  private async onRouteChanged(to: Route, from: Route) : Promise<void> {
    // NOTE(Dan): Don't destructure this below as we still need to keep this in the "params" object.
    const reportGroup = to.params.reportGroup;
    const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.all) || this.reportGroups[0] || null;
    const { ...params } = to.params;
    let updateParams: boolean = false;

    // NOTE(Dan): We should probably compare the previous params to the new ones before trying to find matches.
    //            This will us to prevent setting off watchers on the report group and valuation date uneccessarily.
    //            For example, currently changing a tab on the workflow-steps changes the URL, but only the option.
    //            So we will end up needlessly triggering watchers in this case.
    //            We should probably also bail if there is no params diff either in this case.
    if (from?.params.reportGroup === reportGroup) {
      if (matchingReportGroup?.id === this.currentReportGroup.id) {
        return;
      }
    }

    if (matchingReportGroup !== null) {
      if (kebabcase(matchingReportGroup.name) !== reportGroup) {
        updateParams = true;
        params.reportGroup = kebabcase(matchingReportGroup.name);
      }
      await this.setCurrentReportGroup(matchingReportGroup);
    }

    if (updateParams) {
      try {
        await this.$router.replace({ name: 'summary', params: { ...to.params, ...params } });
      } catch (e) {
        // Navigation cancelled could happen if many watchers are reacting to changes.
      }
    }
  }

  @Watch('currentTenant')
  private async onTenantChanged(tenant: TenantResponse): Promise<void> {
    const { reportGroup } = this.$route.params;
    const matchingReportGroup = this.reportGroups.find((group) => kebabcase(group.name) === reportGroup) || this.reportGroups.find((group) => group.active.all) || this.reportGroups[0] || null;

    if (matchingReportGroup !== null) {
      if (matchingReportGroup.name !== this.currentReportGroup?.name) {
        await this.changeReportGroup(matchingReportGroup);
      } else if (matchingReportGroup.id !== this.currentReportGroup?.id) {
        await this.setCurrentReportGroup(matchingReportGroup);
      } else {
        this.reportGroup = matchingReportGroup;
        await this.$store.dispatch('summary/getSummaryForReportGroupAsync', this.currentReportGroup.id);
      }
    }
  }

  private async changeReportGroup(group: TenantGroupResponse): Promise<void> {
    if (this.$route.params.reportGroup === undefined || this.$route.params.reportGroup !== kebabcase(group.name)) {
      const { reportId, reportGroup, valuationDate, option, documentId, ...params } = this.$route.params;
      await this.$router.push({ name: 'summary', params: { ...params, reportGroup: kebabcase(group.name) } });
    }
  }

  private async setCurrentReportGroup(reportGroup: TenantGroupResponse): Promise<void> {
    const previous = { ...this.currentReportGroup! };
    this.reportGroup = reportGroup;
    this.$store.commit('tenant/setReportGroup', reportGroup);

    await this.$store.dispatch('tryApplyReportGroup', reportGroup);

    if (previous?.id !== reportGroup.id) {
      await this.$store.dispatch('tenant/setCurrentReportGroupAsync', { id: reportGroup!.id, name: reportGroup!.name, tenantId: this.currentTenant.id, tenantName: this.currentTenant.name });
      await this.$store.dispatch('summary/getSummaryForReportGroupAsync', reportGroup!.id);
    }
  }
}
</script>

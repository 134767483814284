import { WorkflowReportRenderQueued } from '../events/workflow-report-render-queued';
import store from '@/store';

async function handleWorkflowReportRenderQueued(data: WorkflowReportRenderQueued): Promise<void> {
  /* eslint-disable */
  console.log(`WorkflowReportRenderQueued event received (${data.payload.aggregateId}): `, JSON.stringify(data));
  /* eslint-enable */
  const reportId = Number(data.payload.aggregateId.split('-')[1]);

  await store.dispatch('workflow/tryUpdateReportProperties', {
    renderingStatus: data.payload.status || 'RenderWaiting',
    reportId: reportId,
    tooltips: {
      renderingStatus: data.payload.tooltip,
    },
  });
}

export default handleWorkflowReportRenderQueued;

<template>
  <div class="row align-items-center">
    <label class="col-auto mb-0 pr-0">Valuation date:</label>
    <div class="col-auto">
      <b-form-select v-model="selectedValuationDate">
        <b-form-select-option v-for="date in valuationDates" :key="date.valuationDate" :value="date">{{ date.displayDate | valuationdate(current.dateFormat) }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Dashboard, DashboardValuationDate } from '@/store/dashboard/state';

const dashboardModule = namespace('dashboard');

@Component
export default class DashboardFilterBarValuationDateSelector extends Vue {
  @dashboardModule.Getter private current!: Dashboard;
  @dashboardModule.Getter private valuationDates!: Array<DashboardValuationDate>;
  @dashboardModule.Getter private currentValuationDate!: DashboardValuationDate | null;

  private get selectedValuationDate(): DashboardValuationDate | null {
    return this.currentValuationDate;
  }

  private set selectedValuationDate(value: DashboardValuationDate | null) {
    this.$store.commit('dashboard/setCurrentValuationDate', value);
  }
}
</script>
